import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

import Button from 'components/Button.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import SalonModal from 'components/SalonModal';
import useTip from 'hooks/useTip.hook';
import fromAnyToString from 'utils/converters/fromAnyToString';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';

import CN from './PaymentStatus.module.scss';

const L = logger('PaymentStatus');

const PaymentStatus = ({ clientSecret, salonId, history, stopAfterSubmitLoading, appointmentId, obj }) => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const { val: renderModalSuccess, yay: showModalSuccess, nay: closeModalSuccess } = useTip(false);

  const { location } = asObject(history);
  const pathname = fromAnyToString(asObject(location).pathname);

  useEffect(() => {
    if (!stripe) return;

    stripe.retrieveSetupIntent(clientSecret).then(result => {
      // Inspect the SetupIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

      const { setupIntent } = asObject(result);
      const { status } = asObject(setupIntent);

      const isInAddNewCard = pathname.indexOf('/add-new-card') >= 0;
      const isInMyProfile = pathname.indexOf('/my-profile') >= 0;
      const isInPayDepositAddNewCard = pathname.indexOf('/pay-deposit-add-new-card') >= 0;

      L.debug('useEffect()', status, { isInAddNewCard, isInMyProfile, isInPayDepositAddNewCard });

      if (status === 'succeeded') {
        setMessage('Success! Your payment method has been saved.');

        if (isInAddNewCard) {
          showModalSuccess();
          setTimeout(() => {
            stopAfterSubmitLoading();
            history.push({
              pathname: `/salon/${salonId}/confirm-with-card`,
              state: { saveCardSuccess: true },
            });
          }, 2000);
        } else if (isInMyProfile) {
          showModalSuccess();
          stopAfterSubmitLoading();
        } else if (isInPayDepositAddNewCard) {
          showModalSuccess();
          setTimeout(() => {
            stopAfterSubmitLoading();
            history.push({
              pathname: `/salon/${salonId}/pay-deposit/${appointmentId}`,
              state: { saveCardSuccess: true },
            });
          }, 2000);
        }
      } else if (status === 'processing') {
        setMessage("Processing payment details. We'll update you when processing is complete.");
      } else if (status === 'requires_payment_method') {
        // Redirect your user back to your payment page to attempt collecting payment again
        setMessage('Failed to process payment details. Please try another payment method.');
      } else {
        setMessage('default');
      }
    });
  }, [obj, appointmentId, clientSecret, history, salonId, stopAfterSubmitLoading, stripe, pathname, showModalSuccess]);

  return (
    <div>
      {message}
      {renderModalSuccess && (
        <SalonModal tag="PaymentStatus__modal-card-updated" isOpen>
          <SalonModal.Header>
            <div className={CN.closeButtonContainer}>
              <Button variant="empty" onClick={closeModalSuccess}>
                <Icon variant="close" variantInvert />
              </Button>
            </div>
          </SalonModal.Header>
          <SalonModal.Body>
            <IconStatus variant="success">
              <Icon variant="confirm" width="xl" />
            </IconStatus>

            <div className={CN.modalContent}>
              <div className={CN.title}>Card details updated!</div>
            </div>
          </SalonModal.Body>
          <SalonModal.Footer>
            <Button variant="primary" onClick={closeModalSuccess}>
              OK, CLOSE
            </Button>
          </SalonModal.Footer>
        </SalonModal>
      )}
    </div>
  );
};

export default PaymentStatus;
