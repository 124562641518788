/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable react/no-danger */
// @flow
/* eslint-disable object-curly-newline */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable comma-dangle */
import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import cn from 'classnames';
import DisclaimerText from 'invite/components/DisclaimerText.component';
import moment from 'moment';

import Button from 'components/Button.component';
import FoldOut from 'components/FoldOut.component';
import { withTheme } from 'providers/Theme.provider';

import { clearBillingInfo, clearIntentClientSecret, CreateToken, setupIntent } from '../../actions/billing';
import {
  clearUserCardDetails,
  getAppointment,
  getClientInvites,
  processInvite,
  setPageVisited,
} from '../../booking/actions';
import HeaderComponent from '../../booking/common/Header';
import AddNewCard from '../../booking/confirmBooking/AddNewCard';
import { NCOB_STRIPE_KEY } from '../../config';
import type { SalonDetails } from '../../types/salonDetails';
import type { Service } from '../../types/service';
import type { Stylist } from '../../types/stylist';

import CN from './InviteCardDetail.module.scss';

type State = {
  errorMessage: string,
  paymentError: boolean,
  invite: any,
};
type StateProps = {
  salon: any,
  billing: any,
  cognito: any,
  booking: any,
  errorMessage: '',
  no_show_protection: boolean,
  ncob_deposit_settings: string,
  salon_ncob_text: string,
  salonDetails: SalonDetails,
  selectedServices: Service[],
  selectedPatchTime: moment | null,
  selectedAppointmentTime: moment,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  bookingResponse: any,
  errorMessage: any,
  totalPrice: number,
  notes: string,
  username: string,
  cardDetails: {
    last4: string,
    exp_month: string,
    exp_year: string,
  },
  CreateToken: (token: any) => void,
  clearBillingInfo: () => void,
  setPageVisited: (page: string) => void,
  stripeToken: any,
  clearUserCardDetails: () => void,
  clearIntentClientSecret: () => void,
  getClientInvites: (username: string) => void,
  processInvite: (cognitoUsername: string, paymentIntentId: string, inviteId: string, data: any) => void,
  getAppointment: (salonId: number, appointmentId: number) => void,
  setupIntent: (salonId: number, username: string) => void,
  errorMessage: string,
  phone_number: string,
  email: string,
  isFetching: boolean,
  invites: [],
  invitePostData: any,
  fetchedAppointmentObj: any,
};
type OwnProps = {
  history: string[],
  match: {
    params: { id: number, appointmentId: number, uniqLink: string },
    url: string,
  },
  public_key: string,
  bookingError: any,
};
type Props = StateProps & OwnProps;

export class InviteCardDetailWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: '',
      paymentError: false,
      invite: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setPageVisited('pay-deposit');
  }
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.invites.length > 0) {
      const invite = nextProps.invites.filter(i => i.unique_code === this.props.match.params.uniqLink)[0];
      this.setState({ invite });
    }
  }

  setToken = (token: any) => {
    this.props.CreateToken(token);
  };

  setPaymentError = (trueOrFalse: boolean) => {
    this.setState({ paymentError: trueOrFalse });
  };

  isEmpty = (obj: any) => {
    const keys = Object.keys(obj);

    if (keys.length > 0) {
      return false;
    }
    return true;
  };

  hideError = () => {
    this.setState({ errorMessage: '' });
  };

  startAgain = () => {
    this.props.history.push(`/salon/${this.props.match.params.id}`);
  };

  render() {
    let { username } = this.props;
    const { salonDetails, isDarkMode, isNeutralMode } = this.props;

    if (!username) {
      if (this.props.cognito && Object.keys(this.props.cognito.attributes).length) {
        [username] = this.props.cognito.attributes.name.split(' ');
      }
    }
    let welcomeMessage;

    welcomeMessage = (
      <div>
        <div>
          <div className={CN.title}>Please enter your card details.</div>
          <div className={CN.small}>Then confirm your booking on the next page.</div>
        </div>
      </div>
    );
    if (this.state.paymentError) {
      welcomeMessage = (
        <div>
          <div className={CN.title}>Card setup failed</div>
          <div className={CN.small}>Please try again</div>
        </div>
      );
    }
    let anyError;
    let bookingError;
    if (this.state.errorMessage) {
      anyError = 'We are having problems creating your booking, please try again.';
    }

    return (
      <div
        data-bem="InviteCardDetail"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          <HeaderComponent history={this.props.history} />
          {welcomeMessage}
          {bookingError && <span className={CN.error}>{bookingError}</span>}
          {bookingError && (
            <Button variant="primary" width="fixed" onClick={this.startAgain}>
              START AGAIN
            </Button>
          )}
          <div className="form-wrapper has-hight-footer">
            <div className="booking-info">
              {!this.state.paymentError && (
                <p className="text-sb">
                  Your card details will be stored securely and anonymously on your account. Once your card is added,
                  you can proceed to pay the deposit for your appointment, or decline the request.
                </p>
              )}
            </div>
            {NCOB_STRIPE_KEY && (
              <StripeProvider apiKey={NCOB_STRIPE_KEY}>
                <Elements>
                  <AddNewCard
                    salonName={
                      this.props.salonDetails && this.props.salonDetails.name
                        ? this.props.salonDetails.name
                        : 'Salon name'
                    }
                    salonPhone={
                      this.props.salonDetails && this.props.salonDetails.phone
                        ? this.props.salonDetails.phone
                        : 'Salon phone number'
                    }
                    setToken={this.setToken}
                    stripeToken={this.props.stripeToken}
                    isEmpty={this.isEmpty}
                    salonId={this.props.match.params.id}
                    appointmentId={this.props.match.params.appointmentId}
                    invite={this.state.invite}
                    history={this.props.history}
                    cardDetails={this.props.cardDetails}
                    selectedAppointmentTime={this.props.selectedAppointmentTime}
                    selectedStylists={this.props.selectedStylists}
                    selectedServices={this.props.selectedServices}
                    salonDetails={this.props.salonDetails}
                    username={this.props.cognito.user ? this.props.cognito.user.username : ''}
                    billing={this.props.billing}
                    bookingResponse={this.props.bookingResponse}
                    bookingError={this.props.bookingError}
                    clearUserCardDetails={this.props.clearUserCardDetails}
                    clearBillingInfo={this.props.clearBillingInfo}
                    setPaymentError={this.setPaymentError}
                    isFetching={this.props.isFetching}
                    pageVisited={this.props.booking.pageVisited}
                    processInvite={this.props.processInvite}
                    invitePostData={this.props.invitePostData}
                    setupIntent={this.props.setupIntent}
                    clearIntentClientSecret={this.props.clearIntentClientSecret}
                  />
                </Elements>
              </StripeProvider>
            )}

            <DisclaimerText />

            {!this.props.isWaitList && (
              <div className={CN.policyContainer} data-bem="InviteCardDetail__foldOut">
                <FoldOut title="Cancellation Policy" icon="policy">
                  <div className={CN.policyText} data-bem="InviteCardDetail__policy--text">
                    {salonDetails.salon_ncob_text}
                  </div>
                </FoldOut>
              </div>
            )}

            {this.props.bookingError.indexOf('Not found') < 0 && <div className={CN.error}>{anyError}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state: any): any => ({
  salonDetails: state.booking.salonDetails,
  selectedServices: state.booking.selectedServices,
  selectedPatchTime: state.booking.selectedPatchTime,
  selectedAppointmentTime: state.booking.selectedAppointmentTime,
  notes: state.booking.notes,
  totalPrice: state.booking.totalPrice,
  selectedStylists: state.booking.selectedStylists,
  bookingResponse: state.booking.bookingResponse,
  bookingError: state.booking.errorMessage,
  isFetching: state.booking.isFetching,
  username: state.billing.username,
  booking: state.booking,
  cardDetails: state.booking.cardDetails,
  stripeToken: Object.keys(state.billing.stripeToken).length ? state.billing.stripeToken : {},
  cognito: state.cognito,
  billing: state.billing,
  ncob_deposit_settings: state.booking.salonDetails.ncob_deposit_settings,
  no_show_protection: state.booking.salonDetails.no_show_protection,
  salon_ncob_text: state.booking.salonDetails.salon_ncob_text,
  invites: state.booking.invites,
  invitePostData: state.booking.invitePostData,
  fetchedAppointmentObj: state.booking.fetchedAppointmentObj,
});

export function mapDispatchToProps(dispatch: any) {
  return {
    CreateToken(token: any) {
      dispatch(CreateToken(token));
    },
    clearUserCardDetails() {
      dispatch(clearUserCardDetails());
    },
    clearBillingInfo() {
      dispatch(clearBillingInfo());
    },
    setPageVisited(page: string) {
      dispatch(setPageVisited(page));
    },
    processInvite(cognitoUsername: string, inviteId: string, data: any) {
      dispatch(processInvite(cognitoUsername, inviteId, data));
    },
    getClientInvites(username: string) {
      dispatch(getClientInvites(username));
    },
    getAppointment(salonId: number, appointmentId: number) {
      dispatch(getAppointment(salonId, appointmentId));
    },
    setupIntent(salonId: number, username: string) {
      dispatch(setupIntent(salonId, username));
    },
    clearIntentClientSecret() {
      dispatch(clearIntentClientSecret());
    },
  };
}
// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(InviteCardDetailWrap));
