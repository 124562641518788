import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import cn from 'classnames';
import FailedPayment from 'invite/components/FailedPayment.component';

import LOGO_AMEX from 'assets/images/amex.svg';
import LOGO_MAESTRO from 'assets/images/maestro.svg';
import LOGO_MASTER from 'assets/images/mastercard.svg';
import LOGO_VISA from 'assets/images/visa.svg';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import { NCOB_STRIPE_KEY } from 'config/index';
import CARD from 'constants/card.const';
import { withTheme } from 'providers/Theme.provider';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';

import { deleteCard } from 'booking/actions';

import CN from './PayInviteCard.module.scss';

const L = logger('PayInviteCard');

const ERROR_INVALID = 'Your card number is invalid';
const ERROR_INCOMPLETE = 'Your card number is incomplete';

const CARD_ERRORS = Object.freeze([
  'Your card has insufficient funds',
  'Your card has been declined',
  'Your card was declined',
  'Your card has expired',
  "Your card's security code is incorrect",
  'An error occurred while processing your card',
]);

class PayInviteCard extends React.Component {
  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.cardElement = React.createRef();
    this.expiryElement = React.createRef();
    this.cvcElement = React.createRef();
    this.state = {
      showCardSubmit: false,
      errorMessage: '',
      paymentError: '',
      cardError: '',
      isLoading: false,
      helpMessage: '',
      xtraAuthenticationDone: false,
      confirmAuthenticationSend: false,
      stripe: window.Stripe(NCOB_STRIPE_KEY),
    };
  }

  componentDidMount = () => {
    this.props.setInvitePostData('');
  };

  checkInvitePostStatus = interval => {
    const { invitePostData, salonId, history } = this.props;
    const pathname = `/salon/${salonId}/my-feeds`;

    if (invitePostData === 'SUCCESS') {
      this.setState({ isLoading: false });
      if (this.props.invitePostStatus === 'requires_capture') {
        clearInterval(interval);
        history.push(pathname);
      } else if (this.props.billing.invite_status === 'requires_action' && !this.state.xtraAuthenticationDone) {
        this.setState({ xtraAuthenticationDone: true }, () => {
          this.state.stripe
            .confirmCardPayment(this.props.billing.intent_client_secret, {
              payment_method: this.props.billing.payment_method,
            })
            .then(result => {
              if (result.error) {
                clearInterval(interval);
                this.setState({ errorMessage: result.error.message });
                this.props.setInvitePostData('FAILED');
              } else if (result.paymentIntent.status === 'requires_capture') {
                if (!this.state.confirmAuthenticationSend) {
                  this.setState({ isLoading: true, confirmAuthenticationSend: true }, () => {
                    this.props.confirmXAuthentication(
                      salonId,
                      this.props.invite.appointment.id,
                      this.props.billing.payment_intent_id,
                      this.props.username,
                    );
                  });
                }
                this.props.setInvitePostData('SUCCESS');
                clearInterval(interval);

                history.push(pathname);
              }
            });
        });
      }
    } else if (invitePostData === 'FAILED') {
      clearInterval(interval);
      this.setState({ isLoading: false });
    }
    if (this.props.confirmAuthenticationSuccess && this.props.confirmAuthenticationStatus === 'succeeded') {
      clearInterval(interval);
      this.setState({ isLoading: false });
      history.push(pathname);
    }
  };

  btnClick = paymentIntentId => {
    const { invite, username, processInvite } = this.props;

    this.setState({ isLoading: true });

    const { payFullAmount, id: inviteId, appointment } = asObject(invite);
    const { id: appointmentId, price } = asObject(appointment);

    const data = {
      accepted: 'True',
      payment_intent: paymentIntentId,
      paid_full_amount: payFullAmount ? price : void 1,
      invite_id: inviteId,
      cognito_username: username,
      appointment_id: appointmentId,
    };

    L.debug('btnClick()', { username, data });
    L.unignore('btnClick()', 'processInvite:', processInvite(username, inviteId, data));
  };

  handleSubmit = async ev => {
    const { disableSubmit } = this.state;
    const { invite, billing } = this.props;

    if (disableSubmit) return;
    if (document.querySelector('.btn.btn-primary').classList.contains('disabled')) return;

    const { requested_deposit: requestedDeposit } = asObject(invite);
    const { payment_intent_id: paymentIntentId } = asObject(billing);

    ev.preventDefault();

    this.setState({ paymentError: '', cardError: '', errorMessage: '', disableSubmit: true, isLoading: true });
    this.btnClick(requestedDeposit ? paymentIntentId : 'None');

    const interval = setInterval(() => void this.checkInvitePostStatus(interval), 500);

    this.setState({ disableSubmit: true });
  };

  deleteCard = async (username, payMethodId) => {
    try {
      await this.props.deleteCard(username, payMethodId);
      L.debug('Card deleted successfully');
    } catch (error) {
      L.error('Error deleting card:', error);
      this.setState({ errorMessage: 'Failed to remove the existing card. Please try again.' });
    }
  };

  addOrUpdateCard = async () => {
    const { history, invite, salonId, clearBillingInfo, clearUserCardDetails, cardDetails, username } = this.props;
    const {
      requested_deposit: requestedDeposit,
      requested_card_only: requestedCardOnly,
      payFullAmount,
      appointment,
    } = asObject(invite);

    if (cardDetails && cardDetails.id) {
      try {
        await this.deleteCard(username, cardDetails.id);
      } catch (error) {
        // this.setState({ errorMessage: 'Failed to remove the existing card. Please try again.' });
        // return;
        L.error('Error deleting card:', error);
        throw new Error('Failed to remove the existing card');
      }
    }

    clearBillingInfo(true);
    clearUserCardDetails();

    this.props.setPaymentError(false);

    const { id: appointmentId, price: appointmentPrice } = asObject(appointment);
    const state = {
      from: 'invite',
      appointmentId,
      appointmentPrice,
      requested_card_only: requestedCardOnly,
      requested_deposit: requestedDeposit,
      payFullAmount,
    };

    const pathname = `/salon/${salonId}/pay-deposit-add-new-card`;
    L.debug('addOrUpdateCard()', 'nav:', { state, pathname });

    history.push({ pathname, state });
  };

  render() {
    const { errorMessage, paymentError, cardError, helpMessage, disableSubmit } = this.state;
    const { cardDetails, setPaymentError, bookingError, isDarkMode, isNeutralMode } = this.props;

    const last4Digits = asObject(cardDetails).last4;
    const cardBrand = asObject(cardDetails).brand;

    const errorMessageExists = errorMessage !== '' || paymentError !== '' || cardError !== '';
    const last4DigitsExists = last4Digits !== '';
    const showCardSubmit = this.state.showCardSubmit || last4DigitsExists;

    const confirmClass = disableSubmit ? 'btn btn-primary disabled' : 'btn btn-primary';

    if (
      errorMessage &&
      errorMessage.indexOf(ERROR_INVALID) < 0 &&
      errorMessage.indexOf(ERROR_INCOMPLETE) < 0 &&
      !paymentError
    ) {
      const renderedErrorMessage = `${errorMessage} 
      Unfortunately the payment was not successful. Please check your details are correct and ensure you have sufficient funds, then try again.`; // eslint-disable-line max-len

      setPaymentError(true);
      this.setState({
        paymentError: renderedErrorMessage,
        isLoading: false,
      });
    } else if (errorMessage && cardError !== errorMessage) {
      let isPaymentError = false;
      for (let i = 0; i < CARD_ERRORS.length; i += 1) {
        if (errorMessage.indexOf(CARD_ERRORS[i]) >= 0) {
          isPaymentError = true;
          break;
        }
      }
      if (!isPaymentError) {
        setPaymentError(true);
        this.setState({ cardError: errorMessage, isLoading: false });
      }
    } else if ((paymentError || cardError) && !errorMessage) {
      this.setState({ cardError: '', paymentError: '' });
    }

    const tryAgain = () => {
      this.setState({ disableSubmit: false, paymentError: '' });
      setPaymentError(false);
      const { salonId, history } = this.props;
      const pathname = `/salon/${salonId}/my-feeds`;

      history.push(pathname);
    };

    // SEE: https://getslick.atlassian.net/browse/GS-13425
    L.debug('render()', 'last4Digits:', last4Digits);

    return (
      <div
        data-bem="PayInviteCard"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        {this.state.isLoading && (
          <div className="spinner-container">
            <div className="load-spinner" />
          </div>
        )}
        {fromAnyToBool(paymentError || cardError) && <p className={CN.error}>{paymentError || cardError}</p>}
        {fromAnyToBool(paymentError) && <p className={CN.error}>{helpMessage}</p>}

        <form data-bem="PaymentInviteCard__card-info-form" className="cardInfo" onSubmit={this.handleSubmit}>
          {last4Digits && (
            <div className="card-number">
              <span className={CN.cardNumberLabel}>Card number</span>
              <div
                className={cn({
                  [CN.input]: true,
                  'card-number-field': true,
                })}
              >
                {`**** **** **** ${last4Digits}`}
                {cardBrand === CARD.visa && <img src={LOGO_VISA} alt="Visa brand logo" className={CN.brandImg} />}
                {cardBrand === CARD.amex && <img src={LOGO_AMEX} alt="AMEX brand logo" className={CN.brandImg} />}
                {cardBrand === CARD.masterCard && (
                  <img src={LOGO_MASTER} alt="MasterCard brand logo" className={CN.brandImg} />
                )}
                {cardBrand === CARD.mastero && (
                  <img src={LOGO_MAESTRO} alt="Mastero brand logo" className={CN.brandImg} />
                )}
              </div>
            </div>
          )}

          <div className={CN.buttonContainer} data-bem="PayInviteCard__update-card-button">
            {last4DigitsExists ? (
              <div>
                {paymentError || cardError ? (
                  <NavLink to={`/salon/${this.props.salonId}/my-profile`}>
                    <span className={CN.linkToProfile}>UPDATE CARD DETAILS</span>
                  </NavLink>
                ) : (
                  <Button variant="tertiary" onClick={this.addOrUpdateCard}>
                    <span className={CN.textUnderline}>UPDATE CARD DETAILS</span>
                  </Button>
                )}
              </div>
            ) : (
              <Button variant="tertiary" width="fixed" onClick={this.addOrUpdateCard}>
                <span className={CN.textUnderline}>ADD A CARD</span>
              </Button>
            )}
          </div>

          {showCardSubmit && (
            <div>
              {errorMessageExists ? (
                <Footer data-bem="PayInviteCard__footer-view-deposit">
                  <Button variant="primary" width="fixed" onClick={tryAgain}>
                    TRY AGAIN
                  </Button>
                </Footer>
              ) : (
                <Footer data-bem="PayInviteCard__footer-pay-deposit-submit">
                  <button className={confirmClass} disabled={errorMessageExists || !last4DigitsExists}>
                    PAY DEPOSIT
                  </button>
                </Footer>
              )}
            </div>
          )}

          {bookingError && <FailedPayment onClose={tryAgain} />}
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteCard: (username, payMethodId) => dispatch(deleteCard(username, payMethodId)),
});

const PayInviteCardHoc = injectStripe(withTheme()(connect(null, mapDispatchToProps)(PayInviteCard)));

export default PayInviteCardHoc;
