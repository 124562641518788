import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import STORE from 'store.singleton';

import Button from 'components/Button.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import SalonModal from 'components/SalonModal';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';

import PaymentStatus from './PaymentStatus';

import CN from './SetupForm.module.scss';

const L = logger('SetupForm');

const SetupForm = ({ history, salonId, setupIntentFrontend, stopLoading, appointmentId, cognito }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [confirmObj, setConfirmObj] = useState(null);
  const [errorModal, setErrorModal] = useState(false);

  const stopAfterSubmitLoading = () => {
    setLoading(false);
  };
  // NOTE: 'pi_' indicating that this clientSecret is associated with a PaymentIntent.
  // because different types of client_secret can be associated with different types such as PaymentIntent or SetupIntent
  const isPaymentIntent = clientSecret => clientSecret && clientSecret.startsWith('pi_');

  const handleSubmit = async event => {
    if (disableSubmit) return;

    setDisableSubmit(true);
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');

    if (!stripe || !elements) return;

    let result;

    try {
      // If clientSecret is associated with PaymentIntent, we use confirmPayment
      if (isPaymentIntent(clientSecret)) {
        result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(PaymentElement),
          },
        });
      } else {
        // If is not PaymentIntent, we use confirmSetup
        result = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: document.location.href,
          },
          redirect: 'if_required',
        });
      }

      const interval = setInterval(() => {
        const { error: resultError, setupIntent, status } = asObject(result);
        const { error: setupIntentError, client_secret: clientSecret } = asObject(setupIntent);

        const {
          setup_intent: setupIntentFromError,
          decline_code: declineCodeFromError,
          code: errorCodeFromError,
        } = asObject(resultError);

        if (result && !resultError && setupIntent) {
          setLoading(false);
          clearInterval(interval);
          setConfirmObj(result);
          STORE.dispatch(setupIntentFrontend(setupIntent));
          setClientSecret(clientSecret);
        } else if (resultError || setupIntentError || status !== 'succeeded') {
          setLoading(false);
          setErrorModal(true);
          clearInterval(interval);

          let errorMessage;
          if (setupIntentFromError || declineCodeFromError || errorCodeFromError) {
            if (declineCodeFromError) {
              switch (declineCodeFromError) {
                case 'insufficient_funds':
                  errorMessage = 'Your card has insufficient funds. Please try again with a different card.';
                  break;
                case 'generic_decline':
                  errorMessage = 'Your card was declined. Please try again with a different card.';
                  break;
                default:
                  errorMessage = 'Your card was declined. Please try again with a different card.';
                  break;
              }
            } else if (errorCodeFromError === 'setup_intent_authentication_failure') {
              errorMessage = 'Please try again and ensure the authentication is completed.';
            } else {
              errorMessage = 'Your card was declined. Please try again with a different card.';
            }
          } else {
            errorMessage = 'Your card was declined. Please try again with a different card.';
          }

          setErrorMessage(errorMessage);
        }
      }, 500);
    } catch (error) {
      L.error('handleSubmit() Error:', error);
      setErrorMessage('There was an issue confirming your card. Please try again.');
      setErrorModal(true);
      setLoading(false);
    }
  };

  const handleStartAgain = () => {
    if (history && asObject(cognito).state === 'LOGGED_IN') {
      history.push(`/salon/${salonId}/`);
    }
  };

  L.debug('handleSubmit()', 'clientSecret:', clientSecret);

  return (
    <>
      <form data-bem="SetupForm" onSubmit={handleSubmit}>
        {loading && (
          <div className="spinner-container">
            <div className="load-spinner" />
          </div>
        )}
        <PaymentElement
          onChange={event => {
            if (event.complete) {
              setDisableSubmit(false);
            }
          }}
          onReady={() => void stopLoading()}
          onFocus={() => void setErrorMessage('')}
        />
        <br />
        <footer className="app-footer complicated">
          <button
            className={disableSubmit ? 'btn btn-primary disabled' : 'btn btn-primary'}
            type="submit"
            disabled={!stripe || !elements || disableSubmit}
          >
            Next
          </button>
        </footer>

        {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}

        {clientSecret && (
          <PaymentStatus
            clientSecret={clientSecret}
            salonId={salonId}
            history={history}
            stopAfterSubmitLoading={stopAfterSubmitLoading}
            appointmentId={appointmentId}
            obj={confirmObj}
          />
        )}
      </form>

      {fromAnyToBool(errorModal) && (
        <SalonModal tag="UpcomingItem__modal" isOpen>
          <SalonModal.Body>
            <IconStatus variant="alert">
              <Icon variant="alert" width="xl" />
            </IconStatus>
            <div className={CN.modalContent}>
              <div className={CN.title}>Card setup unsuccessful</div>
              <div className={CN.subTitle}>Please try again.</div>
            </div>
          </SalonModal.Body>
          <SalonModal.Footer>
            <Button variant="primary" onClick={handleStartAgain}>
              START AGAIN
            </Button>
          </SalonModal.Footer>
        </SalonModal>
      )}
    </>
  );
};

export const mapStateToProps = state => ({
  cognito: state.cognito,
});

const SetupFormHoc = connect(mapStateToProps)(SetupForm);

export default SetupFormHoc;
