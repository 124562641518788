import getState from 'utils/core/getState.util';
import determineMobileOs from 'utils/env/determineMobileOs.util';
import determineProductionConfiguration from 'utils/env/determineProductionConfiguration.util';

// short names as to not interfere much, only local for this file
const P = determineProductionConfiguration();
const D = Symbol('marker for props to be deleted');

// this object will be one due to it being defined at module level and exported
const SLICK = {};

SLICK.root = document.getElementById('root');
SLICK.mobileOs = determineMobileOs();

// these are removed in production due to possible unwanted side effects
SLICK.stat = P ? D : getState;

// the code that actually deletes properties marked with the symbol (to avoid using the value undefined)
for (const [key, val] of Object.entries(SLICK)) {
  if (val === D) {
    delete SLICK[key];
  }
}

// noinspection JSUnusedGlobalSymbols
export default SLICK;
