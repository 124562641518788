import React, { useContext } from 'react';
import { connect } from 'react-redux';

import {
  selectCurrentSalonAddressLine1,
  selectCurrentSalonCanTakeVoucher,
  selectCurrentSalonCity,
  selectCurrentSalonEmail,
  selectCurrentSalonId,
  selectCurrentSalonName,
  selectCurrentSalonPhone,
  selectCurrentSalonPostCode,
  selectCurrentSalonStripeVoucherLink,
} from 'selectors/currentSalon.selectors';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';

const L = logger('SalonBasicInfoProvider');

const Context = React.createContext();

const mapStateToProps = state => ({
  salonId: selectCurrentSalonId(state),
  salonName: selectCurrentSalonName(state),
  salonEmail: selectCurrentSalonEmail(state),
  salonPhone: selectCurrentSalonPhone(state),
  salonAddressLine1: selectCurrentSalonAddressLine1(state),
  salonCity: selectCurrentSalonCity(state),
  salonPostCode: selectCurrentSalonPostCode(state),
  salonHasGiftVoucherEnabled: selectCurrentSalonCanTakeVoucher(state),
  salonGiftVoucherLink: selectCurrentSalonStripeVoucherLink(state),
});

const Provider = ({ children, ...salon }) => {
  L.debug('()', salon);
  return <Context.Provider value={salon}>{children}</Context.Provider>;
};

export const SalonBasicInfoProvider = connect(mapStateToProps)(Provider);

export const useSalonBasicInfo = () => {
  const value = useContext(Context);
  return asObject(value);
};

export const useCurrentSalonId = () => {
  const value = useContext(Context);
  return asObject(value).salonId;
};
