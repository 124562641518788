// @flow

import { SELECT_APPOINTMENT, SELECT_CLIENT } from 'actions/selection';

import type { Appointment } from 'types/appointment';
import type { Client } from 'types/client';

type SelectionState = {
  client: Client | null,
  appointment: Appointment | null,
};

const initialState = {
  client: null,
  appointment: null,
};

function salon(state: SelectionState = initialState, action: Action) {
  switch (action.type) {
    case SELECT_CLIENT:
      return Object.assign({}, state, {
        client: action.client,
      });
    case SELECT_APPOINTMENT:
      return Object.assign({}, state, {
        appointment: action.appointment,
      });
    default:
      return state;
  }
}

export default salon;
