import React, { useCallback, useRef } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import cn from 'classnames';

import IMG_ARROW from 'assets/images/arrow-dark.svg';
import useTip from 'hooks/useTip.hook';
import { useTheme } from 'providers/Theme.provider';
import getAny from 'utils/accessors/getAny.util';
import asArray from 'utils/arrays/asArray.util';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import asObject from 'utils/objects/asObject.util';
import deRef from 'utils/objects/deRef.util';

import ServiceItem from 'booking/serviceSelection/ServiceItem';

import CN from './CategoryItem.module.scss';

const OFFSET_TOP = -70;

const countServices = ({ selectedServices, services }) => {
  const includedId = s => (s.show ? s.id : null);

  let count = 0;
  for (const selected of selectedServices) {
    const isIncluded = services.map(includedId).includes(selected.id);
    if (isIncluded) count += 1;
  }

  return count;
};

const CategoryItem = props => {
  const { isDarkMode, isNeutralMode } = useTheme();
  const { val: hasDropdownOpen, tip: setDropdown } = useTip(fromAnyToBool(props.isSingle));

  const myRef = useRef(null);

  const toggleCategory = useCallback(() => {
    const div = deRef(myRef);
    if (!div) return;

    div.scrollIntoView({ block: 'start' });
    setDropdown();

    const topPosition = OFFSET_TOP - getAny(0, 'offsetHeight', document.getElementById('selected-services-sticky'));
    setTimeout(() => void scroll.scrollMore(topPosition, { duration: 100 }), 100);
  }, [setDropdown]);

  const { category: categoryFromProps, selectedServices: selectedServicesFromProps } = props;

  const selectedServices = asArray(selectedServicesFromProps);
  const category = asObject(categoryFromProps);
  const services = asArray(category.services);

  const count = countServices({ selectedServices, services });

  const componentClasses = cn({
    [CN.component]: true,
    [CN.isOpen]: hasDropdownOpen,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="CategoryItem" className={componentClasses}>
      <div
        data-bem="CategoryItem__button"
        role="button"
        tabIndex="0"
        onClick={toggleCategory}
        ref={myRef}
        className={CN.button}
      >
        <span className={CN.catName}>{category.name}</span>
        <div className={CN.arrowContainer}>
          {count !== 0 && <div className={CN.countLabel}>{count} selected</div>}
          <img
            src={IMG_ARROW}
            alt="dropdown arrow"
            className={cn({
              [CN.arrow]: true,
              [CN.isOpen]: hasDropdownOpen,
            })}
          />
        </div>
      </div>

      {hasDropdownOpen && (
        <div data-bem="CategoryItem__container" className={CN.container}>
          <div className={CN.flex}>
            {services.map(service =>
              service.show ? (
                <ServiceItem service={service} key={`service${service.id}`} selectedServices={selectedServices} />
              ) : null,
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryItem;
