// @flow

/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react';

import StylistItemComponent from 'booking/common/StylistItem';

const FavouriteStylistForOneService = ({
  stylistArr,
  service,
  selectedStylists,
  i,
  onCheckboxClick,
  onClickAnyStylist,
  book_ncob_with_same,
}: {
  stylistArr: any,
  service: any,
  selectedStylists: any,
  i: any,
  onCheckboxClick: any,
  onClickAnyStylist: any,
  book_ncob_with_same: any,
}) => {
  const [favourite, setFavourite] = useState('');
  // const [checkedAny, setCheckedAny] = useState(false);
  // const [selected, setSelected] = useState(false);
  const [numSelected, setNumSelected] = useState(() => Object.keys(selectedStylists).length);

  const divStyle = {
    display: 'flex',
    flexWrap: 'wrap',
  };
  const onCheckboxClickOneService = (serviceId, stylist) => {
    onCheckboxClick(serviceId, stylist);
    if ((favourite && selectedStylists[serviceId].stylist_id !== favourite.stylist_id) || !favourite) {
      setFavourite(stylist);
    } else {
      setFavourite('');
    }
  };
  const onClickAnyStylistOneService = serviceId => {
    onClickAnyStylist(serviceId);
    if (favourite !== null) {
      setFavourite(null);
    } else {
      setFavourite('');
    }
  };
  useEffect(() => {
    /* let sty = null;
    if (selectedStylists) {
      sty = selectedStylists[service.id];
      if (sty === 'any') {
        setCheckedAny(true);
      }
    } */
    if (selectedStylists) {
      const newNum = Object.keys(selectedStylists).length;
      setNumSelected(newNum);
    }
  }, [selectedStylists]);

  const keyId = service.id;
  if (stylistArr.length && i <= numSelected) {
    const div = (
      <div key={`stylist${keyId}`}>
        <span className="service-title">Select a staff member for {stylistArr[0].name}</span>
        <div style={divStyle}>
          {stylistArr.map((stylist, j) => {
            const variablePricing = service.variable_pricing;
            let checked = false;
            if (selectedStylists[service.id] && selectedStylists[service.id].stylist_id === stylist.stylist_id) {
              checked = true;
            }

            if (favourite === '' || checked) {
              return (
                <StylistItemComponent
                  checked={checked}
                  service_id={service.id}
                  key={`stylist${keyId}-${stylist.stylist_name}`}
                  checkId={`cb${i}${j}`}
                  stylist={stylist}
                  favourite=""
                  itemPriceCost={stylist.price}
                  onClick={onCheckboxClickOneService}
                  variable_pricing={variablePricing}
                  noPrice
                  book_ncob_with_same={book_ncob_with_same}
                />
              );
            }
            return null;
          })}
          {stylistArr.length > 1 && (favourite === '' || favourite === null) && (
            <StylistItemComponent
              checked={selectedStylists[service.id] && selectedStylists[service.id] === 'any'}
              checkId={`cbany${i}`}
              stylist={null}
              favourite=""
              key={`any${service.id}`}
              service_id={stylistArr[0].service_id}
              onClick={onCheckboxClick}
              onClickAnyStylist={onClickAnyStylistOneService}
              variable_pricing={false}
              noPrice
              book_ncob_with_same={book_ncob_with_same}
            />
          )}
        </div>
      </div>
    );

    return div;
  }
  return null;
};

export default FavouriteStylistForOneService;
