import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import LINK from 'constants/link.const';
import { useCurrentSalonId } from 'providers/SalonBasicInfo.provider';
import { useTheme } from 'providers/Theme.provider';
import linkTo from 'utils/links/linkTo.util';

import CN from './NoDeposit.module.scss';

const NoDeposit = () => {
  const { isDarkMode, isNeutralMode } = useTheme();
  const salonId = useCurrentSalonId();

  const linkToSalon = linkTo({ pattern: LINK.salon, params: { salonId } });

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="NoDeposit" className={componentClasses}>
      <div className={CN.wrapper}>
        <div className={CN.header}>Deposits</div>
        <IconStatus variant="inactive">
          <Icon variant="poundInactive" width="xl" />
        </IconStatus>

        <div className={CN.container}>
          <div className={CN.title}>You have no new deposit requests</div>
        </div>
      </div>
      <Footer data-bem="NoDeposit__footer">
        <div className={CN.footerContainer}>
          <NavLink to={linkToSalon} data-bem="SingleSalonBookings__link" className={CN.link}>
            <Button variant="primary" width="fixed">
              MAKE A BOOKING
            </Button>
          </NavLink>
        </div>
      </Footer>
    </div>
  );
};

export default NoDeposit;
