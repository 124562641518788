import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import STORE from 'store.singleton';

import { setupIntent, setupIntentFrontend } from 'actions/billing';
import { NCOB_STRIPE_KEY } from 'config/index';

import SetupForm from 'booking/confirmBooking/SetupForm';
import Spinner from 'common/Spinner.component';

const SetupFormWrap = ({ salonId, history, appointmentId }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const newStripePromise = loadStripe(NCOB_STRIPE_KEY);

    setStripePromise(newStripePromise);
    const interval = setInterval(() => {
      const newState = STORE.getState();
      const { username } = newState.cognito.user;
      if (username) {
        clearInterval(interval);
        STORE.dispatch(setupIntent(salonId, username));
      }
    }, 500);
    const interval2 = setInterval(() => {
      const newState2 = STORE.getState();
      if (newState2.billing !== null && newState2.billing.intent_client_secret) {
        setClientSecret(newState2.billing.intent_client_secret);
        clearInterval(interval2);
      }
    }, 500);
  }, [clientSecret, salonId]);

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.

  const appearance = {
    theme: 'stripe',

    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#f5f5f5',
      colorText: '#192340',
      colorDanger: '#df1b41',
      colorTextPlaceholder: '#979797',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
    },
    rules: {
      '.Label': {
        color: '#979797',
        marginBottom: '6px',
        marginTop: '12px',
        fontSize: '14px',
      },
      '.Input': {
        borderColor: '#f5f5f5',
        boxShadow: 'none',
        padding: '12px',
      },
      '.Input:focus': {
        borderColor: '#933cc1',
        colorBackground: 'white',
        boxShadow: '0 0 0 1px #933cc1',
      },
    },
  };

  const options = {
    hidePostalCode: true,
    // passing the client secret obtained in step 2
    clientSecret,
    // Fully customizable with appearance API.
    appearance,
  };

  const stopLoading = () => void setLoading(false);

  return (
    <>
      {loading && <Spinner variant="overlay" />}
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <SetupForm
            history={history}
            salonId={salonId}
            setupIntentFrontend={setupIntentFrontend}
            stopLoading={stopLoading}
            appointmentId={appointmentId}
          />
        </Elements>
      )}
    </>
  );
};

export default SetupFormWrap;
