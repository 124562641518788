// @flow

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import SalonHeader from 'components/SalonHeader';
import type { CreateBookingState } from 'reducers/createbooking';

import Spinner from 'common/Spinner.component';
import type { Appointment } from 'types/appointment';
import type { Client } from 'types/client';

type Props = {
  client: Client,
  appointment: Appointment,
  createbooking: CreateBookingState,
};

type State = {};

class Success extends React.Component<Props, State> {
  calendarEntry = () => {
    const { createbooking } = this.props;
    if (createbooking.response && createbooking.response.ics) {
      window.open(`data:text/calendar;charset=utf8,${encodeURI(createbooking.response.ics)}`);
    }
  };

  render() {
    const { client, appointment, createbooking } = this.props;
    let booking = appointment;
    const hasConfirmed = createbooking.response !== null;
    if (hasConfirmed) {
      booking = createbooking.response;
    }
    let stylists = '';
    let newTitle = '';
    if (booking && booking.procedures) {
      booking.procedures.sort((a, b) => (moment(b.start_time).isBefore(a.start_time) ? 1 : -1));
      // $FlowFixMe
      stylists = booking.procedures.map(proc => proc.stylist.user_name).join(', ');
      // $FlowFixMe
      newTitle = booking.procedures.map(proc => proc.service.name).join(', ');
    }
    // $FlowFixMe
    booking.title = newTitle;
    return (
      <div>
        <SalonHeader client={client} />
        <div className="section">Re-book an Appointment</div>
        <div className="container success">
          {hasConfirmed && (
            <div>
              <div>
                <div className="summary-wrap">
                  <div className="summary-icon ico-svg-services" />
                  <span>{booking.title}</span>
                </div>
                <div className="summary-wrap">
                  <div className="summary-icon ico-svg-stylist" />
                  <span>{stylists}</span>
                </div>
                <div className="summary-wrap">
                  <div className="summary-icon ico-svg-calendar" />
                  <span>
                    {// $FlowFixMe
                    moment(booking.procedures[0].start_time).format('ddd D MMM')}
                  </span>
                </div>
                <div className="summary-wrap">
                  <div className="summary-icon ico-svg-duration" />
                  <span>
                    {// $FlowFixMe
                    moment(booking.procedures[0].start_time).format('H:mm')}
                  </span>
                </div>
              </div>
              <div className="success-badge ico-svg-awesome" />
              <div className="info-text">
                <div className="text-header">Booking confirmed</div>
              </div>
              <button className="simple-button" onClick={() => this.calendarEntry()}>
                Add to calendar
              </button>
            </div>
          )}
          {!hasConfirmed && (
            <div>
              <div className="summary-wrap">
                <div className="summary-icon ico-svg-services" />
                <span>{booking.title}</span>
              </div>
              <div className="summary-wrap">
                <div className="summary-icon ico-svg-stylist" />
                <span>{stylists}</span>
              </div>
              <div className="summary-wrap">
                <div className="summary-icon ico-svg-calendar" />
                <span>
                  {// $FlowFixMe
                  moment(booking.procedures[0].start_time).format('ddd D MMM')}
                </span>
              </div>
              <div className="summary-wrap">
                <div className="summary-icon ico-svg-duration" />
                <span>
                  {// $FlowFixMe
                  moment(booking.procedures[0].start_time).format('H:mm')}
                </span>
              </div>
            </div>
          )}
          {!hasConfirmed && createbooking.isFetching && <Spinner variant="overlay" />}
          {!hasConfirmed && !createbooking.isFetching && createbooking.errorMessage && (
            <div className="conditional">
              <div className="info-text">
                <div className="text-header">
                  Ooops, sorry!
                  <br />
                  Something went wrong!
                </div>
                <div className="conditional-img img_error" />
                <div className="text-subheader">{createbooking.errorMessage}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  client: state.selection.client,
  appointment: state.selection.appointment,
  createbooking: state.createbooking,
});

const mapDispatchToProps = () => ({});

// $FlowFixMe
export default connect<any, Dispatch, Props, any, any>(mapStateToProps, mapDispatchToProps)(Success);
