import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';

import CN from './TabButtonsContainer.module.scss';

const TabButtonsContainer = ({ children }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  return (
    <div
      data-bem="TabButtonsContainer"
      className={cn({
        [CN.component]: true,
        [CN.dark]: isDarkMode,
        [CN.neutral]: isNeutralMode,
      })}
    >
      {children}
    </div>
  );
};

export default TabButtonsContainer;
