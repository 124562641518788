// @flow

/* eslint-disable no-useless-escape */
/* eslint-disable react/no-unused-prop-types */

import React from 'react';

import Footer from 'booking/common/Footer.component';
import HeaderComponent from 'booking/common/Header';

type Props = {
  history: string[],
  match: {
    params: {
      id: string,
    },
  },
  phone: '',
};

type State = {
  phone: string,
  errors: {
    phone: string,
    confirmPassword: string,
    password: string,
  },
};

const validateForm = errors => {
  let valid = true;
  // eslint-disable-next-line no-return-assign
  Object.values(errors).forEach((val: any) => val.length > 0 && (valid = false));
  return valid;
};

export class PasswordResetWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      phone: '',
      errors: {
        phone: '',
        confirmPassword: '',
        password: '',
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onBlur = (phone: string) => {
    // eslint-disable-next-line prefer-destructuring
    const errors = this.state.errors;

    errors.phone = phone.length > 13 ? '' : 'Please enter a valid mobile number';

    this.setState({ errors, phone });
  };

  onResetBtnClick = () => {
    this.props.history.push(`/salon/${this.props.match.params.id}/forgot-password`);
  };

  handleSubmit = (event: Event) => {
    event.preventDefault();

    if (validateForm(this.state.errors)) {
      // 'Valid Form'
    } else {
      // 'Invalid Form'
    }
  };
  handleChange = (value: string, name: string) => {
    // eslint-disable-next-line prefer-destructuring
    const errors = this.state.errors;

    this.setState({ errors, [name]: value });
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div className="app-content color-title no-margin color-white">
          <HeaderComponent title="Password reset" history={this.props.history} />
          <div className="form-wrapper">
            <span className="sms-password-message">Enter your mobile number and we’ll send you a code via SMS</span>

            <form onSubmit={this.handleSubmit}>
              <div className="form-input">
                <label htmlFor="phone" className="label-name">
                  Mobile number
                </label>
                <input
                  type="number"
                  name="phone"
                  onChange={e => this.handleChange(e.target.value, 'phone')}
                  onBlur={e => this.onBlur(e.target.value)}
                  placeholder="Eg. 07875332200"
                  inputMode="decimal"
                />
                {errors.phone.length > 0 && <span className="text-pink error">{errors.phone}</span>}
              </div>
              <span className="input-info">The code may take a few minutes to arrive</span>
            </form>
          </div>
        </div>

        <Footer
          onBtnClick={() => this.onResetBtnClick()}
          btnName="Send reset code"
          isButtonDisabled={!this.state.phone || !validateForm(this.state.errors)}
        />
      </div>
    );
  }
}

export default PasswordResetWrap;
