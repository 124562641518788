import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';
import fromAnyToTrimmed from 'utils/converters/any/fromAnyToTrimmed.util';

import CN from './Toggler.module.scss';

const Toggler = ({ onClick, tag: tagFromProps, selected, disabled, variant, ...extra }) => {
  const tag = fromAnyToTrimmed(tagFromProps);
  const { isDarkMode, isNeutralMode } = useTheme();

  return (
    <div
      {...extra}
      data-bem="Toggler"
      data-tag={tag}
      role="button"
      tabIndex={0}
      onClick={onClick}
      className={cn({
        [CN.component]: true,
        [CN.isSelected]: selected,
        [CN.isDisabled]: disabled,
        [CN.dark]: isDarkMode,
        [CN.neutral]: isNeutralMode,
        [CN[`${variant}Variant`]]: variant,
      })}
    />
  );
};

export default Toggler;
