import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';

import CN from './DisclaimerText.module.scss';

const DisclaimerText = () => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="DisclaimerText" className={componentClasses}>
      <div className={CN.text}>
        By entering your payment details you are giving consent for these to be stored securely and anonymously by our
        payment provider Stripe, and for a no-show fee to be charged if you are in breach of our cancellation policy or
        you fail to show for this appointment or any future appointments, irrespective of how they are booked.
      </div>
      <div className={CN.text}>
        No other charges will be processed without your consent and your payment details will not be shared with any 3rd
        parties.
      </div>
      <div className={CN.text}>
        If you wish to remove your payment details, you can login via My Bookings, head to My Profile, Payment Details
        and remove your card details. You may be asked for them again in future when booking online.
      </div>
    </div>
  );
};

export default DisclaimerText;
