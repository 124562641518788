import React from 'react';

import fromArrayToLength from 'utils/converters/array/fromArrayToLength.util';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import logger from 'utils/logger.util';

import CategoryItem from 'booking/serviceSelection/CategoryItem';
import ServiceItem from 'booking/serviceSelection/ServiceItem';
import BuyVoucherButton from 'booking/voucher/BuyVoucherButton.component';

const L = logger('ServiceSelection');

const USE_LOG = false;

const ServiceSelection = props => {
  const { categories, recommendedServices, selectedServices, showBuyButton } = props;
  const count = fromArrayToLength(categories);

  if (USE_LOG) L.debug('()', count, 'categories:', categories);

  return (
    <div data-bem="ServiceSelection" className="service-container">
      {fromAnyToBool(showBuyButton) && <BuyVoucherButton />}
      {recommendedServices &&
        recommendedServices.length !== 0 &&
        recommendedServices.map(service => (
          <ServiceItem
            service={service}
            isChecked={false}
            id={`service${service.id}`}
            key={`service${service.id}`}
            recommended
            selectedServices={selectedServices}
          />
        ))}
      {recommendedServices &&
        recommendedServices.length === 0 &&
        categories.map((category, index) => {
          if (category.services.length > 0) {
            // eslint-disable-next-line react/no-array-index-key
            const key = `${category.name}-${index}`;
            const isSingle = count === 1;

            return (
              <CategoryItem
                isSingle={isSingle}
                category={category}
                index={index}
                key={key}
                selectedServices={selectedServices}
              />
            );
          }
          return false;
        })}
    </div>
  );
};

ServiceSelection.defaultProps = { recommendedServices: [] };

export default ServiceSelection;
