// @flow

import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import cn from 'classnames';
import Navigation from 'invite/components/Navigation.component';

import IMG_CHECK from 'assets/images/icons/check-circle-purple.svg';
import { withTheme } from 'providers/Theme.provider';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';

import { clearSelectedAppointmentTime, getSalonDetails, setPageVisited } from 'booking/actions';
import SummaryComponent from 'booking/summary/Summary';
import { fbPixelTracking } from 'lib/utils';
import type { SalonDetails } from 'types/salonDetails';
import type { Service } from 'types/service';
import type { Stylist } from 'types/stylist';

import CN from './BookingConfirmedSummary.module.scss';

type Props = {
  history: string[],
  match: {
    params: {
      id: string,
    },
  },
  salonDetails: SalonDetails,
  selectedServices: Service[],
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  setPageVisited: (page: string) => void,
  getSalonDetails: (salonId: number) => void,
  isWaitList: boolean,
};

type State = {
  shouldMount: boolean,
};

export class BookingConfirmedSummary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shouldMount: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.selectedServices.length === 0) {
      this.props.history.push(`/salon/${this.props.match.params.id}`);
      return;
    }

    if (!this.props.salonDetails.id) {
      this.props.getSalonDetails(parseInt(this.props.match.params.id, 10));
    }

    this.setState({ shouldMount: true });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setPageVisited('bookingConfirmed');
    fbPixelTracking(this.props.salonDetails.fb_details.fb_pixel_id, 'Schedule');
    ReactGA.event({
      category: 'Page View',
      action: ' Made a booking',
      label: 'Reached booking confirmation page',
    });
  }

  render() {
    const { isDarkMode, isNeutralMode, salonDetails } = this.props;
    const { ncob_deposit_settings: ncobDeposit, needs_card: needsCard } = salonDetails;

    return (
      <div
        data-bem="BookingConfirmedSummary"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          <Navigation match={this.props.match} />

          <div className={CN.statusIcon}>
            <img src={IMG_CHECK} alt="successful icon" />
          </div>

          <div className={CN.title}>
            {fromAnyToBool(this.props.isWaitList) ? (
              <div>Awesome, you are on the waitlist!</div>
            ) : (
              <div>
                {ncobDeposit !== 'null' && needsCard ? (
                  <div>
                    Payment successful!
                    <br />
                    Booking has been confirmed
                  </div>
                ) : (
                  <div>Booking has been confirmed!</div>
                )}
              </div>
            )}
          </div>

          {this.state.shouldMount && (
            <>
              <SummaryComponent isAppointmentConfirmed salonDetails={salonDetails} history={this.props.history} />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { booking } = state;
  const {
    salonDetails,
    selectedServices,
    selectedStylists,
    selectedAppointmentTime,
    bookingResponse,
    availablePatchDays,
    firstAvailablePatchDate,
    referrerInfo,
    isWaitList,
  } = booking;
  return {
    salonDetails,
    selectedServices,
    selectedStylists,
    selectedAppointmentTime,
    availablePatchDays,
    firstAvailablePatchDate,
    bookingResponse,
    referrerInfo,
    isWaitList,
    appointment_id: bookingResponse ? bookingResponse.appointment_id : null,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getSalonDetails(salonId: number) {
    dispatch(getSalonDetails(salonId));
  },
  clearSelectedAppointmentTime() {
    dispatch(clearSelectedAppointmentTime());
  },
  setPageVisited(page: string) {
    dispatch(setPageVisited(page));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(BookingConfirmedSummary));
