import isMoment from 'utils/predicates/isMoment.util';

const isNotDateWorthy = value => {
  if (value === null) return true;
  if (void 1 === value) return true;

  if (value instanceof Date) return false;

  if (Number.isFinite(value)) return false;
  if (typeof value === 'string') return false;

  // noinspection RedundantIfStatementJS
  if (isMoment(value)) return false;

  return true;
};

export default isNotDateWorthy;
