const P = 'master';
const T = 'test';
const S = 'staging';
const D = 'development';

const DEPLOYMENT = Object.freeze({
  // synonyms for prod
  production: P,
  prod: P,

  // alpha and beta don't get static moniker

  // synonyms for staging
  staging: S,
  stage: S,

  // synonyms for testing
  testing: T,
  test: T,

  // synonyms for development
  development: D,
  dev: D,
  local: D,
});

// noinspection JSUnusedGlobalSymbols
export default DEPLOYMENT;
