// @flow
/* eslint-disable react/require-default-props */

import * as React from 'react';

type PropsType = {
  children?: React.Node,
};

const PrivacyLink = ({ children }: PropsType) => (
  <div className="info-text">
    <div className="text-supplemental">
      {children}
      <a href="https://getslick.com/privacy-policy/">Privacy Policy</a>
    </div>
  </div>
);

export default PrivacyLink;
