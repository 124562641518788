// @flow

/* eslint-disable react/sort-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
/* eslint-disable no-console */

import React from 'react';
import { CognitoState, Login } from 'react-cognito-mm';
// $FlowFixMe
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { clearTokens } from 'auth/actions';
import cn from 'classnames';

import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import S from 'constants/string.const';
import { withTheme } from 'providers/Theme.provider';

import { getClientInvites, getSalonDetails, getUserCardDetails, processInvite, setPageVisited } from 'booking/actions';
import HeaderComponent from 'booking/common/Header';
import { validatePassword } from 'lib/validators';
import type { SalonDetails } from 'types/salonDetails';
import type { Stylist } from 'types/stylist';

import 'react-phone-number-input/style.css';
import CN from './LoginInvite.module.scss';

const LOGIN_MESSAGE = [
  `Ooops, that password seems to be incorrect 😢  `,
  'Please try again or click the Forgotten password link to reset it',
].join(S.space);

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  salonDetails: SalonDetails,
  invites: [],
  state: CognitoState,
  history: string[],
  match: {
    params: {
      id: string,
    },
  },
  cognito: {
    userPool: any,
    config: any,
    state: string,
    user: { username: string },
    error: {
      code: string,
      statusCode: string,
      message: string,
    },
    attributes: {
      name: string,
    },
  },
  user: { username: string }, // eslint-disable-line react/no-unused-prop-types
  username: string,
  attributes: any, // eslint-disable-line react/no-unused-prop-types
  error: {
    message: string,
    code: string,
  },
  isFetching: boolean,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  onSubmit: (userphone: string, password: string) => Promise<*>,
  clearTokens: () => void,
  getUserCardDetails: (username: string) => void,
  getSalonDetails: (salonId: number) => void,
  setPageVisited: (page: string) => void,
  getClientInvites: (cognitoUsername: string) => void,
  salonDetailsList: any,
};

type State = {
  type: string,
  password: string,
  userphone: string,
  errorMessage?: string,
  errors: {
    password: string,
  },
  userphone: string,
  isLoading: boolean,
  redirected: boolean,
  redirectedToFeed: boolean,
  fetchingInvite: boolean,
};

export class LoginInvite extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: 'password',
      password: '',
      errors: {
        password: '',
      },
      userphone: '',
      isLoading: false,
      redirected: false,
      redirectedToFeed: false,
      fetchingInvite: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getSalonDetails(parseInt(this.props.match.params.id, 10));
  }

  componentDidMount() {
    this.props.setPageVisited('login');
  }
  // TODO use COGNITO const for cognito.state
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.username && nextProps.cognito.state === 'LOGGED_IN') {
      if (!this.state.redirected) {
        this.setState({ redirected: true });
        this.props.getUserCardDetails(nextProps.username);
        this.handleLoggedInRedirect();
      }
    } else if (nextProps.username && nextProps.cognito.state === 'LOGGED_OUT') {
      this.setState({
        isLoading: false,
        errorMessage: 'Incorrect username or password',
      });
    }
  }

  onBlur = (event: Event, name: string) => {
    event.preventDefault();
    const { value } = (event.currentTarget: any);
    const { errors } = this.state;

    switch (name) {
      case 'password':
        errors.password = validatePassword(value);
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleClick = () => {
    this.setState({ isLoading: true });
    switch (this.props.cognito.state) {
      case CognitoState.LOGGED_IN:
        this.handleLoggedInRedirect();
        break;
      default:
        this.props.clearTokens();

        this.props.onSubmit(this.state.userphone, this.state.password).catch(err => {
          if (err.code === 'PasswordResetRequiredException') {
            console.log('PasswordResetRequiredException'); // eslint-disable-line no-console
          }
          this.setState({ errorMessage: err.message });
        });
        break;
    }
  };

  showHidePassword = () => {
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text',
    });
  };
  checkInvites = (interval: any) => {
    if (this.props.invites.length > 0) {
      clearInterval(interval);
      this.props.history.push(`/salon/${this.props.match.params.id}/my-feeds`);
    } else if (
      this.props.cognito &&
      this.props.cognito.user &&
      !this.props.isFetching &&
      !this.state.redirectedToFeed
    ) {
      this.setState({ redirectedToFeed: true });
      this.props.history.push(`/salon/${this.props.match.params.id}/my-feeds`);
    }
  };
  handleLoggedInRedirect = () => {
    if (!this.state.fetchingInvite) {
      this.setState({ fetchingInvite: true });
      this.props.getClientInvites(this.props.cognito.user.username);
    }
    const myInterval = setInterval(() => {
      this.checkInvites(myInterval);
    }, 500);
  };
  handleChange = (event: Event, name: string) => {
    event.preventDefault();
    const { value } = (event.currentTarget: any);
    const { errors } = this.state;
    switch (name) {
      case 'password':
        errors.password = validatePassword(value);
        break;
      default:
        break;
    }
    if (name) {
      this.setState({ [name]: value });
    }
  };

  onInternationalPhone = (value: string, type: 'inter') => {
    const phoneSymbolsRegex = /^[0-9+)(\s]*$/;
    if (phoneSymbolsRegex.test(value)) {
      if (type === 'inter') {
        this.setState({ userphone: value });
      }
    }
  };

  render() {
    const {
      salonDetailsList,
      isDarkMode,
      isNeutralMode,
      salonDetails: { country },
    } = this.props;

    const countryFlag = country && country.code;

    const THEME_MODE = salonDetailsList.map(salon => salon.ncob_mode);
    const userError = this.props.cognito.error && this.props.cognito.error.message;
    const userErrorCode = this.props.cognito.error && this.props.cognito.error.code; // Get the error code

    let userExistError = false;
    let loginError1 = '';
    let loginError2 = '';
    let headerText = 'Login to your Slick account to proceed with payment';

    // Check for specific error codes
    if (userErrorCode === 'UserLambdaValidationException') {
      loginError1 =
        'Sorry, it looks like you haven’t setup an online account with us yet. Please create an account to complete your booking. ';
    } else if (userErrorCode === 'NotAuthorizedException') {
      loginError2 = LOGIN_MESSAGE;
    } else if (userError && userError.indexOf('given phone_number already exists') > 0) {
      userExistError = true;
      loginError2 = `Try logging in with your mobile number and password below ${S.thumbsUp}`;
      headerText = `Ooops, it looks like you${S.apos}ve already got an account!`;
    }
    const { errors } = this.state;

    // Only set this state if it's not a specific error case
    if (this.state.errorMessage && !userExistError && !loginError1) {
      if (this.state.isLoading) {
        this.setState({ isLoading: false });
      }
      loginError2 = LOGIN_MESSAGE;
    }

    const isLoginDisabled =
      !(this.state.userphone && isValidPhoneNumber(this.state.userphone)) ||
      !this.state.password ||
      this.state.errors.password.length > 0;

    const passwordClasses = cn({
      [CN.input]: true,
      [CN.hasError]: errors.password.length > 0,
    });

    const phoneClasses = cn({
      [CN.phoneContainer]: true,
      [CN.hasError]: this.state.userphone && !isValidPhoneNumber(this.state.userphone),
    });

    return (
      <div
        data-bem="LoginInvite"
        className={cn({
          [THEME_MODE[0]]: true,

          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className="app-wrap">
          <div className="app-content color-title no-margin color-white  login-invite">
            <HeaderComponent title={headerText} history={this.props.history} />

            {loginError1 && <div className={CN.error}>{loginError1}</div>}
            {loginError2 && <div className={CN.error}>{loginError2}</div>}

            {this.state.isLoading && (
              <div className="spinner-container">
                <div className="load-spinner" />
              </div>
            )}
            <div className={CN.formContainer}>
              <div className={phoneClasses}>
                <label htmlFor="phone" className={CN.inputLabel}>
                  Mobile number
                </label>
                <PhoneInput
                  defaultCountry={countryFlag}
                  placeholder="Enter phone number"
                  value={this.state.userphone}
                  onChange={value => void this.onInternationalPhone(value, 'inter')}
                />

                {this.state.userphone && !isValidPhoneNumber(this.state.userphone) && (
                  <span className={CN.error}>Please enter a valid phone number in order to proceed</span>
                )}
              </div>

              <div className={CN.inputContainer}>
                <label htmlFor="password" className={CN.inputLabel}>
                  Password
                </label>
                <div className={CN.passwordContainer}>
                  <input
                    data-bem="LoginFormProfileWrap__password"
                    type={this.state.type}
                    name="password"
                    onChange={event => this.handleChange(event, 'password')}
                    placeholder="8 characters, 1 uppercase, 1 number"
                    className={passwordClasses}
                  />
                  <div className={CN.eyeContainer} role="button" tabIndex="0" onClick={this.showHidePassword}>
                    {this.state.type === 'password' ? <div className={CN.eyeOpen} /> : <div className={CN.eyeClose} />}
                  </div>
                </div>
                {errors.password.length > 0 && <span className={CN.error}>{errors.password}</span>}

                <div className={CN.forgotLinkContainer}>
                  <Link
                    to={`/salon/${this.props.match.params.id}/forgot`}
                    href={`/salon/${this.props.match.params.id}/forgot`}
                    className={CN.forgotLink}
                  >
                    Forgotten password?
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <Footer data-bem="LoginFormProfileWrap__footer">
            <Button variant="primary" width="fixed" onClick={() => this.handleClick()} disabled={isLoginDisabled}>
              LOGIN
            </Button>
          </Footer>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state: any): any {
  const { needs_card } = state.booking.salonDetails;
  const { booking, billing } = state;
  const {
    salonDetails,
    selectedServices,
    selectedPatchTime,
    selectedAppointmentTime,
    note,
    totalPrice,
    selectedStylists,
    bookingResponse,
    isFetchingCard,
    cardDetails,
    pageVisited,
    invites,
    isFetching,
    salonDetailsList,
  } = booking;
  const cognito = {
    state: state.cognito.state,
    user: state.cognito.user,
    email: state.cognito.attributes.email,
    config: state.cognito.config,
    userPool: state.cognito.userPool,
    error: state.cognito.error,
  };
  return {
    cognito,
    invites,
    needs_card,
    salonDetails,
    selectedServices,
    selectedPatchTime,
    selectedAppointmentTime,
    appointment_cost: billing.appointment_cost,
    pay_today: billing.pay_today,
    note,
    totalPrice,
    selectedStylists,
    bookingResponse,
    isFetchingCard,
    isFetching,
    cardDetails,
    pageVisited,
    username: state.cognito.user ? state.cognito.user.username : '',
    salonDetailsList,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    clearTokens() {
      dispatch(clearTokens);
    },
    getUserCardDetails(username: string) {
      dispatch(getUserCardDetails(username));
    },
    getSalonDetails(salonId: number) {
      dispatch(getSalonDetails(salonId));
    },
    setPageVisited(page: string) {
      dispatch(setPageVisited(page));
    },
    processInvite(cognitoUsername: string, paymentIntentId: string) {
      dispatch(processInvite(cognitoUsername, paymentIntentId));
    },
    getClientInvites(cognitoUsername: string) {
      dispatch(getClientInvites(cognitoUsername));
    },
  };
}

const ConnecteLogin = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme()(LoginInvite)));

const LoginForm2 = () => (
  <Login>
    <ConnecteLogin />
  </Login>
);
export default LoginForm2;
