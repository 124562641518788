import React, { useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

import determineNcobModes from 'bl/salon/determineNcobModes.bl';
import THEME_MODE_NCOB from 'constants/themeModeNcob.const';
import { selectCurrentSalonNcobMode } from 'selectors/currentSalon.selectors';
import withOptions from 'utils/functions/withOptions.util';
import logger from 'utils/logger.util';
import fromComponentToName from 'utils/react/fromComponentToName.util';
import C from 'utils/values/constant.util';

const L = logger('ThemeProvider');

const MN = THEME_MODE_NCOB.neutral;
const MD = THEME_MODE_NCOB.dark;
const MS = THEME_MODE_NCOB.slick;

const SUPER_DEFAULT = C(determineNcobModes({ ncobMode: null, options: { defaultMode: MS } }));

const Context = React.createContext();

const mapStateToProps = state => ({
  ncobMode: selectCurrentSalonNcobMode(state),
});

export const withTheme = withOptions(options => Component => {
  // find out the name of the wrapped component or use a default one
  const displayName = `withTheme(${fromComponentToName(Component)})`;

  // create the wrapper component and use it to render the wrapped one and also
  // remove the props added by connect from the props for the child component
  const WithTheme = props => {
    const { dispatch, ncobMode, ...extra } = props;
    const modes = determineNcobModes({ ncobMode, options });

    return <Component {...extra} {...modes} />;
  };

  // static fields must be copied from the wrapped to the wrapper
  hoistNonReactStatics(WithTheme, Component);

  // nice display name for the wrapper
  WithTheme.displayName = displayName;

  // need to wire up few more wrappers for the extra props needed
  return connect(mapStateToProps)(WithTheme);
});

const Provider = ({ ncobMode, children }) => {
  const value = useMemo(() => {
    L.info('()', 'ncobMode:', ncobMode);

    const defaultN = determineNcobModes({ ncobMode, options: { defaultMode: MN } });
    const defaultD = determineNcobModes({ ncobMode, options: { defaultMode: MD } });
    const defaultS = determineNcobModes({ ncobMode, options: { defaultMode: MS } });

    return { ncobMode, defaultN, defaultD, defaultS };
  }, [ncobMode]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const ThemeProvider = connect(mapStateToProps)(Provider);

export const useTheme = () => {
  const value = useContext(Context);

  return value ? value.defaultS : SUPER_DEFAULT;
};

export const useNcobMode = () => {
  const value = useContext(Context);

  return value.ncobMode;
};
