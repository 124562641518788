// SEE: https://github.com/streamich/react-use/blob/master/src/useAsyncFn.ts

import useInitializedAsyncFn from 'hooks/useInitializedAsyncFn.hook';

// name is short for use async function
const useAfn = (fn, deps) =>
  useInitializedAsyncFn(
    {
      value: void 1,
      error: void 1,
      isLoading: false,
      isLoaded: false,
    },
    fn,
    deps,
  );

// noinspection JSUnusedGlobalSymbols
export default useAfn;
