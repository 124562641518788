import { cognito } from 'react-cognito-mm';
import { combineReducers } from 'redux';

import authentication from 'reducers/authentication';
import availability from 'reducers/availability';
import billing from 'reducers/billing';
import cookies from 'reducers/cookies.reducer';
import createbooking from 'reducers/createbooking';
import login from 'reducers/login';
import salon from 'reducers/salon';
import selection from 'reducers/selection';
import verifybooking from 'reducers/verifybooking';

import booking from 'booking/reducer';

const rootReducer = combineReducers({
  cognito,
  cookies,
  authentication,
  login,
  salon,
  selection,
  availability,
  createbooking,
  verifybooking,
  booking,
  billing,
});

export default rootReducer;
