import React, { useContext, useMemo } from 'react';

import fromAnyToTrimmed from 'utils/converters/any/fromAnyToTrimmed.util';
import alwaysNoop from 'utils/functions/nullary/alwaysNoop.util';
import logger from 'utils/logger.util';
import isNotFn from 'utils/predicates/function/isNotFn.util';

const L = logger('DispatcherProvider');

const Context = React.createContext();

export const DispatcherProvider = ({ store, children }) => {
  L.debug('()', 'store:', store);
  return <Context.Provider value={store}>{children}</Context.Provider>;
};

export const useDispatcher = actionCreator => {
  const store = useContext(Context);

  if (isNotFn(actionCreator)) {
    L.debug('useDispatcher()', 'Invalid function actionCreator:', actionCreator, 'using:', alwaysNoop);
    // eslint-disable-next-line no-param-reassign
    actionCreator = alwaysNoop;
  }

  // noinspection UnnecessaryLocalVariableJS
  const dispatcher = useMemo(() => {
    const temporary = {};
    const functionName = `dispatch(${fromAnyToTrimmed(actionCreator.name) || 'Unknown action creator'})`;

    // using this indirect approach as to let the JS engine give our desired name to the new function
    // eslint-disable-next-line func-names
    temporary[functionName] = function(...args) {
      return store.dispatch(actionCreator.apply(this, args));
    };

    Object.defineProperty(temporary[functionName], 'name', { value: functionName });

    return temporary[functionName];
  }, [actionCreator, store]);

  return dispatcher;
};
