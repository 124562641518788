import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import BaseNavigation from 'invite/components/BaseNavigation.component';

import Badge from 'components/Badge.component';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import { useTheme } from 'providers/Theme.provider';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import fromPropsToParams from 'utils/converters/props/fromPropsToParams.util';

import {
  clearAvailableSlots,
  getCategoriesWithServices,
  getRecommendedServices,
  getStylistsForServiceWeekly,
  setSelectedStylists,
} from 'booking/actions';
import PatchTest from 'booking/common/PatchTest.component';
import StartAgainAndBackButtonsComponent from 'booking/common/StartAgainAndBackButtons.component';
import ServiceSelection from 'booking/serviceSelection/ServiceSelection';
import { fbPixelTracking } from 'lib/utils';

import CN from './RecommendedServiceWrap.module.scss';

class RecommendedServiceWrap extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stylistsForServiceWeekly: null, getStyCalled: false, showPopup: false };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const salonId = parseInt(this.props.match.params.id, 10);
    this.props.getCategoriesWithServices(salonId);

    const serviceIds = this.props.selectedServices.map(service => service.id);
    // if we come back using browser back button, don't need to get recommendedServices any more
    if (!this.props.pageVisited.includes('favouritestylistselect')) {
      this.props.getRecommendedServices(salonId, serviceIds);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    fbPixelTracking(this.props.salonDetails.fb_details.fb_pixel_id);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const salonId = parseInt(nextProps.match.params.id, 10);
    if (this.props.match.params.id !== nextProps.match.params.id) {
      const serviceIds = this.props.selectedServices.map(service => service.id);

      this.props.getRecommendedServices(salonId, serviceIds);
    }

    // to fix the problem of recommendedServices return [] due to stylist based/ roleexecution
    // hopefully this is not needed after a while

    if (nextProps.recommendedServices !== this.props.recommendedServices && !nextProps.recommendedServices.length) {
      if (this.props.salonDetails.ncob_stylists_count > 1) {
        this.props.history.push(`/salon/${salonId}/favouritestylistselect`);
      } else if (this.props.isPatchTestRequred) {
        this.props.history.push(`/salon/${salonId}/patchtimeselect`);
      } else {
        this.props.history.push(`/salon/${salonId}/timeselect`);
      }
    }
    if (nextProps.selectedServices !== this.props.selectedServices) {
      this.checkNextButton();
    }
    if (nextProps.stylistsForServiceWeekly) {
      this.setState({ stylistsForServiceWeekly: nextProps.stylistsForServiceWeekly });
    }
  }

  onClosePatchTest = () => void this.setState({ showPopup: false });

  handleConfirm = isTested => {
    const { id: salonId } = fromPropsToParams(this.props);
    const { history } = this.props;

    history.push(isTested === 'isSelectedYes' ? `/salon/${salonId}/timeselect` : `/salon/${salonId}/patchtimeselect`);
  };

  selectStylists = () => {
    const selectedStylists = {};

    // FIXME: don't use setInterval
    const interval = setInterval(() => {
      const { match, history, isPatchTestRequred, setSelectedStylists, selectedServices } = this.props;
      const { stylistsForServiceWeekly } = this.state;

      if (Object.keys(stylistsForServiceWeekly).length === selectedServices.length) {
        clearInterval(interval);
        for (let i = 0; i < selectedServices.length; i += 1) {
          const { id } = selectedServices[i];
          for (let j = 0; j < Object.keys(stylistsForServiceWeekly).length; j += 1) {
            if (stylistsForServiceWeekly[id]) {
              for (let k = 0; k < stylistsForServiceWeekly[id].length; k += 1) {
                if (stylistsForServiceWeekly[id][k].service_id === id) {
                  selectedStylists[id] = stylistsForServiceWeekly[id][k];
                  break;
                }
              }
            }
          }
        }

        setSelectedStylists(selectedStylists);
        if (isPatchTestRequred) {
          window.scrollTo(0, 0);
          this.setState({ showPopup: true });
        } else {
          history.push(`/salon/${match.params.id}/timeselect`);
        }
      }
    }, 500);
  };

  handleClick = () => {
    if (this.checkNextButton()) return;

    if (this.props.selectedServices && this.props.salonDetails.ncob_stylists_count === 1 && !this.state.getStyCalled) {
      const serviceIds = this.props.selectedServices.map(ser => ser.id);
      this.setState({ getStyCalled: true });
      this.props.getStylistsForServiceWeekly(this.props.salonDetails.id, serviceIds);
    }

    const salonId = parseInt(this.props.match.params.id, 10);
    this.props.setSelectedStylists({});
    this.props.clearAvailableSlots();

    if (this.props.salonDetails.ncob_stylists_count > 1) {
      this.props.history.push(`/salon/${salonId}/favouritestylistselect`);
    } else {
      this.selectStylists();
    }
  };

  checkNextButton = () =>
    fromAnyToBool(this.props.selectedServices[this.props.selectedServices.length - 1].has_processing_time);

  render() {
    const { showPopup } = this.state;
    const {
      match,
      stylistsForServiceWeekly,
      categories,
      isFetching,
      selectService,
      selectedServices,
      recommendedServices,
      isDarkMode,
      isNeutralMode,
    } = this.props;

    const salonId = parseInt(match.params.id, 10);

    return (
      <div
        data-bem="RecommendedServiceWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        {!isFetching && (
          <div className={CN.wrapper}>
            <BaseNavigation match={this.props.match} />
            <div className={CN.header}>
              <StartAgainAndBackButtonsComponent />
            </div>
            <div className={CN.title}>
              <div>Select a finishing service:</div>
              <Badge variant="default">{!selectedServices.length ? '0' : selectedServices.length} selected</Badge>
            </div>
            <div className={CN.container}>
              <ServiceSelection
                categories={categories[salonId]}
                recommendedServices={recommendedServices}
                stylistsForServiceWeekly={stylistsForServiceWeekly}
                selectService={selectService}
                selectedServices={selectedServices}
                showBuyButton={false}
              />
            </div>
          </div>
        )}

        <Footer data-bem="CreateAccountFormWrap__footer">
          <Button variant="primary" width="fixed" onClick={this.handleClick} disabled={this.checkNextButton()}>
            NEXT
          </Button>
        </Footer>
        {showPopup && <PatchTest onClose={this.onClosePatchTest} onConfirmClick={this.handleConfirm} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.booking.isFetching,
  categories: state.booking.categories,
  totalPrice: state.booking.totalPrice,
  selectedServices: state.booking.selectedServices,
  recommendedServices: state.booking.recommendedServices,
  stylistsForServiceWeekly: state.booking.stylistsForServiceWeekly,
  pageVisited: state.booking.pageVisited,
  salonDetails: state.booking.salonDetails,
  isPatchTestRequred: state.booking.isPatchTestRequred,
});

const mapDispatchToProps = dispatch => ({
  getCategoriesWithServices(salonId) {
    dispatch(getCategoriesWithServices(salonId));
  },
  getRecommendedServices(salonId, services) {
    dispatch(getRecommendedServices(salonId, services));
  },
  getStylistsForServiceWeekly(salonId, services) {
    dispatch(getStylistsForServiceWeekly(salonId, services));
  },
  setSelectedStylists(selectedStylists, serviceId, stylist) {
    dispatch(setSelectedStylists(selectedStylists, serviceId, stylist));
  },
  clearAvailableSlots() {
    dispatch(clearAvailableSlots());
  },
});

const RecommendedServiceLoader = props => {
  const theme = useTheme();

  return <RecommendedServiceWrap {...props} {...theme} />;
};

const RecommendedServiceWrapHoc = connect(mapStateToProps, mapDispatchToProps)(RecommendedServiceLoader);

export default RecommendedServiceWrapHoc;
