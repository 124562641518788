// @flow

import React from 'react';
import moment from 'moment';

import DropdownItemComponent from './DropdownItem';

type Props = {
  onMonthClick: (e: string) => void,
  listdata: string[],
  showDropDownList: boolean,
};

const DropdownList = (props: Props) => {
  window.moment = moment;
  return (
    <div className={`dropdown-list ${props.showDropDownList ? 'visible' : 'invisible'}`}>
      <ul>
        {props.listdata.map(alist => (
          <DropdownItemComponent
            monthName={alist.split(' ')[0]}
            key={`key${alist}`}
            onItemClick={() => {
              props.onMonthClick(alist);
            }}
            /* ref={this.myRef} */
          />
        ))}
      </ul>
    </div>
  );
};

export default DropdownList;
