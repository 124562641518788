// @flow

/* eslint-disable linebreak-style */
/* eslint-disable comma-dangle */
/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable camelcase */

import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import cn from 'classnames';
import AppointmentDetails from 'invite/AppointmentDetails.component';
import MyBookCompNew from 'invite/MyBookNew.component';
import SingleSalonBookingsComp from 'invite/SingleSalonBookings.component';

import { getSalonDetails } from 'booking/actions';
import ConfirmVoucherPage from 'booking/voucher/ConfirmVoucherPage.component';
import SendVoucherPage from 'booking/voucher/SendVoucherPage.component';
import type { SalonDetails } from 'types/salonDetails';

import CreateAccountForm from '../auth/login/CreateAccountFormWrap';
import CreateAccountInviteComp from '../auth/login/CreateAccountInvite';
import ForgotPassword from '../auth/login/ForgotPasswordWrap';
import LoginFormProfile from '../auth/login/LoginFormProfileWrap';
import LoginForm from '../auth/login/LoginFormWrap';
import LoginInviteComp from '../auth/login/LoginInvite';
import PasswordReset from '../auth/login/PasswordResetWrap';
import MyFeedComp from '../invite/MyFeed';
import MyProfileComp from '../invite/MyProfile';
import declinePaymentComp from '../invite/payInvite/declinePayment';
import InviteAddNewCardWrapComp from '../invite/payInvite/InviteAddNewCardWrap';
import InviteCardDetail from '../invite/payInvite/InviteCardDetail';
import PayInviteDeposit from '../invite/payInvite/PayInviteDeposit';
import ProfileWrapComp from '../invite/ProfileWrap';
import setMomentTimezone from '../utils/functions/setMomentTimezone';

import AddNewCard from './confirmBooking/AddNewCardWrap';
import BookingConfirmed from './confirmBooking/BookingConfirmedSummary';
import ConfirmWithCardWrap from './confirmBooking/ConfirmWithCardWrap';
import PatchSelection from './patchSelection/PatchSelectionWrap';
import RecommendedServiceWrap from './serviceSelection/RecommendedServiceWrap';
import ServiceSelection from './serviceSelection/ServiceSelectionWrap';
import FavouriteStylistSelect from './stylistSelection/FavouriteStylistSelectWrap';
import StylistSelection from './stylistSelection/StylistSelectionWrap';
import SummaryWrapComponent from './summary/SummaryWrap';
import TimeSelection from './timeSelection/TimeSelectionWrap';

import CN from './BookingWrap.module.scss';

type Props = {
  match: {
    params: {
      id: string,
    },
  },
  // eslint-disable-next-line react/no-unused-prop-types
  salonDetails: SalonDetails,
  timezone: string,
  getSalonDetails: (salonId: number) => void,
  salonDetailsList: any,
};

type State = {};

export class BookingWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    const { timezone, match, getSalonDetails } = this.props;
    getSalonDetails(parseInt(match.params.id, 10));

    setMomentTimezone(timezone);
  }

  render() {
    const { salonDetailsList } = this.props;
    const THEME_MODE = salonDetailsList.map(salon => salon.ncob_mode);

    return (
      // Always get the first(parent) salon ncob_mode theme
      <div
        data-bem="BookingWrap"
        className={cn({
          [CN.component]: true,
          [THEME_MODE[0]]: true,
        })}
      >
        <div className="app-wrap">
          <Switch>
            <Route exact path="/salon/:id" component={ServiceSelection} />
            <Route exact path="/salon/:id/search/:searchQueryId" component={ServiceSelection} />
            <Route path="/salon/:id/recommendedservice" component={RecommendedServiceWrap} />
            <Route path="/salon/:id/timeselect" component={TimeSelection} />
            <Route path="/salon/:id/patchtimeselect" component={PatchSelection} />
            <Route path="/salon/:id/stylistselect" component={StylistSelection} />
            <Route path="/salon/:id/favouritestylistselect" component={FavouriteStylistSelect} />
            <Route path="/salon/:id/patchtimeselect" component={TimeSelection} />
            <Route path="/salon/:id/summary" component={SummaryWrapComponent} />
            <Route path="/salon/:id/login" component={LoginForm} />

            <Route path="/salon/:id/profile-login" component={LoginFormProfile} />
            <Route path="/salon/:id/create-account" component={CreateAccountForm} />
            <Route path="/salon/:id/confirm-with-card" component={ConfirmWithCardWrap} />

            <Route path="/salon/:id/add-new-card" component={AddNewCard} />
            <Route path="/salon/:id/bookingConfirmed" component={BookingConfirmed} />
            <Route path="/salon/:id/forgot" component={ForgotPassword} />
            <Route path="/salon/:id/reset" component={PasswordReset} />
            <Route path="/salon/:id/profile" component={ProfileWrapComp} />
            <Route path="/salon/:id/my-profile" component={MyProfileComp} />

            <Route path="/salon/:id/my-book" component={MyBookCompNew} />

            <Route path="/salon/:id/salon-booking/:bookingsSalonId" component={SingleSalonBookingsComp} />
            <Route path="/salon/:id/:bookingsSalonId/appointment/:appointmentId" component={AppointmentDetails} />

            <Route path="/salon/:id/my-feeds" component={MyFeedComp} />
            <Route path="/salon/:id/pay-deposit/:appointmentId" component={PayInviteDeposit} />
            <Route path="/salon/:id/pay-deposit-add-new-card" component={InviteAddNewCardWrapComp} />
            <Route path="/salon/:id/card-details/:uniqLink" component={InviteCardDetail} />
            <Route path="/salon/:id/decline/" component={declinePaymentComp} />
            <Route path="/invite/:id/:uniqcode/" component={CreateAccountInviteComp} />
            <Route path="/login/:id/:uniqcode" component={LoginInviteComp} />
            <Route path="/salon/:id/voucher/send" component={SendVoucherPage} />
            <Route path="/salon/:id/voucher/confirmation" component={ConfirmVoucherPage} />
            {/* TODO: remove the following as soon as the above is working */}
            <Route path="/salon/:id/completed" component={ConfirmVoucherPage} />
          </Switch>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { booking } = state;
  const { salonDetails, isFetching, salonDetailsList } = booking;
  return {
    salonDetails,
    timezone: salonDetails.timezone,
    isFetching,
    salonDetailsList,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getSalonDetails(salonId: number) {
      dispatch(getSalonDetails(salonId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingWrap);
