import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import IMG_ARROW from 'assets/images/icons/arrow-icon-dark.svg';
import IMG_GIFT from 'assets/images/icons/gift-icon-purple.svg';
import { useTheme } from 'providers/Theme.provider';
import {
  selectCurrentSalonCanTakeVoucher,
  selectCurrentSalonStripeVoucherLink,
} from 'selectors/currentSalon.selectors';
import fromAnyToString from 'utils/converters/fromAnyToString';

import CN from './BuyVoucherButton.module.scss';

const BuyVoucherButton = ({ canTakeVoucher, stripeVoucherLink }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const stripeLink = fromAnyToString(stripeVoucherLink);

  if (!stripeLink || !canTakeVoucher) return null;

  // TODO: crete ExternalLink component

  return (
    <a
      href={stripeLink}
      target="_blank"
      rel="noopener noreferrer"
      data-bem="BuyVoucherButton"
      className={cn({
        [CN.component]: true,
        [CN.dark]: isDarkMode,
        [CN.neutral]: isNeutralMode,
      })}
    >
      <div className={CN.conteiner}>
        <div className={CN.content}>
          <img src={IMG_GIFT} alt="Buy a gift voucher icon" className={CN.icon} />
          <div className={CN.text}>
            <div className={CN.title}>Buy a gift voucher</div>
            <div className={CN.subtitle}>Send a gift to friends & family</div>
          </div>
        </div>
        <img src={IMG_ARROW} alt="Buy a gift voucher button arrow" className={CN.arrow} />
      </div>
    </a>
  );
};

export const mapStateToProps = state => ({
  canTakeVoucher: selectCurrentSalonCanTakeVoucher(state),
  stripeVoucherLink: selectCurrentSalonStripeVoucherLink(state),
});

const BuyVoucherButtonHoc = connect(mapStateToProps)(BuyVoucherButton);

export default BuyVoucherButtonHoc;
