import { API_ROOT } from 'config/index';
import { AUTH_API } from 'middleware/api';
import withOptions from 'utils/functions/withOptions.util';
import actionType from 'utils/mw/actionType.util';
import asObject from 'utils/objects/asObject.util';

const ATAG = 'beSendEgiftVoucher';
const AT = actionType(ATAG);

// SEE: https://getslick.atlassian.net/browse/GS-12578?focusedCommentId=17424

const beSendEgiftVoucher = withOptions(({ sessionId, salonId, data }) => {
  const d = asObject(data);

  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint: `payments/voucher/${salonId}/send/${sessionId}/`,
      authenticated: false,
      types: [AT.init, AT.pass, AT.fail, AT.done],
      config: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          recipient_name: d.recepientName,
          recipient_email: d.recepientEmail,
          purchaser_name: d.purchaserName,
          custom_message: d.customMessage,
        }),
      },
    },
  };
});

beSendEgiftVoucher.ATAG = ATAG;
beSendEgiftVoucher.AT = AT;

export default beSendEgiftVoucher;
