import React from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';

import fromAnyToTrimmed from 'utils/converters/any/fromAnyToTrimmed.util';
import logger from 'utils/logger.util';
import reactChildrenLike from 'utils/react/childrenLike.util';
import hasReactChildren from 'utils/react/hasReactChildren.util';

import CN from './SalonModal.module.scss';

const L = logger('SalonModal');

const MODAL_STYLE = Object.freeze({ overlay: { zIndex: 1000001 } });
const MODAL_CLASS = Object.freeze({ base: CN.wrap, afterOpen: CN.wrap, beforeClose: CN.wrap });

const Body = ({ children }) => <>{children}</>;
const Footer = ({ children }) => <>{children}</>;
const Title = ({ children }) => <>{children}</>;
const Header = ({ children }) => <>{children}</>;

const SalonModal = ({ tag, title, isOpen, children, variant }) => {
  // stops the modal component from complaining in browser's console
  // noinspection JSUnresolvedFunction
  ReactModal.setAppElement('body');

  const bodyChildren = reactChildrenLike(Body, children);
  const footChildren = reactChildrenLike(Footer, children);
  const titleChildren = reactChildrenLike(Title, children);
  const headChildren = reactChildrenLike(Header, children);

  const t = fromAnyToTrimmed(tag);

  if (isOpen) {
    L.debug('()', 'tag:', t);
  }

  return (
    <ReactModal
      ariaHideApp
      className={MODAL_CLASS}
      closeTimeoutMS={0}
      contentLabel={title}
      isOpen={isOpen}
      role="dialog"
      shouldCloseOnOverlayClick
      style={MODAL_STYLE}
    >
      <div
        data-bem="SalonModal__content"
        data-modal-tag={t}
        className={cn({
          [CN.component]: true,
          [CN[`${variant}Variant`]]: variant,
        })}
      >
        <div
          data-bem="SalonModal__header"
          data-modal-tag={t}
          className={cn({
            [CN.header]: true,
            [CN.empty]: !hasReactChildren(titleChildren) && !hasReactChildren(headChildren),
          })}
        >
          {headChildren}

          {hasReactChildren(titleChildren) && (
            <h2 data-bem="SalonModal__title" data-modal-tag={t}>
              {titleChildren}
            </h2>
          )}
        </div>

        <div data-bem="SalonModal__body" data-modal-tag={t} className={CN.body}>
          {bodyChildren}
        </div>
        <div data-bem="SalonModal__footer" data-modal-tag={t} className={CN.footer}>
          {footChildren}
        </div>
      </div>
    </ReactModal>
  );
};

// noinspection JSUnusedGlobalSymbols
export default Object.assign(SalonModal, { Body, Footer, Title, Header });
