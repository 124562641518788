import logger from 'utils/logger.util';

const L = logger('isPast24Hours');

const DAY_IN_MS = 24 * 60 * 60 * 1000;

const isPast24Hours = string => {
  try {
    const parsed = new Date(string).getTime();
    if (Number.isNaN(parsed)) {
      L.warn('()', 'Invalid time string:', string);
      return false;
    }
    const now = new Date().getTime();
    return now - parsed >= DAY_IN_MS;
  } catch (e) {
    L.warn('()', e);
    return false;
  }
};
export default isPast24Hours;
