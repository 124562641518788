/* eslint-disable react/sort-comp */
/* eslint-disable no-useless-escape */
// @flow

import React from 'react';
import { updateAttributes } from 'react-cognito-mm';
// $FlowFixMe
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logoutUser } from 'auth/actions';
import cn from 'classnames';
import DisclaimerText from 'invite/components/DisclaimerText.component';
import Navigation from 'invite/components/Navigation.component';

import { clearBillingInfo, CreateToken, setupIntent } from 'actions/billing';
import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import SalonModal from 'components/SalonModal';
import TabButton from 'components/TabButton.component';
import TabButtonsContainer from 'components/TabButtonsContainer.component';
import { withTheme } from 'providers/Theme.provider';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import alwaysNoop from 'utils/functions/nullary/alwaysNoop.util';

import {
  clearUserCardDetails,
  deleteCard,
  getSalonDetails,
  getUserCardDetails,
  setAddCardError,
} from 'booking/actions';
import SetupFormWrap from 'booking/confirmBooking/SetupFormWrap';

import 'react-phone-number-input/style.css';
import CN from './MyProfile.module.scss';

type Props = {
  match: any,
  cardDetails: any,
  billing: any,
  history: any,
  getUserCardDetails: (username: string) => void,
  clearBillingInfo: (clearBookingInfo?: any) => void,
  clearUserCardDetails: () => void,
  deleteCard: (username: string, payMethodId?: string) => void,
  userData: any,
  username: any,
  cognitoUser: any,
  deletingCard: string,
};

type State = {
  isContact: boolean,
  isPayment: boolean,
  checked: boolean,
  isButtonDisabled: boolean,
  errors: {
    name: string,
    email: string,
  },
  errorMessage: any,
  cardDetailsCalled: boolean,
  isSaving: boolean,
  saved: boolean,
  userData: any,
  paymentError: boolean,
  removeDetailsPopup: boolean,
  isUpdating: boolean,
  logoutUser?: () => void,
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);

export class MyProfile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isContact: false,
      isPayment: true,
      isButtonDisabled: true,
      errors: {
        name: '',
        email: '',
      },
      cardDetailsCalled: false,
      saved: false,
      isSaving: false,
      userData: props.userData,
      errorMessage: null,
      paymentError: false,
      removeDetailsPopup: false,
      isUpdating: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.cardDetails.last4 && !this.state.cardDetailsCalled && !this.state.isUpdating) {
      this.props.getUserCardDetails(this.props.username);
      this.setState({ cardDetailsCalled: true });
    }
    if (nextProps.billing && nextProps.billing.setupIntent && nextProps.billing.setupIntent.status === 'succeeded') {
      if (!this.state.cardDetailsCalled) {
        this.props.getUserCardDetails(this.props.username);
        this.setState({ cardDetailsCalled: true });
      }
    }
  }

  setPaymentError = (trueOrFalse: boolean) => {
    this.setState({ paymentError: trueOrFalse });
  };

  closeErrorPopup = () => {
    this.setState({ errorMessage: null });
    this.setState({ userData: this.props.userData });
  };

  onInternationalPhone = (value: string, type: 'inter') => {
    const phoneSymbolsRegex = /^[0-9+)(\s]*$/;
    if (phoneSymbolsRegex.test(value)) {
      if (type === 'inter') {
        this.setState({ userData: { ...this.state.userData, phone: value } }, this.checkPhoneValidity);
      }
    } else {
      // If value is empty or invalid, update the state and check phone validity
      this.setState({ userData: { ...this.state.userData, phone: '' } }, this.checkPhoneValidity);
    }
  };
  checkPhoneValidity = () => {
    const { phone } = this.state.userData;
    const isPhoneValid = phone && isValidPhoneNumber(phone);
    const isPhoneEmpty = !phone || phone.length === 0;

    this.setState({ isButtonDisabled: !isPhoneEmpty && (!isPhoneValid || phone.length < 4) });
  };
  onBlurPhone = () => {
    this.checkPhoneValidity();
  };

  handleChange = (event: Event, name: string) => {
    event.preventDefault();

    this.setState({ isButtonDisabled: false });
    const { value } = (event.currentTarget: any);
    const { errors, userData } = this.state;

    switch (name) {
      case 'name': {
        errors.name = value.length < 2 ? 'Name must be 2 characters long' : '';
        this.setState({ userData: { ...userData, name: value } });
        break;
      }

      case 'email': {
        errors.email = validEmailRegex.test(value) ? '' : 'Please enter a valid email address';
        this.setState({ userData: { ...userData, email: value } });
        break;
      }
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  saveUserSettings = () => {
    const updateAttrs = {};
    if (this.state.userData.name) {
      updateAttrs.name = this.state.userData.name;
    }
    if (this.state.userData.email) {
      updateAttrs.email = this.state.userData.email;
    }
    if (this.state.userData.phone) {
      updateAttrs.phone_number = this.state.userData.phone;
    }

    this.setState({ isSaving: true }, () => {
      updateAttributes(this.props.cognitoUser, updateAttrs)
        .then(() => {
          this.setState({ isSaving: false });
          this.setState({ saved: true });
        })
        .catch(e => {
          this.setState({ isSaving: false });
          this.setState({
            // $FlowFixMe
            errorMessage: e.message || JSON.stringify(e).replaceAll(/"/g, ''),
          });
        });
    });
  };

  contactDetails = () => {
    this.setState({
      isContact: true,
      isPayment: false,
    });
  };

  paymentDetails = () => {
    // this.props.getUserCardDetails(this.props.username);
    this.setState({
      isContact: false,
      isPayment: true,
    });
  };

  removeCardDetails = () => {
    this.props.deleteCard(this.props.username, this.props.cardDetails.id);
    const interval = setInterval(() => {
      if (this.props.deletingCard === 'deleted') {
        clearInterval(interval);
        this.props.clearBillingInfo(true);
        this.props.clearUserCardDetails();
        this.setState({ removeDetailsPopup: true });
      }
    }, 500);
  };

  updateCard = () => {
    this.props.clearBillingInfo(true);
    this.props.clearUserCardDetails();
    this.setState({
      isUpdating: true,
      cardDetailsCalled: false,
    });
  };

  handleGoBack = () => {
    this.setState({
      isUpdating: false,

      isContact: false,
      isPayment: true,
    });
  };

  getInitials = name => {
    const names = name.split(' ');
    return names.map(n => n[0]).join('');
  };

  logoutUser = () => {
    if (this.props.logoutUser) {
      this.props.logoutUser();
    }
  };

  render() {
    const { isDarkMode, isNeutralMode, cardDetails } = this.props;

    const {
      errors,
      userData,
      paymentError,
      isContact,
      isPayment,
      isUpdating,

      errorMessage,
      saved,
      removeDetailsPopup,
    } = this.state;

    const initials = fromAnyToBool(userData.name) ? this.getInitials(userData.name) : '';

    const nameClasses = cn({
      [CN.input]: true,
      [CN.hasError]: errors.name,
    });

    const phoneClasses = cn({
      [CN.phoneContainer]: true,
      [CN.hasError]: fromAnyToBool(userData.phone) && !isValidPhoneNumber(userData.phone),
    });

    const emailClasses = cn({
      [CN.input]: true,
      [CN.hasError]: errors.email,
    });

    return (
      <div
        data-bem="MyProfile"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <Navigation match={this.props.match} />
        <div className={CN.wrapper}>
          <div className={CN.header}>
            <div className={CN.headerButtons}>
              {isUpdating && (
                <div
                  data-bem="MyProfile_arrowGoBack"
                  className={CN.arrowGoBack}
                  role="button"
                  tabIndex={0}
                  onClick={this.handleGoBack}
                >
                  <img src={IMG_ARROW} alt="Arrow go back" className={CN.arrowImg} />
                </div>
              )}

              <Button variant="primary" width="small" onClick={() => this.logoutUser()}>
                <div className={CN.buttonLogout}>LOGOUT</div>
              </Button>
            </div>
            <div
              className={cn({
                [CN.avatar]: true,
                [CN.isOff]: !userData.name,
              })}
            >
              {initials}
            </div>

            <Button variant="quaternary" width="small">
              <NavLink to={`/salon/${this.props.match.params.id}/my-book`} className={CN.linkContainer}>
                <div className={CN.linkToLocations} />
                <Icon variant="arrowDown" width="lg" />
              </NavLink>
            </Button>
          </div>
          <div className={CN.headerTitle}>{userData.name ? userData.name : ''}</div>

          <TabButtonsContainer>
            <TabButton isActive={isContact} onClick={this.contactDetails}>
              Contact Details
            </TabButton>
            <TabButton isActive={isPayment} onClick={this.paymentDetails}>
              Payment Details
            </TabButton>
          </TabButtonsContainer>

          {isContact && (
            <div className={CN.tabContainer} data-bem="MyProfile__tab-contact">
              <div className={CN.formContainer}>
                <form onSubmit={this.saveUserSettings}>
                  <div className={CN.inputContainer}>
                    <label htmlFor="fullName" className={CN.inputLabel}>
                      Full name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={userData.name ? userData.name : ''}
                      onChange={event => this.handleChange(event, 'name')}
                      placeholder="Eg. Jane"
                      className={nameClasses}
                    />
                    {errors.name.length > 0 && <span className={CN.error}>{errors.name}</span>}
                    <br />
                  </div>
                  <div className={CN.inputContainer}>
                    <label htmlFor="email" className={CN.inputLabel}>
                      Email address
                    </label>
                    <input
                      type="email"
                      name="email"
                      onChange={event => this.handleChange(event, 'email')}
                      placeholder="Eg. janedoe@example.com"
                      autoComplete="off"
                      value={userData.email ? userData.email : ''}
                      className={emailClasses}
                    />
                    {errors.email.length > 0 && <span className={CN.error}>{errors.email}</span>}
                  </div>
                  <div className={phoneClasses}>
                    <label htmlFor="phone" className={CN.inputLabel}>
                      Mobile number
                    </label>
                    <PhoneInput
                      defaultCountry="GB"
                      placeholder="Enter phone number"
                      value={userData.phone ? userData.phone : ''}
                      onChange={value => void this.onInternationalPhone(value, 'inter')}
                      onBlur={this.onBlurPhone}
                    />
                    {userData.phone && !isValidPhoneNumber(userData.phone) && (
                      <span className={CN.error}>Please enter valid phone number</span>
                    )}
                  </div>
                </form>
              </div>
              {/* // TODO: rewrite the hole component and validation */}
              <Footer data-bem="MyProfile__footer-contact">
                {(userData && userData.phone && userData.phone.length < 4) ||
                (userData.phone && !isValidPhoneNumber(userData.phone)) ||
                (this.state.isButtonDisabled && !this.state.isSaving) ||
                errors.name.length > 0 ||
                errors.email.length > 0 ? (
                  <Button variant="primary" width="fixed" onClick={alwaysNoop} disabled>
                    SAVE DETAILS
                  </Button>
                ) : (
                  <Button variant="primary" width="fixed" onClick={this.saveUserSettings}>
                    SAVE DETAILS
                  </Button>
                )}
              </Footer>
            </div>
          )}

          {isPayment && (
            <div className={CN.tabContainer} data-bem="MyProfile__tab-payment">
              {isUpdating ? (
                <div className="salon-list">
                  <div className="form-wrapper">
                    {/* TODO:investigate how to set new way for dark mode for stripe component */}
                    <SetupFormWrap history={this.props.history} salonId={this.props.match.params.id} />
                  </div>
                  <DisclaimerText />
                </div>
              ) : (
                <div>
                  {!cardDetails.last4 && (
                    <div data-bem="MyProfile__no-payment-card">
                      <div className={CN.headerStatus}>
                        <div className={CN.statusIcon} />
                      </div>
                      <div className={CN.message}>No payment card added</div>
                    </div>
                  )}
                  <div className={CN.cardIllustration}>
                    <div className={CN.top}>
                      <div className={CN.imgChip} />
                      <div className={CN.visaLogo} />
                    </div>
                    <div className={CN.middle}>
                      <div className={CN.starContainer}>
                        <span className={CN.star} />
                        <span className={CN.star} />
                        <span className={CN.star} />
                      </div>
                      <div className={CN.digits}> {cardDetails.last4}</div>
                    </div>
                    <div className={CN.bottom}>
                      EXP DATE
                      <div>
                        {cardDetails.exp_month} / {cardDetails.exp_year}
                      </div>
                    </div>
                  </div>
                  {cardDetails.last4 && (
                    <div className={CN.removeCardContainer}>
                      <Button variant="warning" onClick={this.removeCardDetails}>
                        <span className={CN.underline}>REMOVE THIS CARD</span>
                      </Button>
                    </div>
                  )}

                  <Footer data-bem="MyProfile__footer-payment">
                    <Button variant="secondary" width="fixed" onClick={this.updateCard}>
                      UPDATE CARD DETAILS
                    </Button>
                  </Footer>
                </div>
              )}
            </div>
          )}

          {fromAnyToBool(saved) && (
            <SalonModal tag="MyProfile__modal-profile-updated" isOpen>
              <SalonModal.Header>
                <div className={CN.closeButtonContainer}>
                  <Button variant="empty" onClick={() => this.setState({ saved: false })}>
                    <Icon variant="close" variantInvert />
                  </Button>
                </div>
              </SalonModal.Header>
              <SalonModal.Body>
                <IconStatus variant="success">
                  <Icon variant="confirm" width="xl" />
                </IconStatus>
                <div className={CN.modalContent}>
                  <div className={CN.title}>Profile updated!</div>
                  <div className={CN.subTitle} />
                </div>
              </SalonModal.Body>
              <SalonModal.Footer>
                <Button variant="primary" onClick={() => this.setState({ saved: false })}>
                  OK, CLOSE
                </Button>
              </SalonModal.Footer>
            </SalonModal>
          )}
          {fromAnyToBool(removeDetailsPopup) && (
            <SalonModal tag="MyProfile__modal-card-removed" isOpen>
              <SalonModal.Header>
                <div className={CN.closeButtonContainer}>
                  <Button variant="empty" onClick={() => this.setState({ removeDetailsPopup: false })}>
                    <Icon variant="close" variantInvert />
                  </Button>
                </div>
              </SalonModal.Header>
              <SalonModal.Body>
                <IconStatus variant="success">
                  <Icon variant="confirm" width="xl" />
                </IconStatus>
                <div className={CN.modalContent}>
                  <div className={CN.title}>Card details removed!</div>
                  <div className={CN.subTitle} />
                </div>
              </SalonModal.Body>
              <SalonModal.Footer>
                <Button variant="primary" onClick={() => this.setState({ removeDetailsPopup: false })}>
                  OK, CLOSE
                </Button>
              </SalonModal.Footer>
            </SalonModal>
          )}

          {fromAnyToBool(errorMessage) && (
            <SalonModal tag="MyProfile__modal-card-alert" isOpen>
              <SalonModal.Header>
                <div className={CN.closeButtonContainer}>
                  <Button variant="empty" onClick={() => this.setState({ removeDetailsPopup: false })}>
                    <Icon variant="close" variantInvert />
                  </Button>
                </div>
              </SalonModal.Header>
              <SalonModal.Body>
                <IconStatus variant="alert">
                  <Icon variant="alert" width="xl" />
                </IconStatus>
                <div className={CN.modalContent}>
                  <div className={CN.title}>Problems updating your details</div>
                  <div className={CN.subTitle}>{errorMessage}</div>
                </div>
              </SalonModal.Body>
              <SalonModal.Footer>
                <Button variant="primary" onClick={this.closeErrorPopup}>
                  CLOSE
                </Button>
              </SalonModal.Footer>
            </SalonModal>
          )}
          {paymentError && <span className={CN.error}>Card setup failed Please try again</span>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state): any => ({
  salonDetails: state.booking.salonDetails,
  cardDetails: state.booking.cardDetails,
  stripeToken: Object.keys(state.billing.stripeToken).length ? state.billing.stripeToken : {},
  username: state.cognito && state.cognito.user && state.cognito.user.username ? state.cognito.user.username : '',
  cognitoUser: state.cognito.user,
  userData: {
    name: state.cognito.attributes.name,
    email: state.cognito.attributes.email,
    phone: state.cognito.attributes.phone_number,
  },
  attributes: state.cognito.attributes,
  isFetching: state.booking.isFetching,
  deletingCard: state.booking.deletingCard,
  errorMessage: state.booking.errorMessage,
  billing: state.billing,
});

function mapDispatchToProps(dispatch: any) {
  return {
    dispatch,
    deleteCard(username: string, payMethodId: string) {
      dispatch(deleteCard(username, payMethodId));
    },
    getUserCardDetails(username: string) {
      dispatch(getUserCardDetails(username));
    },
    CreateToken(token: any) {
      dispatch(CreateToken(token));
    },
    setupIntent(salonId: number, username: string) {
      dispatch(setupIntent(salonId, username));
    },
    clearBillingInfo(clearBookingInfo?: any) {
      dispatch(clearBillingInfo(clearBookingInfo));
    },
    clearUserCardDetails() {
      dispatch(clearUserCardDetails());
    },
    setAddCardError(errorMessage: string) {
      dispatch(setAddCardError(errorMessage));
    },
    getSalonDetails(salonId: number) {
      dispatch(getSalonDetails(salonId));
    },
    logoutUser() {
      dispatch(logoutUser());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(MyProfile));
