import { Children } from 'react';

const reactChildrenLike = (component, children) => {
  const byArray = candidate => candidate && component.includes(candidate.type);
  const bySingle = candidate => candidate && candidate.type === component;
  return Children.toArray(children).filter(Array.isArray(component) ? byArray : bySingle);
};

// noinspection JSUnusedGlobalSymbols
export default reactChildrenLike;
