// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { persistor } from 'store.singleton';

import Spinner from 'common/Spinner.component';

import { loginUser, logoutUser } from '../actions/authentication';

type Props = {
  login: {
    isFetching: boolean,
    isAuthenticated: boolean,
    urlhash: string | null,
    errorMessage: string | null,
  },
  match: {
    isExact: boolean,
    path: string,
    url: string,
    params: {
      urlhash: string,
    },
  },
  location: string,
  fetchToken: (urlhash: string) => void,
  doLogout: () => void,
};

type State = {
  numAttempts: number,
};

class Authenticate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { numAttempts: 0 };
  }

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillMount() {
    const { match, login, fetchToken, doLogout } = this.props;
    if (login.isFetching === false && login.isAuthenticated === false) {
      fetchToken(match.params.urlhash);
      this.setState({ numAttempts: this.state.numAttempts + 1 });
    } else if (login.isFetching === false && login.isAuthenticated === true && match.params.urlhash !== login.urlhash) {
      // different auth code.   Logout & login again
      doLogout();
      fetchToken(match.params.urlhash);
      this.setState({ numAttempts: this.state.numAttempts + 1 });
    }
  }

  restart = () => {
    // Something has gone really wrong.   So let's clear out and start again
    persistor.purge();
  };

  render() {
    const { login, location } = this.props;
    return (
      <div>
        <div className="section">Re-book an Appointment</div>
        <div className="container authenticate">
          {login.isFetching && (
            <div>
              <div className="info-text">
                <div className="text-header">Checking details</div>
                <div className="text-subheader">This may take a moment</div>
              </div>
              <Spinner variant="overlay" />
            </div>
          )}
          {!login.isFetching && login.isAuthenticated && (
            <Redirect
              to={{
                pathname: '/salon',
                state: { from: location },
              }}
            />
          )}
          {!login.isFetching && !login.isAuthenticated && (
            <div>
              <div className="conditional">
                <div className="info-text">
                  <div className="text-header">
                    Ooops, sorry!
                    <br />
                    Something went wrong!
                  </div>
                  <div className="conditional-img img_error" />
                  <div className="text-subheader">{login.errorMessage}</div>
                </div>
              </div>
              <Link to="/" className="simple-button" onClick={() => this.restart()}>
                Restart
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchToken: urlhash => {
    dispatch(loginUser(urlhash));
  },
  doLogout: () => {
    dispatch(logoutUser());
  },
});

export default withRouter<any, any>(
  connect<any, Props, any, any, any, any>(mapStateToProps, mapDispatchToProps)(Authenticate),
);
