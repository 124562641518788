import { isFunction, noop } from 'lodash';

import logger from 'utils/logger.util';

const L = logger('navFor');

const NOOP_NAV = Object.freeze({
  back: noop,
  frwd: noop,
  goat: noop,
  push: noop,
  repl: noop,
});

const method = (tags, name, fn) => {
  if (isFunction(fn)) {
    const location = `${window.location}`;
    return (...args) => L.debug(...tags, '@', location, `${name}()`, '(', ...args, ')', '->', fn(...args));
  }

  L.warn('method()', ...tags, `Invalid function:`, fn, 'for name:', name);
  return noop;
};

const navFor = (...args) => {
  const props = args.pop();
  if (!props) {
    L.warn('()', 'No component props:', props);
    return NOOP_NAV;
  }

  const { history } = props;
  if (!history) {
    L.warn('()', 'No component props.history:', history);
    return NOOP_NAV;
  }

  if (Array.isArray(history)) {
    // NOTE: Some tests use simple array for history
    return { ...NOOP_NAV, push: method(args, 'push', history.push.bind(history)) };
  }

  return Object.freeze({
    goat: method(args, 'go', history.go),
    back: method(args, 'goBack', history.goBack),
    frwd: method(args, 'goForward', history.goForward),
    repl: method(args, 'replace', history.replace),
    push: method(args, 'push', history.push),
  });
};

// noinspection JSUnusedGlobalSymbols
export default navFor;
