const parseJson = string => {
  try {
    return JSON.parse(string);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('parseJson()', e, 'tried to parse:', string);
    return undefined;
  }
};

// noinspection JSUnusedGlobalSymbols
export default parseJson;
