// @flow

/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */

import {
  CHARGE_CLIENT_SECRET_FAILURE,
  CHARGE_CLIENT_SECRET_REQUEST,
  CHARGE_CLIENT_SECRET_SUCCESS,
  CLEAR_BILLING_INFO,
  CLEAR_INTENT_CLIENT_SECRET,
  CREATE_TOKEN_SUCCESS,
  GET_BOOKING_COST_FAILURE,
  GET_BOOKING_COST_REQUEST,
  GET_BOOKING_COST_SUCCESS,
  GET_CLIENT_SECRET_FAILURE,
  GET_CLIENT_SECRET_REQUEST,
  GET_TOKEN_SUCCESS,
  SET_USERNAME,
  SETUP_INTENT_FAILURE,
  SETUP_INTENT_FRONTEND,
  SETUP_INTENT_REQUEST,
  SETUP_INTENT_SUCCESS,
  UPDATE_STRIP_TOKEN_FAILURE,
  UPDATE_STRIP_TOKEN_REQUEST,
  UPDATE_STRIP_TOKEN_SUCCESS,
} from 'actions/billing';

const SEND_BOOKING_DATA_SUCCESS = 'SEND_BOOKING_DATA_SUCCESS';
export const CLEAR_PAYMENT_INTENT_ID = 'CLEAR_PAYMENT_INTENT_ID';
export const PROCCESS_INVITE_SUCCESS = 'PROCCESS_INVITE_SUCCESS';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';

export type billingTYPE = {
  errorMessage: string,
  isFetching: boolean,
  stripeToken: any,
  username: string,
  pay_today: number,
  appointment_cost: number,
  public_key: string,
  success: boolean,
  payment_intent_id: string,
  intent_client_secret: string,
  book_client_secret: string,
  isFetchingClientSecret: boolean,
  setupIntent: any,
};
export const initState: any = {
  errorMessage: '',
  isFetching: false,
  stripeToken: {},
  username: '',
  pay_today: 0,
  appointment_cost: 0,
  public_key: '',
  success: false,
  payment_intent_id: '',
  intent_client_secret: '',
  book_client_secret: '',
  isFetchingClientSecret: false,
  invite_status: '',
  setupIntent: null,
};

function billing(state: billingTYPE = initState, action: any) {
  if (action && action.type) {
    switch (action.type) {
      case SETUP_INTENT_REQUEST: {
        return {
          ...state,
          success: false,
          isFetching: true,
          payment_intent_id: '',
          // client_secret: '',
        };
      }
      case CHARGE_CLIENT_SECRET_REQUEST: {
        return {
          ...state,
          success: false,
          isFetching: true,
          payment_intent_id: '',
          client_secret: '',
        };
      }
      case GET_CLIENT_SECRET_REQUEST: {
        return {
          ...state,
          success: false,
          isFetchingClientSecret: true,
          payment_intent_id: '',
          client_secret: '',
          appointment_cost: 0,
          pay_today: 0,
        };
      }
      case GET_BOOKING_COST_REQUEST: {
        return {
          ...state,
          success: false,
          pay_today: 0,
          appointment_cost: 0,
          isFetchingCost: true,
        };
      }
      case GET_BOOKING_COST_SUCCESS: {
        return {
          ...state,
          appointment_cost: parseFloat(action.response.appointment_cost.toFixed(2)),
          pay_today: parseFloat(action.response.pay_today.toFixed(2)),
          errorMessage: '',
          isFetchingCost: false,
        };
      }
      case GET_BOOKING_COST_FAILURE: {
        return {
          ...state,
          isFetchingCost: false,
          errorMessage: action.errorMessage,
        };
      }
      case CLEAR_BILLING_INFO: {
        if (action.clearBookingInfo) {
          return {
            ...state,
            success: false,
            isFetching: false,
            payment_intent_id: '',
            intent_client_secret: '',
            book_client_secret: '',
            pay_today: 0,
            appointment_cost: 0,
            stripeToken: {},
            setupIntent: null,
          };
        }
        return {
          ...state,
          success: false,
          isFetching: false,
          payment_intent_id: '',
          client_secret: '',
          stripeToken: {},
        };
      }
      /* case GET_CLIENT_SECRET_SUCCESS: {
        const intent_client_secret =
          action.response && action.response.client_secret ? action.response.client_secret : state.intent_client_secret;
        return {
          ...state,
          isFetchingClientSecret: false,
          ...action.response, // include payment_intent_id
          intent_client_secret,
          errorMessage: '',
        };
      } */
      case GET_CLIENT_SECRET_FAILURE: {
        return {
          ...state,
          isFetchingClientSecret: false,
          errorMessage: action.response.errorMessage,
        };
      }
      case CHARGE_CLIENT_SECRET_SUCCESS: {
        const updatedResponse = Object.assign({}, action.response);
        updatedResponse.pay_today = parseFloat(updatedResponse.pay_today.toFixed(2));
        return {
          ...state,
          isFetching: false,
          ...updatedResponse,
          errorMessage: '',
        };
      }
      case SETUP_INTENT_SUCCESS: {
        const intent_client_secret = action.response.client_secret
          ? action.response.client_secret
          : state.intent_client_secret;
        return {
          ...state,
          isFetching: false,
          intent_client_secret,
          errorMessage: '',
        };
      }
      case SETUP_INTENT_FRONTEND: {
        return {
          ...state,
          setupIntent: action.setupIntentFromStrip,
        };
      }
      case CREATE_TOKEN_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          stripeToken: action.stripetoken.token,
          errorMessage: '',
        };
      }
      case GET_TOKEN_SUCCESS: {
        return {
          ...state,
          stripeToken: state.stripeToken,
          errorMessage: '',
        };
      }
      case UPDATE_STRIP_TOKEN_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case UPDATE_STRIP_TOKEN_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          stripeToken: action.stripetoken,
          errorMessage: '',
        };
      }
      case SETUP_INTENT_FAILURE:
      case CHARGE_CLIENT_SECRET_FAILURE:
      case UPDATE_STRIP_TOKEN_FAILURE:
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      case SET_USERNAME:
        return {
          ...state,
          username: action.username,
        };
      case SEND_BOOKING_DATA_SUCCESS: {
        let { book_client_secret } = state;
        if (action.response.pi_status === 'requires_action') {
          book_client_secret = action.response.client_secret;
        }
        return {
          ...state,
          book_client_secret,
          payment_method: action.response.payment_method,
          payment_intent_id: action.response.pi,
        };
      }
      case CLEAR_PAYMENT_INTENT_ID: {
        return {
          ...state,
          payment_intent_id: null,
        };
      }
      case CLEAR_INTENT_CLIENT_SECRET: {
        return {
          ...state,
          intent_client_secret: null,
        };
      }
      case PROCCESS_INVITE_SUCCESS: {
        return {
          ...state,
          payment_intent_id: action.response.pi,
          intent_client_secret: action.response.client_secret, // guan commented out on 24th May
          payment_method: action.response.payment_method,
          invite_status: action.response.r_status,
        };
      }
      case 'CLEAR_EXTRA_AUTHENTICATION_INFO': {
        // clear the 'requires_action' info that 3d authentication produced
        return {
          ...state,
          book_client_secret: '',
        };
      }
      case DELETE_CARD_SUCCESS: {
        return {
          ...state,
          setupIntent: null,
        };
      }
      default:
        return state;
    }
  }
  return state;
}

export default billing;
