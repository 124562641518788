// @flow
/* eslint-disable camelcase */
/* eslint-disable max-len */

import STORE from 'store.singleton';

import { clearBillingInfo } from '../actions/billing';
import {
  clearAvailableDays,
  clearAvailableSlots,
  clearExtraAuthenticationInfo,
  clearPageVisited,
  clearSalonDetails,
  clearSelectedServices,
  clearUserCardDetails,
  getCategoriesWithServices,
  getSalonDetails,
  updateFirstAvailableDate,
} from '../booking/actions';

const clearCache = () => {
  const salonId = parseInt(window.document.location.href.split('#/salon/')[1].split('/')[0], 10);
  STORE.dispatch(clearBillingInfo(true));
  STORE.dispatch(clearExtraAuthenticationInfo());
  STORE.dispatch(clearSelectedServices());
  STORE.dispatch(clearAvailableSlots());
  STORE.dispatch(clearAvailableDays());
  STORE.dispatch(clearSalonDetails());
  STORE.dispatch(clearUserCardDetails());
  STORE.dispatch(clearPageVisited());
  STORE.dispatch(getSalonDetails(salonId));
  STORE.dispatch(getCategoriesWithServices(salonId));
  STORE.dispatch(updateFirstAvailableDate(null));
};

export default clearCache;
