// @flow
import React from 'react';

type Props = {
  onClick: () => void,
  className: string,
};

const CustomPrevArrow = (props: Props) => (
  <div role="button" tabIndex={0} className={props.className} onClick={props.onClick} />
);

export default CustomPrevArrow;
