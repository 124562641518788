/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from 'react';
import { connect } from 'react-redux';
import Navigation from 'invite/components/Navigation.component';
import RequestItem from 'invite/components/SalonRequestItem';
import DeclinedPayment from 'invite/DeclinedPayment.component';
import NoDeposit from 'invite/NoDeposit.component';
import SuccessfulPayment from 'invite/SuccessfulPayment.component';
import { subscribe, unsubscribe } from 'pusher-redux';

import Button from 'components/Button.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import SalonModal from 'components/SalonModal';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import fromAnyDateToTimestamp from 'utils/converters/time/fromAnyDateToTimestamp.util';
import isPast24Hours from 'utils/date/isPast24Hours.util';

import { getClientInvites, INVITATION_SENT, processInvite, setInvitePostData, setPayFullAmount } from 'booking/actions';

import CN from './MyFeed.module.scss';

export class MyFeed extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      bookingRequest: props.invites,
      clientName: props.cognito.attributes.name,
      requestCount: props.invites.filter(i => i.status === 'invited' || i.status === 'reminded').length,
      popupSet: false,
      showSuccessPopup: false,
      isBookingErrorDismissed: false,
    };
  }

  componentWillMount() {
    if (this.props.cognito.user && this.props.cognito.user.username) {
      this.props.getClientInvites(this.props.cognito.user.username);
    }
  }

  componentDidMount() {
    this.subscribe();
    window.scrollTo(0, 0);
    const salonId = (this.props.location: any).pathname.split('/')[2];
    if (!this.props.cognito.user) {
      this.props.history.push(`/salon/${salonId}/login`);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const byReminded = ({ status }) => status === 'invited' || status === 'reminded';

    const {
      invites: nextInvites,
      cognito: prevCognito,
      confirmAuthenticationStatus,
      inviteStatus,
      confirmAuthenticationSuccess,
      invitePostData,
    } = nextProps;
    const { invites: prevInvites, getClientInvites, cognito: nextCognito } = this.props;
    const newInviteCount = nextInvites.filter(byReminded).length;
    const oldInviteCount = prevInvites.filter(byReminded).length;

    if (newInviteCount !== oldInviteCount) {
      this.setState({ requestCount: newInviteCount });
      if (nextCognito && nextCognito.user) {
        getClientInvites(nextCognito.user.username);
      }
    }

    if (invitePostData === 'DECLINED') {
      if (nextCognito && nextCognito.user) {
        getClientInvites(nextCognito.user.username);
      }
    }

    if (
      invitePostData === 'SUCCESS' &&
      !this.state.popupSet &&
      (confirmAuthenticationSuccess || inviteStatus !== 'requires_action')
    ) {
      this.showPopup('showSuccessPopup', true);
      this.setState({ popupSet: true });
    } else if (invitePostData === 'FAILED' && !this.state.popupSet && confirmAuthenticationStatus !== 'succeeded') {
      this.setState({ popupSet: true });
    }
    if (confirmAuthenticationStatus === 'succeeded' && !this.state.popupSet) {
      this.showPopup('showSuccessPopup', true);
      this.setState({ popupSet: true });
    }
    if (prevCognito && prevCognito.attributes && prevCognito.attributes.name && !this.state.clientName) {
      this.setState({ clientName: prevCognito.attributes.name });
    }

    const sortedInvites = nextProps.invites.sort(
      (a, b) => fromAnyDateToTimestamp(b.appointment.start_time) - fromAnyDateToTimestamp(a.appointment.start_time),
    );
    this.setState({ bookingRequest: sortedInvites });
  }

  closeAllPopup = () => {
    this.props.setInvitePostData('');
    if (this.props.invitePostData === '') {
      this.showPopup('showDeclinePopup', false);
      this.showPopup('showSuccessPopup', false);
    }
  };

  closePaymentPopup = () => {
    // FIXME: this is not the correct way to dismiss the error, it should be isBookingErrorDismissed: false
    // FIXME: this is not the correct way to permanently dismiss the error, it should be cleared through dispatch
    this.setState({ isBookingErrorDismissed: !this.state.isBookingErrorDismissed });
  };
  showPopup = (name: string, showHide: boolean) => {
    this.setState({ [name]: showHide });
  };
  startBook = () => {
    this.props.history.push(`/salon/${this.props.match.params.id}`);
  };
  subscribe = () => {
    subscribe(`${this.props.salonDetails.id}_channel`, 'invitation', INVITATION_SENT);
  };
  unsubscribe = () => {
    unsubscribe(`${this.props.salonDetails.id}_channel`, 'invitation', INVITATION_SENT);
  };

  render() {
    const { bookingRequest, clientName, isBookingErrorDismissed } = this.state;
    const { bookingError } = this.props;
    const salonNames = this.props.invites.map(invite => invite.salon_name);
    const set = new Set(salonNames);
    const uniqSalonNames = Array.from(set);
    const { confirmAuthenticationSuccess, invitePostData, currentInviteSalon } = this.props;
    let popupTitle;
    if (this.props.invitePostData === 'SUCCESS' && !this.props.currentInviteCardOnly) {
      popupTitle = 'Your payment is successful';
    } else if (this.props.invitePostData === 'SUCCESS' && this.props.currentInviteCardOnly) {
      popupTitle = 'Your card has been successfully added';
    } else if (this.props.invitePostData === 'FAILED') {
      popupTitle = 'We are unable to take your payment';
    } else if (this.props.invitePostData === 'DECLINED') {
      popupTitle = `Ok we've told ${currentInviteSalon} you've declined.`;
    }
    if (this.props.bookingError) {
      popupTitle = 'We are unable to take your payment';
    }

    let newAppointmentsCountForSalon = 0;
    for (const name of uniqSalonNames) {
      const invitesFromSalon = this.state.bookingRequest.filter(
        invite => invite.salon_name === name && (invite.status === 'invited' || invite.status === 'reminded'),
      );

      for (const invite of invitesFromSalon) {
        const isNew = !isPast24Hours(invite.appointment.start_time);
        if (isNew) {
          newAppointmentsCountForSalon += 1;
        }
      }
    }

    return (
      <div className="app-content no-margin">
        <Navigation match={this.props.match} />

        <div className={CN.container}>
          {this.state.requestCount === 0 ? (
            <NoDeposit />
          ) : (
            <>
              {bookingRequest && clientName && (
                <div className={CN.container}>
                  <div>
                    <div className={CN.pageTitle}>Deposits</div>
                    {newAppointmentsCountForSalon > 0 && (
                      <div className={CN.requestNewLabel}>{newAppointmentsCountForSalon} new</div>
                    )}
                  </div>
                  {uniqSalonNames.map(name => {
                    const invitesFromSalon = this.state.bookingRequest.filter(
                      invite =>
                        invite.salon_name === name && (invite.status === 'invited' || invite.status === 'reminded'),
                    );

                    return (
                      <div key={name}>
                        {invitesFromSalon.map(invite => (
                          <div key={invite.id}>
                            <RequestItem
                              uniqSalonName={name}
                              invite={invite}
                              history={this.props.history}
                              match={this.props.match}
                              username={this.props.cognito.user.username}
                              processInvite={this.props.processInvite}
                              invitePostData={invitePostData}
                              salonName={this.props.salonDetails.name}
                              setPayFullAmount={this.props.setPayFullAmount}
                              setInvitePostData={this.props.setInvitePostData}
                              getClientInvites={this.props.getClientInvites}
                              isFetching={this.props.isFetching}
                              confirmAuthenticationSuccess={confirmAuthenticationSuccess}
                              confirmAuthenticationStatus={this.props.confirmAuthenticationStatus}
                              invite_status={this.props.inviteStatus}
                              currentInvite={this.props.currentInvite}
                            />
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>

        {this.props.invitePostData === 'DECLINED' && (
          <DeclinedPayment onClose={this.closeAllPopup} title={popupTitle} />
        )}

        {this.state.showSuccessPopup && this.props.invitePostData === 'SUCCESS' && (
          <SuccessfulPayment title={popupTitle} onClose={this.closeAllPopup} />
        )}

        {fromAnyToBool(bookingError && isBookingErrorDismissed) && (
          <SalonModal tag="MyFeed__modal-warning" isOpen>
            <SalonModal.Body>
              <IconStatus variant="warning">
                <Icon variant="warning" width="xl" />
              </IconStatus>
              <div className={CN.modalContent}>
                <div className={CN.title}>{popupTitle}</div>
                <div className={CN.subTitle}>Your payment was not successful.</div>
              </div>
            </SalonModal.Body>
            <SalonModal.Footer>
              <Button variant="primary" onClick={this.closePaymentPopup}>
                OK
              </Button>
            </SalonModal.Footer>
          </SalonModal>
        )}
      </div>
    );
  }
}

export const mapStateToProps = (state: any): any => ({
  salonDetails: state.booking.salonDetails,
  invites: state.booking.invites,
  invitePostData: state.booking.invitePostData,
  cognito: state.cognito,
  location: state.router.location,
  isFetching: state.booking.isFetching,
  confirmAuthenticationSuccess: state.booking.confirmAuthenticationSuccess,
  confirmAuthenticationStatus: state.booking.confirmAuthenticationStatus,
  inviteStatus: state.billing.invite_status,
  currentInvite: state.booking.currentInvite,
  currentInviteSalon: state.booking.currentInviteSalon,
  currentInviteCardOnly: state.booking.currentInviteCardOnly,
  bookingError: state.booking.errorMessage,
});

export function mapDispatchToProps(dispatch: any) {
  return {
    processInvite(cognitoUsername: string, inviteId: string, data: any) {
      dispatch(processInvite(cognitoUsername, inviteId, data));
    },
    setPayFullAmount(appointmentId: string, inviteId: string) {
      dispatch(setPayFullAmount(appointmentId, inviteId));
    },
    getClientInvites(cognitoUsername: string) {
      dispatch(getClientInvites(cognitoUsername));
    },
    setInvitePostData(status: string) {
      dispatch(setInvitePostData(status));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyFeed);
