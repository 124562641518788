// @flow

/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

import { fetchAvailabilityList } from 'actions/availability';
import SalonHeader from 'components/SalonHeader';
import type { AvailabilityState } from 'reducers/availability';

import Spinner from 'common/Spinner.component';
import type { Appointment } from 'types/appointment';
import type { Client } from 'types/client';

type Props = {
  day: moment | null,
  match: {
    isExact: boolean,
    path: string,
    url: string,
    params: {
      client_id: string,
    },
  },
  appointment: Appointment,
  client: Client,
  availability: AvailabilityState,
  doFetchAvailability: (appt_id: string, start: moment, end: moment) => void,
};

type State = {
  start: moment,
  end: moment,
};

class AppointmentTime extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let start = moment()
      .add(1, 'days')
      .startOf('day');
    if (props.day) {
      start = moment(props.day).startOf('day');
    }
    const end = moment(start)
      .add(2, 'days')
      .startOf('day');
    this.state = {
      start,
      end,
    };
  }

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillMount() {
    const { doFetchAvailability, appointment } = this.props;
    doFetchAvailability(`${appointment.id}`, this.state.start, this.state.end);
  }

  componentDidMount() {
    ReactGA.event({
      category: 'Page View',
      action: 'Stared booking',
      label: 'Reached timeslot selection page',
    });
  }

  earlier = () => {
    const newStart = moment(this.state.start).subtract(1, 'days');
    if (newStart.isBefore(moment().startOf('day'))) {
      return;
    }
    const newEnd = moment(newStart).add(3, 'days');
    const { doFetchAvailability, appointment } = this.props;
    doFetchAvailability(`${appointment.id}`, newStart, newEnd);
    this.setState({ start: newStart, end: newEnd });
  };
  later = () => {
    const newStart = moment(this.state.start).add(1, 'days');
    const newEnd = moment(newStart).add(3, 'days');
    const { doFetchAvailability, appointment } = this.props;
    doFetchAvailability(`${appointment.id}`, newStart, newEnd);
    this.setState({ start: newStart, end: newEnd });
  };

  render() {
    const { appointment, availability, client } = this.props;
    const leftDay = moment(this.state.start);
    const centreDay = moment(this.state.start).add(1, 'days');
    const rightDay = moment(this.state.start).add(2, 'days');
    let minSlots = 96;
    let slots = availability.days[leftDay.format('YYYYMMDD')];
    if (slots) {
      minSlots = Math.min(slots.length, minSlots);
    }
    slots = availability.days[centreDay.format('YYYYMMDD')];
    if (slots) {
      minSlots = Math.min(slots.length, minSlots);
    }
    slots = availability.days[rightDay.format('YYYYMMDD')];
    if (slots) {
      minSlots = Math.min(slots.length, minSlots);
    }
    const thresholdForMinToastSlots = 12;
    let totalDuration = moment.duration(0, 'minutes');
    let totalPrice = 0;
    let stylists = '';
    let newTitle = '';
    appointment.procedures.sort((a, b) => (moment(b.start_time).isBefore(a.start_time) ? 1 : -1));
    if (appointment !== undefined && appointment.procedures !== undefined) {
      appointment.procedures.forEach(d => {
        totalPrice += d.price;
        totalDuration = totalDuration.add(moment.duration(d.duration, 'minutes'));
      });
      // $FlowFixMe
      stylists = appointment.procedures.map(proc => proc.stylist.user_name).join(', ');
      // $FlowFixMe
      newTitle = appointment.procedures.map(proc => proc.service.name).join(', ');
    }
    return (
      <div>
        {minSlots < thresholdForMinToastSlots && (
          <div className="toast">
            <span className="arrow-up" />
            <div className="text-header">There is limited availability for your service</div>
            <div className="text-subheader">
              If you can&apos;t find a time suitable then give us a call and we&apos;ll see if we can fit you in.
            </div>
          </div>
        )}
        <SalonHeader client={client} />
        <div className="section">Re-book an Appointment</div>
        <div className="container appointment-time">
          <div className="full-width-wrap">
            <div className="service-list-full">
              <div className="text-stylist">
                <span>{stylists}</span>
              </div>
              <div className="text-price">£{totalPrice}</div>
              <div className="text-service">
                {newTitle} ({totalDuration.humanize()})
              </div>
            </div>
            <div className="info-text-full">
              <div className="text-header">Please select a time for your appointment</div>
            </div>
            <div className="time-picker-full clearfix">
              <span
                id="left-nav"
                className="arrow-button ico-chevron-left"
                role="button"
                tabIndex={0}
                onClick={() => this.earlier()}
              />
              <span
                id="right-nav"
                className="arrow-button ico-chevron-right"
                role="button"
                tabIndex={0}
                onClick={() => this.later()}
              />
              {availability.isFetching ? (
                <Spinner variant="overlay" />
              ) : (
                <div className="time-picker-columns">
                  {availability.errorMessage && (
                    <div id="error" className="info-well">
                      <div className="text-header">Sorry, something went wrong</div>
                      <div className="text-subheader">{availability.errorMessage}</div>
                    </div>
                  )}
                  <div className="left-column">
                    <div className="date-header">
                      <span>{leftDay.format('ddd')}</span>
                      {leftDay.format('D MMM')}
                    </div>
                    {availability.days[leftDay.format('YYYYMMDD')] ? (
                      availability.days[leftDay.format('YYYYMMDD')].map(avail => (
                        <span className="button-wrap" key={avail}>
                          <Link
                            to={{
                              pathname: `/confirm/${appointment.id}`,
                              state: {
                                start_time: avail,
                                end_time: moment(avail).add(moment.duration(totalDuration, 'minutes')),
                                title: newTitle,
                                stylists,
                              },
                            }}
                            id="available-time"
                            className="simple-button"
                            key={client.id}
                          >
                            {moment(avail).format('H:mm')}
                          </Link>
                        </span>
                      ))
                    ) : (
                      <div className="date-full">No available times</div>
                    )}
                  </div>
                  <div className="center-column">
                    <div className="date-header">
                      <span>{centreDay.format('ddd')}</span>
                      {centreDay.format('D MMM')}
                    </div>
                    {availability.days[centreDay.format('YYYYMMDD')] ? (
                      availability.days[centreDay.format('YYYYMMDD')].map(avail => (
                        <span className="button-wrap" key={avail}>
                          <Link
                            to={{
                              pathname: `/confirm/${appointment.id}`,
                              state: {
                                start_time: avail,
                                end_time: moment(avail).add(moment.duration(totalDuration, 'minutes')),
                                title: newTitle,
                                stylists,
                              },
                            }}
                            id="available-time"
                            className="simple-button"
                            key={client.id}
                          >
                            {moment(avail).format('H:mm')}
                          </Link>
                        </span>
                      ))
                    ) : (
                      <div className="date-full">No available times</div>
                    )}
                  </div>
                  <div className="right-column">
                    <div className="date-header">
                      <span>{rightDay.format('ddd')}</span>
                      {rightDay.format('D MMM')}
                    </div>
                    {availability.days[rightDay.format('YYYYMMDD')] ? (
                      availability.days[rightDay.format('YYYYMMDD')].map(avail => (
                        <span className="button-wrap" key={avail}>
                          <Link
                            to={{
                              pathname: `/confirm/${appointment.id}`,
                              state: {
                                start_time: avail,
                                end_time: moment(avail).add(moment.duration(totalDuration, 'minutes')),
                                title: newTitle,
                                stylists,
                              },
                            }}
                            id="available-time"
                            className="simple-button"
                            key={client.id}
                          >
                            {moment(avail).format('H:mm')}
                          </Link>
                        </span>
                      ))
                    ) : (
                      <div className="date-full">No available times</div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  client: state.selection.client,
  appointment: state.selection.appointment,
  availability: state.availability,
});

const mapDispatchToProps = (dispatch: any) => ({
  doFetchAvailability: (apptId, start, end) => {
    dispatch(fetchAvailabilityList(apptId, start, end));
  },
});

export const AppointmentTimeComponent = AppointmentTime;

export default withRouter<any, any>(
  connect<any, Props, any, any, any, any, any>(mapStateToProps, mapDispatchToProps)(AppointmentTime),
);
