const external = Object.freeze({
  privacyPolicy: 'https://www.getslick.com/privacy-policy',
});

const LINK = Object.freeze({
  //
  salon: '/salon/:salonId',
  //
  favStylistSelect: `/salon/:salonId/favouritestylistselect`,
  recommendedService: `/salon/:salonId/recommendedservice`,
  timeSelect: `/salon/:salonId/timeselect`,
  bookingConfirmed: `/salon/:salonId/bookingConfirmed`,
  addNewCard: `/salon/:salonId/add-new-card`,
  //
  external,
  //
  voucherSend: `/salon/:salonId/voucher/send?session_id=:sessionId`,
});

export default LINK;
