// @flow

/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import moment from 'moment-timezone/moment-timezone';
import type { Dispatch as ReduxDispatch } from 'redux';
import STORE from 'store.singleton';

import { fetchGithub } from 'actions/authentication';
import { API_ROOT } from 'config/index';
import LSKEY from 'constants/lskey.const';
import { AUTH_API } from 'middleware/api';
import fromAnyToTrimmed from 'utils/converters/any/fromAnyToTrimmed.util';
import fromDateToMomentTzFormat from 'utils/date/fromDateToMomentTzFormat.util';
import lsGet from 'utils/dom/lsGet.util';
import lsSet from 'utils/dom/lsSet.util';

import type { AvailabilitySlot } from 'types/availabilitySlot';
import type { NewBooking } from 'types/newBooking';
import type { PatchTestBooking } from 'types/patchTestBooking';
import type { Service } from 'types/service';
import type { ServicesToBook } from 'types/servicesToBook';
import type { Stylist } from 'types/stylistsForService';

export const SALON_DETAILS_REQUEST = 'SALON_DETAILS_REQUEST';
export const SALON_DETAILS_SUCCESS = 'SALON_DETAILS_SUCCESS';
export const SALON_DETAILS_FAILURE = 'SALON_DETAILS_FAILURE';
export const GET_SERVICES_REQUEST = 'GET_SERVICES_REQUEST';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE';
export const SELECT_SERVICE = 'SELECT_SERVICE';
export const CLEAR_SELECTED_SERVICES = 'CLEAR_SELECTED_SERVICES';
export const GET_BOOKING_AVAILABILITY_REQUEST = 'GET_BOOKING_AVAILABILITY_REQUEST';
export const GET_BOOKING_AVAILABILITY_SUCCESS = 'GET_BOOKING_AVAILABILITY_SUCCESS';
export const GET_BOOKING_AVAILABILITY_FAILURE = 'GET_BOOKING_AVAILABILITY_FAILURE';
export const GET_WEEKLY_AVAILABILITY_REQUEST = 'GET_WEEKLY_AVAILABILITY_REQUEST';
export const GET_WEEKLY_AVAILABILITY_SUCCESS = 'GET_WEEKLY_AVAILABILITY_SUCCESS';
export const GET_WEEKLY_AVAILABILITY_FAILURE = 'GET_WEEKLY_AVAILABILITY_FAILURE';
export const GET_PATCH_WEEKLY_AVAILABILITY_REQUEST = 'GET_PATCH_WEEKLY_AVAILABILITY_REQUEST';
export const GET_PATCH_WEEKLY_AVAILABILITY_SUCCESS = 'GET_PATCH_WEEKLY_AVAILABILITY_SUCCESS';
export const GET_PATCH_WEEKLY_AVAILABILITY_FAILURE = 'GET_PATCH_WEEKLY_AVAILABILITY_FAILURE';
export const GET_PATCH_AVAILABILITY_REQUEST = 'GET_PATCH_AVAILABILITY_REQUEST';
export const GET_PATCH_AVAILABILITY_SUCCESS = 'GET_PATCH_AVAILABILITY_SUCCESS';
export const GET_PATCH_AVAILABILITY_FAILURE = 'GET_PATCH_AVAILABILITY_FAILURE';
export const SET_PATCH_TIME = 'SET_PATCH_TIME';
export const SET_APPOINTMENT_TIME = 'SET_APPOINTMENT_TIME';
export const SET_AVAILABILITY_SLOT = 'SET_AVAILABILITY_SLOT';
export const CLEAR_AVAILABLE_SLOTS = 'CLEAR_AVAILABLE_SLOTS';
export const CLEAR_AVAILABLE_DAYS = 'CLEAR_AVAILABLE_DAYS';
export const SET_SELECTED_STYLISTS = 'SET_SELECTED_STYLISTS';
export const CLEAR_SELECTED_STYLISTS = 'CLEAR_SELECTED_STYLISTS';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const UPDATE_TOTAL_PRICE = 'UPDATE_TOTAL_PRICE';
export const UPDATE_CLIENT_NAME = 'UPDATE_CLIENT_NAME';
export const UPDATE_CLIENT_EMAIL = 'UPDATE_CLIENT_EMAIL';
export const UPDATE_CLIENT_PHONE = 'UPDATE_CLIENT_PHONE';
export const UPDATE_FIRST_AVAILABLE_DATE = 'UPDATE_FIRST_AVAILABLE_DATE';
export const UPDATE_FIRST_AVAILABLE_PATCH_DATE = 'UPDATE_FIRST_AVAILABLE_PATCH_DATE';
export const SEND_BOOKING_DATA_REQUEST = 'SEND_BOOKING_DATA_REQUEST';
export const SEND_BOOKING_DATA_SUCCESS = 'SEND_BOOKING_DATA_SUCCESS';
export const SEND_BOOKING_DATA_FAILURE = 'SEND_BOOKING_DATA_FAILURE';
export const SEND_PATCH_TEST_BOOKING_DATA_REQUEST = 'SEND_PATCH_TEST_BOOKING_DATA_REQUEST';
export const SEND_PATCH_TEST_BOOKING_DATA_SUCCESS = 'SEND_PATCH_TEST_BOOKING_DATA_SUCCESS';
export const SEND_PATCH_TEST_BOOKING_DATA_FAILURE = 'SEND_PATCH_TEST_BOOKING_DATA_FAILURE';
export const CLEAR_SELECTED_PATCH_TIME = 'CLEAR_SELECTED_PATCH_TIME';
export const CLEAR_SELECTED_APPOINTMENT_TIME = 'CLEAR_SELECTED_APPOINTMENT_TIME';
export const GET_STYLISTS_FOR_SERVICE_REQUEST = 'GET_STYLISTS_FOR_SERVICE_REQUEST';
export const GET_STYLISTS_FOR_SERVICE_SUCCESS = 'GET_STYLISTS_FOR_SERVICE_SUCCESS';
export const GET_STYLISTS_FOR_SERVICE_FAILURE = 'GET_STYLISTS_FOR_SERVICE_FAILURE';
export const CLEAR_AVAILABLE_STYLISTS = 'CLEAR_AVAILABLE_STYLISTS';
export const SET_SERVICES_TO_SHOW = 'SET_SERVICES_TO_SHOW';
export const CLEAR_SERVICES_TO_SHOW = 'CLEAR_SERVICES_TO_SHOW';

export const GET_RECOMMENDED_SERVICES_REQUEST = 'GET_RECOMMENDED_SERVICES_REQUEST';
export const GET_RECOMMENDED_SERVICES_SUCCESS = 'GET_RECOMMENDED_SERVICES_SUCCESS';
export const GET_RECOMMENDED_SERVICES_FAILURE = 'GET_RECOMMENDED_SERVICES_FAILURE';

export const GET_STYLISTS_FOR_SERVICE_WEEKLY_REQUEST = 'GET_STYLISTS_FOR_SERVICE_WEEKLY_REQUEST';
export const GET_STYLISTS_FOR_SERVICE_WEEKLY_SUCCESS = 'GET_STYLISTS_FOR_SERVICE_WEEKLY_SUCCESS';
export const GET_STYLISTS_FOR_SERVICE_WEEKLY_FAILURE = 'GET_STYLISTS_FOR_SERVICE_WEEKLY_FAILURE';
export const SET_SERVICES_TO_BOOK = 'SET_SERVICES_TO_BOOK';
export const CLEAR_SERVICES_TO_BOOK = 'CLEAR_SERVICES_TO_BOOK';
export const SET_REFERRER = 'SET_REFERRER';
export const GET_CARD_DETAILS_REQUEST = 'GET_CARD_DETAILS_REQUEST';
export const GET_CARD_DETAILS_SUCCESS = 'GET_CARD_DETAILS_SUCCESS';
export const GET_CARD_DETAILS_FAILURE = 'GET_CARD_DETAILS_FAILURE';
export const CLEAR_USER_CARD_DETAILS = 'CLEAR_USER_CARD_DETAILS';
export const CLEAR_SALON_DETAILS = 'CLEAR_SALON_DETAILS';
export const SET_PAGE_VISITED = 'SET_PAGE_VISITED';
export const CLEAR_PAGE_VISITED = 'CLEAR_PAGE_VISITED';
export const SEARCH_QUERY_SERVICES = 'SEARCH_QUERY_SERVICES';
export const GET_CLIENT_DETAILS_REQUEST = 'GET_CLIENT_DETAILS_REQUEST';
export const GET_CLIENT_DETAILS_SUCCESS = 'GET_CLIENT_DETAILS_SUCCESS';
export const GET_CLIENT_DETAILS_FAILURE = 'GET_CLIENT_DETAILS_FAILURE';
export const GET_CLIENT_INVITES_REQUEST = 'GET_CLIENT_INVITES_REQUEST';
export const GET_CLIENT_INVITES_SUCCESS = 'GET_CLIENT_INVITES_SUCCESS';
export const GET_CLIENT_INVITES_FAILURE = 'GET_CLIENT_INVITES_FAILURE';
export const PROCCESS_INVITE_REQUEST = 'PROCCESS_INVITE_REQUEST';
export const PROCCESS_INVITE_SUCCESS = 'PROCCESS_INVITE_SUCCESS';
export const PROCCESS_INVITE_FAILURE = 'PROCCESS_INVITE_FAILURE';
export const SET_PAY_FULL_AMOUNT = 'SET_PAY_FULL_AMOUNT';
export const FETCH_APPOINTMENT_REQUEST = 'FETCH_APPOINTMENT_REQUEST';
export const FETCH_APPOINTMENT_SUCCESS = 'FETCH_APPOINTMENT_SUCCESS';
export const FETCH_APPOINTMENT_FAILURE = 'FETCH_APPOINTMENT_SUCCESS';
export const CONFIRM_EXTRA_AUTHENTICATION_REQUEST = 'CONFIRM_EXTRA_AUTHENTICATION_REQUEST';
export const CONFIRM_EXTRA_AUTHENTICATION_SUCCESS = 'CONFIRM_EXTRA_AUTHENTICATION_SUCCESS';
export const CONFIRM_EXTRA_AUTHENTICATION_FAILURE = 'CONFIRM_EXTRA_AUTHENTICATION_FAILURE';
export const CLEAR_PAYMENT_INTENT_ID = 'CLEAR_PAYMENT_INTENT_ID';
export const CLEAR_EXTRA_AUTHENTICATION_INFO = 'CLEAR_EXTRA_AUTHENTICATION_INFO';
export const SET_INVITE_POST_DATA = 'SET_INVITE_POST_DATA';
export const INVITATION_SENT = 'INVITATION_SENT';
export const GET_CLIENT_APPOINTMENTS_REQUEST = 'GET_CLIENT_APPOINTMENTS_REQUEST';
export const GET_CLIENT_APPOINTMENTS_SUCCESS = 'GET_CLIENT_APPOINTMENTS_SUCCESS';
export const GET_CLIENT_APPOINTMENTS_FAILURE = 'GET_CLIENT_APPOINTMENTS_FAILURE';
export const CANCEL_APPOINTMENT_REQUEST = 'CANCEL_APPOINTMENT_REQUEST';
export const CANCEL_APPOINTMENT_SUCCESS = 'CANCEL_APPOINTMENT_SUCCESS';
export const CANCEL_APPOINTMENT_FAILURE = 'CANCEL_APPOINTMENT_FAILURE';
export const CLEAR_CLIENT_APPOINTMENTS = 'CLEAR_CLIENT_APPOINTMENTS';
export const CHECK_WAITLIST_FOR_SERVICES_REQUEST = 'CHECK_WAITLIST_FOR_SERVICES_REQUEST';
export const CHECK_WAITLIST_FOR_SERVICES_SUCCESS = 'CHECK_WAITLIST_FOR_SERVICES_SUCCESS';
export const CHECK_WAITLIST_FOR_SERVICES_FAILURE = 'CHECK_WAITLIST_FOR_SERVICES_FAILURE';
export const CRUD_CARD_REQUEST = 'CRUD_CARD_REQUEST';
export const CRUD_CARD_SUCCESS = 'CRUD_CARD_SUCCESS';
export const CRUD_CARD_FAILURE = 'CRUD_CARD_FAILURE';
export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_SUCCESS';
export const SET_ADD_CARD_ERROR = 'SET_ADD_CARD_ERROR';
export const SET_SOHOW_ALL_AVAILABLE = 'SET_SOHOW_ALL_AVAILABLE';
export const GET_SALON_ONLINE_GROUP_REQUEST = 'GET_SALON_ONLINE_GROUP_REQUEST';
export const GET_SALON_ONLINE_GROUP_SUCCESS = 'GET_SALON_ONLINE_GROUP_SUCCESS';
export const GET_SALON_ONLINE_GROUP_FAILURE = 'GET_SALON_ONLINE_GROUP_FAILURE';
export const SET_UTM = 'SET_UTM';
export const CLEAR_AVAILABLE_PATCH_DAYS = 'CLEAR_AVAILABLE_PATCH_DAYS';

type Dispatch = ReduxDispatch<Action>;

export function fetchSalonGroupsList(groupId: number) {
  return {
    [AUTH_API]: {
      api_root: API_ROOT,
      endpoint: `book-online/group/${groupId}/`,
      // endpoint: 'book-online/group/2/',
      authenticated: false,
      config: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      types: [GET_SALON_ONLINE_GROUP_REQUEST, GET_SALON_ONLINE_GROUP_SUCCESS, GET_SALON_ONLINE_GROUP_FAILURE],
      params: { id: groupId },
    },
  };
}

export const getSalonDetails = salonId => ({
  [AUTH_API]: {
    apiRoot: API_ROOT,
    endpoint: `book-online/salon/${salonId}/`,
    authenticated: false,
    config: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    types: [SALON_DETAILS_REQUEST, SALON_DETAILS_SUCCESS, SALON_DETAILS_FAILURE],
  },
});

export const getCategoriesWithServices = (salonId: number) => ({
  [AUTH_API]: {
    apiRoot: API_ROOT,
    endpoint: `book-online/services/${salonId}/`,
    authenticated: false,
    types: [GET_SERVICES_REQUEST, GET_SERVICES_SUCCESS, GET_SERVICES_FAILURE],
    params: { salonId },
  },
});

export function selectService(service: Service) {
  const gitVersionFetched = lsGet(LSKEY.gitver);
  if (gitVersionFetched === 'false') {
    lsSet(LSKEY.gitver, 'true');
    setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log('gitVersionFetched set to false');
      lsSet(LSKEY.gitver, 'false');
    }, 10 * 60 * 1000);
  }
  STORE.dispatch(fetchGithub());
  return {
    type: SELECT_SERVICE,
    service,
  };
}

export function clearSelectedServices() {
  return {
    type: CLEAR_SELECTED_SERVICES,
  };
}

export function clearClientAppointments() {
  return {
    type: CLEAR_CLIENT_APPOINTMENTS,
  };
}

export function getWeeklyAvailability(
  salonId: number,
  services: number[],
  stylists: number[],
  startDateOfWeek: moment,
  check: number,
  isWaitList?: boolean,
) {
  const { check_ncob_availability_until } = STORE.getState().booking.salonDetails;

  if (!isWaitList) {
    if (moment(startDateOfWeek).diff(moment(), 'd') < check_ncob_availability_until) {
      return {
        [AUTH_API]: {
          apiRoot: API_ROOT,
          endpoint: `book-online/availability/weekly/${salonId}/${services.toString()}/${stylists.toString()}/${fromDateToMomentTzFormat(
            startDateOfWeek,
          )}/${check}/`,
          authenticated: false,
          params: { check_ncob_availability_until },
          types: [GET_WEEKLY_AVAILABILITY_REQUEST, GET_WEEKLY_AVAILABILITY_SUCCESS, GET_WEEKLY_AVAILABILITY_FAILURE],
        },
      };
    }
    STORE.dispatch({ type: 'Exceeds_Maximum_Days', params: { startDateOfWeek } });
  }
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint: `book-online/waitlist/availability/weekly/${salonId}/${services.toString()}/${fromDateToMomentTzFormat(
        startDateOfWeek,
      )}/`,
      authenticated: false,
      params: { isWaitList },
      types: [GET_WEEKLY_AVAILABILITY_REQUEST, GET_WEEKLY_AVAILABILITY_SUCCESS, GET_WEEKLY_AVAILABILITY_FAILURE],
    },
  };
}

export function getWeeklyAvailabilityRepeat(
  salonId: number,
  services: number[],
  stylists: number[],
  startDateOfWeek: moment,
  isWaitList?: boolean,
) {
  return (dispatch: Dispatch) => {
    const { check_ncob_availability_until } = STORE.getState().booking.salonDetails;

    if (check_ncob_availability_until && moment(startDateOfWeek).diff(moment(), 'd') > check_ncob_availability_until) {
      STORE.dispatch({ type: 'Exceeds_Maximum_Days', params: { startDateOfWeek } });
      return;
    }

    dispatch(getWeeklyAvailability(salonId, services, stylists, startDateOfWeek, 1, isWaitList)).then(data => {
      const newDate = data.response && Object.keys(data.response).length && moment(Object.keys(data.response).pop());
      let finalDate = newDate;

      if (
        data.response &&
        Object.values(data.response).indexOf(true) < 0 &&
        moment(newDate).diff(moment(), 'd') < check_ncob_availability_until
      ) {
        finalDate = newDate;
        dispatch(getWeeklyAvailabilityRepeat(salonId, services, stylists, moment(newDate), isWaitList));
      } else if (check_ncob_availability_until && moment(newDate).diff(moment(), 'd') > check_ncob_availability_until) {
        dispatch({ type: 'Exceeds_Maximum_Days', params: { finalDate } });
      }
    });
  };
}

export function getPatchAvailability(salonId: number, appointmentDate: moment, isWaitList: boolean) {
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint: `book-online/availability/patch/${salonId}/${fromDateToMomentTzFormat(appointmentDate)}/`,
      authenticated: false,
      params: { isWaitList },
      types: [GET_PATCH_AVAILABILITY_REQUEST, GET_PATCH_AVAILABILITY_SUCCESS, GET_PATCH_AVAILABILITY_FAILURE],
    },
  };
}

export function getPatchWeeklyAvailability(salonId: number, appointmentDate: moment, check: number) {
  const { check_ncob_availability_until } = STORE.getState().booking.salonDetails;

  if (moment(appointmentDate).diff(moment(), 'd') >= check_ncob_availability_until) {
    // store.dispatch({ type: 'Exceeds_Maximum_Days', params: { appointmentDate } });
    return { type: 'Exceeds_Maximum_Days', params: { appointmentDate } };
  }
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint: `book-online/availability/weekly/patch/${salonId}/${fromDateToMomentTzFormat(
        appointmentDate,
      )}/${check}/`,
      authenticated: false,
      types: [
        GET_PATCH_WEEKLY_AVAILABILITY_REQUEST,
        GET_PATCH_WEEKLY_AVAILABILITY_SUCCESS,
        GET_PATCH_WEEKLY_AVAILABILITY_FAILURE,
      ],
    },
  };
}

export function getPatchWeeklyAvailabilityRepeat(salonId: number, appointmentDate: moment) {
  return (dispatch: Dispatch) => {
    const { check_ncob_availability_until } = STORE.getState().booking.salonDetails;
    if (check_ncob_availability_until && moment(appointmentDate).diff(moment(), 'd') > check_ncob_availability_until) {
      STORE.dispatch({ type: 'Exceeds_Maximum_Days', params: { appointmentDate } });
      return;
    }
    dispatch(getPatchWeeklyAvailability(salonId, appointmentDate, 1)).then(data => {
      const newDate = data.response && Object.keys(data.response).length && moment(Object.keys(data.response).pop());
      let finalDate = newDate;

      if (
        data.response &&
        Object.values(data.response).indexOf(true) < 0 &&
        moment(newDate).diff(moment(), 'd') < check_ncob_availability_until
      ) {
        finalDate = newDate;
        dispatch(getPatchWeeklyAvailabilityRepeat(salonId, moment(appointmentDate)));
      } else if (check_ncob_availability_until && moment(newDate).diff(moment(), 'd') > check_ncob_availability_until) {
        dispatch({ type: 'Exceeds_Maximum_Days', params: { finalDate } });
      }
    });
  };
}

export function getBookingAvailability(
  salonId: number,
  appointmentDate: moment,
  services: number[],
  stylists: number[],
  isWaitList: boolean,
) {
  let endpoint;
  if (!isWaitList) {
    endpoint = `book-online/new/availability/appointment/${salonId}/${moment(
      appointmentDate,
    ).format()}/${services.toString()}/${stylists.toString()}/`;
  } else {
    endpoint = `book-online/new/waitlist/availability/appointment/${salonId}/${fromDateToMomentTzFormat(
      appointmentDate,
    )}/${services.toString()}/`;
  }
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: false,
      params: { isWaitList },
      types: [GET_BOOKING_AVAILABILITY_REQUEST, GET_BOOKING_AVAILABILITY_SUCCESS, GET_BOOKING_AVAILABILITY_FAILURE],
    },
  };
}

export function getStylistsForService(
  salonId: number,
  serviceId: number,
  dateTime: any,
  services: any[],
  stylists: number[],
  isWaitList?: boolean,
) {
  let endpoint;
  if (!isWaitList) {
    endpoint = `book-online/new/availability/appointment/${salonId}/${fromDateToMomentTzFormat(
      dateTime,
    )}/${services.toString()}/${stylists.toString()}/${serviceId}/`;
  } else {
    endpoint = `book-online/new/waitlist/availability/appointment/${salonId}/${fromDateToMomentTzFormat(
      dateTime,
    )}/${services.toString()}/${serviceId}/`;
  }
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: false,
      params: { isWaitList },
      types: [GET_STYLISTS_FOR_SERVICE_REQUEST, GET_STYLISTS_FOR_SERVICE_SUCCESS, GET_STYLISTS_FOR_SERVICE_FAILURE],
    },
  };
}

export function getStylistsForServiceWeekly(salonId: number, services: number[]) {
  const endpoint = `book-online/services/stylists/${salonId}/${services.toString()}/`;
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: false,
      types: [
        GET_STYLISTS_FOR_SERVICE_WEEKLY_REQUEST,
        GET_STYLISTS_FOR_SERVICE_WEEKLY_SUCCESS,
        GET_STYLISTS_FOR_SERVICE_WEEKLY_FAILURE,
      ],
    },
  };
}

export function getRecommendedServices(salonId: number, services: number[]) {
  const endpoint = `book-online/services/recommended/${salonId}/${services.toString()}/`;

  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: false,
      types: [GET_RECOMMENDED_SERVICES_REQUEST, GET_RECOMMENDED_SERVICES_SUCCESS, GET_RECOMMENDED_SERVICES_FAILURE],
    },
  };
}

export function clearAvailableSlots() {
  return {
    type: CLEAR_AVAILABLE_SLOTS,
  };
}

export function clearAvailableDays() {
  return {
    type: CLEAR_AVAILABLE_DAYS,
  };
}

export function clearAvailablePatchDays() {
  return {
    type: CLEAR_AVAILABLE_PATCH_DAYS,
  };
}

export function setPatchTime(time: moment, stylistId: number) {
  return {
    type: SET_PATCH_TIME,
    time: fromDateToMomentTzFormat(time),
    stylistId,
  };
}

export function setAppointmentTime(time: moment) {
  return {
    type: SET_APPOINTMENT_TIME,
    time,
  };
}

export function setAvailabilitySlot(slot: AvailabilitySlot) {
  return {
    type: SET_AVAILABILITY_SLOT,
    slot,
  };
}

export function setSelectedStylists(
  selectedStylists: { [serviceId: string]: null | Stylist },
  serviceId?: number,
  stylist?: any,
) {
  return {
    type: SET_SELECTED_STYLISTS,
    selectedStylists,
    serviceId,
    stylist,
  };
}

export function setServicesToBook(servicesToBook: ServicesToBook[]) {
  return {
    type: SET_SERVICES_TO_BOOK,
    servicesToBook,
  };
}

export function clearServicesToBook() {
  return {
    type: CLEAR_SERVICES_TO_BOOK,
  };
}

export function clearSelectedStylists() {
  return {
    type: CLEAR_SELECTED_STYLISTS,
  };
}

export function updateNote(notes: string) {
  return {
    type: UPDATE_NOTE,
    notes,
  };
}

export function updateTotalPrice(price: string) {
  return {
    type: UPDATE_TOTAL_PRICE,
    price,
  };
}

export function updateFirstAvailableDate(date: moment | null) {
  return {
    type: UPDATE_FIRST_AVAILABLE_DATE,
    date,
  };
}

export function updateFirstAvailablePatchDate(date: string) {
  return {
    type: UPDATE_FIRST_AVAILABLE_PATCH_DATE,
    date,
  };
}

export function updateClientName(clientName: string) {
  return {
    type: UPDATE_CLIENT_NAME,
    clientName,
  };
}

export function updateClientPhone(clientPhone: string) {
  return {
    type: UPDATE_CLIENT_PHONE,
    clientPhone,
  };
}

export function updateClientEmail(clientEmail: string) {
  return {
    type: UPDATE_CLIENT_EMAIL,
    clientEmail,
  };
}

export function sendBookingData(data: NewBooking, salonId: number, isWaitList?: boolean) {
  if (isWaitList || !data.payment_needed) {
    data.payment_needed = false;
  }
  let endpoint;
  if (!isWaitList) {
    endpoint = `book-online/appointment/create/${salonId}/`;
  } else {
    endpoint = `book-online/waitlist/appointment/create/${salonId}/`;
  }
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: true,
      types: [SEND_BOOKING_DATA_REQUEST, SEND_BOOKING_DATA_SUCCESS, SEND_BOOKING_DATA_FAILURE],
      config: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    },
  };
}

export function sendPatchTestBookingData(data: PatchTestBooking, salonId: number) {
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint: `book-online/appointment/create/patch/${salonId}/`,
      authenticated: false,
      types: [
        SEND_PATCH_TEST_BOOKING_DATA_REQUEST,
        SEND_PATCH_TEST_BOOKING_DATA_SUCCESS,
        SEND_PATCH_TEST_BOOKING_DATA_FAILURE,
      ],
      config: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    },
  };
}

export function clearAvailableStylists() {
  return {
    type: CLEAR_AVAILABLE_STYLISTS,
  };
}

export function clearSelectedPatchTime() {
  return {
    type: CLEAR_SELECTED_PATCH_TIME,
  };
}

export function clearSelectedAppointmentTime() {
  return {
    type: CLEAR_SELECTED_APPOINTMENT_TIME,
  };
}

export function clearSalonDetails() {
  return {
    type: CLEAR_SALON_DETAILS,
  };
}

export function setServicesToShow(services: any) {
  return {
    type: SET_SERVICES_TO_SHOW,
    services,
  };
}

export function clearServicesToShow() {
  return {
    type: CLEAR_SERVICES_TO_SHOW,
  };
}

export const getUserCardDetails = username => ({
  [AUTH_API]: {
    apiRoot: API_ROOT,
    endpoint: `ncob/checkout/salon/user/getcard/${fromAnyToTrimmed(username)}/`,
    authenticated: true,
    types: [GET_CARD_DETAILS_REQUEST, GET_CARD_DETAILS_SUCCESS, GET_CARD_DETAILS_FAILURE],
    config: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  },
});

export function clearUserCardDetails() {
  return {
    type: CLEAR_USER_CARD_DETAILS,
  };
}

export function setPageVisited(page: string) {
  return {
    type: SET_PAGE_VISITED,
    page,
  };
}

export function clearPageVisited() {
  return {
    type: CLEAR_PAGE_VISITED,
  };
}

export function searchServices(searchQuery: string) {
  return {
    type: SEARCH_QUERY_SERVICES,
    searchQuery,
  };
}

export function getClientDetailsFromUniqLink(uniqlink: string) {
  const endpoint = `ncob/invite/${uniqlink}/`;
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: true,
      types: [GET_CLIENT_DETAILS_REQUEST, GET_CLIENT_DETAILS_SUCCESS, GET_CLIENT_DETAILS_FAILURE],
      config: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  };
}

export function getClientAppointments(cognitoUserName: string) {
  const endpoint = `ncob/client/appointments/${cognitoUserName}/`;
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: true,
      types: [GET_CLIENT_APPOINTMENTS_REQUEST, GET_CLIENT_APPOINTMENTS_SUCCESS, GET_CLIENT_APPOINTMENTS_FAILURE],
      config: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  };
}

export function getClientInvites(cognitoUsername: string) {
  const endpoint = `client/get/invite/${cognitoUsername}/`;
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: true,
      types: [GET_CLIENT_INVITES_REQUEST, GET_CLIENT_INVITES_SUCCESS, GET_CLIENT_INVITES_FAILURE],
      config: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  };
}

export function processInvite(cognitoUsername: string, inviteId: string, data: any) {
  const endpoint = `client/post/invite/${cognitoUsername}/${inviteId}`;
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: true,
      types: [PROCCESS_INVITE_REQUEST, PROCCESS_INVITE_SUCCESS, PROCCESS_INVITE_FAILURE],
      params: { data },
      config: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    },
  };
}

export function setPayFullAmount(appointmentId: string, inviteId: string) {
  return {
    type: SET_PAY_FULL_AMOUNT,
    appointmentId,
    inviteId,
  };
}

export function getAppointment(salonId: number, id: number) {
  return {
    [AUTH_API]: {
      api_root: API_ROOT,
      endpoint: `salon/${salonId}/appointment/${id}/`,
      authenticated: true,
      types: [FETCH_APPOINTMENT_REQUEST, FETCH_APPOINTMENT_SUCCESS, FETCH_APPOINTMENT_FAILURE],
    },
  };
}

export function confirmXAuthentication(
  salonId: any,
  appointmentId: any,
  paymentintentId: any,
  cognitoUserName: string,
) {
  return {
    [AUTH_API]: {
      api_root: API_ROOT,
      endpoint: `book-online/confirm/${salonId}/${appointmentId}/${paymentintentId}/${cognitoUserName}/`,
      authenticated: true,
      types: [
        CONFIRM_EXTRA_AUTHENTICATION_REQUEST,
        CONFIRM_EXTRA_AUTHENTICATION_SUCCESS,
        CONFIRM_EXTRA_AUTHENTICATION_FAILURE,
      ],
      config: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  };
}

export function resetPaymentIntentId() {
  return { type: CLEAR_PAYMENT_INTENT_ID };
}

export function clearExtraAuthenticationInfo() {
  return { type: CLEAR_EXTRA_AUTHENTICATION_INFO };
}

export function setInvitePostData(status: string) {
  return { type: SET_INVITE_POST_DATA, status };
}

export function setAddCardError(errorMessage: string) {
  return { type: SET_ADD_CARD_ERROR, errorMessage };
}

export function setShowAllAvailable(tf: boolean) {
  return { type: SET_SOHOW_ALL_AVAILABLE, tf };
}

export function cancelAppointment(salonId: number, appointmentId: number, cognitoUserName: string) {
  const endpoint = `ncob/client/cancel/appointment/${appointmentId}/${salonId}/${cognitoUserName}/`;
  return {
    [AUTH_API]: {
      api_root: API_ROOT,
      endpoint,
      authenticated: true,
      types: [CANCEL_APPOINTMENT_REQUEST, CANCEL_APPOINTMENT_SUCCESS, CANCEL_APPOINTMENT_FAILURE],
      config: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  };
}

export function checkWaitListForService(salonId: string, services: number[]) {
  const endpoint = `book-online/show/waitlist/option/${salonId}/${services.toString()}/`;

  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: false,
      types: [
        CHECK_WAITLIST_FOR_SERVICES_REQUEST,
        CHECK_WAITLIST_FOR_SERVICES_SUCCESS,
        CHECK_WAITLIST_FOR_SERVICES_FAILURE,
      ],
    },
  };
}

export function crudCard(username: string, actionMethod: string) {
  const endpoint = `ncob/checkout/client/cards/${username}/`;
  let method = 'GET';
  if (actionMethod === 'add') {
    method = 'POST';
  } else if (actionMethod === 'update') {
    method = 'PUT';
  }

  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: true,
      types: [CRUD_CARD_REQUEST, CRUD_CARD_SUCCESS, CRUD_CARD_FAILURE],
      config: {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  };
}

export function deleteCard(username: string, payMethodId: string) {
  const endpoint = `ncob/checkout/client/delete/card/${username}/${payMethodId}/`;
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: true,
      types: [DELETE_CARD_REQUEST, DELETE_CARD_SUCCESS, DELETE_CARD_FAILURE],
      config: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  };
}

export function setUtm(utm: string) {
  return {
    type: SET_UTM,
    utm,
  };
}
