import { setupCognito } from 'react-cognito-mm';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import HISTORY from 'history.singleton';
import { configurePusher } from 'pusher-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { autoRehydrate, persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';

import { API_ROOT, COGNITO_CONFIG, PUSHER_ID } from 'config';
import api from 'middleware/api';
import segment from 'middleware/segment';
import tracking from 'middleware/tracking';
import rootReducer from 'reducers/index';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const STORE = createStore(
  connectRouter(HISTORY)(rootReducer),
  undefined,
  composeEnhancers(
    applyMiddleware(thunkMiddleware, api, routerMiddleware(HISTORY), segment, tracking),
    autoRehydrate(),
  ),
);

const persistor = persistStore(STORE, { blacklist: ['booking'], key: 'root' });

if (COGNITO_CONFIG) {
  setupCognito(STORE, COGNITO_CONFIG);
  if (PUSHER_ID) {
    configurePusher(STORE, PUSHER_ID, {
      cluster: 'eu',
      authTransport: 'jsonp',
      encrypted: true,
      authEndpoint: `${API_ROOT}websocket/`,
    });
  }
}

export { persistor };
export default STORE;
