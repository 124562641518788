// @flow

/* eslint-disable max-len */

// $FlowFixMe
import { isValidNumber, parse } from 'libphonenumber-js';

const COUNTRY_CODE = 'GB';

export function validateEmail(value: string) {
  // eslint-disable-next-line max-len
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegex.test(value);
}

export function validatePassword(value: string) {
  const capitalRegex = /[A-Z]+/;
  const numberRegex = /[0-9]+/;
  const lowercaseRegex = /[a-z+]/;
  if (!capitalRegex.test(value)) {
    return 'Password needs to be at least 8 characters in length and contain lowercase character, at least one uppercase character and a number.';
  }
  if (!numberRegex.test(value)) {
    return 'Password needs to be at least 8 characters in length and contain lowercase character, at least one uppercase character and a number.';
  }
  if (!lowercaseRegex.test(value)) {
    return 'Password needs to be at least 8 characters in length and contain lowercase character, at least one uppercase character and a number.';
  }
  if (value.length < 8) {
    return 'Password needs to be at least 8 characters in length and contain lowercase character, at least one uppercase character and a number.';
  }
  return '';
}

export function validateNameLength(name: string, length?: number) {
  let maxLength = 120;
  if (length) {
    maxLength = length;
  }
  if (name.length > maxLength) {
    return false;
  }
  return true;
}

export function validateName(name: string) {
  const noWhitespaces = name.replace(/\s/g, '');
  if (noWhitespaces.length === 0 || name.length > 200) {
    return false;
  }
  return true;
}

export function validatePhone(value: string) {
  if (value === '') {
    return false;
  }
  // We need to know what country we expect the user to be in, because she probably won't type her country code in
  const newValue = value.replace(/ /g, '');
  const parsedNumber = parse(newValue, COUNTRY_CODE);
  try {
    if (isValidNumber(parsedNumber)) {
      return true;
    }
  } catch (error) {
    if (error instanceof TypeError) {
      return false;
    }
    throw error;
  }
  return false;
}

export function validatePrice(value: string) {
  const regex = /^\d+(?:\.\d{0,2})$/;

  if (regex.test(value)) {
    return true;
  }
  return false;
}
