import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import Navigation from 'invite/components/Navigation.component';
import { bindActionCreators } from 'redux';

import IMG_SLICK from 'assets/images/Slick-logo.svg';
import Button from 'components/Button.component';
import ExternalLink from 'components/ExternalLink.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import LINK from 'constants/link.const';
import useAef from 'hooks/useAef.hook';
import { useCurrentSalonId } from 'providers/SalonBasicInfo.provider';
import { useTheme } from 'providers/Theme.provider';
import alwaysNoop from 'utils/functions/nullary/alwaysNoop.util';
import linkTo from 'utils/links/linkTo.util';
import logger from 'utils/logger.util';

import { getClientInvites } from 'booking/actions';
import beGetSalons from 'booking/beGetSalons.action';
import Spinner from 'common/Spinner.component';

import CN from './MyBookNew.module.scss';

const L = logger('MyBookNew');
const LINK_MAP = 'https://www.getslick.com/oko456-8nsh2r5ggdn';
const USE_BUTTON = false;

const MyBookNew = ({ match, cognitoUserName, beGetSalons, getClientInvites }) => {
  const { isDarkMode, isNeutralMode } = useTheme();
  const salonId = useCurrentSalonId();
  const linkToSalon = linkTo({ pattern: LINK.salon, params: { salonId } });

  const { value: salonsData, isLoading, error } = useAef(async () => {
    try {
      const result = await beGetSalons({ cognitoUserName });

      return result;
    } catch (error) {
      throw error;
    }
  }, [beGetSalons, cognitoUserName]);

  useEffect(() => {
    if (cognitoUserName) {
      getClientInvites(cognitoUserName);
    }
  }, [beGetSalons, cognitoUserName, getClientInvites]);

  L.debug('()', { isLoading, error, salonsData });

  return (
    <div
      data-bem="MyBookNew"
      className={cn({
        [CN.component]: true,
        [CN.neutral]: isNeutralMode,
        [CN.dark]: isDarkMode,
      })}
    >
      <div className={CN.wrapper}>
        <Navigation match={match} />
        <div className={CN.header}>
          <div className={CN.title}>Locations</div>
          {USE_BUTTON && (
            <Button variant="primary" onClick={alwaysNoop}>
              <ExternalLink link={LINK_MAP}>
                <div className={CN.link}>FIND SALON</div>
              </ExternalLink>
            </Button>
          )}
        </div>

        <div className={CN.subTitle}>
          <div className={CN.icon}>
            <Icon variant="pin" width="lg" />
          </div>
          Choose location:
        </div>
        {isLoading ? (
          <Spinner variant="overlay" />
        ) : (
          <div className={CN.container}>
            {salonsData &&
              salonsData.response &&
              salonsData.response.length > 0 &&
              salonsData.response.map(salon => {
                const { id, profile_image_url: salonLogo, name: salonName } = salon;
                return (
                  <NavLink to={`/salon/${match.params.id}/salon-booking/${id}`} key={id} className={CN.salonItem}>
                    <div
                      className={CN.logoContainer}
                      style={{
                        backgroundImage: `url("${salonLogo || IMG_SLICK}")`,
                      }}
                    />
                    <div className={CN.salonName}>{salonName}</div>
                  </NavLink>
                );
              })}
          </div>
        )}
      </div>
      <Footer data-bem="MyBookNew__footer">
        <div className={CN.footerContainer}>
          <NavLink to={linkToSalon} data-bem="SingleSalonBookings__link" className={CN.link}>
            <Button variant="primary" width="fixed">
              MAKE A BOOKING
            </Button>
          </NavLink>
        </div>
      </Footer>
    </div>
  );
};

const mapStateToProps = state => ({
  cognitoUserName: state.cognito.user.username,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      beGetSalons,
      getClientInvites,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyBookNew);
