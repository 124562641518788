// @flow

import React from 'react';

type Props = {
  onBtnClick: () => void,
  btnName: string,
  disabled?: boolean,
  hidden?: boolean,
};

const Button = (props: Props) => {
  let className = props.disabled ? 'btn btn-primary disabled' : 'btn btn-primary';
  className = props.hidden ? 'hidden' : `${className}`;
  return (
    <button className={className} onClick={props.onBtnClick}>
      {props.btnName}
    </button>
  );
};

Button.defaultProps = { disabled: false, hidden: false };
export default Button;
