// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';

import { composeBooking } from 'actions/createbooking';
import { selectAppointment } from 'actions/selection';
import PrivacyLink from 'components/PrivacyLink';
import SalonHeader from 'components/SalonHeader';

import type { Appointment } from 'types/appointment';
import type { Client } from 'types/client';

type Props = {
  appointment: Appointment,
  match: {
    isExact: boolean,
    path: string,
    url: string,
    params: {
      client_id: string,
    },
  },
  history: {
    goBack: () => void,
  },
  location: {
    pathname: string,
    search: ?string,
    hash: ?string,
    state: {
      start_time: string,
      end_time: string,
      title: string,
      stylists: string,
    },
  },
  appointment: Appointment,
  client: Client,
  doCreateBooking: (appointment: Appointment) => void,
  doSelectAppointment: (appt: Appointment) => void,
};

type State = {
  confirmedAppointment: boolean,
};

class Confirm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      confirmedAppointment: false,
    };
  }

  onCancel = () => {
    this.props.history.goBack();
  };
  makeBooking = (/* notes: string */) => {
    const { doCreateBooking, doSelectAppointment, client } = this.props;
    const newBooking = JSON.parse(JSON.stringify(this.props.appointment));
    let stylist = {};
    let service = {};
    let firstProcedureOriginalStartTime = moment(this.props.appointment.procedures[0].start_time);
    newBooking.procedures.forEach(d => {
      if (moment(d.start_time).isBefore(firstProcedureOriginalStartTime)) {
        firstProcedureOriginalStartTime = moment(d.start_time);
      }
    });
    const newStart = this.props.location.state.start_time;
    if (newBooking.procedures !== undefined) {
      newBooking.procedures.forEach(d => {
        /* eslint-disable no-param-reassign */
        ({ stylist, service } = d);
        d.stylist_id = stylist.id;
        d.service_id = service.id;
        const diff = moment(d.start_time).diff(firstProcedureOriginalStartTime);
        d.start_time = moment(newStart).add(diff);
      });
      newBooking.procedures.sort((a, b) => moment(b.start_time).isBefore(a.start_time));
    }
    delete newBooking.id;
    newBooking.start_time = this.props.location.state.start_time;
    newBooking.end_time = this.props.location.state.end_time;
    newBooking.status = 'unconfirmed';
    newBooking.client_id = client.id;
    newBooking.origin = 'online_rebooking';
    doCreateBooking(newBooking);
    doSelectAppointment(newBooking);
    this.setState({ confirmedAppointment: true });
  };

  render() {
    const { client, location } = this.props;
    return (
      <div>
        <SalonHeader client={client} />
        {(!location || !location.state) && (
          <Redirect
            to={{
              pathname: '/salon',
              state: { from: location },
            }}
          />
        )}
        {this.state.confirmedAppointment && (
          <Redirect
            to={{
              pathname: '/end',
              state: { from: location },
            }}
            push
          />
        )}
        <div className="section">Re-book an Appointment</div>
        <div className="container confirm">
          <div className="summary-text-full">
            {location && location.state && (
              <div className="text-summary">
                You have selected to book a <span>{location.state.title}</span> with{' '}
                <span>{location.state.stylists}</span> on{' '}
                <span>{moment(location.state.start_time).format('ddd D MMM [at] H:mm')}</span>
              </div>
            )}
          </div>
          <div className="info-text">
            <div className="text-header">Additional Details or Requests</div>
          </div>
          {/* }
            <form>
            <textarea
              className="block-input-textarea"
              placeholder="e.g. Can I add a blow dry? Can I have a half head of highlights, not a full head?"
              value={this.state.notes}
              onChange={event => this.setState({ notes: event.target.value })}
            />
            </form>
          */}
          <PrivacyLink>
            By clicking confirm you agree to the <br />
          </PrivacyLink>

          <div className="clearfix">
            <div className="left-button">
              <span className="button-wrap">
                <button className="simple-button lesser-button" onClick={() => this.onCancel()}>
                  Back
                </button>
              </span>
            </div>
            <div className="right-button">
              <span className="button-wrap">
                <button id="confirm" className="simple-button" onClick={() => this.makeBooking()}>
                  Confirm
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  client: state.selection.client,
  appointment: state.selection.appointment,
});

const mapDispatchToProps = (dispatch: any) => ({
  doCreateBooking: (appointment: Appointment) => {
    dispatch(composeBooking(appointment));
  },
  doSelectAppointment: appt => {
    dispatch(selectAppointment(appt));
  },
});

export const ConfirmComponent = Confirm;

export default withRouter<any, any>(
  connect<any, Props, any, any, any, any>(mapStateToProps, mapDispatchToProps)(Confirm),
);
