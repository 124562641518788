import { useEffect, useRef } from 'react';

const useIsMountedRef = () => {
  // start assuming the component is mounted (since this hook would be called from inside)
  const isMountedRef = useRef(true);

  // useEffect runs once and sets up a cleanup function that gets called on unmount

  // eslint-disable-next-line no-return-assign
  useEffect(() => () => void (isMountedRef.current = false), []);

  return isMountedRef;
};

// noinspection JSUnusedGlobalSymbols
export default useIsMountedRef;
