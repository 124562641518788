import logger from '../logger.util';

const L = logger('lsGet');

const lsGet = key => {
  try {
    return L.debug(key, ':', localStorage.getItem(key));
  } catch (e) {
    L.warn('lsGet()', e);
    return null;
  }
};

// noinspection JSUnusedGlobalSymbols
export default lsGet;
