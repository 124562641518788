const STRING = Object.freeze({
  // NOTE: these are older, resolve them with those down
  amp: "'",
  ampr: '&',
  apos: "'",
  cry: '😢',
  sad: '😢',
  thumbsUp: '👍',

  // NOTE: the following are copied from ui.salon

  // special cases, try to keep them in alphabetic order
  empty: '',
  newline: '\n',

  // named regular keyboard symbols, try to keep them in alphabetic order
  colon: ':',
  minus: '-',
  percent: '%',
  plus: '+',
  space: ' ',
  underscore: '_',
  zero: '0',

  // for punctuation, try to keep them in alphabetic order
  ndash: String.fromCodePoint(8211), // NOTE: shorter dash;
  mdash: String.fromCodePoint(8212), // NOTE: longer dash;
  quoteOpen: '‘',
  quoteClose: '’',
  quote: '"',

  // for HTML entities, try to keep them in alphabetic order
  // amp: '&', // TODO: use this only after refactoring the above use
  // apos: "'", // TODO: use this only after refactoring the above use
  nbsp: '\xa0', // non-breaking space

  // currencies, try to keep them in alphabetic order
  dirham: 'د.إ', // NOTE: string inside is right-to-left
  dollar: '$',
  euro: '€',
  pound: '£',

  // arrow emojis, try to keep them in alphabetic order
  arrowE: '➡️',
  arrowEW: '↔️',
  arrowN: '⬆️',
  arrowNS: '↕️',
  arrowS: '⬇️',
  arrowW: '⬅️',

  // backhand index emojis, try to keep them in alphabetic order
  indexDown: '👇',
  indexLeft: '👈',
  indexRight: '👉',
  indexUp: '👆',

  // mixed emojis, try to keep them in alphabetic order
  bomb: '💣',
  crossMark: '❌',
  college: '👩',
  deliveryTruck: '🚚',
  duck: '🦆',
  fist: '💪',
  heart: '🤍',
  heartSlick: '💜',
  like: '👍',
  markVGreen: '✅',
  megaphone: '📣',
  party: '🎉',
  phoneReceiver: '📞',
  palm: '🌴',
  raisedHands: '🙌',
  recycle: '♻',
  restricted: '🚫',
  shuffle: '🔀',
  star: '⭐️',
  sandwich: '🥪',
  thinking: '🤔',
  trophy: '🏆',
  partyingFace: '🥳',
  moneyWithWings: '💸',
  moneyBag: '💰',

  // other symbols, try to keep them in alphabetic order
  checkmark: '✓', // NOTE: Unicode Character “✓” (U+2713)
  markX: '✖',
  markV: '✔',
  passdot: '●', // NOTE: Unicode String.fromCodePoint(9679) or String.fromCharCode(9679)
  times: 'x',
  wavingHand: '👋',
  rocket: '🚀',
});

export default STRING;
