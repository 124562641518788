import getAnyOrNull from 'utils/accessors/getAnyOrNull.util';
import fromEntriesToObject from 'utils/converters/object/fromEntriesToObject.util';
import nullBind from 'utils/functions/nullBind.util';

const getSearch = nullBind(getAnyOrNull, 'location.search');

const fromPropsToQuery = props => {
  const search = getSearch(props);
  if (!search) {
    return {};
  }
  return fromEntriesToObject(new URLSearchParams(search).entries());
};

// noinspection JSUnusedGlobalSymbols
export default fromPropsToQuery;
