// SEE: https://github.com/streamich/react-use/blob/master/src/useAsync.ts

import { useEffect } from 'react';

import useInitializedAsyncFn from 'hooks/useInitializedAsyncFn.hook';
import asArray from 'utils/arrays/asArray.util';

// name is short for use async effect
const useAef = (fn, deps) => {
  const [state, callback] = useInitializedAsyncFn(
    {
      value: void 1,
      error: void 1,
      isLoading: true,
      isLoaded: false,
    },
    fn,
    asArray(deps),
  );

  useEffect(() => void callback(), [callback]);

  return state;
};

// noinspection JSUnusedGlobalSymbols
export default useAef;
