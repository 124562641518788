import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';

import CN from './Button.module.scss';

const Button = ({ children, variant, height, width, ...extra }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  return (
    <button
      type="button"
      {...extra}
      data-bem="Button"
      className={cn({
        [CN.component]: true,
        [CN.neutral]: isNeutralMode,
        [CN.dark]: isDarkMode,
        [CN[`${variant}Variant`]]: variant,
        [CN[`${width}Width`]]: width,
        [CN[`${height}Height`]]: height,
      })}
    >
      {children}
    </button>
  );
};

export default Button;
