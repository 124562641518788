// @flow

import { AUTH_API } from '../middleware/api';
import type { Appointment } from '../types/appointment';

export const CREATEBOOKING_REQUEST = 'CREATEBOOKING_REQUEST';
export const CREATEBOOKING_SUCCESS = 'CREATEBOOKING_SUCCESS';
export const CREATEBOOKING_FAILURE = 'CREATEBOOKING_FAILURE';

export function composeBooking(appointment: Appointment) {
  return {
    [AUTH_API]: {
      endpoint: 'rebooking/rebook/',
      authenticated: true,
      config: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(appointment),
      },
      types: [CREATEBOOKING_REQUEST, CREATEBOOKING_SUCCESS, CREATEBOOKING_FAILURE],
    },
  };
}
