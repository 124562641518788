// @flow

import React from 'react';

const NotFound404 = () => (
  <div className="container confirm">
    <div className="text-header">
      Sorry, we are unable to find what you are looking for. Please refresh your page and try again.
    </div>
  </div>
);

export default NotFound404;
