// @flow

/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */

import React from 'react';
import { Action, registerUser } from 'react-cognito-mm';
import { connect } from 'react-redux';
import cn from 'classnames';

import { setUserName } from 'actions/billing';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import SalonModal from 'components/SalonModal';
import { withTheme } from 'providers/Theme.provider';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';

import { getClientDetailsFromUniqLink, getClientInvites } from 'booking/actions';
import HeaderComponent from 'booking/common/Header';
import Spinner from 'common/Spinner.component';
import { setPageVisitedFunc } from 'lib/utils';
import { validatePassword } from 'lib/validators';

import CN from './LoginFormWrap.module.scss';

type Props = {
  history: string[],
  cognito: {
    userPool: any,
    config: any,
    state: string,
    user: { username: string },
    error: {
      code: string,
      statusCode: string,
      message: string,
    },
  },
  clearCache: () => void,
  registerUser: (userPool: any, config: any, username: string, password: string, attributes: any) => void,
  setUserName: (name: string) => void,
  getClientDetailsFromUniqLink: (uniqlink: string) => void,
  getClientInvites: (username: string) => void,

  match: {
    params: {
      id: string,
      uniqcode: string,
    },
  },
  newClient: {
    email: string,
    phone: string,
    first_name: string,
    last_name: string,
  },
  cardDetails: any,
  errorMessage: string,
};

type State = {
  type: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  isButtonDisabled: boolean,
  errors: {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
  },
  isLoading: boolean,
  isGettingClient: boolean,
  errorMessage: string,
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);
const validateForm = errors => {
  let valid = true;
  // eslint-disable-next-line no-return-assign
  Object.values(errors).forEach(val => val && (valid = false));
  return valid;
};

export class CreateAccountInvite extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      type: 'password',
      isButtonDisabled: true,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
      isLoading: false,
      isGettingClient: false,
      errorMessage: props.errorMessage,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setPageVisitedFunc('create-account');
    this.props.clearCache();
  }

  componentWillReceiveProps(nextProps: any) {
    if (this.props.errorMessage || nextProps.errorMessage) {
      this.setState({ errorMessage: 'Not Found' });
    }

    if (nextProps.cognito.state === 'LOGGED_IN') {
      this.setState({ isLoading: false });
      this.newUserRedirect(nextProps.cognito.user.username);
    }

    if (nextProps.cognito.attributes) {
      this.props.setUserName(nextProps.cognito.attributes.name);
    }

    if (!this.props.newClient.phone && !this.state.isGettingClient) {
      const uniqLink = this.props.match.params.uniqcode;
      this.props.getClientDetailsFromUniqLink(uniqLink);
      this.setState({ isGettingClient: true });
    }
  }

  checkButton = () => {
    if (this.state.firstName) {
      this.setState({ firstName: this.state.firstName });
    }
    if (this.state.lastName) {
      this.setState({ lastName: this.state.lastName });
    }
    const isButtonDisabled = !this.state.password || !validateForm(this.state.errors);
    this.setState({ isButtonDisabled });
  };

  handleChange = (event: Event, name: string) => {
    event.preventDefault();
    const isButtonDisabled = !this.state.password || !validateForm(this.state.errors);

    this.setState({ isButtonDisabled });
    const { value } = (event.currentTarget: any);
    const { errors } = this.state;

    switch (name) {
      case 'firstName':
        errors.firstName = value.length < 2 ? 'First Name must be 2 characters long' : '';
        this.setState({ firstName: value });
        if (!this.state.lastName) {
          errors.lastName = 'Pleas enter your last name';
        }
        break;
      case 'lastName':
        errors.lastName = value.length < 1 ? 'Pleas enter your last name' : '';
        this.setState({ lastName: value });
        break;
      case 'email':
        errors.email = validEmailRegex.test(value) ? '' : 'Please enter a valid email address';
        this.setState({ email: value });
        break;
      case 'password':
        errors.password = validatePassword(value);
        this.setState({ password: value });
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleSubmit = (event: Event) => {
    event.preventDefault();

    if (validateForm(this.state.errors)) {
      // console.info('Valid Form');
    } else {
      // console.error('Invalid Form');
    }
  };

  showHidePassword = () => {
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text',
    });
  };

  closeErrorPopup = () => {
    this.setState({ errorMessage: '' });
    this.props.history.push(`/salon/${this.props.match.params.id}`);
  };

  createAccount = () => {
    if (this.state.isButtonDisabled) {
      return;
    }
    this.setState({ isLoading: true });
    const { cognito } = this.props;
    const { userPool, config } = cognito;
    const phoneNumber = this.props.newClient.phone;
    const email = this.props.newClient.email;
    const fullName = `${this.props.newClient.first_name} ${this.props.newClient.last_name}`;
    try {
      this.props.registerUser(userPool, config, phoneNumber, this.state.password, {
        name: fullName,
        phone_number: phoneNumber,
        email,
      });
    } catch (error) {
      if (error instanceof TypeError) {
        this.props.registerUser(userPool, config, phoneNumber, this.state.password, {
          name: fullName,
          phone_number: phoneNumber,
          email,
        });
      } else {
        throw error;
      }
    }
  };

  newUserRedirect = (username: string) => {
    const uniqLink = this.props.match.params.uniqcode;
    this.props.getClientInvites(username);
    if (!this.props.cardDetails.last4) {
      this.props.history.push(`/salon/${this.props.match.params.id}/card-details/${uniqLink}`);
    }
  };

  render() {
    const { newClient, isDarkMode, isNeutralMode } = this.props;

    if (this.state.firstName) {
      newClient.first_name = this.state.firstName;
    }
    if (this.state.lastName) {
      newClient.last_name = this.state.lastName;
    }
    if (this.state.email) {
      newClient.email = this.state.email;
    }
    const { errors } = this.state;
    const loginError1 = this.props.cognito.error.message;
    let hideError = true;
    if (loginError1 && loginError1.indexOf('given phone_number already exists') > 0) {
      this.props.history.push(`/salon/${this.props.match.params.id}/login`);
    }
    if (loginError1 && loginError1.indexOf('Only radix 2, 4, 8, 16, 32 are supported') > 0) {
      hideError = true;
    }

    const inputFields = ['email', 'lastName', 'firstName', 'password'];
    const inputClasses = inputFields.reduce(
      (classes, field) => ({
        ...classes,
        [field]: cn({
          [CN.input]: true,
          [CN.hasError]: errors[field],
        }),
      }),
      {},
    );

    return (
      <div
        data-bem="CreateAccountInvite"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          {this.state.isLoading && <Spinner variant="overlay" />}
          <HeaderComponent history={this.props.history} />
          <div className={CN.subTitle}>
            <div className={CN.icon} />
            Create your account
          </div>

          <div className={CN.formContainer}>
            <form onSubmit={this.handleSubmit}>
              <div className={CN.inputContainer}>
                <label htmlFor="firstName" className={CN.inputLabel}>
                  First name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={event => this.handleChange(event, 'firstName')}
                  onBlur={this.checkButton}
                  placeholder="Eg. Jane"
                  className={inputClasses.firstName}
                />
                {errors.firstName.length > 0 && <span className={CN.error}>{errors.firstName}</span>}

                <br />
                <br />
                <label htmlFor="lastName" className={CN.inputLabel}>
                  Last name
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={event => this.handleChange(event, 'lastName')}
                  onBlur={this.checkButton}
                  placeholder="Eg. Doe"
                  className={inputClasses.lastName}
                />
                {errors.lastName.length > 0 && <span className={CN.error}>{errors.lastName}</span>}
              </div>
              <div className={CN.inputContainer}>
                <label htmlFor="email" className={CN.inputLabel}>
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={event => this.handleChange(event, 'email')}
                  onBlur={this.checkButton}
                  placeholder="Eg. janedoe@example.com"
                  autoComplete="off"
                  value={this.state.email}
                  className={inputClasses.email}
                />
                {errors.email.length > 0 && <span className={CN.error}>{errors.email}</span>}
              </div>
              <div className={CN.inputContainer}>
                <label htmlFor="phone" className={CN.inputLabel}>
                  Mobile number
                </label>
                <input
                  type="text"
                  name="phone"
                  readOnly
                  onChange={event => this.handleChange(event, 'phone')}
                  onBlur={this.checkButton}
                  value={newClient.phone ? newClient.phone : ''}
                  className={CN.input}
                />
              </div>
              <div className={CN.inputContainer}>
                <label htmlFor="password" className={CN.inputLabel}>
                  Create password
                </label>
                <input
                  type={this.state.type}
                  name="password"
                  onChange={event => this.handleChange(event, 'password')}
                  placeholder="At least 7 characters, 1 uppercase, 1 number"
                  onBlur={this.checkButton}
                  className={CN.input}
                />
                <div className={CN.eyeContainer} role="button" tabIndex="0" onClick={this.showHidePassword}>
                  {this.state.type === 'password' ? <div className={CN.eyeOpen} /> : <div className={CN.eyeClose} />}
                </div>
              </div>
              {errors.password.length > 0 && <span className={CN.error}>{errors.password}</span>}
            </form>
          </div>
        </div>

        {loginError1 && !hideError && <span className={CN.error}>{loginError1}</span>}

        <Footer data-bem="CreateAccountInvite__footer">
          <Button
            variant="primary"
            width="fixed"
            onClick={() => this.createAccount()}
            disabled={this.state.isButtonDisabled}
          >
            CREATE ACCOUNT
          </Button>
        </Footer>

        {fromAnyToBool(this.state.errorMessage) && (
          <SalonModal tag="CreateAccountInvite__modal" isOpen>
            <SalonModal.Body>
              <IconStatus variant="warning">
                <Icon variant="warning" width="xl" />
              </IconStatus>
              <div className={CN.modalContent}>
                <div className={CN.title}>
                  This deposit request is no longer valid and does not require action on your part. You can create an
                  online booking account when you book online.
                </div>
                <div className={CN.subTitle} />
              </div>
            </SalonModal.Body>
            <SalonModal.Footer>
              <Button variant="primary" onClick={this.closeErrorPopup}>
                OK
              </Button>
            </SalonModal.Footer>
          </SalonModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const cognito = {
    state: state.cognito.state,
    user: state.cognito.user,
    email: state.cognito.attributes.email,
    config: state.cognito.config,
    userPool: state.cognito.userPool,
    error: state.cognito.error,
  };
  const { booking } = state;
  const { needs_card } = booking.salonDetails;
  const { bookingResponse, newClient, invites, cardDetails, errorMessage } = booking;
  return {
    cognito,
    needs_card,
    pageVisited: booking.pageVisited,
    bookingResponse,
    newClient,
    invites,
    cardDetails,
    errorMessage,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  clearCache() {
    dispatch(Action.clearCache());
  },
  registerUser(userPool, config, username, password, attributes) {
    registerUser(userPool, config, username, password, attributes, dispatch).catch(() => {});
  },
  setUserName(name: string) {
    dispatch(setUserName(name));
  },
  getClientDetailsFromUniqLink(uniqlink: string) {
    dispatch(getClientDetailsFromUniqLink(uniqlink));
  },
  getClientInvites(username: string) {
    dispatch(getClientInvites(username));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(CreateAccountInvite));
