import React from 'react';
import cn from 'classnames';

import Toggler from 'components/Toggler.component';
import { useTheme } from 'providers/Theme.provider';
import asObject from 'utils/objects/asObject.util';

import currency from 'booking/common/currency.util';
import currencyRange from 'booking/common/currencyRange.util';

import CN from './ItemSticky.module.scss';

const ItemSticky = ({ service, currency: abbreviation, isChecked, onCheckboxClick, recommended }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const { max_price: maxPrice, variable_pricing: variablePricing, min_price: minPrice, name } = asObject(service);

  const price =
    minPrice !== maxPrice
      ? `${currencyRange({ from: minPrice, to: maxPrice, abbreviation })}`
      : `${currency({ value: minPrice, abbreviation })}`;

  const componentClasses = cn({
    [CN.component]: true,
    isHidden: !isChecked,
    recommended,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="ItemSticky" className={componentClasses}>
      <div className={CN.container}>
        <div>
          <div className={CN.info}>
            <span className={CN.title}>{name}</span>
          </div>
          <div className={CN.row} role="button" tabIndex={0} onClick={onCheckboxClick}>
            <span className={CN.price}>
              {variablePricing ? 'from ' : ''}
              {price}
            </span>
          </div>
        </div>
        <Toggler selected={isChecked} variant="circle" tag="ListItem__toggler" />
      </div>
    </div>
  );
};

export default ItemSticky;
