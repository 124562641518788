import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import cn from 'classnames';

import COGNITO from 'constants/cognito.const';
import { useTheme } from 'providers/Theme.provider';

import { getClientInvites } from 'booking/actions';
import clearHalfCache from 'lib/clearHalfCache';

import CN from './Navigation.module.scss';

const Navigation = ({ match, username, invites, getClientInvites, cognito, history }) => {
  const [newInvites, setNewInvites] = useState([]);
  const { isDarkMode, isNeutralMode } = useTheme();

  useEffect(() => {
    if (username) {
      getClientInvites(username);
    }
  }, [username, getClientInvites]);

  useEffect(() => {
    const filteredInvites = invites.filter(i => i.status === 'invited' || i.status === 'reminded');
    setNewInvites(filteredInvites);
  }, [invites]);

  const gotoMyBooking = useCallback(() => {
    const salonId = match.params.id;
    const pathname = `/salon/${salonId}/my-book`;
    const login = `/salon/${salonId}/profile-login`;
    clearHalfCache();
    if (history) {
      if (cognito && cognito.state === COGNITO.loggedIn) {
        history.push(pathname);
      } else {
        history.push({
          pathname: login,
          state: { fromMyBook: true },
        });
      }
    }
  }, [history, cognito, match.params.id]);

  const gotoMyFeed = useCallback(() => {
    const salonId = match.params.id;
    const pathname = `/salon/${salonId}/my-feeds`;
    const login = `/salon/${salonId}/profile-login`;
    clearHalfCache();
    if (history) {
      if (cognito && cognito.state === COGNITO.loggedIn) {
        history.push(pathname);
      } else {
        history.push({
          pathname: login,
          state: { fromMyBook: true },
        });
      }
    }
  }, [history, cognito, match.params.id]);

  return (
    <nav
      data-bem="Navigation"
      className={cn({
        [CN.component]: true,
        [CN.neutral]: isNeutralMode,
        [CN.dark]: isDarkMode,
      })}
    >
      <ul className={CN.navigation}>
        <li className={CN.navItem}>
          <NavLink to={`/salon/${match.params.id}/my-profile`} activeClassName={CN.selected} className={CN.navLink}>
            <div className={CN.navContent}>
              <div className={CN.iconProfile} />
              <div>Profile</div>
            </div>
          </NavLink>
        </li>
        <li className={CN.navItem}>
          <div className={CN.navLink} role="button" tabIndex={0} onClick={gotoMyBooking}>
            <div className={CN.navContent}>
              <div className={CN.iconBooking} />
              <div>Bookings</div>
            </div>
          </div>
        </li>
        {cognito.state === COGNITO.loggedIn ? (
          <li className={CN.navItem}>
            <NavLink to={`/salon/${match.params.id}/my-feeds`} activeClassName={CN.selected} className={CN.navLink}>
              <div className={CN.navContent}>
                <div className={CN.iconContainer}>
                  <div className={CN.iconPound} />
                  {cognito.state === COGNITO.loggedIn && newInvites.length > 0 && (
                    <div className={CN.bubble}>{newInvites.length}</div>
                  )}
                </div>
                <div>Deposits</div>
              </div>
            </NavLink>
          </li>
        ) : (
          <li className={CN.navItem}>
            <div className={CN.navLink} role="button" tabIndex={0} onClick={gotoMyFeed}>
              <div className={CN.navContent}>
                <div className={CN.iconBooking} />
                <div>Deposits</div>
              </div>
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
};

const mapStateToProps = state => {
  const { booking, cognito } = state;
  const { salonDetails, invites } = booking;
  return { cognito, salonId: salonDetails.id, invites, username: cognito.user ? cognito.user.username : '' };
};

const mapDispatchToProps = dispatch => ({
  getClientInvites: cognitoUsername => dispatch(getClientInvites(cognitoUsername)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navigation));
