import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import LOGO_SLICK from 'assets/images/Slicklogo.svg';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import Toggler from 'components/Toggler.component';
import useTip from 'hooks/useTip.hook';
import { useTheme } from 'providers/Theme.provider';

import CN from './PatchTest.module.scss';

const MAX_TEXT = 260;

const defaultPolicyText = [
  'When booking a hair colour or beauty treatment,',
  'it is a legal requirement to have a recent patch test carried out by our salon to check for allergies.',
  ' If you are unsure yours is still valid, contact us to check before booking.',
].join(' ');

const PatchTest = ({ onClose, onConfirmClick, salonDetails }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const { val: isSelectedYes, yay: setSelectedYes, nay: removeSelectedYes } = useTip(false);
  const { val: isSelectedNo, yay: setSelectedNo, nay: removeSelectedNo } = useTip(false);
  const { val: isExpanded, tip: toggle } = useTip(false);

  const ncobPatchTestCopy = salonDetails.ncob_patch_test_copy;
  const truncatedText =
    ncobPatchTestCopy && ncobPatchTestCopy.length > MAX_TEXT
      ? `${ncobPatchTestCopy.substring(0, MAX_TEXT)}`
      : ncobPatchTestCopy;

  const handleSelection = useCallback(
    isTested => {
      if (isTested === 'isSelectedYes' && !isSelectedYes) {
        setSelectedYes();
        removeSelectedNo();
      } else if (isTested === 'isSelectedNo' && !isSelectedNo) {
        setSelectedNo();
        removeSelectedYes();
      } else {
        removeSelectedYes();
        removeSelectedNo();
      }
    },
    [isSelectedYes, isSelectedNo, setSelectedYes, removeSelectedNo, setSelectedNo, removeSelectedYes],
  );

  const handleConfirmTest = useCallback(() => {
    if (isSelectedYes || isSelectedNo) {
      onConfirmClick(isSelectedYes ? 'isSelectedYes' : 'isSelectedNo');
    }
  }, [isSelectedYes, isSelectedNo, onConfirmClick]);

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="PatchTest" className={componentClasses}>
      <div className={CN.wrapper}>
        <div className={CN.header}>
          <div data-bem="PatchTest_arrowGoBack" className={CN.arrowGoBack} role="button" tabIndex={0} onClick={onClose}>
            <img src={IMG_ARROW} alt="Arrow go back" className={CN.arrowImg} />
          </div>
          <div className={CN.logo}>
            <span>Powered by</span>
            <img src={LOGO_SLICK} alt="Slick powered by logo" className={CN.slickLogo} />
          </div>
        </div>

        <div className={CN.title}>
          <div className={CN.icon} />
          Select a time for your patch test
        </div>

        {ncobPatchTestCopy ? (
          <div className={CN.text}>
            {isExpanded ? ncobPatchTestCopy : truncatedText}
            {ncobPatchTestCopy.length > MAX_TEXT && (
              <div className={CN.readMore} onClick={toggle} role="button" tabIndex={0}>
                {isExpanded ? 'close' : 'read more...'}
              </div>
            )}
          </div>
        ) : (
          <div className={CN.text}> {defaultPolicyText}</div>
        )}

        <div className={CN.focused}>Please note: Patch tests must be done at least 48 hrs before your appointment</div>
        <div className={CN.flex}>
          <div
            className={cn({
              [CN.selectItem]: true,
              [CN.selected]: isSelectedYes,
            })}
            role="button"
            tabIndex={0}
            onClick={() => handleSelection('isSelectedYes')}
          >
            <div className={CN.name}>Yes, my patch test is still valid</div>
            <Toggler selected={isSelectedYes} tag="PatchTestPopup__toggler_yes" />
          </div>
          <div
            className={cn({
              [CN.selectItem]: true,
              [CN.selected]: isSelectedNo,
            })}
            role="button"
            tabIndex={0}
            onClick={() => handleSelection('isSelectedNo')}
          >
            <div className={CN.name}>No, I need a patch test</div>
            <Toggler selected={isSelectedNo} tag="PatchTestPopup__toggler_no" />
          </div>
        </div>
      </div>
      <Footer data-bem="PatchTestPopup__footer">
        <Button variant="primary" width="fixed" onClick={handleConfirmTest} disabled={!isSelectedYes && !isSelectedNo}>
          NEXT
        </Button>
      </Footer>
    </div>
  );
};
const mapStateToProps = state => ({
  salonDetails: state.booking.salonDetails,
});
const PatchTestHoc = connect(mapStateToProps)(PatchTest);

export default PatchTestHoc;
