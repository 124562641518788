import React from 'react';
import cn from 'classnames';

import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import S from 'constants/string.const';
import { useTheme } from 'providers/Theme.provider';

import CN from './CancelAppointment.module.scss';

const CancelAppointment = ({ onClose, onCancel, phone }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="CancelAppointment" className={componentClasses}>
      <div className={CN.wrapper}>
        <div className={CN.header}>
          <div
            data-bem="CancelAppointment_arrowGoBack"
            className={CN.arrowGoBack}
            role="button"
            tabIndex={0}
            onClick={onClose}
          >
            <img src={IMG_ARROW} alt="Arrow go back" className={CN.arrowImg} />
          </div>
        </div>
        <IconStatus variant="warning">
          <Icon variant="warning" width="xl" />
        </IconStatus>

        <div className={CN.container}>
          <div className={CN.title}>Are you sure you want to cancel this appointment?</div>
          <div className={CN.text}>
            <div className={CN.gap}>
              If you have paid a deposit online it will be refunded once you cancel the appointment.
            </div>
            <div>
              If you paid the deposit in-store or over the phone the amount paid will be held on your account for future
              use.
            </div>
            <div className={CN.subTitle}>
              <div className={CN.icon} /> Want to reschedule?{S.space}
            </div>
            Continue to cancel this appointment and then book a new one on the homepage.
            <div className={CN.phoneContainer}>Alternatively contact them on:</div>
            <a href={`tel:${phone}`} className={CN.phoneLink}>
              {phone}
            </a>
          </div>
        </div>
      </div>
      <Footer data-bem="CancelAppointment__footer">
        <Button variant="error" width="fixed" onClick={onCancel}>
          CANCEL APPOINTMENT
        </Button>
      </Footer>
    </div>
  );
};

export default CancelAppointment;
