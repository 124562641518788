// @flow

/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */

import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import Navigation from 'invite/components/Navigation.component';
import moment from 'moment';

import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import { withTheme } from 'providers/Theme.provider';

import {
  clearAvailablePatchDays,
  clearAvailableSlots,
  getPatchAvailability,
  getPatchWeeklyAvailability,
  getPatchWeeklyAvailabilityRepeat,
  setPatchTime,
  updateFirstAvailablePatchDate,
} from 'booking/actions';
import StartAgainAndBackButtonsComponent from 'booking/common/StartAgainAndBackButtons.component';
import PatchTimeSelectionComponent from 'booking/timeSelection/PatchTimeSelection';
import { fbPixelTracking } from 'lib/utils';
import type { AvailableDays } from 'types/availableDays';
import type { Service } from 'types/service';

import CN from './PatchSelectionWrap.module.scss';

type Props = {
  history: string[],
  match: {
    params: {
      id: string,
    },
  },
  check_ncob_availability_until: number,
  isWaitList: boolean,
  selectedServices: Service[],
  selectedPatchTime?: null | moment,
  slotMessage: string,
  salonDetails: any,
  appointment_id: number,
  client_id: number,
  skip_today: boolean,
  availablePatchDays: AvailableDays,
  availablePatchSlots: any,
  firstAvailablePatchDate: any,
  updateFirstAvailablePatchDate: (date: string) => void,
  getPatchAvailability: (salonId: number, appointmentDate: moment) => void,
  getPatchWeeklyAvailability: (salonId: number, appointmentDate: moment, check: number) => void,
  getPatchWeeklyAvailabilityRepeat: (salonId: number, appointmentDate: moment) => void,
  setPatchTime: (time: moment, id?: any) => void,
  clearAvailableSlots: () => void,
  clearAvailablePatchDays: () => void,
};

type State = {
  PatchAppointmentTime: null | moment,
  firstAvailablePatchDate: string,
  firstAvailablePatchDateSet: boolean,
  stylistId: number | null,
};

export class PatchSelectionWrap extends React.Component<Props, State> {
  static defaultProps = { selectedPatchTime: null };

  constructor(props: Props) {
    super(props);
    let PatchAppointmentTime = null;
    const { selectedPatchTime } = props;
    if (selectedPatchTime) {
      PatchAppointmentTime = selectedPatchTime;
    }
    this.state = {
      PatchAppointmentTime,
      firstAvailablePatchDate: '',
      firstAvailablePatchDateSet: false,
      stylistId: null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.selectedServices.length === 0) {
      this.props.history.push(`/salon/${this.props.match.params.id}`);
    }
  }

  componentDidMount() {
    fbPixelTracking(this.props.salonDetails.fb_details.fb_pixel_id);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.history.push(`/salon/${nextProps.match.params.id}`);
    } else {
      if (this.state.firstAvailablePatchDate) {
        return;
      }
      if (nextProps.availablePatchDays !== this.props.availablePatchDays && !this.state.firstAvailablePatchDateSet) {
        let firstAvailablePatchDate;
        const { availablePatchDays } = nextProps;

        const keys = Object.keys(availablePatchDays);
        for (let i = 0; i < keys.length; i += 1) {
          if (availablePatchDays[keys[i]] === true) {
            firstAvailablePatchDate = keys[i];
            this.setState({ firstAvailablePatchDateSet: true, firstAvailablePatchDate });
            // add a firstAvailablePatchDate in store
            this.props.updateFirstAvailablePatchDate(firstAvailablePatchDate);
            break;
          }
        }
      }
      if (nextProps.availablePatchSlots && !this.state.firstAvailablePatchDateSet) {
        let firstAvailablePatchDate;
        let firstAvailable;

        const { availablePatchSlots } = nextProps;

        if (Object.keys(availablePatchSlots).length > 0) {
          [firstAvailablePatchDate] = Object.keys(availablePatchSlots);
          if (firstAvailablePatchDate) {
            [firstAvailable] = firstAvailablePatchDate.split('T');
          }
          this.setState({ firstAvailablePatchDateSet: true, firstAvailablePatchDate });
          // add a firstAvailableDate in store
          if (firstAvailable) {
            this.props.updateFirstAvailablePatchDate(firstAvailable);
          }
        } else {
          this.getPatchWeeklyAvailabilityRepeat(
            moment()
              .add(1, 'day')
              .startOf('day'),
          );
        }
      }
    }
  }

  setTime = (time: moment, stylistId: number) => {
    this.setState({ PatchAppointmentTime: time, stylistId });
  };
  getPatchAvailability = (appointmentDate: moment) => {
    const salonId = parseInt(this.props.match.params.id, 10);
    this.props.getPatchAvailability(salonId, appointmentDate);
  };
  getPatchWeeklyAvailability = (appointmentDate: moment, check: number) => {
    const salonId = parseInt(this.props.match.params.id, 10);
    this.props.getPatchWeeklyAvailability(salonId, appointmentDate, check);
  };
  getPatchWeeklyAvailabilityRepeat = (appointmentDate: moment) => {
    const salonId = parseInt(this.props.match.params.id, 10);
    this.props.getPatchWeeklyAvailabilityRepeat(salonId, appointmentDate);
  };
  btnClick = () => {
    const patchTime = this.state.PatchAppointmentTime;
    if (patchTime) {
      this.props.setPatchTime(patchTime, this.state.stylistId);
      this.props.history.push(`/salon/${this.props.match.params.id}/timeselect`);
    }
  };

  render() {
    const { isDarkMode, isNeutralMode } = this.props;

    return (
      <div
        data-bem="PatchSelectionWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          <Navigation match={this.props.match} />
          <div className={CN.header}>
            <StartAgainAndBackButtonsComponent />
          </div>
          <div className={CN.title}>Select a time for your patch test</div>
          <PatchTimeSelectionComponent
            slotMessage={this.props.slotMessage}
            appointment_id={this.props.appointment_id}
            getPatchAvailability={this.getPatchAvailability}
            getPatchWeeklyAvailability={this.getPatchWeeklyAvailability}
            getPatchWeeklyAvailabilityRepeat={this.getPatchWeeklyAvailabilityRepeat}
            skip_today={this.props.skip_today}
            setTime={this.setTime}
            selectedTime={this.state.PatchAppointmentTime}
            clearAvailableSlots={this.props.clearAvailableSlots}
            firstAvailablePatchDate={this.props.firstAvailablePatchDate}
            availablePatchDays={this.props.availablePatchDays}
            check_ncob_availability_until={this.props.check_ncob_availability_until}
            updateFirstAvailablePatchDate={this.props.updateFirstAvailablePatchDate}
            clearAvailablePatchDays={this.props.clearAvailablePatchDays}
          />
        </div>

        <Footer data-bem="PatchSelectionWrap__footer">
          <div className={CN.footerContainer}>
            <div className={CN.footerText}>
              {this.state.PatchAppointmentTime !== null &&
                'Patch test time selected, hit next to choose appointment time'}
            </div>
            <Button
              variant="primary"
              width="fixed"
              onClick={() => this.btnClick()}
              disabled={this.state.PatchAppointmentTime === null}
            >
              NEXT
            </Button>
          </div>
        </Footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { booking } = state;
  const {
    selectedPatchTime,
    selectedServices,
    slotMessage,
    salonDetails,
    bookingResponse,
    selectedAppointmentTime,
    firstAvailablePatchDate,
    availablePatchDays,
    availablePatchSlots,
  } = booking;
  return {
    selectedPatchTime,
    selectedAppointmentTime,
    selectedServices,
    slotMessage,
    salonDetails,
    skip_today: salonDetails.skip_today,
    appointment_id: bookingResponse ? bookingResponse.appointment_id : null,
    client_id: bookingResponse ? bookingResponse.client_id : null,
    firstAvailablePatchDate,
    availablePatchDays,
    availablePatchSlots,
    check_ncob_availability_until: salonDetails.check_ncob_availability_until,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getPatchAvailability(salonId: number, appointmentDate: moment, isWaitList: boolean) {
      dispatch(getPatchAvailability(salonId, appointmentDate, isWaitList));
    },
    getPatchWeeklyAvailability(salonId: number, appointmentDate: moment, check: number) {
      dispatch(getPatchWeeklyAvailability(salonId, appointmentDate, check));
    },
    getPatchWeeklyAvailabilityRepeat(salonId: number, appointmentDate: moment) {
      dispatch(getPatchWeeklyAvailabilityRepeat(salonId, appointmentDate));
    },
    setPatchTime(time: moment, id: number) {
      dispatch(setPatchTime(time, id));
    },
    clearAvailableSlots() {
      dispatch(clearAvailableSlots());
    },
    clearAvailablePatchDays() {
      dispatch(clearAvailablePatchDays());
    },
    updateFirstAvailablePatchDate(date: string) {
      dispatch(updateFirstAvailablePatchDate(date));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(PatchSelectionWrap));
