/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable flowtype/no-types-missing-file-annotation */
/* eslint-disable no-console, react/no-multi-comp, no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-undef */

import React, { Component } from 'react';
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';
import cn from 'classnames';

import { withTheme } from 'providers/Theme.provider';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';

import { fbPixelTracking } from 'lib/utils';

import CN from './AddNewCard.module.scss';

const handleBlur = () => {};

const handleFocus = () => {};
const handleReady = () => {};
const createOptions = (fontSize, padding) => ({
  style: {
    base: {
      fontSize,
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
      ...(padding ? { padding } : {}),
    },
    invalid: {
      color: '#9e2146',
    },
  },
});
type Props = {
  salonName: string,
  salonPhone: any,
};

class _AddNewCard extends Component<Props> {
  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.cardElement = React.createRef();
    this.expiryElement = React.createRef();
    this.cvcElement = React.createRef();
    this.state = {
      expiryError: '',
      cardError: '',
      isLoading: false,
      cardSaveStarted: false,
      getCardCalled: false,
      cardComplete: false,
      expireComplete: false,
      securityComplete: false,
    };
  }

  componentDidMount = () => {
    this.props.clearBillingInfo();
    this.props.clearUserCardDetails();
    this.props.clearIntentClientSecret();

    this.cardElement.current._element.on('ready', () => {
      this.cardElement.current._element.focus();
    });
  };

  componentWillReceiveProps() {
    this.cardElement.current._element.on('ready', () => {
      this.cardElement.current._element.focus();
    });
  }

  handleChange = change => {
    if (change.complete && !change.error) {
      this.setState({ cardError: '' });
      this.expiryElement.current._element.focus();
    } else if (change.error) {
      this.setState({ cardError: change.error.message });
    } else if (!this.state.cardError) {
      this.setState({ cardComplete: true });
    }
  };
  handleChange2 = change => {
    if (change.complete && !change.error) {
      this.setState({ expireComplete: true });
      this.cvcElement.current._element.focus();
    } else if (change.error) {
      this.setState({ expiryError: change.error.message });
    } else {
      this.setState({ expiryError: '', expireComplete: true });
    }
  };
  handleChange3 = change => {
    if (change.complete) {
      this.setState({ securityComplete: true });
    }
  };
  checkCardStatus = interval => {
    if (this.props.stripeToken && this.props.stripeToken.card) {
      const { id } = this.props.stripeToken;
      if (!this.state.getCardCalled && !this.props.billing.intent_client_secret) {
        this.props.setupIntent(this.props.salonId, this.props.username);
        this.setState({ getCardCalled: true });
      }
      if (this.props.billing.intent_client_secret && !this.state.cardSaveStarted) {
        this.saveCard(id, this.props.billing.intent_client_secret, interval);
        this.setState({ cardSaveStarted: true });
      }
    }
  };
  gotoMyfeeds = () => {
    this.props.history.push(`/salon/${this.props.salonId}/my-feeds`);
  };
  handleSubmit = ev => {
    if (this.state.disableSubmit) {
      return;
    }
    this.updateCard();
    this.setState({ cardError: '', expiryError: '' });
    ev.preventDefault();
    this.setState({ isLoading: true });
    if (this.props.stripe && !this.state.disableSubmit && !this.props.cardDetails.last4) {
      this.props.stripe
        .createToken()
        .then(payload => {
          this.props.setToken(payload);
        })
        .catch(error => {
          console.group('setAddCardError', error);
          this.props.setAddCardError(error);
        });
      const interval = setInterval(() => {
        this.checkCardStatus(interval);
      }, 500);
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
    this.setState({ disableSubmit: true });
  };

  saveCard = (cardId, intent_client_secret, interval) => {
    console.log('this.props.stripe', this.props.stripe);
    this.props.stripe
      .confirmCardSetup(intent_client_secret, {
        payment_method: { type: 'card', card: { token: cardId } },
      })
      .then(result => {
        clearInterval(interval);
        if (result.error) {
          if (result.error.type === 'invalid_request_error' && result.error.code === 'testmode_charges_only') {
            this.setState({
              expiryError: `Unfortunately your payment could not be processed. Please contact ${this.props.salonName} directly to make your booking and please ask them to contact Slick regarding their Stripe payment account`,
              disableSubmit: true,
              isLoading: false,
            });
          } else if (result.error.type === 'invalid_request_error') {
            this.setState({
              expiryError:
                'Unfortunately there was a problem processing your payment. Please check your card details and try again. If the problem persists, please try using another card',
              disableSubmit: true,
              isLoading: false,
            });
          } else {
            this.setState({
              expiryError: `Unfortunately there has been a problem adding your card. Please try using another card and if the problem persists, please contact ${this.props.salonName} on ${this.props.salonPhone} as their payments account may need updating`,
              disableSubmit: true,
              isLoading: false,
            });
          }
        } else if (result.setupIntent.id && this.props.history.location.pathname.indexOf('add-new-card') < 0) {
          fbPixelTracking(this.props.salonDetails.fb_details.fb_pixel_id, 'Card added');
          this.gotoMyfeeds();
        } else {
          let path;
          // invite add new card
          fbPixelTracking(this.props.salonDetails.fb_details.fb_pixel_id, 'Card added');
          if (this.props.history.location.state && this.props.history.location.state.from === 'invite') {
            path = `/salon/${this.props.salonId}/pay-deposit/${this.props.history.location.state.appointmentId}`;
          } else {
            // normal booking, confirm payment
            // path = `/salon/${this.props.salonId}/confirm-with-new-card`;
            path = `/salon/${this.props.salonId}/confirm-with-card`;
          }
          this.props.history.push({
            pathname: path,
            state: { saveCardSuccess: true },
          });
        }
      });
  };
  startAgain = () => {
    if (!this.props.history.location.state) {
      this.props.history.push(`/salon/${this.props.salonId}`);
    } else if (this.props.history.location.state && this.props.history.location.state.from === 'invite') {
      this.gotoMyfeeds();
    }
  };
  updateCard = () => {
    this.props.clearBillingInfo(true);
    this.props.clearUserCardDetails();
    this.props.setPaymentError(false);
    this.setState({ getCardCalled: false, cardSaveStarted: false });
  };

  render() {
    const { cardError, expiryError, securityComplete, cardComplete, expireComplete } = this.state;
    const { isDarkMode, isNeutralMode } = this.props;

    const confirmClass = !this.state.disableSubmit ? 'btn btn-primary' : 'btn btn-primary disabled';

    const expiryMonth = '';
    const expiryYear = '';
    const cardNumber = '1234 1234 1234 1234';
    const cvc = '';

    return (
      <div
        data-bem="AddNewCard"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        {this.state.isLoading && (
          <div data-bem="AddNewCard__spinner" className="spinner-container">
            <div className="load-spinner" />
          </div>
        )}

        {fromAnyToBool(cardError) && <p className={CN.error}>{cardError}</p>}
        {fromAnyToBool(expiryError) && <p className={CN.error}>{expiryError}</p>}

        <form data-bem="AddNewCard__card-info-form" className="cardInfo" onSubmit={this.handleSubmit}>
          <label htmlFor="CardNumberElement" className="cardNumber">
            <span className={CN.cardNumberLabel}>Card number</span>
            <CardNumberElement
              onBlur={e => handleBlur(e)}
              onChange={this.handleChange}
              onFocus={this.handleChange}
              onReady={handleReady}
              {...createOptions(this.props.fontSize)}
              placeholder={cardNumber}
              ref={this.cardElement}
            />
          </label>
          <br />
          <label htmlFor="CardExpiryElement">
            <span className={CN.cardNumberLabel}>Expiration date</span>
            <CardExpiryElement
              onBlur={handleBlur}
              onChange={this.handleChange2}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(this.props.fontSize)}
              placeholder={`${expiryMonth}/${expiryYear}`}
              ref={this.expiryElement}
            />
          </label>

          <label htmlFor="CardCVCElement">
            <span className={CN.cardNumberLabel}>Security code</span>
            <CardCVCElement
              onBlur={handleBlur}
              onChange={this.handleChange3}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(this.props.fontSize)}
              ref={this.cvcElement}
              placeholder={cvc}
            />
          </label>
          {cardComplete && securityComplete && expireComplete && expiryError && (
            <button className="btn btn-primary try-again" onClick={this.startAgain}>
              Start again
            </button>
          )}
          {cardComplete && securityComplete && expireComplete && (
            <footer className="app-footer complicated">
              <button className={confirmClass} disabled={expiryError || cardError}>
                Next
              </button>
            </footer>
          )}
        </form>
      </div>
    );
  }
}

const AddNewCard = injectStripe(withTheme()(_AddNewCard));

export default AddNewCard;
