// @flow

import React from 'react';

import arrDown from 'assets/images/prev.svg';

type Props = {
  year: string,
  onClick: () => void,
};

const DropdownBtn = (props: Props) => (
  <div className="dropdown-btn" role="button" tabIndex={0} onClick={props.onClick}>
    {props.year}
    {/* this.props.monthsToShow */}
    {
      <span className="arr-icon" role="button" tabIndex={0} onClick={props.onClick}>
        <img src={arrDown} alt="show mounth" />
      </span>
    }
  </div>
);

export default DropdownBtn;
