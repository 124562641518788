import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { trackingSet } from 'actions/trackingSet.action';
import CONSENT from 'constants/consent.const';
import TRACKING from 'constants/tracking.const';
import { selectCookieChoice, selectTrackingStatus } from 'selectors/cookie.selectors';
import { selectCurrentSalonId } from 'selectors/currentSalon.selectors';
import determineProductionConfiguration from 'utils/env/determineProductionConfiguration.util';
import withOptions from 'utils/functions/withOptions.util';
import logger from 'utils/logger.util';

const IS_PROD = determineProductionConfiguration();

const L = logger('TrackingPixel');

const initGa = withOptions(({ choice, salonId, status, onSet }) => {
  // some checks first before tracking is enabled

  if (choice !== CONSENT.all) return L.debug('initGa()', 'Stopping due to opt-out by choice:', choice);
  if (status) return L.debug('initGa()', 'Stopping due to already having status:', status);

  const gaId = IS_PROD ? TRACKING.gaProd[salonId] : TRACKING.gaStage[salonId];

  if (!gaId) return L.debug('initGa()', 'Stopping due to missing gaId:', gaId);

  onSet(true);

  L.info('Initializing analytics for salonId:', salonId, 'with gaId:', gaId);

  return ReactGA.initialize(gaId);
});

const TrackingPixel = ({ salonId, cookieChoice, trackingStatus, trackingSet }) => {
  useEffect(
    () =>
      void initGa({
        choice: cookieChoice,
        salonId,
        status: trackingStatus,
        onSet: trackingSet,
      }),
    [salonId, cookieChoice, trackingSet, trackingStatus],
  );

  return <div data-bem="TrackingPixel" />;
};

const mapStateToProps = state => ({
  salonId: selectCurrentSalonId(state),
  cookieChoice: selectCookieChoice(state),
  trackingStatus: selectTrackingStatus(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ trackingSet }, dispatch);
const TrackingPixelHoc = connect(mapStateToProps, mapDispatchToProps)(TrackingPixel);

export default TrackingPixelHoc;
