// @flow

/* eslint-disable no-trailing-spaces */
/* eslint-disable react/sort-comp */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */

import React from 'react';
import { Action, registerUser } from 'react-cognito-mm';
// $FlowFixMe
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import cn from 'classnames';

import { setUserName } from 'actions/billing';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import { withTheme } from 'providers/Theme.provider';

import HeaderComponent from 'booking/common/Header';
import Spinner from 'common/Spinner.component';
import createBooking, { fbPixelTracking, setPageVisitedFunc } from 'lib/utils';
import { validatePassword } from 'lib/validators';

import 'react-phone-number-input/style.css';
import CN from './CreateAccountFormWrap.module.scss';

type Props = {
  appointment_cost: number,
  history: string[],
  cognito: {
    userPool: any,
    config: any,
    state: string,
    user: { username: string },
    error: {
      code: string,
      statusCode: string,
      message: string,
    },
    attributes?: {
      name: string,
    },
  },
  bookingResponse: any,
  needs_card: boolean,
  isWaitList: boolean,
  registerUser: (userPool: any, config: any, username: string, password: string, attributes: any) => void,
  setUserName: (name: string) => void,
  match: {
    params: {
      id: string,
    },
  },
  salonDetails: any,
};

type State = {
  type: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  phone2: string,
  password: string,
  requestedOptOut: boolean,
  isButtonDisabled: boolean,
  errors: {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
  },
  isLoading: boolean,
  bookingDataSent: boolean,
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);

const validateForm = errors => {
  let valid = true;
  // eslint-disable-next-line no-return-assign
  Object.values(errors).forEach(val => val && (valid = false));
  return valid;
};

export class CreateAccountFormWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      type: 'password',
      isButtonDisabled: true,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      phone2: '',
      password: '',
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
      isLoading: false,
      bookingDataSent: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setPageVisitedFunc('create-account');
    if (this.props.bookingResponse) {
      if (Object.keys(this.props.bookingResponse.salonDetails).length !== 0) {
        fbPixelTracking(this.props.bookingResponse.salonDetails.fb_details.fb_pixel_id);
      }
    }
  }

  checkButton = () => {
    if (this.state.firstName) {
      this.setState({ firstName: this.state.firstName });
    }
    if (this.state.lastName) {
      this.setState({ lastName: this.state.lastName });
    }
    const isButtonDisabled =
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.email ||
      !this.state.password ||
      !validateForm(this.state.errors);
    this.setState({ isButtonDisabled });
  };

  handleChange = (event: Event, name: string) => {
    event.preventDefault();

    const isButtonDisabled =
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.email ||
      !this.state.password ||
      !validateForm(this.state.errors);

    this.setState({ isButtonDisabled });
    const { value } = (event.currentTarget: any);
    const { errors } = this.state;

    switch (name) {
      case 'firstName':
        errors.firstName = value.length < 2 ? 'First Name must be 2 characters long' : '';
        this.setState({ firstName: value });
        if (!this.state.lastName) {
          errors.lastName = 'Pleas enter your last name';
        }
        break;
      case 'lastName':
        errors.lastName = value.length < 1 ? 'Pleas enter your last name' : '';
        this.setState({ lastName: value });
        break;

      case 'email':
        errors.email = validEmailRegex.test(value) ? '' : 'Please enter a valid email address';
        this.setState({ email: value });
        break;
      case 'password':
        errors.password = validatePassword(value);
        this.setState({ password: value });
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleSubmit = (event: Event) => {
    event.preventDefault();

    if (this.state.phone && isValidPhoneNumber(this.state.phone)) {
      // console.info("Valid Form");
    } else {
      //   console.error("Invalid Form");
    }
  };
  checkUserStatus = (intervalId: any) => {
    if (this.props.cognito && this.props.cognito.attributes && this.props.cognito.attributes.name) {
      this.props.setUserName(this.props.cognito.attributes.name);
    }
    if (!this.state.bookingDataSent && this.props.cognito.state !== 'LOGGED_OUT') {
      clearInterval(intervalId);
      this.newUserRedirect();
    }
    // when don't need card
    if (this.props.bookingResponse !== null) {
      clearInterval(intervalId);
      this.props.history.push(`/salon/${this.props.match.params.id}/bookingConfirmed`);
    }
  };

  showHidePassword = () => {
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text',
    });
  };

  createAccount = () => {
    if (this.state.isButtonDisabled) {
      return;
    }
    this.setState({ isLoading: true });
    const { cognito } = this.props;
    const { userPool, config } = cognito;
    const fullName = `${this.state.firstName} ${this.state.lastName}`;
    try {
      this.props.registerUser(userPool, config, this.state.phone, this.state.password, {
        name: fullName,
        phone_number: this.state.phone,
        email: this.state.email,
      });
      const interval = setInterval(() => {
        this.checkUserStatus(interval);
      }, 500);
    } catch (error) {
      if (error instanceof TypeError) {
        this.props.registerUser(userPool, config, this.state.phone, this.state.password, {
          name: fullName,
          phone_number: this.state.phone,
          email: this.state.email,
        });
      } else {
        throw error;
      }
    }
  };

  newUserRedirect = () => {
    if (this.props.needs_card && !this.props.isWaitList) {
      this.props.history.push(`/salon/${this.props.match.params.id}/add-new-card`);
    } else {
      this.props.history.push(`/salon/${this.props.match.params.id}/summary`);
    }
    const dontNeedToPay = this.props.appointment_cost === 0 || !this.props.needs_card;

    if (dontNeedToPay && !this.props.bookingResponse && !this.state.bookingDataSent) {
      this.setState({ bookingDataSent: true }, () => {
        createBooking();
      });
    }
  };

  onInternationalPhone = (value: string, type: 'inter' | 'inter2') => {
    const phoneSymbolsRegex = /^[0-9+)(\s]*$/;
    if (phoneSymbolsRegex.test(value)) {
      if (type === 'inter') {
        this.setState({ phone: value });
      }
      if (type === 'inter2') {
        this.setState({ phone2: value });
      }
    }
  };

  render() {
    const {
      salonDetails: { country },
      isDarkMode,
      isNeutralMode,
    } = this.props;

    const countryFlag = country && country.code;

    const { errors } = this.state;

    const loginError1 = this.props.cognito.error.message;
    if (loginError1 && loginError1.indexOf('given phone_number already exists') > 0) {
      this.props.history.push(`/salon/${this.props.match.params.id}/login`);
    }

    const phoneClasses = cn({
      [CN.phoneContainer]: true,
      [CN.hasError]: this.state.phone && !isValidPhoneNumber(this.state.phone),
    });

    const phone2Classes = cn({
      [CN.phoneContainer]: true,
      [CN.hasError]: this.state.phone2 && !isValidPhoneNumber(this.state.phone2),
    });

    const inputFields = ['email', 'lastName', 'firstName', 'password'];
    const inputClasses = inputFields.reduce(
      (classes, field) => ({
        ...classes,
        [field]: cn({
          [CN.input]: true,
          [CN.hasError]: errors[field],
        }),
      }),
      {},
    );

    return (
      <div
        data-bem="CreateAccountFormWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          {this.state.isLoading && <Spinner variant="overlay" />}
          <HeaderComponent history={this.props.history} />
          <div className={CN.subTitle}>
            <div className={CN.icon} />
            Create your account
          </div>

          <div className={CN.formContainer}>
            <form onSubmit={this.handleSubmit}>
              <div className={CN.inputContainer}>
                <label htmlFor="firstName" className={CN.inputLabel}>
                  First name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={event => this.handleChange(event, 'firstName')}
                  onBlur={this.checkButton}
                  placeholder="Eg. Jane"
                  className={inputClasses.firstName}
                />
                {errors.firstName.length > 0 && <span className={CN.error}>{errors.firstName}</span>}
                <br />
                <br />
                <label htmlFor="lastName" className={CN.inputLabel}>
                  Last name
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={event => this.handleChange(event, 'lastName')}
                  onBlur={this.checkButton}
                  placeholder="Eg. Doe"
                  className={inputClasses.lastName}
                />
                {errors.lastName.length > 0 && <span className={CN.error}>{errors.lastName}</span>}
              </div>
              <div className={CN.inputContainer}>
                <label htmlFor="email" className={CN.inputLabel}>
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={event => this.handleChange(event, 'email')}
                  onBlur={this.checkButton}
                  placeholder="Eg. janedoe@example.com"
                  // eslint-disable-next-line prefer-template
                  autoComplete={'' + Math.random()} //  example 0.6182367684498542  // "off"
                  value={this.state.email}
                  className={inputClasses.email}
                />
                {errors.email.length > 0 && <span className={CN.error}>{errors.email}</span>}
              </div>
              <div className={phoneClasses}>
                <label htmlFor="phone" className={CN.inputLabel}>
                  Mobile number
                </label>
                <PhoneInput
                  international
                  defaultCountry={countryFlag}
                  placeholder="Enter phone number"
                  value={this.state.phone}
                  onChange={value => void this.onInternationalPhone(value, 'inter')}
                />

                {this.state.phone && !isValidPhoneNumber(this.state.phone) && (
                  <span className={CN.error}>Please enter valid phone number</span>
                )}
              </div>
              <div className={phone2Classes}>
                <label htmlFor="phone2" className={CN.inputLabel}>
                  Confirm mobile number
                </label>
                <PhoneInput
                  defaultCountry={countryFlag}
                  placeholder="Enter phone number"
                  value={this.state.phone2}
                  onChange={value => void this.onInternationalPhone(value, 'inter2')}
                />

                {this.state.phone2 && !isValidPhoneNumber(this.state.phone2) && (
                  <span className={CN.error}>Please enter valid phone number</span>
                )}
                {this.state.phone !== this.state.phone2 && (
                  <span className={CN.error}>Phone numbers do not match. Please check and try again</span>
                )}
              </div>
              <div className={CN.inputContainer}>
                <label htmlFor="password" className={CN.inputLabel}>
                  Create password
                </label>
                <div className={CN.passwordContainer}>
                  <input
                    type={this.state.type}
                    name="password"
                    onChange={event => this.handleChange(event, 'password')}
                    placeholder="At least 7 characters, 1 uppercase, 1 number"
                    onBlur={this.checkButton}
                    className={inputClasses.password}
                  />
                  <div className={CN.eyeContainer} role="button" tabIndex="0" onClick={this.showHidePassword}>
                    {this.state.type === 'password' ? <div className={CN.eyeOpen} /> : <div className={CN.eyeClose} />}
                  </div>
                </div>
              </div>
              {errors.password.length > 0 && <span className={CN.error}>{errors.password}</span>}
            </form>
          </div>
        </div>

        <Footer data-bem="CreateAccountFormWrap__footer">
          <Button
            variant="primary"
            width="fixed"
            onClick={() => this.createAccount()}
            disabled={
              this.state.phone.length < 4 ||
              (this.state.phone && !isValidPhoneNumber(this.state.phone)) ||
              this.state.phone2.length < 4 ||
              (this.state.phone2 && !isValidPhoneNumber(this.state.phone2)) ||
              this.state.phone !== this.state.phone2 ||
              this.state.isButtonDisabled
            }
          >
            CREATE ACCOUNT
          </Button>
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const cognito = {
    state: state.cognito.state,
    user: state.cognito.user,
    email: state.cognito.attributes.email,
    config: state.cognito.config,
    userPool: state.cognito.userPool,
    error: state.cognito.error,
  };
  const { booking, billing } = state;
  const { needs_card } = booking.salonDetails;
  const { bookingResponse, salonDetails } = booking;
  return {
    salonDetails,
    cognito,
    needs_card,
    pageVisited: booking.pageVisited,
    bookingResponse,
    appointment_cost: billing.appointment_cost,
    pay_today: billing.pay_today,
    isWaitList: booking.isWaitList,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  clearCache() {
    dispatch(Action.clearCache());
  },
  registerUser(userPool, config, username, password, attributes) {
    registerUser(userPool, config, username, password, attributes, dispatch).catch(() => {});
  },
  setUserName(name: string) {
    dispatch(setUserName(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(CreateAccountFormWrap));
