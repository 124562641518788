import React from 'react';
import { connect } from 'react-redux';

import { selectService } from 'booking/actions';
import ListItem from 'booking/common/ListItem.component';

class ServiceItem extends React.Component {
  static defaultProps = { recommended: false };

  onCheckboxClick = ev => {
    ev.preventDefault();
    this.props.selectService(this.props.service);
  };

  render() {
    const {
      salonDetails: { currency },
    } = this.props;

    let checked = false;
    for (let i = 0; i < this.props.selectedServices.length; i += 1) {
      if (this.props.selectedServices[i].id === this.props.service.id) {
        checked = true;
        break;
      }
    }
    return (
      <ListItem
        currency={currency}
        service={this.props.service}
        isChecked={checked}
        onCheckboxClick={this.onCheckboxClick}
        key={`serviceItem${this.props.service.id}`}
        recommended={this.props.recommended}
        isSelected={checked ? ' ' : 'isHidden'}
      />
    );
  }
}

const mapStateToProps = state => ({
  selectedServices: state.booking.selectedServices,
  salonDetails: state.booking.salonDetails,
});

const mapDispatchToProps = dispatch => ({
  selectService(service) {
    dispatch(selectService(service));
  },
});

const ServiceItemHoc = connect(mapStateToProps, mapDispatchToProps)(ServiceItem);

export default ServiceItemHoc;
