import asObject from 'utils/objects/asObject.util';

const fromPropsToRouting = props => {
  const { match, location, history } = asObject(props);
  const { hash, pathname, search, state } = asObject(location);
  const { isExact, params, path, url } = asObject(match);

  return {
    // the main objects from react-router

    match,
    location,
    history,

    // shortcuts for ease of access/destructure

    hash,
    h: hash,

    pathname,
    l: pathname,

    search,
    q: search,

    state,
    s: state,

    isExact,
    x: isExact,

    params,
    m: params,

    path,
    p: path,

    url,
    u: url,
  };
};

// noinspection JSUnusedGlobalSymbols
export default fromPropsToRouting;
