import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import cn from 'classnames';
import Navigation from 'invite/components/Navigation.component';
import { bindActionCreators } from 'redux';

import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import Badge from 'components/Badge.component';
import Button from 'components/Button.component';
import FoldOut from 'components/FoldOut.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import S from 'constants/string.const';
import useAfn from 'hooks/useAfn.hook';
import useTip from 'hooks/useTip.hook';
import { useCurrentSalonId, useSalonBasicInfo } from 'providers/SalonBasicInfo.provider';
import { useTheme } from 'providers/Theme.provider';
import { formatDayToYearFullWeekday, formatHourAndMinute } from 'utils/date/format.util';
import logger from 'utils/logger.util';

import { cancelAppointment, getClientInvites } from 'booking/actions';
import currency from 'booking/common/currency.util';
import Spinner from 'common/Spinner.component';

import SuccessAppointment from './components/SuccessAppointment.component';
import WarningAppointment from './components/WarningAppointment.component';
import CancelAppointment from './CancelAppointment.component';

import CN from './AppointmentDetails.module.scss';

const CTAG = 'AppointmentDetails';
const L = logger(CTAG);

const AppointmentDetails = ({
  match,
  history,
  currency: abbreviation,
  getClientInvites,
  cognitoUserName,
  cancelAppointment,
  salonDetails,
}) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const { salonName, salonPhone } = useSalonBasicInfo();
  const salonId = useCurrentSalonId();
  const location = useLocation();
  const { bookingsSalonId } = useParams();

  const { val: renderModalCancel, yay: showModalCancel, nay: hideModalCancel } = useTip(false);
  const { val: renderModalWarning, yay: showModalWarning, nay: hideModalWarning } = useTip(false);
  const { val: renderModalSuccess, yay: showModalSuccess, nay: closeModalSuccess } = useTip(false);

  const procedureData = location.state ? location.state.procedureData : null;
  const appointmentId = procedureData.id;
  const isWaitList = procedureData && procedureData.procedures[0].stylist.serves_as_waitlist;

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const [{ isLoading: isLoadingSend }, handleCancelAppointment] = useAfn(async () => {
    const result = await cancelAppointment(bookingsSalonId, appointmentId, cognitoUserName);
    if (result.errorMessage) {
      showModalWarning();
      hideModalCancel();
    } else {
      hideModalCancel();
      showModalSuccess();
    }

    L.warn('handleCancelAppointment()', 'result:', result);
  }, [
    cancelAppointment,
    bookingsSalonId,
    appointmentId,
    cognitoUserName,
    hideModalCancel,
    showModalSuccess,
    showModalWarning,
  ]);

  const handleCloseModalSuccess = useCallback(() => {
    closeModalSuccess();
    history.goBack();
  }, [closeModalSuccess, history]);

  useEffect(() => {
    if (cognitoUserName) {
      getClientInvites(cognitoUserName);
    }
  }, [cognitoUserName, getClientInvites]);

  L.debug(
    'salon id->',
    salonId,
    'bookings salon id->',
    bookingsSalonId,
    'appointment id->',
    appointmentId,
    'procedureData->',
    procedureData,
  );

  return (
    <div
      data-bem="AppointmentDetails"
      className={cn({
        [CN.component]: true,
        [CN.neutral]: isNeutralMode,
        [CN.dark]: isDarkMode,
      })}
    >
      {isLoadingSend && <Spinner variant="overlay" />}

      <div className={CN.wrapper}>
        <Navigation match={match} />
        <div className={CN.header}>
          <div
            data-bem="AppointmentDetails_arrow-go-back"
            className={CN.arrowGoBack}
            role="button"
            tabIndex={0}
            onClick={handleGoBack}
          >
            <img src={IMG_ARROW} alt="Arrow go back" className={CN.arrowImg} />
          </div>
          <div className={CN.badgeContainer}>
            {isWaitList ? (
              <Badge variant="default">Waitlist Booking</Badge>
            ) : (
              <Badge variant="confirm">
                <Icon variant="check" width="xs" /> <span className={CN.space}>Booking Confirmed</span>
              </Badge>
            )}
          </div>
        </div>

        <div className={CN.pageTitle} data-bem="AppointmentDetails__title">
          <div className={CN.icon} />
          Appointment Details:
        </div>

        <div className={CN.procedureContainer}>
          <div className={CN.flexRow}>
            <div>
              <div className={CN.title}>{formatDayToYearFullWeekday(procedureData.starts_at)}</div>
              <div className={CN.serviceInfo}>
                {formatHourAndMinute(procedureData.starts_at)} - {formatHourAndMinute(procedureData.ends_at)}
              </div>
            </div>
          </div>

          {procedureData.procedures.map(procedure => (
            <div key={procedure.id} data-bem="AppointmentDetails__procedures">
              <div className={CN.flexRow}>
                <div>
                  <div className={CN.title}>{procedure.service.name}</div>
                  <div className={CN.serviceInfo}>
                    <span>
                      {procedure.stylist && procedure.stylist.user_name ? procedure.stylist.user_name : '-'}
                      {S.space}
                      {S.minus}
                      {S.space}
                      {procedure.stylist && procedure.stylist.role_name ? procedure.stylist.role_name : '-'}
                    </span>
                  </div>
                </div>
                <div className={CN.textFocused}>{currency({ value: procedure.price, abbreviation })}</div>
              </div>
            </div>
          ))}

          <div className={CN.row}>
            <div className={CN.title}>Total: </div>
            <div className={CN.textFocused}>{currency({ value: procedureData.price, abbreviation })}</div>
          </div>
          <div className={CN.row}>
            <div className={CN.title}>Deposit paid:</div>
            <div className={CN.textFocused}>{currency({ value: procedureData.deposit, abbreviation })}</div>
          </div>
        </div>
        {!isWaitList && (
          <div className={CN.policyContainer} data-bem="AppointmentDetails__foldOut">
            <FoldOut title="Cancellation Policy" icon="policy">
              <div className={CN.policyText} data-bem="AppointmentDetails__policy--text">
                {salonDetails.salon_ncob_text}
              </div>
            </FoldOut>
          </div>
        )}
      </div>

      <Footer data-bem="AppointmentDetails__footer">
        <Button variant="secondary" width="fixed" onClick={showModalCancel}>
          CANCEL/EDIT APPOINTMENT
        </Button>
      </Footer>

      {renderModalCancel && (
        <CancelAppointment onClose={hideModalCancel} onCancel={handleCancelAppointment} phone={salonPhone} />
      )}

      {renderModalWarning && (
        <WarningAppointment
          onCancel={hideModalWarning}
          onClose={hideModalWarning}
          salonName={salonName}
          salonPhone={salonPhone}
        />
      )}

      {renderModalSuccess && <SuccessAppointment onCancel={handleCloseModalSuccess} />}
    </div>
  );
};

const mapStateToProps = state => ({
  cognitoUserName: state.cognito.user.username,
  salonDetails: state.booking.salonDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getClientInvites,
      cancelAppointment,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppointmentDetails));
