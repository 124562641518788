import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import LINK from 'constants/link.const';
import { useCurrentSalonId } from 'providers/SalonBasicInfo.provider';
import { useTheme } from 'providers/Theme.provider';
import linkTo from 'utils/links/linkTo.util';

import CN from './SuccessfulPayment.module.scss';

const SuccessfulPayment = ({ onClose, title }) => {
  const { isDarkMode, isNeutralMode } = useTheme();
  const salonId = useCurrentSalonId();

  const linkToSalon = linkTo({ pattern: LINK.salon, params: { salonId } });

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="SuccessfulPayment" className={componentClasses}>
      <div className={CN.wrapper}>
        <div className={CN.header} />
        <IconStatus variant="success">
          <Icon variant="confirm" width="xl" />
        </IconStatus>

        <div className={CN.container}>
          <div className={CN.title}>{title}</div>
          <div className={CN.text}>
            <div className={CN.subTitle}>Your booking is now confirmed.</div>
          </div>
        </div>
      </div>
      <Footer data-bem="SuccessfulPayment__footer">
        <div className={CN.footerContainer}>
          <Button variant="secondary" width="fixed" onClick={onClose}>
            CLOSE
          </Button>
          <NavLink to={linkToSalon} data-bem="SingleSalonBookings__link" className={CN.link}>
            <Button variant="primary" width="fixed">
              BOOK ANOTHER APPOINTMENT
            </Button>
          </NavLink>
        </div>
      </Footer>
    </div>
  );
};

export default SuccessfulPayment;
