import { API_ROOT } from 'config/index';
import { AUTH_API } from 'middleware/api';
import withOptions from 'utils/functions/withOptions.util';
import actionType from 'utils/mw/actionType.util';

const ATAG = 'beGetSalons';
const AT = actionType(ATAG);

const beGetSalons = withOptions(({ cognitoUserName }) => ({
  [AUTH_API]: {
    apiRoot: API_ROOT,
    endpoint: `ncob/client/salons/${cognitoUserName}/`,
    authenticated: true,
    types: [AT.init, AT.pass, AT.fail, AT.done],
    config: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  },
}));

beGetSalons.ATAG = ATAG;
beGetSalons.AT = AT;

export default beGetSalons;
