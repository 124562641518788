// @flow

import React from 'react';

import type { Client } from 'types/client';

type PropsType = {
  client: Client,
};

const SalonHeader = ({ client }: PropsType) => (
  <div className="header-wrap">
    <div className="text-salon">{client.salon.name}</div>
    <a href={`tel:${client.salon.phone}`}>
      <span className="call ico-phone" />
    </a>
  </div>
);

export default SalonHeader;
