// @flow

import {
  SALON_DEPOSIT_ERROR,
  SALON_DEPOSIT_REQUEST,
  SALON_DEPOSIT_SUCCESS,
  SALON_FAILURE,
  SALON_REQUEST,
  SALON_SUCCESS,
} from 'actions/salon';

type SalonState = {
  isFetching: boolean,
  errorMessage: string | null,
  authenticated: boolean,
  response: Array<{
    id: number,
    salon: { id: number, name: string, phone: string },
  }>,
};

const initialState = {
  isFetching: false,
  errorMessage: null,
  authenticated: false,
  response: [],
};

function salon(state: SalonState = initialState, action: Action) {
  switch (action.type) {
    case SALON_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        response: [],
        errorMessage: null,
      });
    case SALON_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        response: action.response,
        authenticated: action.authenticated || false,
      });
    case SALON_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.errorMessage,
      });
    case SALON_DEPOSIT_REQUEST: {
      return { ...state, isFetching: true };
    }
    case SALON_DEPOSIT_SUCCESS: {
      return { ...state, ...action.response, isFetching: false };
    }
    case SALON_DEPOSIT_ERROR: {
      return { ...state, isFetching: false, errorMessage: action.errorMessage, error: action.error };
    }
    default:
      return state;
  }
}

export default salon;
