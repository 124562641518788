// @flow

/* eslint-disable react/sort-comp */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */

import React from 'react';
import { CognitoState, Login } from 'react-cognito-mm';
// $FlowFixMe
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { clearTokens } from 'auth/actions';
import cn from 'classnames';

import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import S from 'constants/string.const';
import { withTheme } from 'providers/Theme.provider';

import { getSalonDetails, getUserCardDetails, setPageVisited } from 'booking/actions';
import HeaderComponent from 'booking/common/Header';
import Spinner from 'common/Spinner.component';
import createBooking, { fbPixelTracking } from 'lib/utils';
import { validatePassword } from 'lib/validators';
import type { Stylist } from 'types/stylist';

import 'react-phone-number-input/style.css';
import CN from './LoginFormWrap.module.scss';

const LOGIN_MESSAGE = [
  `Ooops, that password seems to be incorrect 😢  `,
  'Please try again or click the Forgotten password link to reset it',
].join(S.space);

type Props = {
  needs_card: boolean,
  appointment_cost: number,
  location: any,
  state: CognitoState,
  history: string[],
  match: {
    params: {
      id: string,
    },
  },
  cognito: {
    userPool: any,
    config: any,
    state: string,
    user: { username: string },
    error: {
      code: string,
      statusCode: string,
      message: string,
    },
    attributes: {
      name: string,
    },
  },
  bookingResponse: any,
  user: { username: string }, // eslint-disable-line react/no-unused-prop-types
  username: string,
  attributes: any, // eslint-disable-line react/no-unused-prop-types
  error: {
    message: string,
    code: string,
  },
  isFetchingCard: boolean,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  selectedServices: [],
  onSubmit: (userphone: string, password: string) => Promise<*>,
  clearTokens: () => void,
  getUserCardDetails: (username: string) => void,
  getSalonDetails: (salonId: number) => void,
  setPageVisited: (page: string) => void,
  salonDetails: any,
  cardDetails: any,
  isWaitList?: boolean,
};

type State = {
  type: string,
  password: string,
  userphone: string,
  errorMessage?: string,
  bookingError: string,
  errors: {
    password: string,
    phone: string,
  },
  userphone: string,
  isLoading: boolean,
  redirected: boolean,
  bookingDataSent: boolean,
  getCardCalled: boolean,
  isFetchingCard: boolean,
};

export class LoginFormWrap extends React.Component<Props, State> {
  static defaultProps = {
    isWaitList: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      type: 'password',
      password: '',
      errors: {
        password: '',
        phone: '',
      },
      userphone: '',
      isLoading: false,
      redirected: false,
      bookingDataSent: false,
      getCardCalled: false,
      isFetchingCard: false,
      bookingError: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getSalonDetails(parseInt(this.props.match.params.id, 10));
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setPageVisited('login');
    if (this.props.bookingResponse) {
      if (Object.keys(this.props.bookingResponse.salonDetails).length !== 0) {
        fbPixelTracking(this.props.bookingResponse.salonDetails.fb_details.fb_pixel_id);
      }
    }
  }

  componentWillUnmount() {
    this.setState({ errorMessage: '' });
  }

  onCreateAccount = () => {
    this.props.history.push(`/salon/${this.props.match.params.id}/create-account`);
  };

  onBlur = (event: Event, name: string) => {
    event.preventDefault();
    const { value } = (event.currentTarget: any);
    const { errors } = this.state;

    switch (name) {
      case 'phone':
        errors.phone = value.length < 15 ? '' : 'Please enter a valid UK mobile number starting 07';
        break;
      case 'password':
        errors.password = validatePassword(value);
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  checkLoginStatus = (intervalVar: any) => {
    const dontNeedToPay = this.props.appointment_cost === 0 || !this.props.needs_card;

    if (this.props.cognito.user && this.props.cognito.state === 'LOGGED_IN') {
      if (!this.state.getCardCalled) {
        this.setState({ getCardCalled: true, isFetchingCard: true }, () => {
          this.props.getUserCardDetails(this.props.username);
        });
      }

      if (this.props.isWaitList && !this.state.redirected && !this.state.bookingDataSent) {
        this.setState({ bookingDataSent: true }, () => {
          createBooking();
        });
        this.setState({ redirected: true });
        clearInterval(intervalVar);
        const interval = setInterval(() => {
          this.handleLoggedInRedirect(interval);
        }, 500);
      } else if (!this.props.isWaitList && !dontNeedToPay && !this.state.redirected) {
        this.setState({ redirected: true });
        clearInterval(intervalVar);
        const interval = setInterval(() => {
          this.handleLoggedInRedirect(interval);
        }, 500);
      } else if (!this.props.isWaitList && dontNeedToPay && !this.state.redirected && !this.state.bookingDataSent) {
        this.setState({ bookingDataSent: true }, () => {
          createBooking();
        });
        this.setState({ redirected: true });
        clearInterval(intervalVar);
        const interval = setInterval(() => {
          this.handleLoggedInRedirect(interval);
        }, 500);
      }
    } else if (this.props.cognito.user && this.props.cognito.state === 'LOGGED_OUT') {
      this.setState({
        isLoading: false,
        errorMessage: 'Incorrect username or password',
      });
    }
  };

  handleClick = () => {
    const intervalVar = setInterval(() => {
      this.checkLoginStatus(intervalVar);
    }, 500);

    this.setState({ isLoading: true });
    switch (this.props.cognito.state) {
      case CognitoState.LOGGED_IN:
        this.handleLoggedInRedirect(intervalVar);
        break;
      default:
        this.props.clearTokens();

        this.props.onSubmit(this.state.userphone, this.state.password).catch(err => {
          if (err.code === 'PasswordResetRequiredException') {
            console.log('PasswordResetRequiredException'); // eslint-disable-line no-console
          }

          this.setState({ errorMessage: err.message });
        });
        break;
    }
  };
  showHidePassword = () => {
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text',
    });
  };

  handleLoggedInRedirect = (theInterval: any) => {
    const dontNeedToPay = this.props.appointment_cost === 0 || !this.props.needs_card;
    if (
      (this.props.location.state && this.props.location.state.fromMyBook) ||
      this.props.selectedServices.length === 0
    ) {
      clearInterval(theInterval);
      this.props.history.push(`/salon/${this.props.match.params.id}/my-book`);
      return;
    }
    if (
      this.props.isWaitList &&
      this.props.bookingResponse &&
      this.props.bookingResponse.createBookingFailed !== true
    ) {
      clearInterval(theInterval);
      this.props.history.push(`/salon/${this.props.match.params.id}/bookingConfirmed`);
    } else if (!this.props.isWaitList && this.props.needs_card && !(this.props.appointment_cost === 0)) {
      if (this.props.cardDetails && this.props.cardDetails.last4) {
        clearInterval(theInterval);
        this.props.history.push(`/salon/${this.props.match.params.id}/confirm-with-card`);
      } else if (!this.props.isFetchingCard && !this.state.isFetchingCard) {
        clearInterval(theInterval);
        this.props.history.push(`/salon/${this.props.match.params.id}/add-new-card`);
      }

      if (this.state.isFetchingCard) {
        if (!this.props.isFetchingCard) {
          this.setState({ isFetchingCard: false });
        }
      }
    } else if (
      !this.props.isWaitList &&
      dontNeedToPay &&
      this.props.bookingResponse &&
      this.props.bookingResponse.createBookingFailed !== true
    ) {
      clearInterval(theInterval);
      this.props.history.push(`/salon/${this.props.match.params.id}/bookingConfirmed`);
    } else if (this.props.bookingResponse && this.props.bookingResponse.createBookingFailed === true) {
      const bookingError = 'We are having problems creating your booking, please try again.';
      this.setState({ bookingError });
    }
  };

  handleChange = (event: Event, name: string) => {
    event.preventDefault();
    const { value } = (event.currentTarget: any);
    const { errors } = this.state;
    switch (name) {
      case 'password':
        errors.password = validatePassword(value);
        break;
      default:
        break;
    }
    if (name) {
      this.setState({ [name]: value });
    }
  };

  onInternationalPhone = (value: string, type: 'inter') => {
    const phoneSymbolsRegex = /^[0-9+)(\s]*$/;
    if (phoneSymbolsRegex.test(value)) {
      if (type === 'inter') {
        this.setState({ userphone: value });
      }
    }
  };

  render() {
    const {
      isDarkMode,
      isNeutralMode,
      salonDetails: { country },
    } = this.props;

    const countryFlag = country && country.code;

    const userError = this.props.cognito.error && this.props.cognito.error.message;
    const userErrorCode = this.props.cognito.error && this.props.cognito.error.code; // Get the error code

    let userExistError = false;
    let loginError1 = '';
    let loginError2 = '';

    let headerText = '';

    // Check for specific error codes
    if (userErrorCode === 'UserLambdaValidationException') {
      loginError1 =
        'Sorry, it looks like you haven’t setup an online account with us yet. Please create an account to complete your booking. ';
    } else if (userErrorCode === 'NotAuthorizedException') {
      loginError2 = LOGIN_MESSAGE;
    } else if (userError && userError.indexOf('given phone_number already exists') > 0) {
      userExistError = true;
      loginError2 = `Try logging in with your mobile number and password below ${S.thumbsUp}`;

      headerText = `Ooops, it looks like you${S.apos}ve already got an account!`;
    }

    const { errors } = this.state;

    // Only set this state if it's not a specific error case
    if (this.state.errorMessage && !userExistError && !loginError1) {
      if (this.state.isLoading) {
        this.setState({ isLoading: false });
      }
      loginError2 = LOGIN_MESSAGE;
    }

    const showSpinner = this.state.isLoading && !this.props.bookingResponse;

    const isLoginDisabled =
      !(this.state.userphone && isValidPhoneNumber(this.state.userphone)) ||
      !this.state.password ||
      this.state.errors.password.length > 0;

    const passwordClasses = cn({
      [CN.input]: true,
      [CN.hasError]: errors.password.length > 0,
    });

    const phoneClasses = cn({
      [CN.phoneContainer]: true,
      [CN.hasError]: this.state.userphone && !isValidPhoneNumber(this.state.userphone),
    });

    return (
      <div
        data-bem="LoginFormWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          {showSpinner && <Spinner variant="overlay" />}
          <HeaderComponent title={headerText} history={this.props.history} />

          <div className={CN.pageTitle}>Login to complete your booking</div>
          <div className={CN.center}>
            {loginError1 && <div className={CN.error}>{loginError1}</div>}
            {loginError2 && <div className={CN.error}>{loginError2}</div>}

            {this.state.bookingError && <div className={CN.error}>{this.state.bookingError}</div>}
          </div>

          <div className={CN.formContainer}>
            <div className={phoneClasses}>
              <label htmlFor="phone" className={CN.inputLabel}>
                Mobile number
              </label>
              <PhoneInput
                data-bem="LoginFormWrap__phone"
                defaultCountry={countryFlag}
                placeholder="Phone number"
                value={this.state.userphone}
                onChange={value => void this.onInternationalPhone(value, 'inter')}
              />
              {this.state.userphone && !isValidPhoneNumber(this.state.userphone) && (
                <span className={CN.error}>Please enter a valid phone number in order to proceed</span>
              )}
            </div>
            <div className={CN.inputContainer}>
              <label htmlFor="password" className={CN.inputLabel}>
                Password
              </label>
              <div className={CN.passwordContainer}>
                <input
                  data-bem="LoginFormWrap__password"
                  type={this.state.type}
                  name="password"
                  onChange={event => this.handleChange(event, 'password')}
                  placeholder="8 characters, 1 uppercase, 1 number"
                  className={passwordClasses}
                />
                <div className={CN.eyeContainer} role="button" tabIndex="0" onClick={this.showHidePassword}>
                  {this.state.type === 'password' ? <div className={CN.eyeOpen} /> : <div className={CN.eyeClose} />}
                </div>
              </div>
              {errors.password.length > 0 && <span className={CN.error}>{errors.password}</span>}

              <div className={CN.forgotLinkContainer}>
                <Link
                  to={`/salon/${this.props.match.params.id}/forgot`}
                  href={`/salon/${this.props.match.params.id}/forgot`}
                  className={CN.forgotLink}
                >
                  Forgotten password?
                </Link>
              </div>
            </div>
          </div>

          <div className={CN.buttonContainer}>
            Don{S.apos}t have an account?
            <Button variant="tertiary" width="fixed" onClick={() => this.onCreateAccount()}>
              <div className={CN.underline}>CREATE ACCOUNT</div>
            </Button>
          </div>
        </div>

        <Footer data-bem="LoginFormWrap__footer">
          <Button variant="primary" width="fixed" onClick={() => this.handleClick()} disabled={isLoginDisabled}>
            LOGIN
          </Button>
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { needs_card } = state.booking.salonDetails;
  const { booking, billing } = state;
  const {
    salonDetails,
    selectedServices,
    selectedPatchTime,
    selectedAppointmentTime,
    note,
    totalPrice,
    selectedStylists,
    bookingResponse,
    isFetchingCard,
    cardDetails,
    pageVisited,
    isWaitList,
  } = booking;
  const cognito = {
    state: state.cognito.state,
    user: state.cognito.user,
    email: state.cognito.attributes.email,
    config: state.cognito.config,
    userPool: state.cognito.userPool,
    error: state.cognito.error,
  };
  return {
    cognito,
    needs_card,
    salonDetails,
    selectedServices,
    selectedPatchTime,
    selectedAppointmentTime,
    appointment_cost: billing.appointment_cost,
    isWaitList,
    note,
    totalPrice,
    selectedStylists,
    bookingResponse,
    isFetchingCard,
    cardDetails,
    pageVisited,
    username: state.cognito.user ? state.cognito.user.username : '',
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  clearTokens() {
    dispatch(clearTokens);
  },
  getUserCardDetails(username: string) {
    dispatch(getUserCardDetails(username));
  },
  getSalonDetails(salonId: number) {
    dispatch(getSalonDetails(salonId));
  },
  setPageVisited(page: string) {
    dispatch(setPageVisited(page));
  },
});

const ConnecteLogin = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme()(LoginFormWrap)));

const LoginForm = () => (
  <Login>
    <ConnecteLogin />
  </Login>
);
export default LoginForm;
