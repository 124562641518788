// @flow

import React from 'react';

import ServiceSticky from 'booking/serviceSelection/ServiceSticky';
import type { Service } from 'types/service';

const CategorySticky = ({
  category,
  selectedServices,
}: {
  category: { services: Service[], name: string },
  selectedServices: Service[],
}) => (
  <>
    {category.services.map(service =>
      service.show ? (
        <ServiceSticky service={service} key={`service${service.id}`} selectedServices={selectedServices} />
      ) : null,
    )}
  </>
);

export default CategorySticky;
