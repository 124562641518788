import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';

import CN from './TabButton.module.scss';

const TabButton = ({ children, onClick, isActive }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
    [CN.isActive]: isActive,
  });

  return (
    <div data-bem="TabButton" className={componentClasses}>
      <div
        onClick={onClick}
        className={cn({
          [CN.tabButton]: true,
          [CN.isActive]: isActive,
        })}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    </div>
  );
};

export default TabButton;
