import React from 'react';
import cn from 'classnames';

import fromAnyToTrimmed from 'utils/converters/any/fromAnyToTrimmed.util';

import CN from './IconStatus.module.scss';

const IconStatus = ({ children, tag, variant }) => (
  <div data-bem="IconStatus" data-tag={fromAnyToTrimmed(tag)} className={CN.component}>
    <div
      className={cn({
        [CN.circle]: true,
        [CN[`${variant}Variant`]]: variant,
      })}
    >
      {children}
    </div>
  </div>
);

export default IconStatus;
