// @flow
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable react/no-danger */
/* eslint-disable comma-dangle */

import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import Navigation from 'invite/components/Navigation.component';
import STORE from 'store.singleton';

import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import { withTheme } from 'providers/Theme.provider';

import PatchTest from 'booking/common/PatchTest.component';
import StartAgainAndBackButtonsComponent from 'booking/common/StartAgainAndBackButtons.component';
import FavouriteStylistSelect from 'booking/stylistSelection/FavouriteStylistSelect';
import { fbPixelTracking, setPageVisitedFunc } from 'lib/utils';
import type { Service } from 'types/service';
import type { Stylist } from 'types/stylistsForService';

import {
  clearAvailableDays,
  clearAvailableSlots,
  clearAvailableStylists,
  getStylistsForServiceWeekly,
  getUserCardDetails,
  setSelectedStylists,
  updateFirstAvailableDate,
} from '../actions';

import CN from './FavouriteStylistSelectWrap.module.scss';

type StylistWithFavourite = Stylist & { favourite_or_any?: string };

type StylistWithFavDict = {
  [serviceId: string]: null | StylistWithFavourite,
};

type Props = {
  history: string[],
  cognito: any,
  cardDetails: any,
  salonDetails: any,
  username: string,
  match: {
    params: {
      id: string,
    },
  },
  selectedServices: Service[],
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  salonId: number,
  isFetching: boolean,
  isPatchTestRequred: boolean,
  stylistsForServiceWeekly: any,
  setSelectedStylists: (
    selectedStylists: { [serviceId: string]: null | Stylist },
    serviceId?: any,
    stylist?: any,
  ) => void,
  getStylistsForServiceWeekly: (saloneId: number, services: number[]) => void,
  getUserCardDetails: (username: string) => void,
  book_ncob_with_same: boolean,
};

type State = {
  selectedStylists: StylistWithFavDict,
  isButtonDisabled: boolean,
  showPopup: boolean,
  getCardStarted: boolean,
  stylistForServiceWeeklySameStylist: any,
};

export class FavouriteStylistSelectWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isButtonDisabled: true,
      showPopup: false,
      getCardStarted: false,
      stylistForServiceWeeklySameStylist: {},
      selectedStylists: {}, // this is where we will keep the selected stylists
    };
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.selectedServices.length === 0) {
      this.props.history.push(`/salon/${this.props.match.params.id}`);
      return;
    }
    STORE.dispatch(clearAvailableSlots());
    STORE.dispatch(clearAvailableStylists());
    STORE.dispatch(clearAvailableDays());
    STORE.dispatch(updateFirstAvailableDate(null));
    const serviceIds = this.props.selectedServices.map(service => service.id);
    this.props.getStylistsForServiceWeekly(parseInt(this.props.match.params.id, 10), serviceIds);

    this.checkFooterButton();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    fbPixelTracking(this.props.salonDetails.fb_details.fb_pixel_id);
    setPageVisitedFunc('favouritestylistselect');
    if (
      this.props.cognito.user &&
      this.props.cognito.state === 'LOGGED_IN' &&
      !this.props.cardDetails.last4 &&
      !this.state.getCardStarted
    ) {
      this.props.getUserCardDetails(this.props.username);
      this.setState({ getCardStarted: true });
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    /* when selected stylists, equal selected services
      go to next screen */
    if (Object.keys(nextProps.selectedStylists).length !== Object.keys(this.props.selectedStylists).length) {
      this.setState({ selectedStylists: nextProps.selectedStylists });
    }
    if (Object.keys(nextProps.selectedStylists).length === nextProps.selectedServices.length) {
      if (Object.keys(this.state.selectedStylists).length === 0) {
        this.setState({ selectedStylists: nextProps.selectedStylists });
      } else if (Object.keys(this.state.selectedStylists).length === nextProps.selectedServices.length) {
        // this.props.setSelectedStylists(this.state.selectedStylists);
      }

      if (nextProps.isPatchTestRequred) {
        window.scrollTo(0, 0);

        setTimeout(() => this.setState({ showPopup: true }), 1000);
      }
    }
    // first service stylist selected, hide the ones that's not the selected
    const { stylistForServiceWeeklySameStylist } = this.state;
    if (nextProps.stylistsForServiceWeekly && Object.keys(stylistForServiceWeeklySameStylist).length === 0) {
      this.setState({
        stylistForServiceWeeklySameStylist: {
          ...nextProps.stylistsForServiceWeekly,
        },
      });
    }
    if (
      nextProps.stylistsForServiceWeekly &&
      nextProps.book_ncob_with_same &&
      Object.keys(nextProps.selectedStylists).length > 0
    ) {
      for (let i = 0; i < nextProps.selectedServices.length; i += 1) {
        const serviceId = nextProps.selectedServices[i].id;
        if (i === 0 && nextProps.selectedServices.length > 1) {
          const nextServiceId = nextProps.selectedServices[i + 1].id;
          const stylistPerformSecondService = nextProps.stylistsForServiceWeekly[nextServiceId];
          const firstServiceStylist = nextProps.selectedStylists[nextProps.selectedServices[0].id.toString()];
          let indx = -1;
          let canFirstDoSecond = false;
          if (firstServiceStylist) {
            indx = stylistPerformSecondService.findIndex(
              stylist => stylist.stylist_id === firstServiceStylist.stylist_id,
            );
            canFirstDoSecond = indx >= 0;
          }

          if (canFirstDoSecond) {
            // first service stylist can perform second service, select it for second service
            stylistForServiceWeeklySameStylist[nextServiceId] = [stylistPerformSecondService[indx]];
          }
        } else {
          const stylistPerformService = nextProps.stylistsForServiceWeekly[serviceId];
          const firstServiceStylist = nextProps.selectedStylists[nextProps.selectedServices[0].id.toString()];

          let indx = -1;

          let canFirstDoSecond = false;

          if (firstServiceStylist) {
            if (i === 1 && stylistPerformService) {
              indx = stylistPerformService.findIndex(stylist => stylist.stylist_id === firstServiceStylist.stylist_id);
              canFirstDoSecond = indx >= 0;
            }
            if (i === 2 && stylistPerformService) {
              indx = stylistPerformService.findIndex(stylist => stylist.stylist_id === firstServiceStylist.stylist_id);
            }
          }

          if (canFirstDoSecond && i === 1 && indx === i && stylistPerformService) {
            // first service stylist can perform second service
            stylistForServiceWeeklySameStylist[serviceId] = [stylistPerformService[indx]];
          }
        }
      }
      this.setState({ stylistForServiceWeeklySameStylist });
    }
  }

  onClosePatchTest = () => void this.setState({ showPopup: false });

  onPopupConfrimClick = isTested => {
    if (isTested === 'isSelectedYes') {
      this.props.history.push(`/salon/${this.props.match.params.id}/timeselect`);
    } else if (isTested === 'isSelectedNo') {
      this.props.history.push(`/salon/${this.props.match.params.id}/patchtimeselect`);
    }
  };
  onCheckboxClick = (serviceId, stylist) => {
    const selectedStylists = { ...this.state.selectedStylists };
    const name = stylist ? stylist.stylist_name : '';
    if (window.analytics) {
      window.analytics.track(`salon${this.props.salonId.toString()}_click Clicked favourite stylist`, {
        label: name,
        category: `salon${this.props.salonId.toString()}_click`,
      });
    }
    if (stylist) {
      // If a new stylist is selected, we add or replace them
      selectedStylists[serviceId] = stylist;
    } else {
      // If the stylist is removed, we delete it from the selected stylists
      delete selectedStylists[serviceId];
    }

    // console.log('onCheckboxClick', serviceId, stylist);
    this.props.setSelectedStylists(selectedStylists, serviceId, stylist);

    this.setState({ selectedStylists }, () => {
      this.checkFooterButton();
    });
  };

  onClickAnyStylist = (serviceId: number) => {
    const checked = serviceId in this.state.selectedStylists;
    const name = 'any';

    if (window.analytics) {
      window.analytics.track(`salon${this.props.salonId.toString()}_click Clicked any stylist`, {
        label: name,
        category: `salon${this.props.salonId.toString()}_click`,
      });
    }
    const selectedStylists = Object.assign({}, this.state.selectedStylists);

    if (!checked) {
      selectedStylists[serviceId] = 'any'; // { stylist_id: 0, stylist_name: "any" };
    } else {
      delete selectedStylists[serviceId];
    }
    this.setState({ selectedStylists }, () => {
      this.checkFooterButton();
    });
  };

  findStylistForService = (serviceId: number, stylistId: number) => {
    const { stylistsForServiceWeekly } = this.props;

    let index = -1;
    if (stylistsForServiceWeekly[serviceId]) {
      for (let j = 0; j < stylistsForServiceWeekly[serviceId].length; j += 1) {
        index = stylistsForServiceWeekly[serviceId].findIndex(stylist => stylist.stylist_id === stylistId);
      }
      if (index >= 0) {
        return stylistsForServiceWeekly[serviceId][index];
      }
      return stylistsForServiceWeekly[serviceId];
    }
    return null;
  };
  checkFooterButton = () => {
    let isButtonDisabled = false;
    if (this.state.selectedStylists) {
      const stylistIds = Object.keys(this.state.selectedStylists);

      if (stylistIds.length === 0 || stylistIds.length !== this.props.selectedServices.length) {
        isButtonDisabled = true;
      }
    }
    this.setState({ isButtonDisabled });
  };

  btnClick = () => {
    if (this.state.isButtonDisabled) {
      return;
    }
    if (this.props.isPatchTestRequred) {
      window.scrollTo(0, 0);
      setTimeout(() => this.setState({ showPopup: true }), 1000);
    }

    if (!this.props.isPatchTestRequred) {
      this.props.history.push(`/salon/${this.props.match.params.id}/timeselect`);
    }
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  handleStartAgain = () => void (this.props.history && this.props.history.push(`/salon/${this.props.salonId}/`));

  render() {
    const { isDarkMode, isNeutralMode } = this.props;

    return (
      <div
        data-bem="FavouriteStylistSelectWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          <Navigation match={this.props.match} />
          <div className={CN.header}>
            <StartAgainAndBackButtonsComponent />
          </div>
          {this.props.isFetching && (
            <div className="spinner-container">
              <div className="load-spinner" />
            </div>
          )}

          <FavouriteStylistSelect
            selectedStylists={this.state.selectedStylists}
            selectedServices={this.props.selectedServices}
            onCheckboxClick={this.onCheckboxClick}
            onClickAnyStylist={this.onClickAnyStylist}
            stylistsForServiceWeekly={this.props.stylistsForServiceWeekly}
            stylistForServiceWeeklySameStylist={this.state.stylistForServiceWeeklySameStylist}
            book_ncob_with_same={this.props.book_ncob_with_same}
          />
        </div>

        {this.state.showPopup ? (
          <PatchTest onClose={this.onClosePatchTest} onConfirmClick={this.onPopupConfrimClick} />
        ) : (
          <Footer data-bem="FavouriteStylistSelectWrap__footer">
            <Button
              variant="primary"
              width="fixed"
              onClick={() => this.btnClick()}
              disabled={this.state.isButtonDisabled}
            >
              NEXT
            </Button>
          </Footer>
        )}
      </div>
    );
  }
}

function mapStateToProps(state): any {
  const { booking, cognito } = state;
  const {
    isFetching,
    salonDetails,
    selectedServices,
    selectedStylists,
    servicesToShow,
    stylistsForServiceWeekly,
    isPatchTestRequred,
    pageVisited,
    cardDetails,
  } = booking;

  return {
    isFetching,
    selectedServices,
    selectedStylists,
    stylistsForServiceWeekly,
    servicesToShow,
    salonDetails,
    salonId: salonDetails.id,
    book_ncob_with_same: salonDetails.book_ncob_with_same,
    isPatchTestRequred,
    pageVisited,
    cognito,
    cardDetails,
    username: cognito.user ? cognito.user.username : '',
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedStylists(selectedStylists: { [serviceId: string]: null | Stylist }, serviceId: any, stylist: any) {
      dispatch(setSelectedStylists(selectedStylists, serviceId, stylist));
    },
    getStylistsForServiceWeekly(salonId: number, services: number[]) {
      dispatch(getStylistsForServiceWeekly(salonId, services));
    },
    clearAvailableStylists() {
      dispatch(clearAvailableStylists());
    },
    getUserCardDetails(username: string) {
      dispatch(getUserCardDetails(username));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(FavouriteStylistSelectWrap));
