// @flow
import React from 'react';

type Props = {
  message: string,
};

const ErrorPopup = (props: Props) => <div className="error-popup animated">{props.message}</div>;

export default ErrorPopup;
