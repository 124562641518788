/* eslint-disable quotes */
/* eslint-disable linebreak-style */
// @flow
/* eslint-disable object-curly-newline */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable comma-dangle */
import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import cn from 'classnames';
import DisclaimerText from 'invite/components/DisclaimerText.component';
import moment from 'moment';

import { clearBillingInfo, CreateToken, setupIntent } from 'actions/billing';
import Button from 'components/Button.component';
import FoldOut from 'components/FoldOut.component';
import { NCOB_STRIPE_KEY } from 'config';
import { withTheme } from 'providers/Theme.provider';

import {
  clearUserCardDetails,
  confirmXAuthentication,
  getUserCardDetails,
  processInvite,
  setInvitePostData,
  setPageVisited,
} from 'booking/actions';
import currency from 'booking/common/currency.util';
import HeaderComponent from 'booking/common/Header';
import type { SalonDetails } from 'types/salonDetails';
import type { Service } from 'types/service';
import type { Stylist } from 'types/stylist';

import PayInviteCard from './PayInviteCard';

import CN from './PayInviteDeposit.module.scss';

const DISPLAY_NCOB_TEXT = false;

type State = {
  errorMessage: string,
  paymentError: boolean,
  getCostDone: boolean,
  getCardCalled: boolean,
};
type StateProps = {
  salon: any,
  billing: any,
  cognito: any,
  booking: any,
  errorMessage: '',
  no_show_protection: boolean,
  ncob_deposit_settings: string,
  salon_ncob_text: string,
  salonDetails: SalonDetails,
  selectedServices: Service[],
  selectedPatchTime: moment | null,
  selectedAppointmentTime: moment,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  bookingResponse: any,
  errorMessage: any,
  totalPrice: number,
  notes: string,
  username: string,
  cognitoUsername: string,
  cardDetails: {
    last4: string,
    exp_month: string,
    exp_year: string,
  },
  CreateToken: (token: any) => void,
  clearBillingInfo: () => void,
  setPageVisited: (page: string) => void,
  stripeToken: any,
  clearUserCardDetails: () => void,
  getUserCardDetails: (username: string) => void,
  processInvite: (cognitoUsername: string, inviteId: string, data: any) => void,
  setupIntent: (salonId: number, username: string) => void,
  setInvitePostData: (status: string) => void,
  confirmXAuthentication: (
    salonId: number,
    appointmentId: number,
    paymentintentId: number,
    cognitoUserName: string,
  ) => void,
  errorMessage: string,
  phone_number: string,
  email: string,
  isFetching: boolean,
  invites: [],
  invitePostData: any,
  invitePostStatus: string,
  confirmAuthenticationSuccess: boolean,
  confirmAuthenticationStatus: string,
};
type OwnProps = {
  history: string[],
  match: {
    params: { id: number, appointmentId: number },
    url: string,
  },
  public_key: string,
  bookingError: any,
};
type Props = StateProps & OwnProps;

export class PayInviteDepositWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: '',
      paymentError: false,
      getCostDone: false,
      getCardCalled: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setPageVisited('pay-deposit');
  }
  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.billing.appointment_cost && !this.state.getCostDone) {
      this.setState({ getCostDone: true });
    }
    if (this.props.cognitoUsername && !this.state.getCardCalled) {
      this.setState({ getCardCalled: true }, () => {
        this.props.getUserCardDetails(this.props.cognitoUsername);
      });
    }
  }

  setToken = (token: any) => {
    this.props.CreateToken(token);
  };
  setPaymentError = (trueOrFalse: boolean) => {
    this.setState({ paymentError: trueOrFalse });
  };
  isEmpty = (obj: any) => {
    const keys = Object.keys(obj);

    if (keys.length > 0) {
      return false;
    }
    return true;
  };

  hideError = () => {
    this.setState({ errorMessage: '' });
  };
  onAppleRequestBtnClick = () => {
    //
  };
  startAgain = () => {
    this.props.history.push(`/salon/${this.props.match.params.id}`);
  };
  render() {
    let { username } = this.props;
    const { salonDetails, isDarkMode, isNeutralMode } = this.props;
    const abbreviation = salonDetails.currency;

    if (!username) {
      if (this.props.cognito && Object.keys(this.props.cognito.attributes).length) {
        [username] = this.props.cognito.attributes.name.split(' ');
      }
    }
    let welcomeMessage;

    if (!this.props.booking.cardDetails.last4) {
      welcomeMessage = (
        <div>
          <div className={CN.title}>Please enter your card details.</div>
          <div className={CN.small}>Then confirm your booking on the next page.</div>
        </div>
      );
    } else {
      welcomeMessage = (
        <div>
          <div className={CN.title}> Welcome back {username}! </div>
          <div className={CN.small}> Please check your payment details are correct then pay your deposit</div>
        </div>
      );
    }
    if (this.state.paymentError) {
      welcomeMessage = (
        <div>
          <div className={CN.title}>Payment failed </div>
          <div className={CN.small}>Please try again</div>
        </div>
      );
    }
    let anyError;
    let bookingError;
    if (this.state.errorMessage) {
      anyError = 'We are having problems confirming your booking, please try again.';
    }
    const theInvite = this.props.invites.filter(
      i => i.appointment.id === parseInt(this.props.match.params.appointmentId, 10),
    )[0];
    let totalToPayToday = theInvite && theInvite.requested_deposit;
    const bookingPrice = theInvite.appointment.price;
    if (theInvite.payFullAmount) {
      totalToPayToday = bookingPrice;
    }

    return (
      <div
        data-bem="PayInviteDepositWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <HeaderComponent history={this.props.history} />
        {welcomeMessage}
        {bookingError && <span className={CN.error}>{bookingError}</span>}

        {bookingError && (
          <Button variant="primary" width="fixed" onClick={this.startAgain}>
            START AGAIN
          </Button>
        )}

        <div className="booking-info">
          {theInvite && (
            <div className={CN.bookingValue}>
              <span>Total to pay today:</span>
              <span className={CN.total}>{currency({ value: totalToPayToday, abbreviation })}</span>
            </div>
          )}
          {DISPLAY_NCOB_TEXT && (
            <div>
              {!this.state.paymentError && theInvite && theInvite.requested_card_only && (
                <div className={CN.bold}>
                  Card details are required to confirm your booking but you will NOT be charged today. Payment will only
                  be taken after your appointment is complete. We reserve the right to charge a cancellation or no-show
                  fee as per our cancellation policy below.
                </div>
              )}
              {!this.state.paymentError && theInvite && theInvite.requested_deposit && (
                <div className={CN.bold}>
                  To secure your booking we require an upfront payment which we reserve the right to keep if you fail to
                  show for your appointment or cancel last minutes. Cancellations with advanced notice may be refundable
                  in line with our cancellation policy.
                </div>
              )}
            </div>
          )}
        </div>
        {NCOB_STRIPE_KEY && (
          <StripeProvider apiKey={NCOB_STRIPE_KEY}>
            <Elements>
              <PayInviteCard
                setToken={this.setToken}
                stripeToken={this.props.stripeToken}
                isEmpty={this.isEmpty}
                salonId={this.props.match.params.id}
                appointmentId={this.props.match.params.appointmentId}
                invite={
                  this.props.invites.filter(
                    i => i && i.appointment.id === parseInt(this.props.match.params.appointmentId, 10),
                  )[0]
                }
                history={this.props.history}
                cardDetails={this.props.cardDetails}
                selectedAppointmentTime={this.props.selectedAppointmentTime}
                selectedStylists={this.props.selectedStylists}
                selectedServices={this.props.selectedServices}
                salonDetails={salonDetails}
                username={this.props.cognito.user ? this.props.cognito.user.username : ''}
                billing={this.props.billing}
                bookingResponse={this.props.bookingResponse}
                bookingError={this.props.bookingError}
                clearUserCardDetails={this.props.clearUserCardDetails}
                clearBillingInfo={this.props.clearBillingInfo}
                setPaymentError={this.setPaymentError}
                isFetching={this.props.isFetching}
                pageVisited={this.props.booking.pageVisited}
                processInvite={this.props.processInvite}
                invitePostData={this.props.invitePostData}
                invitePostStatus={this.props.invitePostStatus}
                setupIntent={this.props.setupIntent}
                confirmAuthenticationSuccess={this.props.confirmAuthenticationSuccess}
                confirmAuthenticationStatus={this.props.confirmAuthenticationStatus}
                setInvitePostData={this.props.setInvitePostData}
                confirmXAuthentication={this.props.confirmXAuthentication}
              />
            </Elements>
          </StripeProvider>
        )}

        <DisclaimerText />

        {!this.props.isWaitList && (
          <div className={CN.policyContainer} data-bem="AddNewCardWrap__foldOut">
            <FoldOut title="Cancellation Policy" icon="policy">
              <div className={CN.policyText} data-bem="AddNewCardWrap__policy--text">
                {salonDetails.salon_ncob_text}
              </div>
            </FoldOut>
          </div>
        )}
        {this.props.bookingError.indexOf('Not found') < 0 && <div className={CN.error}>{anyError}</div>}
      </div>
    );
  }
}

export const mapStateToProps = (state: any): any => ({
  salonDetails: state.booking.salonDetails,
  selectedServices: state.booking.selectedServices,
  selectedPatchTime: state.booking.selectedPatchTime,
  selectedAppointmentTime: state.booking.selectedAppointmentTime,
  notes: state.booking.notes,
  totalPrice: state.booking.totalPrice,
  selectedStylists: state.booking.selectedStylists,
  bookingResponse: state.booking.bookingResponse,
  bookingError: state.booking.errorMessage,
  isFetching: state.booking.isFetching,
  username: state.billing.name,
  cognitoUsername: state.cognito.user.username,
  booking: state.booking,
  cardDetails: state.booking.cardDetails,
  stripeToken: Object.keys(state.billing.stripeToken).length ? state.billing.stripeToken : {},
  cognito: state.cognito,
  billing: state.billing,
  ncob_deposit_settings: state.booking.salonDetails.ncob_deposit_settings,
  no_show_protection: state.booking.salonDetails.no_show_protection,
  salon_ncob_text: state.booking.salonDetails.salon_ncob_text,
  invites: state.booking.invites,
  invitePostData: state.booking.invitePostData,
  invitePostStatus: state.booking.invitePostStatus,
  confirmAuthenticationSuccess: state.booking.confirmAuthenticationSuccess,
  confirmAuthenticationStatus: state.booking.confirmAuthenticationStatus,
});

export function mapDispatchToProps(dispatch: any) {
  return {
    CreateToken(token: any) {
      dispatch(CreateToken(token));
    },
    clearUserCardDetails() {
      dispatch(clearUserCardDetails());
    },
    clearBillingInfo() {
      dispatch(clearBillingInfo());
    },
    setPageVisited(page: string) {
      dispatch(setPageVisited(page));
    },
    processInvite(cognitoUsername: string, inviteId: string, data: any) {
      dispatch(processInvite(cognitoUsername, inviteId, data));
    },
    setupIntent(salonId: number, username: string) {
      dispatch(setupIntent(salonId, username));
    },
    setInvitePostData(status: string) {
      dispatch(setInvitePostData(status));
    },
    confirmXAuthentication(salonId: any, appointmentId: any, paymentintentId: any, cognitoUserName: string) {
      dispatch(confirmXAuthentication(salonId, appointmentId, paymentintentId, cognitoUserName));
    },
    getUserCardDetails(username: string) {
      dispatch(getUserCardDetails(username));
    },
  };
}
// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(PayInviteDepositWrap));
