// @flow

/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { fetchHistoryList } from 'actions/history';
import { selectAppointment } from 'actions/selection';
import SalonHeader from 'components/SalonHeader';
import type { HistoryState } from 'reducers/history';

import Spinner from 'common/Spinner.component';
import type { Appointment } from 'types/appointment';
import type { Client } from 'types/client';

type Props = {
  match: {
    isExact: boolean,
    path: string,
    url: string,
    params: {
      client_id: string,
    },
  },
  fetchHistoryList: (client: string) => void,
  doSelectAppointment: (appt: Appointment) => void,
  history: HistoryState,
  client: Client,
};

type State = {};

class HistoryList extends React.Component<Props, State> {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { match } = this.props;
    this.props.fetchHistoryList(match.params.client_id);
  }

  totalPrice = appt => {
    let totalPrice = 0;
    appt.procedures.forEach(d => {
      totalPrice += d.price;
    });
    return totalPrice;
  };

  render() {
    const { client, history, doSelectAppointment } = this.props;
    return (
      <div>
        <SalonHeader client={client} />
        <div className="section">Re-book an Appointment</div>
        <div className="container history-list">
          <div className="margin-width-wrap">
            {!history.isFetching && history.response && history.response.length > 0 && (
              <div className="info-text">
                <div className="text-header">Please select which appointment you&apos;d like to book again</div>
              </div>
            )}
            {!history.isFetching &&
              history.response &&
              history.response.length > 0 &&
              history.response.map(appt => (
                <Link
                  to={`/time/${appt.id}`}
                  className="service-list-button"
                  id="history-link"
                  key={appt.id}
                  onClick={() => doSelectAppointment(appt)}
                >
                  <div className="text-stylist">
                    {appt.procedures
                      .sort((a, b) => (moment(b.start_time).isBefore(a.start_time) ? 1 : -1))
                      // $FlowFixMe
                      .map(proc => proc.stylist.user_name)
                      .join(', ')}
                  </div>
                  <div className="text-price">£{this.totalPrice(appt)}</div>
                  <div className="text-service">
                    {appt.procedures.map(proc => (
                      <p>
                        {
                          // $FlowFixMe
                          proc.service.name
                        }{' '}
                        &nbsp; ({moment.duration(proc.duration, 'minutes').humanize()})
                      </p>
                    ))}
                  </div>
                </Link>
              ))}
            {!history.isFetching && history.response && history.response.length === 0 && (
              <div className="conditional">
                <div className="info-text" id="apology">
                  <div className="text-header">
                    Sorry, we can&apos;t find any past appointments at {client.salon.name}
                  </div>
                  <div className="conditional-img img_error" />
                  <div className="text-subheader">
                    To ensure you can rebook you need to have already been to the salon
                  </div>
                </div>
              </div>
            )}
            {history.isFetching && <Spinner variant="overlay" />}
            {!history.isFetching && history.errorMessage && (
              <div className="conditional">
                <div className="info-text">
                  <div className="text-header">
                    Ooops, sorry!
                    <br />
                    Something went wrong!
                  </div>
                  <div className="conditional-img img_error" />
                  <div className="text-subheader">{history.errorMessage}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  history: state.history,
  client: state.selection.client,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchHistoryList: client => {
    dispatch(fetchHistoryList(client));
  },
  doSelectAppointment: appt => {
    dispatch(selectAppointment(appt));
  },
});

// $FlowFixMe
export default connect<any, Dispatch, Props, any, any>(mapStateToProps, mapDispatchToProps)(HistoryList);
