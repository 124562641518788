import logger from '../logger.util';

const L = logger('lsSet');

const lsSet = (key, val) => {
  try {
    L.debug(key, ':', val);
    localStorage.setItem(key, val);
    return val;
  } catch (e) {
    L.warn('lsSet()', e);
    return null;
  }
};

// noinspection JSUnusedGlobalSymbols
export default lsSet;
