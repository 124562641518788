// @flow

/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-console */

import ReactPixel from 'react-facebook-pixel';
import moment from 'moment';
import STORE from 'store.singleton';

import { getBookingCost } from '../actions/billing';
import { sendBookingData, setPageVisited } from '../booking/actions';
import CONSENT from '../constants/consent.const';
import LSKEY from '../constants/lskey.const';
import type { NewBooking, NewBookingService } from '../types/newBooking';
import lsGet from '../utils/dom/lsGet.util';
import logger from '../utils/logger.util';
import asObject from '../utils/objects/asObject.util';

const L = logger('UTILS');

export const getTotalPrice = () => {
  const state = STORE.getState();
  if (Object.values(state.booking.selectedStylists).length === 0) {
    return 0;
  }
  const reducer = (accumulator, currentValue) => (accumulator: any).price + (currentValue: any).price;
  let total_price;
  const stylists = Object.values(state.booking.selectedStylists);
  if (stylists.length === 1) {
    total_price = (stylists[0]: any).price;
  } else {
    total_price = parseFloat(Object.values(state.booking.selectedStylists).reduce(reducer));
  }
  return total_price;
};

const createBooking = async () => {
  const state = STORE.getState();
  const {
    selectedAppointmentTime,
    selectedPatchTime,
    seletedPatchStylistId,
    notes,
    selectedStylists,
    isWaitList,
    utm,
  } = state.booking;

  const { payment_intent_id, pay_today } = state.billing;

  const { username } = state.cognito.user;
  // const { id } = state.booking.salonDetails;
  const id = parseInt(document.location.href.split('#/salon/')[1].split('/')[0], 10);
  if (selectedAppointmentTime === null) {
    return { success: false, message: 'selectedAppointmentTime is missing' };
  }

  const procedures: NewBookingService[] = [];
  const keys = Object.keys(selectedStylists);
  for (let i = 0; i < keys.length; i += 1) {
    const serviceId = keys[i];
    const stylist = selectedStylists[serviceId];
    if (stylist === null) {
      return { success: false, message: 'stylist is missing' };
    }
    const service: NewBookingService = {
      service_id: stylist.service_id,
      stylist_id: stylist.stylist_id,
      role: stylist.stylist_role,
      start_time: stylist.start_time,
      duration: stylist.duration,
      price: stylist.price,
      processing: stylist.processing,
    };
    procedures.push(service);
  }

  if (procedures.length === 0) {
    return { success: false, message: 'procedures are missing' };
  }

  procedures.sort((a, b) => {
    if (moment(a.start_time).isBefore(moment(b.start_time))) {
      return -1;
    }
    return 1;
  });
  const deposit = isWaitList ? 0 : pay_today;

  const data: NewBooking = {
    start_time: state.booking.selectedAppointmentTime.toISOString(),
    procedures,
    total_price: state.billing.appointment_cost,
    cognito_username: username,
    payment_intent: payment_intent_id,
    notes,
    deposit,
    user: {
      name: state.cognito.attributes.name,
      phone_number: state.cognito.attributes.phone_number,
      salon_id: id,
      notes: '',
    },
    payment_needed: deposit > 0,
  };

  if (selectedPatchTime !== null) {
    data.patch_test = {
      salon: id,
      start_time: moment(selectedPatchTime).toISOString(),
      stylist_id: seletedPatchStylistId,
    };
  }

  const validOrigin = ['facebook', 'instagram', 'sms'];
  if (utm && utm.includes('=')) {
    const utm_origin = utm.split('=')[1];
    const origin = utm_origin.replace(/'/g, '');
    if (origin && validOrigin.includes(origin)) {
      data.utm_origin = origin;
    }
  }

  return STORE.dispatch(sendBookingData(data, id, isWaitList));
};

export default createBooking;

export const getCancelPolicy = () => {
  const state = STORE.getState();
  const { salonDetails } = state.booking;
  let cancelPolicy;
  if (salonDetails.ncob_deposit_settings === 'full_amount') {
    cancelPolicy = `CANCELLATION POLICY: As a small salon we hope you understand that we can’t afford no-shows or last minute
    cancellations. As such cancellations within 48hrs will be charged 100%`;
  } else if (salonDetails.ncob_deposit_settings === 'half_amount') {
    cancelPolicy = `CANCELLATION POLICY: As a small salon we hope you understand that we can’t afford no-shows or last minute
    cancellations. As such cancellations within 48hrs will be charged 50%`;
  } else if (salonDetails.ncob_deposit_settings !== 'null' && !salonDetails.no_show_protection) {
    // custom_amount
    cancelPolicy = `CANCELLATION POLICY: As a small salon we hope you understand that we can’t afford no-shows or last minute
    cancellations. As such cancellations within 48hrs will be charged £${state.ncob_deposit_settings}`;
  } else if (salonDetails.ncob_deposit_settings === 'null' && !salonDetails.no_show_protection) {
    cancelPolicy = '';
  }
  return cancelPolicy;
};

export const getBookingCostFunc = () => {
  const state = STORE.getState();
  const serviceIds = state.booking.selectedServices.map(service => service.id);
  const stylistIds = [];
  for (let i = 0; i < serviceIds.length; i += 1) {
    window.booking = state.booking;
    const stylist = state.booking.selectedStylists[serviceIds[i]];
    if (stylist) {
      stylistIds.push(stylist.stylist_id);
    } else {
      stylistIds.push(0);
    }
  }
  const salonId = state.booking.salonDetails.id;
  STORE.dispatch(getBookingCost(salonId, moment(state.booking.selectedAppointmentTime), serviceIds, stylistIds));
};

export const setPageVisitedFunc = (page: string) => {
  STORE.dispatch(setPageVisited(page));
};

export const fbPixelTracking = (fbPixel: string, event?: string, data?: {}) => {
  if (!ReactPixel.initialized) {
    L.info('fbPixelTracking()', 'Initializing...');

    if (!fbPixel) {
      L.warn('fbPixelTracking()', 'The pixel ID is missing');
    }

    ReactPixel.init(fbPixel, {}, { agent: 'Slick' });
    ReactPixel.revokeConsent();
  }

  const isTracking = lsGet(LSKEY.cookies) === CONSENT.all;
  L.info('fbPixelTracking()', isTracking ? 'Page is being tracked' : 'Page is not being tracked');

  if (!isTracking) {
    return;
  }

  ReactPixel.grantConsent();
  ReactPixel.pageView();

  if (event) {
    ReactPixel.track(event, asObject(data));
  }
};

export const trackingEvent = (name: string, label: string) => {
  if (!window.analytics) {
    return;
  }

  const state = STORE.getState();
  const salonId = state.booking.salonDetails.id;
  const category = `salon${salonId}_${name}`;

  window.analytics.track(name, { category, label });
};

export const convertDurationToHrMin = (m: any) => {
  const hrs = Math.floor(moment.duration(m).asMinutes() / 60);
  const mins = moment.duration(m).asMinutes() % 60;
  if (hrs < 1) {
    return `${mins} mins`;
  }
  if (hrs === 1) {
    if (mins > 0) {
      return `1 hour ${mins} mins`;
    }
    return '1 hour';
  }
  if (mins > 0) {
    return `${hrs} hours ${mins} mins`;
  }
  return `${hrs} hours`;
};
