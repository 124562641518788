// @flow
import React from 'react';
import cn from 'classnames';

import { withTheme } from 'providers/Theme.provider';

import CN from './InputVerifyCode.module.scss';

type Props = {
  onBlurHandler?: (value: Array<number | null>) => void,
  validateChange?: (value: Array<number | null>) => boolean,
  validateBlur?: (value: Array<number | null>) => string,
  type: string,
};

type State = {
  code: Array<number | null>,
  errorMessage: string | null,
};

export class InputVerifyCode extends React.Component<Props, State> {
  static defaultProps = {
    onBlurHandler: () => {},
    validateChange: () => true,
    validateBlur: () => '',
  };
  constructor(props: Props) {
    super(props);

    this.state = {
      code: [null, null, null, null, null, null],
      errorMessage: '',
    };
    this.inputFields = [null, null, null, null, null, null];
  }
  onChange = (event: SyntheticInputEvent<HTMLInputElement>, index: number) => {
    const { target } = event;
    const { value } = target;
    const code = this.state.code.slice();
    code[index] = parseInt(value, 10);

    if (code[index] !== null && code[index] >= 0 && code[index] <= 9 && index < 5) {
      const inputField = this.inputFields[index + 1];
      if (inputField) {
        inputField.focus();
      }
    }
    if (this.props.validateChange && !this.props.validateChange(code)) {
      // do nothing
    }
    this.setState({ code });
  };
  onBlur = (event: SyntheticInputEvent<HTMLInputElement>, index: number) => {
    const { target } = event;
    const { value } = target;
    const code = this.state.code.slice();
    code[index] = parseInt(value, 10);
    if (this.props.validateBlur) {
      const errorMessage = this.props.validateBlur(code);
      this.setState({ errorMessage });
    }
    this.setState({ code });
    if (this.props.onBlurHandler) {
      this.props.onBlurHandler(code);
    }
  };

  inputFields: Array<HTMLInputElement | null>;

  render() {
    const { isDarkMode, isNeutralMode } = this.props;

    const errorMessageDivClassName = this.state.errorMessage ? 'input-prompt-show' : 'input-prompt-hide';
    const firstDiv = cn({
      [CN.inputContainer]: true,
      [CN.first]: true,
    });
    const lastDiv = cn({
      [CN.inputContainer]: true,
      [CN.last]: true,
    });

    return (
      <div
        data-bem="InputVerifyCode"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <label htmlFor="verifyCode">
          <div className={CN.container}>
            <div className={firstDiv}>
              <input
                ref={input => {
                  if (input) {
                    this.inputFields[0] = input;
                  }
                }}
                className={CN.input}
                type={this.props.type}
                onChange={event => this.onChange(event, 0)}
                onBlur={event => this.onBlur(event, 0)}
              />
            </div>
            <div className={CN.inputContainer}>
              <input
                ref={input => {
                  if (input) {
                    this.inputFields[1] = input;
                  }
                }}
                className={CN.input}
                type={this.props.type}
                onChange={event => this.onChange(event, 1)}
                onBlur={event => this.onBlur(event, 1)}
              />
            </div>
            <div className={CN.inputContainer}>
              <input
                ref={input => {
                  if (input) {
                    this.inputFields[2] = input;
                  }
                }}
                className={CN.input}
                type={this.props.type}
                onChange={event => this.onChange(event, 2)}
                onBlur={event => this.onBlur(event, 2)}
              />
            </div>
            <div className={CN.inputContainer}>
              <input
                ref={input => {
                  if (input) {
                    this.inputFields[3] = input;
                  }
                }}
                className={CN.input}
                type={this.props.type}
                onChange={event => this.onChange(event, 3)}
                onBlur={event => this.onBlur(event, 3)}
              />
            </div>
            <div className={CN.inputContainer}>
              <input
                ref={input => {
                  if (input) {
                    this.inputFields[4] = input;
                  }
                }}
                className={CN.input}
                type={this.props.type}
                onChange={event => this.onChange(event, 4)}
                onBlur={event => this.onBlur(event, 4)}
              />{' '}
            </div>
            <div className={lastDiv}>
              <input
                ref={input => {
                  if (input) {
                    this.inputFields[5] = input;
                  }
                }}
                className={CN.input}
                type={this.props.type}
                onChange={event => this.onChange(event, 5)}
                onBlur={event => this.onBlur(event, 5)}
              />
            </div>
          </div>
        </label>

        <div className={errorMessageDivClassName}>
          <span className={CN.error}>{this.state.errorMessage}</span>
        </div>
      </div>
    );
  }
}

export default withTheme()(InputVerifyCode);
