// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import Spinner from 'common/Spinner.component';

const LoginPresentation = ({ authentication, isAuthenticated, location }) => (
  <div className="pink-bgr">
    <div className="section">Re-book an Appointment</div>
    <div className="container login">
      <div className="info-text">
        <div className="text-header">Oops, it looks like your salon has an old online booking link.</div>
      </div>
      <div className="text-subheader">
        Please ask them to contact Slick for the correct link to their online booking page
      </div>

      {isAuthenticated && (
        <Redirect
          to={{
            pathname: '/salon',
            state: { from: location },
          }}
        />
      )}
      {authentication.isFetching && <Spinner variant="overlay" />}
      {!authentication.isFetching && authentication.errorMessage && (
        <div className="conditional">
          <div className="info-text">
            <div className="text-header">
              Ooops, sorry!
              <br />
              Something went wrong!
            </div>
            <div className="conditional-img img_error" />
            <div className="text-subheader">
              Check your number and try again
              <br />
              {authentication.errorMessage}
            </div>
          </div>
        </div>
      )}
      {!authentication.isFetching && authentication.smsSent && (
        <div className="conditional">
          <div className="info-text">
            <div className="text-header">Link sent to {authentication.phone}</div>
            <div className="conditional-ico ico-svg-book-by-phone" />
            <div className="text-subheader">Check your phone in a few seconds and open the text message</div>
          </div>
        </div>
      )}
    </div>
    <div className="footer-wrap clearfix">
      <a className="footer-text" href="https://getslick.com/terms-and-conditions/">
        Terms & Conditions
      </a>
      <a className="footer-text" href="https://getslick.com/privacy-policy/">
        Privacy Policy
      </a>
    </div>
  </div>
);

const mapStateToProps = state => {
  const { isAuthenticated } = state.login;
  return {
    authentication: state.authentication,
    isAuthenticated,
  };
};

export const LoginComponent = connect<any, Dispatch, any, any, any>(mapStateToProps)(LoginPresentation);

const Login = withRouter<any, any>(LoginComponent);

export default Login;
