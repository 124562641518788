import { createTracker, EventTypes } from 'redux-segment';

import getString from 'utils/accessors/getString.util';

const customMapper = {
  mapper: {
    '@@reduxReactRouter/initRoutes': EventTypes.page,
    '@@reduxReactRouter/routerDidChange': EventTypes.page,
    '@@reduxReactRouter/replaceRoutes': EventTypes.page,
    '@@router/INIT_PATH': EventTypes.page,
    '@@router/UPDATE_PATH': EventTypes.page,
    '@@router/LOCATION_CHANGE': (getState, action) => ({
      eventType: EventTypes.page,
      eventPayload: { name: getString('payload.pathname', action).replace(/[0-9]+/g, 'X') },
    }),
  },
};

export default createTracker(customMapper);
