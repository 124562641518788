import fromAnyToJsonClone from 'utils/converters/fromAnyToJsonClone.util';

const deepFreeze = value => {
  if (Array.isArray(value)) {
    Object.freeze(value);
    for (const item of value) {
      deepFreeze(item);
    }
    return value;
  }

  if (value !== null && typeof value === 'object') {
    Object.freeze(value);
    for (const prop of Object.values(value)) {
      deepFreeze(prop);
    }
  }

  return value;
};

// NOTE: JSON cloning filters out incidental functions, symbols etc. and will throw if object graph is not cyclical
const constant = value =>
  value === null || typeof value === 'undefined' || Number.isNaN(value) || typeof value === 'symbol'
    ? value
    : deepFreeze(fromAnyToJsonClone(value));

export default constant;
