// NOTE: old version of Node will not support Object.fromEntries
// thus Jest tests may fail and require polyfil, but browsers will support it

import logger from '../../logger.util';

const L = logger('fromEntriesToObject');

const fromEntries =
  Object.fromEntries || (entries => [...entries].reduce((o, [k, v]) => Object.assign(o, { [k]: v }), {}));

const fromEntriesToObject = entries => {
  try {
    return fromEntries(entries);
  } catch (e) {
    L.warn('()', e);
    return {};
  }
};

// noinspection JSUnusedGlobalSymbols
export default fromEntriesToObject;
