import React from 'react';
import { ConsentManager } from '@segment/consent-manager';
import inEU from '@segment/in-eu';

import { SEGMENT_WRITE_KEY } from 'config/index';

const USE_SEGMENT = false;

const DummySegmentConsentManager = () => null;

const SegmentConsentManager = () => {
  const bannerContent = (
    <span className="cookie-policy">
      We use cookies (and other similar technologies) to collect data to improve your experience on our site. By using
      our website, you’re agreeing to the collection of data as described in our{' '}
      <a href="https://www.getslick.com/privacy-policy" rel="noopener noreferrer" target="_blank">
        Website Data Collection Policy
      </a>
      .
    </span>
  );
  const bannerSubContent = <span className="banner-btn">Manage cookie</span>;
  const preferencesDialogTitle = 'Website Data Collection Preferences';
  const preferencesDialogContent = [
    'We use data collected by cookies and JavaScript libraries to',
    'to improve your browsing experience, analyze site traffic, deliver',
    'personalized advertisements, and increase the overall performance of our site.',
  ]
    .join(' ')
    .trim();

  const cancelDialogTitle = 'Are you sure you want to cancel?';
  const cancelDialogContent =
    'Your preferences have not been saved. By continuing to use our ' +
    'website, you՚re agreeing to our Website Data Collection Policy.';

  return (
    <div id="target-container">
      <div className="inner">
        <ConsentManager
          writeKey={SEGMENT_WRITE_KEY}
          shouldRequireConsent={inEU}
          bannerContent={bannerContent}
          bannerSubContent={bannerSubContent}
          preferencesDialogTitle={preferencesDialogTitle}
          preferencesDialogContent={preferencesDialogContent}
          cancelDialogTitle={cancelDialogTitle}
          cancelDialogContent={cancelDialogContent}
          closeBehavior={categories => ({
            ...categories,
            // advertising: true ,
          })}
          // implyConsentOnInteraction="true" // Close banner on clicks anywhere outside the consent, no set cookie
        />

        {/* <button type="button" onClick={openConsentManager}>
        Website Data Collection Preferences
      </button> */}
      </div>
    </div>
  );
};

export default USE_SEGMENT ? SegmentConsentManager : DummySegmentConsentManager;
