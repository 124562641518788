// @flow
/* eslint-disable camelcase */

// $FlowFixMe
import { Action } from 'react-cognito-mm';

import { API_ROOT } from 'config/index';
import { AUTH_API } from 'middleware/api';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_REDIRECT = 'SET_REDIRECT';
export const ADD_NCOB_USER_REQUEST = 'ADD_NCOB_USER_REQUEST';
export const ADD_NCOB_USER_SUCCESS = 'ADD_NCOB_USER_SUCCESS';
export const ADD_NCOB_USER_FAILURE = 'ADD_NCOB_USER_FAILURE';

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

export function logoutUser() {
  return (dispatch: any, getState: any) => {
    const { cognito, booking } = getState();
    if (cognito.user && cognito.user.state === 'LOGGED_IN') {
      cognito.user.signOut();
    }
    dispatch(Action.logout());
    dispatch(receiveLogout());
    window.location.replace(`#/salon/${booking.salonDetails.id}`);
  };
}

export function clearTokens() {
  return (dispatch: any, getState: any) => {
    const { cognito } = getState();
    if (cognito.user) {
      cognito.user.signOut();
    }
  };
}

export function addNcobuser(username: string, phone: string, email: number, full_name: string) {
  const endpoint = 'add-ncobuser/';
  const data = {
    username,
    phone,
    email,
    full_name,
    credentials: 'include',
  };

  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint,
      authenticated: true,
      credentials: 'include',
      types: [ADD_NCOB_USER_REQUEST, ADD_NCOB_USER_SUCCESS, ADD_NCOB_USER_FAILURE],
      config: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    },
  };
}
