import React from 'react';
import cn from 'classnames';
import moment from 'moment';

import S from 'constants/string.const';
import { useTheme } from 'providers/Theme.provider';

import { convertDurationToHrMin } from 'lib/utils';

import CN from './ServiceListItem.module.scss';

const USE_DURATION = false;

const ServiceListItem = ({ service, stylist, price }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  return (
    <div
      data-bem="ServiceListItem"
      className={cn({
        [CN.component]: true,
        [CN.dark]: isDarkMode,
        [CN.neutral]: isNeutralMode,
      })}
    >
      <div>
        <div className={CN.boldText}>{service.name}</div>
        <div className={CN.textSecondary}>
          <span>{stylist.stylist_name ? stylist.stylist_name : 'Name'}</span>
          <span> - {stylist.stylist_role ? stylist.stylist_role : 'Stylist'}</span>
          {USE_DURATION && (
            <span>
              {convertDurationToHrMin(moment.duration(stylist.duration).add(moment.duration(stylist.processing)))}
            </span>
          )}
        </div>
      </div>
      <div className={CN.rowValue}>
        <>{service.variable_pricing === true ? <span className={CN.inline}>From {S.space}</span> : ' '}</>
        {price}
      </div>
    </div>
  );
};
export default ServiceListItem;
