/* eslint-disable indent */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */

import * as React from 'react';
import Slider from 'react-slick';
import cn from 'classnames';
import moment from 'moment-timezone/moment-timezone';

import { withTheme } from 'providers/Theme.provider';
import fromDateToMomentTzFormat from 'utils/date/fromDateToMomentTzFormat.util';

import CustomNextArrow from 'booking/common/CalendarSlider/CustomNextArrow';
import DropdownBtnComponent from 'booking/common/DropdownBtn';
import DropdownList from 'booking/common/DropdownList';
import ErrorPopup from 'booking/common/ErrorPopup';

import CustomPrevArrow from './CustomPrevArrow';

import CN from './Slider.module.scss';

const DAYS_TO_ADD = 15;

class CalendarSlider extends React.Component {
  static defaultProps = {
    firstAvailableDate: null,
    firstAvailablePatchDate: null,
    skip_today: false,
    appointment_id: 0,
    availableDays: {},
    availablePatchDays: {},
    availablePatchSlots: null,
    selectedPatchTime: null,
    clearAvailableSlots: () => {},
    clearAvailableDays: () => {},
    clearAvailablePatchDays: () => {},
    getAvailability: () => {},
    getPatchAvailability: () => {},
    getPatchWeeklyAvailability: () => {},
    getPatchWeeklyAvailabilityRepeat: () => {},
    getWeeklyAvailability: () => {},
    getWeeklyAvailabilityRepeat: () => {},
    showWaitListAvailability: () => {},
    isWaitList: false,
    updateFirstAvailablePatchDate: () => {},
    monthPickerTime: '',
  };

  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    let bufferDate;
    let date;

    if (this.props.firstAvailablePatchDate && document.location.href.indexOf('patchtimeselect') > 0) {
      bufferDate = moment(this.props.firstAvailablePatchDate);
    } else if (this.props.firstAvailableDate && document.location.href.indexOf('timeselect') > 0) {
      bufferDate = moment(this.props.firstAvailableDate);
    } else if (this.props.selectedPatchTime) {
      bufferDate = moment(this.props.selectedPatchTime).add(2, 'day'); // Note: Check why this skips two days ahead
    } else {
      bufferDate = moment();
    }
    if (this.props.skip_today && bufferDate.isSame(moment(), 'day')) {
      date = moment(bufferDate.add(1, 'days'));
    } else {
      date = bufferDate;
    }

    const daysToShow = [];

    const lastAddedDate = moment(date).startOf('day');
    let selectedDate;
    if (props.selectedDate !== null) {
      ({ selectedDate } = props);
    } else {
      selectedDate = moment(date).startOf('day');
    }

    daysToShow.push(moment(lastAddedDate));

    for (let i = 0; i < 180; i += 1) {
      lastAddedDate.add(1, 'days');
      daysToShow.push(moment(lastAddedDate));
    }
    this.state = {
      lastAddedDate,
      selectedDate,
      daysToShow,
      animateToFirstDate: false,
      animateToFirstPatchDate: false,
      clickedFirstAvailablePatchDate: false,
      firstVisibleDate: moment(daysToShow[0]),
      nextArrowDisabled: false,
      haveBeenToFirstAvailable: false,
      haveBeenToFirstAvailablePatch: false,
      touchMove: true,
      prevArrowDisabled: false,
      monthsToShow: [],
      // changedMonth: false,
      MONTHS_TO_DROP: 6,
      showDropDownList: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.getAvailableDays();
  }

  componentDidMount() {
    if (this.state.firstVisibleDate) {
      const newTime = this.props.selectedDate
        ? moment(this.props.selectedDate).format('MMMM YYYY')
        : moment(this.state.firstVisibleDate).format('MMMM YYYY');
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ monthPickerTime: newTime });
    }
  }

  componentWillReceiveProps(nextProps) {
    const arrowDiv = document.querySelector('.slick-next.custom');
    if (nextProps.disableNextArrow) {
      this.setState({ nextArrowDisabled: true });
      arrowDiv.classList.add('slick-disabled');
    }
    const w = window.innerWidth;
    let width = 1;
    if (w > 480 && w < 800) {
      width = 2;
    } else if (w > 350 && w < 480) {
      width = 3;
    } else if (w < 350) {
      width = 4;
    }

    let days = 6;
    switch (width) {
      case 2:
        days = 5;
        break;
      case 3:
        days = 3;
        break;
      case 4:
        days = 2;
        break;
      default:
        break;
    }
    if (
      (nextProps.firstAvailableDate && !this.state.animateToFirstDate) ||
      (nextProps.firstAvailablePatchDate && !this.state.animateToFirstPatchDate)
    ) {
      const { firstAvailableDate, firstAvailablePatchDate } = nextProps;
      if (this.sliderRef && this.sliderRef.current) {
        let diff;
        if (document.location.href.indexOf('/timeselect') > 0) {
          diff = moment(firstAvailableDate).diff(moment(this.state.daysToShow[0]), 'd') - days;
        } else if (document.location.href.indexOf('/patchtimeselect') > 0) {
          diff = moment(firstAvailablePatchDate).diff(moment(this.state.daysToShow[0]), 'd') - days;
        }
        if (this.sliderRef && this.sliderRef.current) {
          if (diff && diff > 0) {
            this.sliderRef.current.slickGoTo(diff);
            setTimeout(() => {
              this.updateDropdownTime();
            }, 1000);
          }
          if (document.location.href.indexOf('patch') > 0) {
            setTimeout(() => {
              this.setState({ haveBeenToFirstAvailablePatch: true });
            }, 2000);
            this.onSelectDay(firstAvailablePatchDate);
            this.setState({ animateToFirstPatchDate: true });
          } else {
            setTimeout(() => {
              this.setState({ haveBeenToFirstAvailable: true });
            }, 2000);
            this.onSelectDay(firstAvailableDate);
            this.setState({ animateToFirstDate: true });
          }
        }
      }
    }
    if (nextProps.mounted && nextProps.selectedTime && !this.state.animateToFirstDate) {
      const { selectedTime } = nextProps;
      if (this.sliderRef && this.sliderRef.current) {
        let diff;
        if (document.location.href.indexOf('/timeselect') > 0) {
          diff = moment(selectedTime).diff(moment(this.state.daysToShow[0]), 'd') - days;
        }
        if (this.sliderRef && this.sliderRef.current) {
          if (diff && diff > 0) {
            this.sliderRef.current.slickGoTo(diff);
            this.sliderRef.current.slickGoTo(diff);
            setTimeout(() => {
              this.updateDropdownTime();
            }, 1000);
          }
        }
      }
    }

    // when firstAvailableDate is set to null
    if (this.props.firstAvailableDate && !nextProps.firstAvailableDate) {
      this.setState({ haveBeenToFirstAvailable: false });
    }
    // when firstAvailablePatchDate is set to null
    if (this.props.firstAvailablePatchDate && !nextProps.firstAvailablePatchDate) {
      this.setState({ haveBeenToFirstAvailablePatch: false });
    }
    // only when haveBeenToFirstAvailable is true, click arrow will fetch weekly availability
    if (!this.props.firstAvailableDate && nextProps.firstAvailableDate) {
      setTimeout(() => {
        this.setState({ haveBeenToFirstAvailable: true });
      }, 2000);
    }
    // only when haveBeenToFirstAvailablePatch is true, click arrow will fetch weekly patch availability
    if (!this.props.firstAvailablePatchDate && nextProps.firstAvailablePatchDate) {
      setTimeout(() => {
        this.setState({ haveBeenToFirstAvailablePatch: true });
      }, 2000);
    }
  }

  onSelectDay = (day, isDisabled = false) => {
    if (isDisabled) {
      return;
    }
    // this.setState({ isFetching: true });
    const { clearAvailableSlots } = this.props;
    if (
      this.props.firstAvailableDate &&
      moment(this.state.firstVisibleDate).toISOString() !== moment(this.props.firstAvailableDate).toISOString()
    ) {
      clearAvailableSlots();
    }
    if (
      this.props.firstAvailablePatchDate &&
      moment(this.state.firstVisibleDate).toISOString() !== moment(this.props.firstAvailablePatchDate).toISOString()
    ) {
      clearAvailableSlots();
    }
    if (isDisabled) {
      clearAvailableSlots();
      this.setState({ selectedDate: moment(day) });

      return;
    }
    this.setState({ errorMessage: '' });

    this.setState({ selectedDate: moment(day) }, () => {
      const d = moment(this.state.selectedDate);
      const { getAvailability, getPatchAvailability } = this.props;
      if (getAvailability) {
        if (d.toISOString()) {
          getAvailability(fromDateToMomentTzFormat(d), this.props.isWaitList ? this.props.isWaitList : false);
        }
      }
      if (getPatchAvailability) {
        getPatchAvailability(fromDateToMomentTzFormat(d));
      }
    });
  };

  onMonthClick = e => {
    this.props.clearAvailableSlots();
    this.props.clearAvailableDays();
    this.props.clearSelectedDate();
    setTimeout(() => {
      this.updateDropdownTime();
    }, 1000);
    // different month should set new firstAvailableDate
    this.props.updateFirstAvailableDate(null);

    this.setState({ showDropDownList: !this.state.showDropDownList, errorMessage: '' });

    const year = e.split(' ')[1];

    const currentMonth =
      moment().month() < 10
        ? `0${moment().month()}`
        : moment()
            .month()
            .toString();
    let day;

    const monthNumber = moment.months().findIndex(m => m === e.split(' ')[0]) + 1;
    const monthClicked = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
    const salonId = this.props.salonDetails.id;

    if (window.analytics) {
      window.analytics.track('Clicked Months', {
        category: `salon${salonId}_month_click`,
        label: 'Months dropdown',
        value: monthClicked,
      });
    }

    if (parseInt(currentMonth, 10) + 1 !== parseInt(monthClicked, 10)) {
      day = '01';
      // this.setState({ changedMonth: true });
    } else {
      day = moment().format('DD');
    }
    if (this.sliderRef && this.sliderRef.current) {
      this.sliderRef.current.slickGoTo(0);
    }

    const newDate = moment(`${year}-${monthClicked}-${day}`);

    this.onSelectDay(newDate);
    const lastAddedDate = moment(newDate);

    const daysToShow = [];

    daysToShow.push(moment(newDate));

    for (let i = 0; i < newDate.daysInMonth(); i += 1) {
      lastAddedDate.add(1, 'days');

      daysToShow.push(moment(lastAddedDate));
    }
    this.setState({
      lastAddedDate,
      daysToShow,
      firstVisibleDate: newDate,
    });
    const { getPatchAvailability, getWeeklyAvailabilityRepeat, isWaitList } = this.props;
    if (getPatchAvailability && document.location.href.indexOf('patch') > 0 && !this.props.availablePatchSlots) {
      getPatchAvailability(moment(newDate).startOf('day'), isWaitList);
    }
    if (this.props.selectedPatchTime && getWeeklyAvailabilityRepeat) {
      getWeeklyAvailabilityRepeat(
        moment(this.props.selectedPatchTime)
          .add(2, 'day')
          .startOf('day'),
        // $FlowFixMe
        isWaitList,
      );
    } else {
      // $FlowFixMe
      getWeeklyAvailabilityRepeat(moment(newDate).startOf('day'), isWaitList);
    }
  };

  onDropDownClick = () => {
    this.setState({ showDropDownList: !this.state.showDropDownList });
  };

  setDropDownMonths = () => {
    const monthsToShow = [];

    for (let i = 0; i < 7; i += 1) {
      if (monthsToShow.length === this.state.MONTHS_TO_DROP) {
        this.setState({ monthsToShow });
        break;
      }
      monthsToShow.push(
        moment()
          .add(i, 'months')
          .format('MMMM YYYY'),
      );
    }
  };

  getAvailableDays = (waitList, fromDate) => {
    if (!waitList) {
      waitList = this.props.isWaitList;
    }
    if (!fromDate) {
      fromDate = this.state.daysToShow[0];
    }
    if (this.props.firstAvailablePatchDate && !this.state.clickedFirstAvailablePatchDate) {
      this.onSelectDay(moment(this.props.firstAvailablePatchDate));
      this.setState({ clickedFirstAvailablePatchDate: true });
    }
    const { getPatchWeeklyAvailabilityRepeat, getWeeklyAvailabilityRepeat } = this.props;
    if (
      getPatchWeeklyAvailabilityRepeat &&
      document.location.href.indexOf('patch') > 0 &&
      !Object.keys(this.props.availablePatchSlots).length
    ) {
      getPatchWeeklyAvailabilityRepeat(moment(fromDate).startOf('day'));
    }
    if (getWeeklyAvailabilityRepeat) {
      if (this.props.selectedPatchTime) {
        getWeeklyAvailabilityRepeat(
          moment(this.props.selectedPatchTime)
            .add(2, 'day')
            .startOf('day'),
          // $FlowFixMe
          waitList,
        );
      } else if (!this.props.availableDays || Object.keys(this.props.availableDays).length === 0) {
        // $FlowFixMe
        getWeeklyAvailabilityRepeat(moment(fromDate).startOf('day'), waitList);
      }
    }
    this.setDropDownMonths();
  };

  addMoreDays = () => {
    const daysToShow = this.state.daysToShow.slice();
    const lastAddedDate = moment(this.state.lastAddedDate);
    const arrowDiv = document.querySelector('.slick-next.custom');
    for (let i = 0; i < DAYS_TO_ADD; i += 1) {
      if (moment(this.state.firstVisibleDate).diff(moment(), 'days') < this.props.check_ncob_availability_until - 6) {
        lastAddedDate.add(1, 'days');
        daysToShow.push(moment(lastAddedDate));
        arrowDiv.classList.remove('slick-disabled');
        // this.setState({ nextArrowDisabled: false });
      } else {
        arrowDiv.classList.add('slick-disabled');
        this.setState({ nextArrowDisabled: true });
      }
    }
    this.setState({ lastAddedDate, daysToShow });
  };
  updateDropdownTime = () => {
    if (this.state.firstVisibleDate) {
      const newTime = this.props.selectedDate
        ? moment(this.props.selectedDate).format('MMMM YYYY')
        : moment(this.state.firstVisibleDate).format('MMMM YYYY');
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ monthPickerTime: newTime });
    }
  };
  next = () => {
    const nextArrowEle = document.querySelector('.slick-next.custom');
    if (this.state.nextArrowDisabled) {
      return;
    }
    if (nextArrowEle) {
      if (this.sliderRef && this.sliderRef.current && !this.state.nextArrowDisabled) {
        this.sliderRef.current.slickNext();
        setTimeout(() => {
          this.updateDropdownTime();
        }, 1000);
        this.props.clearSelectedDate();
        this.props.clearSelectedTime();
        if (this.props.updateFirstAvailableDate) {
          this.props.updateFirstAvailableDate(null);
        }
        if (this.props.updateFirstAvailablePatchDate) {
          this.props.updateFirstAvailablePatchDate(null);
        }
        nextArrowEle.classList.add('slick-disabled');
        this.setState({ errorMessage: '', nextArrowDisabled: true, selectedDate: null });
        this.props.clearAvailableSlots();
      }
    }
    setTimeout(() => {
      this.setState({ nextArrowDisabled: false });
      if (nextArrowEle) {
        nextArrowEle.classList.remove('slick-disabled');
      }
    }, 2000);
  };
  prev = () => {
    const prevArrowEle = document.querySelector('.slick-prev.custom');
    const nextArrowEle = document.querySelector('.slick-next.custom');
    if (this.state.prevArrowDisabled) {
      return;
    }
    if (prevArrowEle) {
      prevArrowEle.classList.add('slick-disabled');
      if (this.props.updateFirstAvailableDate) {
        this.props.updateFirstAvailableDate(null);
      }
      if (this.props.updateFirstAvailablePatchDate) {
        this.props.updateFirstAvailablePatchDate(null);
      }
      if (this.sliderRef && this.sliderRef.current && !this.state.prevArrowDisabled) {
        this.sliderRef.current.slickPrev();
        setTimeout(() => {
          this.updateDropdownTime();
        }, 1000);
        this.props.clearSelectedDate();
        this.props.clearSelectedTime();
        this.setState({ errorMessage: '' });
        this.setState({ nextArrowDisabled: false });
        if (nextArrowEle) {
          nextArrowEle.classList.remove('slick-disabled');
        }
      }
      this.setState({ selectedDate: null, prevArrowDisabled: true });
      this.props.clearAvailableSlots();
    }
    setTimeout(() => {
      this.setState({ prevArrowDisabled: false });
      if (prevArrowEle) {
        prevArrowEle.classList.remove('slick-disabled');
      }
    }, 2000);
  };

  render() {
    const { isDarkMode, isNeutralMode } = this.props;

    const settings = {
      dots: false,
      infinite: false,
      // focusOnSelect: true, // this move the selected at 1 position
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 7,
      edgeFriction: 0,
      initialSlide: 0,
      touchMove: this.state.touchMove,
      afterChange: currentSlide => {
        this.setState({ touchMove: false });
        setTimeout(() => {
          this.setState({ touchMove: true });
        }, 2000);
        const startDateOfWeek = this.state.daysToShow[currentSlide].startOf('day');

        const {
          getPatchWeeklyAvailability,
          clearAvailableSlots,
          clearAvailablePatchDays,
          isWaitList,
          getWeeklyAvailability,
          updateFirstAvailablePatchDate,
        } = this.props;
        if (this.state.haveBeenToFirstAvailable) {
          this.props.clearSelectedDate();
          this.props.clearSelectedTime();
          this.props.clearAvailableSlots();
        }
        this.setState({ firstVisibleDate: startDateOfWeek }, () => {
          if (clearAvailableSlots && document.location.href.indexOf('patchtimeselect') < 0) {
            if (
              moment(this.state.daysToShow[currentSlide]).diff(this.props.firstAvailableDate, 'day') > 1 &&
              moment(this.state.firstVisibleDate).toISOString() !== moment(this.state.selectedDate).toISOString()
            ) {
              clearAvailableSlots();
            }
          } else if (clearAvailableSlots && document.location.href.indexOf('patchtimeselect') > 0) {
            if (
              moment(this.state.daysToShow[currentSlide]).diff(this.props.firstAvailablePatchDate, 'day') > 1 &&
              moment(this.state.firstVisibleDate).toISOString() !== moment(this.state.selectedDate).toISOString()
            ) {
              clearAvailableSlots();
            }
          }
          setTimeout(() => {
            this.updateDropdownTime();
          }, 1000);
        });
        // when send user to firstAvailableDate, we don't want to set that date as null
        if (document.location.href.indexOf('patchtimeselect') < 0) {
          if (this.state.haveBeenToFirstAvailable) {
            this.setState({ selectedDate: null });
            this.props.clearAvailableSlots();
            this.props.updateFirstAvailableDate(null);
          }
        } else if (this.state.haveBeenToFirstAvailablePatch) {
          this.setState({ selectedDate: null });
          this.props.clearAvailableSlots();
          if (
            clearAvailablePatchDays &&
            this.props.availablePatchDays &&
            Object.keys(this.props.availablePatchDays).length > 1
          ) {
            clearAvailablePatchDays();
          }
          if (updateFirstAvailablePatchDate) {
            updateFirstAvailablePatchDate(null);
          }
        }

        // wait 1s for the above to be cleared
        setTimeout(() => {
          if (
            document.location.href.indexOf('patchtimeselect') > 0 &&
            getPatchWeeklyAvailability &&
            !this.props.firstAvailablePatchDate
          ) {
            getPatchWeeklyAvailability(startDateOfWeek, 0);
          } else if (getWeeklyAvailability && !this.props.firstAvailableDate) {
            getWeeklyAvailability(startDateOfWeek, isWaitList, 0);
          }
        }, 0);
      },
      prevArrow: <CustomPrevArrow className="slick-arrow slick-prev custom" onClick={this.prev} />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 7,
            infinite: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 7,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 7,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    };

    return (
      <div
        data-bem="Slider"
        className={cn({
          'select-time': true,
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={`dropdown-container ${this.state.showDropDownList ? 'open' : 'close'}`}>
          <DropdownBtnComponent year={this.state.monthPickerTime} onClick={this.onDropDownClick} />
          {this.state.showDropDownList && (
            <DropdownList
              listdata={this.state.monthsToShow}
              onMonthClick={this.onMonthClick}
              showDropDownList={this.state.showDropDownList}
            />
          )}
        </div>
        <div className="wapper-slider" data-bem="Slider__wrapper">
          <div className="calendar-slider">
            <CustomPrevArrow className="slick-prev custom" onClick={this.prev} />
            <Slider ref={this.sliderRef} {...settings}>
              {this.state.daysToShow.map(day => {
                let disabled = '';

                const availableDays =
                  document.location.href.indexOf('patch') < 0
                    ? this.props.availableDays
                    : this.props.availablePatchDays;

                let isBeforePatchDay = false;
                if (this.props.selectedPatchTime && day.isBefore(this.props.selectedPatchTime)) {
                  isBeforePatchDay = true;
                }

                const ind = Object.entries(availableDays).findIndex(
                  d => day.format('YYYY-MM-DD') === d[0] && d[1] === false,
                );
                let shouldDisable = false;
                if (moment(day).diff(moment(), 'd') > this.props.check_ncob_availability_until) {
                  shouldDisable = true;
                }
                if (this.props.firstAvailableDate) {
                  if (moment(day).isBefore(moment(this.props.firstAvailableDate))) {
                    shouldDisable = true;
                  }
                }
                if (this.props.firstAvailablePatchDate) {
                  if (moment(day).isBefore(moment(this.props.firstAvailablePatchDate))) {
                    shouldDisable = true;
                  }
                }
                if (ind >= 0 || isBeforePatchDay || shouldDisable) {
                  disabled = 'disabled';
                } else {
                  disabled = '';
                }

                return (
                  <div
                    data-bem="Slider__item"
                    className={cn({
                      [CN.slickItem]: true,
                      [CN.selected]: moment(this.state.selectedDate).isSame(day, 'day'),
                      [CN.disabled]: disabled,
                    })}
                    key={`${day.format('DD-MM-YY')}`}
                    role="button"
                    tabIndex={0}
                    onClick={() => (!disabled ? this.onSelectDay(day) : this.onSelectDay(day, true))}
                  >
                    <div className={CN.circle}>
                      <span className={CN.dayNumber}>{day.format('D')}</span>
                    </div>
                    <div>
                      <div className={CN.dayText}>{day.format('ddd')}</div>
                      <div
                        className={cn({
                          [CN.availability]: true,
                          [CN.disabled]: disabled,
                        })}
                      >
                        {disabled ? 'No availability' : 'Availability'}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
            <CustomNextArrow className="slick-next custom" onClick={this.next} />
          </div>
        </div>
        {this.state.errorMessage && <ErrorPopup message={this.state.errorMessage} />}
      </div>
    );
  }
}

export default withTheme()(CalendarSlider);
