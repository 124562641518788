import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from 'auth/actions';

import Button from 'components/Button.component';
import COGNITO from 'constants/cognito.const';
import { useCurrentSalonId } from 'providers/SalonBasicInfo.provider';

import CN from './LogoutButton.module.scss';

const LogoutButton = ({ logoutUser, history, cognito }) => {
  const salonId = useCurrentSalonId();

  const handleLogout = () => {
    logoutUser();
    history.push(`/salon/${salonId}/`);
  };

  return (
    <div data-bem="LogoutButton">
      {cognito && cognito.state === COGNITO.loggedIn && (
        <Button variant="quaternary" width="small" onClick={handleLogout}>
          <span className={CN.text}>LOGOUT</span>
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { booking, cognito } = state;
  const { salonDetails } = booking;
  return {
    salonDetails,
    cognito,
  };
};

const mapDispatchToProps = dispatch => ({
  logoutUser() {
    dispatch(logoutUser());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutButton));
