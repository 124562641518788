/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import Button from 'components/Button.component';
import S from 'constants/string.const';
import { withTheme } from 'providers/Theme.provider';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import { formatDayToYearFullWeekday, formatHourAndMinuteShortMeridiem, formatPartTime } from 'utils/date/format.util';
import isPast24Hours from 'utils/date/isPast24Hours.util';

import { getSalonDetails } from 'booking/actions';
import currency from 'booking/common/currency.util';
import Spinner from 'common/Spinner.component';

import DeclineAppointment from './DeclineAppointment.component';

import CN from './SalonRequestItem.module.scss';

const USE_TOTAL = false;

type Props = {
  invite: any,
  history: any,
  username: string,
  salonName: string,
  invitePostData: string,
  confirmAuthenticationSuccess: boolean,
  invite_status: string,
  processInvite: (cognitoUsername: string, inviteId: string, data: any) => void,
  setPayFullAmount: (appointmentId: string, inviteId: string) => void,
  setInvitePostData: (status: string) => void,
  getSalonDetails: (salonId: number) => void,
};

type State = {
  popupSet: boolean,
  showDeclinePopup: boolean,
};

export class SalonRequestItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      popupSet: false,
      showDeclinePopup: false,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.invitePostData === 'SUCCESS' &&
      !this.state.popupSet &&
      (nextProps.confirmAuthenticationSuccess || nextProps.invite_status !== 'requires_action')
    ) {
      this.showPopup('showSuccessPopup', true);
      this.setState({ popupSet: true });
    } else if (nextProps.invitePostData === 'FAILED' && !this.state.popupSet) {
      this.showPopup('showFailedPopup', true);
      this.setState({ popupSet: true });
    }
  }

  payDeposit = (appId: string, fullOrcardOnly?: string) => {
    const inviteSalonId = this.props.invite.salon;

    this.props.getSalonDetails(inviteSalonId);
    this.props.setInvitePostData('');

    this.setState({ popupSet: false });
    if (!fullOrcardOnly) {
      this.props.history.push(`/salon/${inviteSalonId}/pay-deposit/${appId}`);
    } else if (fullOrcardOnly === 'full') {
      this.props.setPayFullAmount(appId, this.props.invite.id);
      this.props.history.push(`/salon/${inviteSalonId}/pay-deposit/${appId}`);
    } else if (fullOrcardOnly === 'card-only') {
      this.props.history.push(`/salon/${inviteSalonId}/pay-deposit/${appId}`);
    }
  };

  closeAllPopup = () => {
    this.props.setInvitePostData('');
    if (this.props.invitePostData === '') {
      this.showPopup('showDeclinePopup', false);
      this.showPopup('showSuccessPopup', false);
      this.showPopup('showFailedPopup', false);
    }
  };
  declineInvite = () => {
    // decline invite
    this.setState({ showDeclinePopup: false });
    this.setState({ popupSet: false });
    this.props.setInvitePostData('');
    const inviteId = this.props.invite.id;
    const data = { accepted: 'False', invite_id: this.props.invite.id };
    this.props.processInvite(this.props.username, inviteId, data);
  };

  showPopup = (name: string, showHide: boolean) => {
    this.setState({ [name]: showHide });
  };

  render() {
    const { appointment } = this.props.invite;
    const { isDarkMode, isNeutralMode } = this.props;
    const abbreviation = this.props.salonDetails.currency;

    const isNewAppointment = !isPast24Hours(appointment.start_time);

    const totalAmount = appointment.procedures.reduce((total, procedure) => total + procedure.price, 0);
    const createTime = this.props.invite.created_at;

    return (
      <div
        data-bem="SalonRequestItem"
        className={cn({
          [CN.component]: true,
          [CN.selected]: isNewAppointment,
          [CN.neutral]: isNeutralMode,
          [CN.dark]: isDarkMode,
        })}
      >
        {this.props.invitePostData === 'POSTING' && <Spinner variant="overlay" />}

        <div className={CN.row}>
          <div className={CN.salonName}>
            {this.props.invite.requested_deposit > 0 && (
              <div>
                {this.props.uniqSalonName} is requesting a deposit of
                <span className={CN.focused}>
                  {S.space}
                  {currency({ value: this.props.invite.requested_deposit, abbreviation })}
                </span>
              </div>
            )}
          </div>
          <div className={CN.time}>
            <div className={CN.date}> {formatDayToYearFullWeekday(createTime)}</div>
            <div>{formatHourAndMinuteShortMeridiem(createTime)}</div>
          </div>
        </div>

        <div className={CN.dayTime}>
          {formatDayToYearFullWeekday(appointment.start_time)} <br />
          <div className={CN.startTime}>{formatPartTime(appointment.start_time)}</div>
        </div>

        {appointment.procedures.map(procedure => (
          <div key={procedure.id} className={CN.details}>
            <div>
              <div className={CN.service}> {procedure.service.name}</div>
              <div className={CN.stylist}> {procedure.stylist.user_name}</div>
            </div>
            <div className={CN.price}>{currency({ value: procedure.price, abbreviation })}</div>
          </div>
        ))}
        {USE_TOTAL && (
          <div className={CN.row}>
            <div className={CN.servicesTotal}>
              {`${appointment.procedures.length} ${appointment.procedures.length === 1 ? 'Service' : 'Services'}`}
              {S.space}
              {S.passdot}
              {S.space} {currency({ value: totalAmount, abbreviation })}
            </div>
          </div>
        )}

        {this.props.invite.requested_deposit > 0 && (
          <div className={CN.rowButtons}>
            <Button variant="primary" height="middle" onClick={() => this.payDeposit(appointment.id)}>
              <div className={CN.buttonFont}>PAY DEPOSIT</div>
            </Button>
            <Button variant="warning" height="middle" onClick={() => this.showPopup('showDeclinePopup', true)}>
              <div className={CN.buttonFont}>
                <div className={CN.underline}>DECLINE</div>
              </div>
            </Button>
          </div>
        )}
        {this.props.invite.requested_card_only && (
          <Button variant="primary" height="middle" onClick={() => this.payDeposit(appointment.id, 'card-only')}>
            Confirm with card details
          </Button>
        )}

        {fromAnyToBool(this.state.showDeclinePopup) && (
          <DeclineAppointment
            salonName={this.props.salonName}
            onClose={() => this.setState({ showDeclinePopup: false })}
            onDecline={() => this.declineInvite()}
          />
        )}
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  salonDetails: state.booking.salonDetails,
});

export function mapDispatchToProps(dispatch: any) {
  return {
    getSalonDetails(salonId: number) {
      dispatch(getSalonDetails(salonId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(SalonRequestItem));
