// @flow

/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { fetchSalonList } from 'actions/salon';
import { selectClient } from 'actions/selection';

import Spinner from 'common/Spinner.component';
import type { Client } from 'types/client';

type Props = {
  salon: {
    isFetching: boolean,
    errorMessage: string | null,
    response: Array<Client>,
  },
  fetchSalons: () => void,
  doSelectClient: Client => void,
};

type State = {};

class SalonList extends React.Component<Props, State> {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { fetchSalons } = this.props;
    fetchSalons();
  }

  addressableName = (salons: Array<Client> | null): string => {
    let name = 'you';
    if (salons) {
      for (let i = 0, len = salons.length; i < len; i += 1) {
        if (salons[i].first_name && salons[i].first_name !== '') {
          name = salons[i].first_name;
          break;
        }
      }
    }
    return name;
  };

  render() {
    const { salon, doSelectClient } = this.props;
    const name = this.addressableName(salon.response);
    if (salon.response.length === 1) {
      doSelectClient(salon.response[0]);
    }
    return (
      <div>
        <div className="section">Re-book an Appointment</div>
        <div className="container salon-list">
          <div className="info-text">
            <div className="text-header">Welcome back {name}. Please select your salon from the options below</div>
          </div>
          {!salon.isFetching &&
            salon.response &&
            salon.response.length > 1 &&
            salon.response.map(client => (
              <Link
                to={`/history/${client.id}`}
                id="salon-link"
                className="simple-button"
                key={client.id}
                onClick={() => doSelectClient(client)}
              >
                {client.salon.name}
                <div className="text-subheader">{client.title}</div>
              </Link>
            ))}
          {!salon.isFetching && salon.response && salon.response.length === 1 && (
            // $FlowFixMe
            <Redirect
              id="salon-redirect"
              to={{
                pathname: `/history/${salon.response[0].id}`,
              }}
              push
            />
          )}
          {!salon.isFetching && salon.response && salon.response.length === 0 && (
            <div className="conditional">
              <div className="info-text" id="apology">
                <div className="text-header">We can&apos;t find any salons for you</div>
                <div className="conditional-img img_error" />
                <div className="text-subheader">
                  Please call your salon to make an appointment, and ask them to check your mobile phone number
                </div>
              </div>
            </div>
          )}
          {salon.isFetching && <Spinner variant="overlay" />}
          {!salon.isFetching && salon.errorMessage && (
            <div className="conditional">
              <div className="info-text">
                <div className="text-header">
                  Ooops, sorry!
                  <br />
                  Something went wrong!
                </div>
                <div className="conditional-img img_error" />
                <div className="text-subheader">{salon.errorMessage}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  salon: state.salon,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchSalons: () => {
    dispatch(fetchSalonList());
  },
  doSelectClient: client => {
    dispatch(selectClient(client));
  },
});

// $FlowFixMe
export default connect<any, Dispatch, Props, any, any>(mapStateToProps, mapDispatchToProps)(SalonList);
