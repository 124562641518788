// @flow

import { CREATEBOOKING_FAILURE, CREATEBOOKING_REQUEST, CREATEBOOKING_SUCCESS } from 'actions/createbooking';

import type { Appointment } from 'types/appointment';

export type CreateBookingState = {
  isFetching: boolean,
  errorMessage: string | null,
  authenticated: boolean,
  response: Appointment | null,
};

const initialState = {
  isFetching: false,
  errorMessage: null,
  authenticated: true,
  response: null,
};

function createbooking(state: CreateBookingState = initialState, action: Action) {
  switch (action.type) {
    case CREATEBOOKING_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        response: null,
        errorMessage: null,
      });
    case CREATEBOOKING_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        response: action.response,
        authenticated: action.authenticated || false,
      });
    case CREATEBOOKING_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        response: null,
        errorMessage: action.errorMessage,
      });
    default:
      return state;
  }
}

export default createbooking;
