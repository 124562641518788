// @flow

/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

// middleware/api.js

import Raven from 'raven-js';

import { API_ROOT } from 'config/index';
import parseJson from 'utils/parsers/parseJson';

import type { Dispatch } from 'types/index';

import 'whatwg-fetch';

const ERROR_NAME = 'CallException';

// default to 30s timeout to match API gateway
const fetchWithTimeout = (url, options, timeout = 30000) =>
  Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error(`fetch timeout after ${timeout / 1000}s`)), timeout)),
  ]);

const callApi = async (endpoint, authenticated, config, apiRoot, store) => {
  try {
    if (authenticated) {
      const state = store.getState();
      const token = state.cognito.user.signInUserSession.idToken.getJwtToken();

      if (token && token !== '') {
        if (config.headers) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          // eslint-disable-next-line no-param-reassign
          config.headers = { Authorization: `Bearer ${token}` };
        }
      } else {
        // eslint-disable-next-line no-throw-literal
        throw {
          name: ERROR_NAME,
          message: 'No token saved!',
          code: 500,
          body: {},
          case: 1,
        };
      }
    }
  } catch (e) {
    console.log(e);
  }

  const root = apiRoot || API_ROOT;
  const response = await fetchWithTimeout(`${root}${endpoint}`, config);

  const captureMessage = `${apiRoot}${endpoint} - ${response.statusText}`;
  let responseText = void 1;
  let responseJson = {};
  // 204 == No Content, so don't try to parse
  const responseStatus = response.status;

  if (responseStatus !== 204) {
    responseText = await response.text();

    if (response.headers.get('Content-Type') === 'application/json' || window.Cypress) {
      responseJson = parseJson(responseText);
    } else {
      if (!response.ok) {
        if (responseStatus !== 404) {
          //  404 isn't a loggable error
          Raven.captureMessage(captureMessage, { extra: { status: responseStatus, body: response.body } });
        }
        Raven.captureMessage(captureMessage, { extra: { status: responseStatus, body: response.body } });
        // eslint-disable-next-line no-throw-literal
        throw {
          name: ERROR_NAME,
          message: `Unknown error with code ${responseStatus}`,
          code: responseStatus,
          body: response.body,
          case: 2,
          response,
          responseStatus,
          responseText,
          responseJson,
        };
      }
      return {
        value: responseText,
        response,
        responseStatus,
        responseText,
        responseJson,
      };
    }
  }

  if (!response.ok) {
    // If there was a problem, we want to dispatch the error condition
    if (responseStatus !== 404) {
      Raven.captureMessage(captureMessage, { extra: { status: responseStatus, body: response.body } });
    }
    if (responseJson.detail) {
      // eslint-disable-next-line no-throw-literal
      throw {
        name: ERROR_NAME,
        message: responseJson.detail,
        code: responseStatus,
        body: responseJson,
        case: 3,
        response,
        responseStatus,
        responseText,
        responseJson,
      };
    } else if (response.statusText) {
      // eslint-disable-next-line no-throw-literal
      throw {
        name: ERROR_NAME,
        message: response.statusText,
        code: responseStatus,
        body: responseJson,
        case: 4,
        response,
        responseStatus,
        responseText,
        responseJson,
      };
    } else if (responseJson.non_field_errors) {
      // eslint-disable-next-line no-throw-literal
      throw {
        name: ERROR_NAME,
        message: responseJson.non_field_errors.join(' '),
        code: responseStatus,
        body: responseJson,
        case: 5,
        response,
        responseStatus,
        responseText,
        responseJson,
      };
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        name: ERROR_NAME,
        message: `Unknown error with code ${responseStatus}`,
        code: responseStatus,
        body: response.body,
        case: 6,
        response,
        responseStatus,
        responseText,
        responseJson,
      };
    }
  } else {
    return {
      response,
      responseStatus,
      responseText,
      responseJson,
      value: responseJson,
    };
  }
};

export const AUTH_API = 'AUTH_API';

export default (store: any) => (next: Dispatch | ((any: any) => any)) => async (action: any): Promise<*> => {
  const callAPI = action[AUTH_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') return next(action);

  const { endpoint, types, authenticated, apiRoot, params } = callAPI;

  let { config } = callAPI;
  if (!config) config = { method: 'GET', headers: { 'Content-Type': 'application/json' } };
  const [requestType, successType, errorType] = types;

  next({ type: requestType, isFetching: true, params });

  try {
    const { value, response, responseStatus, responseText, responseJson } = await callApi(
      endpoint,
      authenticated,
      config,
      apiRoot,
      store,
      next,
    );
    return next({
      responseStatus,
      responseJson,
      responseText,
      responseObject: response,
      response: value,
      authenticated,
      isFetching: false,
      type: successType,
    });
  } catch (error) {
    return next({
      errorMessage: error.message || 'Unknown Call error',
      responseStatus: error.responseStatus,
      responseJson: error.responseJson,
      responseText: error.responseText,
      responseObject: error.response,
      authenticated,
      isFetching: false,
      type: errorType,
    });
  }
};
