import React from 'react';
import cn from 'classnames';

import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import { useTheme } from 'providers/Theme.provider';

import CN from './FailedPayment.module.scss';

const FailedPayment = ({ onClose }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="FailedPayment" className={componentClasses}>
      <div className={CN.wrapper}>
        <div className={CN.header} />
        <IconStatus variant="alert">
          <Icon variant="alert" width="xl" />
        </IconStatus>
        <div className={CN.container}>
          <div className={CN.title}>We are unable to take your payment</div>
          <div className={CN.text}>
            <div className={CN.subTitle}>Your payment was not successful.</div>
          </div>
        </div>
      </div>
      <Footer data-bem="FailedPayment__footer">
        <div className={CN.footerContainer}>
          <Button variant="secondary" width="fixed" onClick={onClose}>
            CLOSE
          </Button>
        </div>
      </Footer>
    </div>
  );
};

export default FailedPayment;
