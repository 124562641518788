import THEME_MODE_NCOB from 'constants/themeModeNcob.const';
import fromAnyToTrimmed from 'utils/converters/any/fromAnyToTrimmed.util';
import withOptions from 'utils/functions/withOptions.util';
import isEnumVal from 'utils/predicates/enum/isEnumVal.util';

const determineCalendarModes = withOptions(({ ncobMode, defaultMode, relayMode }) => {
  const cm = fromAnyToTrimmed(ncobMode);

  const isDarkMode = THEME_MODE_NCOB.dark === cm;
  const isSlickMode = THEME_MODE_NCOB.slick === cm;
  const isNeutralMode = THEME_MODE_NCOB.neutral === cm;

  if (isDarkMode || isSlickMode || isNeutralMode) {
    const result = { isNeutralMode, isDarkMode, isSlickMode };
    if (relayMode) result.ncobMode = ncobMode;
    return result;
  }

  // they're all falsy, data might be missing, try the default
  if (isEnumVal(THEME_MODE_NCOB, defaultMode)) {
    const result = {
      isNeutralMode: THEME_MODE_NCOB.neutral === defaultMode,
      isDarkMode: THEME_MODE_NCOB.dark === defaultMode,
      isSlickMode: THEME_MODE_NCOB.slick === defaultMode,
    };
    if (relayMode) result.ncobMode = ncobMode;
    return result;
  }

  // even the defaultMode is missing or invalid, so we pick light
  const result = { isSlickMode: true, isDarkMode: false, isNeutralMode: false };
  if (relayMode) result.ncobMode = ncobMode;
  return result;
});
export default determineCalendarModes;
