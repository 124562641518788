import logger from '../logger.util';

const L = logger('lsClr');

const lsClr = () => {
  try {
    L.debug('()');
    return localStorage.clear();
  } catch (e) {
    L.warn('lsClr()', e);
    return null;
  }
};

// noinspection JSUnusedGlobalSymbols
export default lsClr;
