// @flow

import { AUTH_API } from '../middleware/api';

export const SALON_REQUEST = 'SALON_REQUEST';
export const SALON_SUCCESS = 'SALON_SUCCESS';
export const SALON_FAILURE = 'SALON_FAILURE';
export const SALON_DEPOSIT_REQUEST = 'SALON_DEPOSIT_REQUEST';
export const SALON_DEPOSIT_SUCCESS = 'SALON_DEPOSIT_SUCCESS';
export const SALON_DEPOSIT_ERROR = 'SALON_DEPOSIT_ERROR';

export function fetchSalonList() {
  return {
    [AUTH_API]: {
      endpoint: 'rebooking/salons/',
      authenticated: true,
      types: [SALON_REQUEST, SALON_SUCCESS, SALON_FAILURE],
    },
  };
}
