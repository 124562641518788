import getAnyOrNull from 'utils/accessors/getAnyOrNull.util';
import nullBind from 'utils/functions/nullBind.util';

export const selectCurrentSalon = nullBind(getAnyOrNull, 'booking.salonDetails');

export const selectCurrentSalonId = nullBind(getAnyOrNull, 'booking.salonDetails.id');
export const selectCurrentSalonName = nullBind(getAnyOrNull, 'booking.salonDetails.name');

export const selectCurrentSalonEmail = nullBind(getAnyOrNull, 'booking.salonDetails.email');
export const selectCurrentSalonPhone = nullBind(getAnyOrNull, 'booking.salonDetails.phone');
export const selectCurrentSalonAddressLine1 = nullBind(getAnyOrNull, 'booking.salonDetails.address_line_1');
export const selectCurrentSalonCity = nullBind(getAnyOrNull, 'booking.salonDetails.city');
export const selectCurrentSalonPostCode = nullBind(getAnyOrNull, 'booking.salonDetails.postcode');

export const selectCurrentSalonNcobMode = nullBind(getAnyOrNull, 'booking.salonDetails.ncob_mode');

export const selectCurrentSalonCanTakeVoucher = nullBind(
  getAnyOrNull,
  'booking.salonDetails.can_take_voucher_payments',
);
export const selectCurrentSalonStripeVoucherLink = nullBind(getAnyOrNull, 'booking.salonDetails.stripe_voucher_link');
