const CONFIRM_WITH_CARD_CAPTURE_MESSAGES = Object.freeze({
  confirm1: 'Aborting due to positive xtraAuthenticationDone flag',
  confirm2: `Aborting due to payment intent status different from 'succeeded'`,
  confirm3: 'Aborting due to positive confirmAuthenticationSend flag',
  confirm4: 'Online booking confirmed with BE',
  check3d1: 'Aborting due to positive isWaiting flag',
  check3d2: 'Advancing to bookingConfirmed screen due to positive result',
  submit1: 'Attempting to create a booking',
  submit2: 'Create a booking failed',
  submit3: 'Create a booking succeeded',
  submit4: 'Create a booking failed with an error',
  checkstat1: 'No bookingResponse (yet)',
  checkstat2: "Received booking response with payment intent status different from 'succeeded'",
  checkstat3: `Received booking response with payment intent status of 'succeeded'`,
  checkstat4: 'Aborting due to booking error',
  checkstat5: "Advancing to confirmCardAuthentication due to payment intent status of 'requires_action'",
});

// noinspection JSUnusedGlobalSymbols
export default CONFIRM_WITH_CARD_CAPTURE_MESSAGES;
