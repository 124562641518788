import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import LOGO_SLICK from 'assets/images/Slick-logo.svg';
import LOGO_SLICK_TEXT from 'assets/images/Slicklogo.svg';
import { withTheme } from 'providers/Theme.provider';

import CN from './SalonPickerHeader.module.scss';

const SalonPickerHeader = props => {
  const { salonImage = '', title, isDarkMode, isNeutralMode } = props;

  const [logo, setLogo] = useState(salonImage || LOGO_SLICK);

  useEffect(() => {
    if (salonImage !== props.salonImage) {
      if (salonImage) {
        setLogo(salonImage);
      } else {
        setLogo(LOGO_SLICK);
      }
    }
  }, [props.salonImage, salonImage]);

  const logoUrl = {
    backgroundImage: `url("${logo}")`,
  };

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="SalonPickerHeader" className={componentClasses}>
      <header>
        <div className={CN.gradientContainer} data-bem="SalonPickerHeader__gradient" />
        <div className={CN.panel}>
          <div className={CN.logoContainer}>
            <span className={CN.salonLogo} style={logo && logoUrl} />
          </div>

          <div className={CN.logoText}>
            <span>Powered by</span>
            <img src={LOGO_SLICK_TEXT} alt="Slick logo" className={CN.slickLogo} />
          </div>
        </div>
        <span className={CN.title}>Welcome to {title}</span>
      </header>
    </div>
  );
};

const mapStateToProps = state => {
  const { booking, cognito } = state;
  const { salonDetails } = booking;
  return {
    salonDetails,
    salonImage: salonDetails.image,
    cognito,
  };
};

export default connect(mapStateToProps)(withTheme()(SalonPickerHeader));
