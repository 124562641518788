import moment from 'moment';

import fromMomentToTimestamp from 'utils/converters/time/fromMomentToTimestamp.util';
import logger from 'utils/logger.util';
import isMoment from 'utils/predicates/isMoment.util';
import isNotDateWorthy from 'utils/predicates/time/isNotDateWorthy.util';

const L = logger('fromAnyDateToTimestamp');

const fromAnyDateToTimestamp = any => {
  try {
    if (Number.isSafeInteger(any)) return any;
    if (isNotDateWorthy(any)) return NaN;

    return fromMomentToTimestamp(isMoment(any) ? any : moment(any));
  } catch (e) {
    L.warn('()', e);
    return NaN;
  }
};

export default fromAnyDateToTimestamp;
