// @flow
import moment from 'moment';

import { AVAILABILITY_FAILURE, AVAILABILITY_REQUEST, AVAILABILITY_SUCCESS } from '../actions/availability';

export type AvailableSlot = string[];

export type AvailabilityState = {
  isFetching: boolean,
  errorMessage: string | null,
  authenticated: boolean,
  days: {},
  response: AvailableSlot,
};

const initialState = {
  isFetching: false,
  errorMessage: null,
  authenticated: false,
  days: {},
  response: [],
};

function availabilityByDay(list: AvailableSlot): {} {
  const days = {};
  for (let i = 0, len = list.length; i < len; i += 1) {
    const slot = list[i];
    const dayStamp = moment(slot).format('YYYYMMDD');
    if (!days[dayStamp]) {
      days[dayStamp] = [];
    }
    days[dayStamp].push(slot);
  }
  return days;
}

function availability(state: AvailabilityState = initialState, action: Action) {
  switch (action.type) {
    case AVAILABILITY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        response: [],
        errorMessage: null,
      });
    case AVAILABILITY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        response: action.response,
        days: availabilityByDay(action.response),
        authenticated: action.authenticated || false,
      });
    case AVAILABILITY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.errorMessage,
      });
    default:
      return state;
  }
}

export default availability;
