// @flow

import { API_ROOT, ENVIRONMENT } from 'config/index';
import { AUTH_API } from 'middleware/api';

import type { Dispatch } from '../types';

export const SMS_REQUEST = 'SMS_REQUEST';
export const SMS_SUCCESS = 'SMS_SUCCESS';
export const SMS_FAILURE = 'SMS_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const GET_VERSION_REQUEST = 'GET_VERSION_REQUEST';
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
export const GET_VERSION_FAILURE = 'GET_VERSION_FAILURE';

export type SmsRequestAction = {
  type: typeof SMS_REQUEST,
  isFetching: boolean,
  isAuthenticated: boolean,
  phone: string,
};

export type SmsSuccessAction = {
  type: typeof SMS_SUCCESS,
  isFetching: boolean,
  isAuthenticated: boolean,
  response: any,
};

export type SmsFailureAction = {
  type: typeof SMS_FAILURE,
  isFetching: boolean,
  isAuthenticated: boolean,
  errorMessage: string,
};

export function requestAuthenticationSMS(number: string) {
  return {
    [AUTH_API]: {
      endpoint: 'rebooking/auth_request/',
      authenticated: false,
      config: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone: number }),
      },
      types: [SMS_REQUEST, SMS_SUCCESS, SMS_FAILURE],
    },
  };
}

export type LoginRequestAction = {
  type: 'LOGIN_REQUEST',
  isFetching: boolean,
  isAuthenticated: boolean,
  urlhash: string,
};

function requestLogin(urlhash: string): LoginRequestAction {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    urlhash,
  };
}

export type LoginSuccessAction = {
  type: 'LOGIN_SUCCESS',
  isFetching: boolean,
  isAuthenticated: boolean,
  token: string | null,
};

function receiveLogin(user): LoginSuccessAction {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token: user.token,
  };
}

export function fetchGithub() {
  return {
    [AUTH_API]: {
      api_root: API_ROOT,
      endpoint: `latest/release/ui.booking/${ENVIRONMENT}/`,
      authenticated: false,
      types: [GET_VERSION_REQUEST, GET_VERSION_SUCCESS, GET_VERSION_FAILURE],
      config: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  };
}

export type LoginFailureAction = {
  type: 'LOGIN_FAILURE',
  isFetching: boolean,
  isAuthenticated: boolean,
  message: string,
};

function loginError(message): LoginFailureAction {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

export type LogoutRequestAction = {
  type: 'LOGOUT_REQUEST',
  isFetching: boolean,
  isAuthenticated: boolean,
};

function requestLogout(): LogoutRequestAction {
  return { type: LOGOUT_REQUEST, isFetching: true, isAuthenticated: true };
}

export type LogoutSuccessAction = {
  type: 'LOGOUT_SUCCESS',
  isFetching: boolean,
  isAuthenticated: boolean,
};

function receiveLogout(): LogoutSuccessAction {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
    token: null,
  };
}

// Calls the API to get a token and dispatches actions along the way
export function loginUser(urlhash: string) {
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const url = `${API_ROOT}rebooking/token/${urlhash}/`;

  return async (dispatch: Dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(urlhash));

    try {
      const response = await fetch(url, config);
      const responseJson = await response.json();
      if (!response.ok) {
        // If there was a problem, we want to dispatch the error condition
        if (responseJson.detail) {
          dispatch(loginError(responseJson.detail));
        } else {
          dispatch(loginError(responseJson.message));
        }
        return responseJson;
      }
      // Dispatch the success action
      dispatch(receiveLogin(responseJson));
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('Fetch-Error: ', error);
      }
      if (error.message) {
        dispatch(loginError(error.message));
      } else {
        dispatch(loginError(error));
      }
    }
    return null;
  };
}

// Logs the user out
export function logoutUser() {
  return (dispatch: Dispatch) => {
    dispatch(requestLogout());
    dispatch(receiveLogout());
  };
}
