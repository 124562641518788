import React from 'react';

import Button from 'booking/common/Button';

const Footer = ({ onBtnClick, isButtonDisabled, btnName, children }) => (
  <footer className="app-footer">
    <Button btnName={btnName} onBtnClick={onBtnClick} disabled={isButtonDisabled} />
    {children}
  </footer>
);

export default Footer;
