// @flow

import { API_ROOT } from 'config/index';
import { AUTH_API } from 'middleware/api';

export const VERIFYBOOKING_REQUEST = 'VERIFYBOOKING_REQUEST';
export const VERIFYBOOKING_SUCCESS = 'VERIFYBOOKING_SUCCESS';
export const VERIFYBOOKING_FAILURE = 'VERIFYBOOKING_FAILURE';
export const OPT_OUT_MARKETING_SMS_REQUEST = 'OPT_OUT_MARKETING_SMS_REQUEST';
export const OPT_OUT_MARKETING_SMS_SUCCESS = 'OPT_OUT_MARKETING_SMS_REQUEST';
export const OPT_OUT_MARKETING_SMS_FAILURE = 'OPT_OUT_MARKETING_SMS_REQUEST';

export function verifyBooking(salonId: string, uniqcode: string) {
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint: `book-online/verify/${salonId}/${uniqcode}/`,
      authenticated: false,
      config: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      types: [VERIFYBOOKING_REQUEST, VERIFYBOOKING_SUCCESS, VERIFYBOOKING_FAILURE],
    },
  };
}

export function optOutMarketingSMS(salonId: string, uniqcode: string) {
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint: `salon/v2/${salonId}/marketing/sms/opt-out/verify/${uniqcode}/`,
      authenticated: false,
      config: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      types: [OPT_OUT_MARKETING_SMS_REQUEST, OPT_OUT_MARKETING_SMS_SUCCESS, OPT_OUT_MARKETING_SMS_FAILURE],
    },
  };
}
