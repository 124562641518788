import { useCallback, useMemo, useState } from 'react';

import useIsMounted from 'hooks/useIsMountedRef.hook';

const useStateWhileMounted = initialState => {
  const isMountedRef = useIsMounted();
  const [val, setVal] = useState(initialState);

  const set = useCallback(
    (...args) => {
      if (!isMountedRef.current) return;
      setVal(...args);
    },
    [isMountedRef],
  );

  return useMemo(() => [val, set], [val, set]);
};

// noinspection JSUnusedGlobalSymbols
export default useStateWhileMounted;
