// @flow

import moment from 'moment';
import Raven from 'raven-js';

import ENV from './env.conf';

// TODO: FIX THIS AFTER SERVER IS PUSHED TO STAGING
// eslint-disable-next-line no-unused-vars

let apiRoot = '';
let segmentWriteKey = '';
let ncobStripeKey = '';
let cognitoConfig;
let environment = 'development';
let pusherId = '';

switch (ENV.NODE_ENV) {
  case 'development':
    apiRoot = 'https://staging-core.api.getslick.com/';
    // apiRoot = 'http://localhost:8000/';
    segmentWriteKey = 'TzKe9BGgbsyNGUeEvBtSYgAkoeQjaZTM';
    ncobStripeKey = 'pk_test_m60xtyW3fzI818qGUqy3jRwB00AbyH4NY3';
    environment = 'development';
    cognitoConfig = {
      region: 'eu-west-1',
      userPool: 'eu-west-1_P7dIDYnHf',
      identityPool: 'eu-west-1:8a9cddb3-f4b1-4a8f-b7b8-896075a14269',
      clientId: 'j7dn68p54nis13kdlsm0t7h2q',
    };
    pusherId = '11ea0d95bbfdba6c003f';
    break;
  case 'production':
    switch (ENV.REACT_APP_ENV) {
      case 'staging':
        apiRoot = 'https://staging-core.api.getslick.com/';
        segmentWriteKey = 'TzKe9BGgbsyNGUeEvBtSYgAkoeQjaZTM';
        ncobStripeKey = 'pk_test_m60xtyW3fzI818qGUqy3jRwB00AbyH4NY3';
        environment = 'staging';
        cognitoConfig = {
          region: 'eu-west-1',
          userPool: 'eu-west-1_P7dIDYnHf',
          identityPool: 'eu-west-1:8a9cddb3-f4b1-4a8f-b7b8-896075a14269',
          clientId: 'j7dn68p54nis13kdlsm0t7h2q',
        };
        break;
      default:
        // this is production
        apiRoot = 'https://core.api.getslick.com/';
        segmentWriteKey = 'WZkwV5pXMjyWieh5Aq2KlGjA4lblHcYw';
        Raven.config('https://d813e0179b784103b32d63144765da4a@sentry.io/227792').install();
        ncobStripeKey = 'pk_live_4Al1bQKKIDcj7XRKSV62DZnU00WSrMgtmG';
        environment = 'master';
        cognitoConfig = {
          region: 'eu-west-1',
          userPool: 'eu-west-1_IroKNz5pH',
          identityPool: 'eu-west-1:3a8ddc8c-5883-4828-b662-f852c583ffbd',
          clientId: '1afm689to5v127e8muadcl5plv',
        };
        pusherId = '11ea0d95bbfdba6c003f';
        break;
    }
    break;
  case 'test':
    apiRoot = 'http://127.0.0.1:8000/';
    environment = 'test';
    break;
  default:
    if (ENV.NODE_ENV) {
      // eslint-disable-next-line no-console
      console.log(`Unknown environment ${ENV.NODE_ENV}`);
    } else {
      // eslint-disable-next-line no-console
      console.log('process.env.NODE_ENV not defined');
    }
}

// apiRoot = 'http://127.0.0.1:8000/';
export const API_ROOT = apiRoot; // 'https://p2jhfy5nv5.execute-api.eu-west-1.amazonaws.com/dev/'; //apiRoot;
export const SEGMENT_WRITE_KEY = segmentWriteKey;
export const GET_FIRST_AVAILABLE_DATE = true;
export const NCOB_STRIPE_KEY = ncobStripeKey;
export const COGNITO_CONFIG = cognitoConfig;
export const ENVIRONMENT = environment;
export const PUSHER_ID = pusherId;
// set moment threshold duration to 120 minutes to display total duration in hours when exceeding 120 mins
// $FlowFixMe flow-typed doesn't include the relativeTimeThreshold defintion
moment.relativeTimeThreshold('m', 120);
