import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';

import SLICK from 'config/slick.singleton';

window.SLICK = SLICK;

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<App />, SLICK.root);
