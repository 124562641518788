import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import CreateAccountInviteForm from 'auth/login/CreateAccountInvite';
import LoginInviteForm from 'auth/login/LoginInvite';
import PrivateRoute from 'auth/PrivateRoute';
import { ConnectedRouter } from 'connected-react-router';
import AppointmentTime from 'containers/AppointmentTime';
import Authenticate from 'containers/Authenticate';
import Confirm from 'containers/Confirm';
import HistoryList from 'containers/HistoryList';
import Login from 'containers/Login';
import NotFound404 from 'containers/NotFound404';
import SalonList from 'containers/SalonList';
import Success from 'containers/Success';
import Verification from 'containers/Verification';
import SalonPickerComp from 'group/SalonPicker';
import HISTORY from 'history.singleton';
import STORE from 'store.singleton';

import CookieBanner from 'components/CookieBanner.component';
import TrackingPixel from 'components/TrackingPixel.component';
import LSKEY from 'constants/lskey.const';
import SegmentConsent from 'middleware/SegmentConsentManager';
import { DispatcherProvider } from 'providers/Dispatcher.provider';
import { SalonBasicInfoProvider } from 'providers/SalonBasicInfo.provider';
import { ThemeProvider, useNcobMode, useTheme, withTheme } from 'providers/Theme.provider';
import lsClr from 'utils/dom/lsClr.util';
import lsGet from 'utils/dom/lsGet.util';
import lsSet from 'utils/dom/lsSet.util';

import BookingComponent from 'booking/BookingWrap';

import 'styles/App.scss';

// NOTE: this used to be localStorage.clear() which is a nuclear option - clears things we want to keep
// eslint-disable-next-line no-lone-blocks
{
  const KEYS = Object.freeze([LSKEY.cookies]);
  const keep = Object.create(null);

  for (const key of KEYS) {
    keep[key] = lsGet(key);
  }

  lsClr();

  for (const key of KEYS) {
    lsSet(key, keep[key]);
  }
}

const reload = () => window.location.reload();

// TODO: delete this ThemeTestFunctional once there are other uses of useTheme and/or useNcobMode in this project
const ThemeTestFunctional = () => {
  const theme = useTheme();
  const mode = useNcobMode();
  // eslint-disable-next-line no-console
  console.log('ThemeTestFunctional()', 'mode:', mode, 'theme:', theme);
  return null;
};

// TODO: delete this ThemeTestFunctional once there are other uses of withTheme in this project
const ThemeTestClassical = withTheme()(
  // eslint-disable-next-line react/prefer-stateless-function
  class InnerThemeTestClassical extends React.Component {
    render() {
      // eslint-disable-next-line no-console
      console.log('ThemeTestClassical()', 'props:', this.props);
      return null;
    }
  },
);

const App = () => (
  <Provider store={STORE}>
    <ConnectedRouter history={HISTORY}>
      <DispatcherProvider store={STORE}>
        <SalonBasicInfoProvider>
          <ThemeProvider>
            <ThemeTestFunctional />
            <ThemeTestClassical />
            <div data-bem="App">
              <SegmentConsent />
              <CookieBanner />
              <TrackingPixel />
              <Switch>
                <Route path="/.well-known/apple-developer-merchantid-domain-association" onEnter={reload} />
                <Route exact path="/" component={Login} />
                <Route path="/login/:id/:uniqcode" component={LoginInviteForm} />
                <Route path="/invite/:id/:uniqcode/" component={CreateAccountInviteForm} />
                <Route path="/auth/:urlhash" component={Authenticate} />
                <PrivateRoute exact path="/salon" component={SalonList} />
                <Route path="/salon/:id" component={BookingComponent} />
                <PrivateRoute path="/history/:client_id" component={HistoryList} />
                <PrivateRoute path="/time/:booking_id" component={AppointmentTime} />
                <PrivateRoute path="/confirm/:booking_id" component={Confirm} />
                <PrivateRoute path="/end" component={Success} />
                <Route path="/verify/:id/:code" component={Verification} />
                <Route path="/opt-out/verify/:id/:code" component={Verification} />
                <Route path="/group/:id/" component={SalonPickerComp} />
                <Route path="/" component={NotFound404} />
              </Switch>
            </div>
          </ThemeProvider>
        </SalonBasicInfoProvider>
      </DispatcherProvider>
    </ConnectedRouter>
  </Provider>
);

export default App;
