import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import STORE from 'store.singleton';

const PrivateRoute = ({ component: InputComponent, isAuthenticated, ...rest }) => {
  if (isAuthenticated) {
    return <Route {...rest} render={props => <InputComponent {...props} />} />;
  }
  const state = STORE.getState();
  const salonId = state.booking.salonDetails.id;

  return (
    <Route
      {...rest}
      render={props => (
        <Redirect
          to={{
            pathname: `/salon/${salonId}`,
            state: { from: props.location },
          }}
        />
      )}
    />
  );
};

function mapStateToProps(state) {
  const { isAuthenticated, isFetching } = state.login;
  return { isAuthenticated, isFetching };
}

export default withRouter(connect(mapStateToProps)(PrivateRoute));
