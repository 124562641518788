import withOptions from 'utils/functions/withOptions.util';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';
import isFn from 'utils/predicates/function/isFn.util';

const L = logger('batchValidate');

// NOTE: some fields may have sensitive info, like passwords, so turn this off in production environment
const USE_LOG = false;

const determineKeys = withOptions(options => {
  // if the keys are already an array, use that
  if (Array.isArray(options.keys)) return options.keys;

  // otherwise it may be a dictionary, in which case, we need their values
  if (options.keys) return Object.values(options.keys);

  // these should be all the validators, so may as well use their keys
  if (options.validators) return Object.keys(options.validators);

  // last option is to match the keys of the existing fields, may not be as complete as using options.keys
  return Object.keys(options.fields);
});

const batchValidate = withOptions(options => {
  try {
    const validators = asObject(options.validators);
    const fields = asObject(options.fields);
    const keys = determineKeys(options);

    if (USE_LOG) L.debug('()', { options, keys, fields, validators });

    // will try to loop over each key from fields and find a validator to use for it
    for (const key of keys) {
      const validate = validators[key];
      const value = fields[key];

      // if there is a validator, it must pass, otherwise we continue to the next
      if (isFn(validate) && !validate({ value })) {
        if (USE_LOG) L.debug('()', 'Invalid value:', value, 'for', { key, validators, fields });
        return false;
      }
    }

    // if execution reached here, it means the validators didn't complain, that's good
    return true;
  } catch (e) {
    L.warn('()', e);
    return false;
  }
});

// noinspection JSUnusedGlobalSymbols
export default batchValidate;
