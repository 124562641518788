import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';

import CN from './Footer.module.scss';

const Footer = ({ children }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="Footer" className={componentClasses}>
      {children}
    </div>
  );
};

export default Footer;
