const create = (locale, currency) =>
  new Intl.NumberFormat(locale, { style: 'currency', currency });

// SEE: https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
const LOCALE = Object.freeze({
  uae: 'ar-AE',
  deu: 'de-DE',
  gbr: 'en-GB',
  usa: 'en-US',
});

// SEE: https://en.wikipedia.org/wiki/List_of_circulating_currencies
const ABBREVIATION = Object.freeze({
  aed: 'AED',
  eur: 'EUR',
  gbp: 'GBP',
  usd: 'USD',
});

const AED_UAE_FORMATTER = create(LOCALE.uae, ABBREVIATION.aed);
const EUR_DEU_FORMATTER = create(LOCALE.deu, ABBREVIATION.eur);
const GBP_GBR_FORMATTER = create(LOCALE.gbr, ABBREVIATION.gbp);
const USD_USA_FORMATTER = create(LOCALE.usa, ABBREVIATION.usd);

const MAP = Object.freeze({
  [ABBREVIATION.eur]: EUR_DEU_FORMATTER,
  [LOCALE.deu]: EUR_DEU_FORMATTER,

  [ABBREVIATION.aed]: AED_UAE_FORMATTER,
  [LOCALE.uae]: AED_UAE_FORMATTER,

  [ABBREVIATION.usd]: USD_USA_FORMATTER,
  [LOCALE.usa]: USD_USA_FORMATTER,

  [ABBREVIATION.gbp]: GBP_GBR_FORMATTER,
  [LOCALE.gbr]: GBP_GBR_FORMATTER,

  // more abbreviations and formatters can be added here
});

const currency = ({ value, abbreviation, locale }) => {
  const formatter = MAP[abbreviation] || MAP[locale];
  if (formatter) {
    return formatter.format(value);
  }

  return GBP_GBR_FORMATTER.format(value);
};

export default currency;
