export default () => next => async action => {
  if (action.type !== '@@router/LOCATION_CHANGE') {
    return next(action);
  }

  const { analytics } = window;
  if (!analytics) {
    return next(action);
  }

  const {
    referrer,
    location: { href, origin },
  } = document;

  if (href.indexOf('verify') < 0) {
    const id = parseInt(href.split('#/salon/')[1], 10).toString();
    const ref = referrer.indexOf(origin) === 0 ? '' : referrer;
    analytics.track(`salon${id}_page ${action.payload.pathname}`, { category: `salon${id}_${ref}`, label: id });
  } else {
    const id = parseInt(href.split('/verify/')[1], 10).toString();
    analytics.page({ title: `salon${id}_verify_booking`, path: `salon${id}` });
  }

  return next(action);
};
