import ATS from 'constants/actionTypeSuffix.const';
import fromAnyToString from 'utils/converters/fromAnyToString';

const actionType = prefix => {
  const name = fromAnyToString(prefix);

  return Object.freeze({
    name,
    // normal fetch operations
    init: name + ATS.init,
    pass: name + ATS.pass,
    fail: name + ATS.fail,
    done: name + ATS.done,
    // pusher communication
    push: name + ATS.push,
  });
};

// noinspection JSUnusedGlobalSymbols
export default actionType;
