import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import SalonPickerHeader from 'group/SalonPickerHeader.component';
import { bindActionCreators } from 'redux';

import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import Icon from 'components/Icon.component';
import { withTheme } from 'providers/Theme.provider';

import { fetchSalonGroupsList } from 'booking/actions';

import CN from './SalonPicker.module.scss';

const SalonPicker = props => {
  const { salonDetails, fetchSalonGroupsList, isFetching, groupList, utm, isDarkMode, isNeutralMode } = props;

  useEffect(() => {
    let group = salonDetails && salonDetails.group;
    const fetchGroupData = () => {
      if (window.location.href.indexOf('group') > 0) {
        group = parseInt(window.location.href.split('group/')[1], 10);
      }
      if (group) {
        fetchSalonGroupsList(group);
      }
    };

    fetchGroupData();
    const timeoutId = setTimeout(fetchGroupData, 1000);

    return () => clearTimeout(timeoutId);
  }, [salonDetails, fetchSalonGroupsList]);

  return (
    <div
      data-bem="SalonPicker"
      className={cn({
        [CN.component]: true,
        [CN.dark]: isDarkMode,
        [CN.neutral]: isNeutralMode,
      })}
    >
      <div className={CN.wrapper}>
        {!isFetching && (
          <div>
            <SalonPickerHeader title={groupList.name ? groupList.name : 'Welcome'} />
            <div className={CN.title}>
              <div className={CN.icon}>
                <Icon variant="pin" width="lg" />
              </div>
              Choose a location to book:
            </div>
            <div className={CN.container}>
              {groupList.salons &&
                groupList.salons.map(s => {
                  let url = s.ncob_tiny_url;

                  if (utm) {
                    url = `${url}${utm}`;
                  }
                  return (
                    <a className={CN.item} key={s.id} href={url} target="blank" data-bem="SalonPicker__item">
                      <div className={CN.flex}>
                        <div className={CN.salonName}>{s.name}</div>
                      </div>
                      <div className={CN.arrow}>
                        <img src={IMG_ARROW} alt="Arrow to salon location" className={CN.arrowImg} />
                      </div>
                    </a>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { booking } = state;
  const { salonDetails, groupList, utm, isFetching, salonDetailsList } = booking;
  return {
    salonDetails,
    groupList,
    utm,
    isFetching,
    salonDetailsList,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSalonGroupsList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(SalonPicker));
