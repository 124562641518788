// @flow

import { AUTH_API } from '../middleware/api';

export const HISTORY_REQUEST = 'HISTORY_REQUEST';
export const HISTORY_SUCCESS = 'HISTORY_SUCCESS';
export const HISTORY_FAILURE = 'HISTORY_FAILURE';

export function fetchHistoryList(clientId: string) {
  return {
    [AUTH_API]: {
      endpoint: `rebooking/history/${clientId}/appointments/`,
      authenticated: true,
      types: [HISTORY_REQUEST, HISTORY_SUCCESS, HISTORY_FAILURE],
    },
  };
}
