// @flow

/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone/moment-timezone';

import SliderComponent from 'booking/common/CalendarSlider/Slider';
import ErrorPopupComponent from 'booking/common/ErrorPopup';
import TimeItem from 'booking/common/TimeItem';
import type { SalonDetails } from 'types/salonDetails';
import type { Stylist } from 'types/stylistsForService';

import CN from './PatchTimeSelection.module.scss';

type Props = {
  isFetching: boolean,
  availablePatchDays: {
    [time: string]: boolean,
  },
  check_ncob_availability_until: number,
  availablePatchSlots: any,
  firstAvailablePatchDate: moment | null,
  selectedTime: moment | null,
  skip_today?: boolean,
  errorMessage: string,
  salonDetails: SalonDetails,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  appointment_id?: number,
  clearAvailableSlots: () => void,
  clearAvailablePatchDays: () => void,
  getPatchAvailability: (appointmentDate: moment) => void,
  getPatchWeeklyAvailability: (appointmentDate: moment, check: number) => void,
  getPatchWeeklyAvailabilityRepeat: (appointmentDate: moment) => void,
  setTime: (appointmentTime: moment, id?: any) => void,
  updateFirstAvailableDate: (date: moment | null) => void,
  updateFirstAvailablePatchDate: (date: moment | null) => void,
};

type State = {
  selectedDate: moment | null,
  disableNextArrow: boolean,
};

export class PatchTimeSelection extends React.Component<Props, State> {
  static defaultProps = { skip_today: false, appointment_id: 0 };
  constructor(props: Props) {
    super(props);
    let selectedDate = null;
    if (props.selectedTime !== null) {
      selectedDate = props.selectedTime;
    }
    this.state = {
      selectedDate,
      disableNextArrow: false,
      // isFetching: false,
    };
  }
  componentWillReceiveProps(nextProps: Props) {
    if (
      Object.keys(nextProps.availablePatchDays).length === 1 &&
      Object.values(nextProps.availablePatchDays)[0].indexOf('Exceeds') >= 0
    ) {
      this.setState({ disableNextArrow: true });
    } else {
      this.setState({ disableNextArrow: false });
    }
  }
  onCheckboxClick = (time, styId) => {
    this.props.setTime(moment(time), styId);
  };
  getPatchAvailability = (appointmentDate: moment) => {
    this.setState({ selectedDate: appointmentDate });
    this.props.getPatchAvailability(appointmentDate);
  };
  getPatchWeeklyAvailability = (appointmentDate: moment, check: number) => {
    this.setState({ selectedDate: appointmentDate });
    this.props.getPatchWeeklyAvailability(appointmentDate, check);
  };
  getPatchWeeklyAvailabilityRepeat = (appointmentDate: moment) => {
    this.setState({ selectedDate: appointmentDate });
    this.props.getPatchWeeklyAvailabilityRepeat(appointmentDate);
  };
  sortFnc = (s1, s2) => {
    if (moment(s1[0]).isBefore(moment(s2[0]))) {
      return -1;
    } else if (moment(s1[0]).isAfter(moment(s2[0]))) {
      return 1;
    }
    return 0;
  };
  render() {
    let { errorMessage, availablePatchSlots, salonDetails } = this.props;
    const { timezone: timeZone } = salonDetails || {};
    let slots = [];
    let sortedSlots = [];
    if (availablePatchSlots) {
      slots = Object.entries(this.props.availablePatchSlots);
      sortedSlots = slots.sort(this.sortFnc);
    }
    let mappedSlots = [];
    if (slots) {
      mappedSlots = sortedSlots.map((slot, i) => {
        let checked = false;
        if (this.props.selectedTime && moment(this.props.selectedTime).isSame(slot[0])) {
          checked = true;
        }
        return (
          <TimeItem
            checkId={`cb${i}`}
            ItemTime={slot[0]}
            itemStylistId={slot[1]}
            timezone={timeZone}
            onCheckboxClick={this.onCheckboxClick}
            key={slot[0]}
            checked={checked}
            optionsCount={1}
            hasMore={false}
          />
        );
      });
    }

    return (
      <div>
        {document.location.href.indexOf('patchtimeselect') > 0 && (
          <SliderComponent
            firstAvailablePatchDate={this.props.firstAvailablePatchDate}
            skip_today={this.props.skip_today}
            appointment_id={this.props.appointment_id}
            getPatchAvailability={this.getPatchAvailability}
            getPatchWeeklyAvailability={this.getPatchWeeklyAvailability}
            getPatchWeeklyAvailabilityRepeat={this.getPatchWeeklyAvailabilityRepeat}
            errorMessage={errorMessage}
            selectedDate={this.state.selectedDate}
            availablePatchDays={this.props.availablePatchDays}
            clearAvailablePatchDays={this.props.clearAvailablePatchDays}
            clearAvailableSlots={this.props.clearAvailableSlots}
            salonDetails={salonDetails}
            availablePatchSlots={this.props.availablePatchSlots}
            availableSlots={[]}
            isFetching={this.props.isFetching}
            updateFirstAvailableDate={this.props.updateFirstAvailableDate}
            updateFirstAvailablePatchDate={this.props.updateFirstAvailablePatchDate}
            check_ncob_availability_until={this.props.check_ncob_availability_until}
            disableNextArrow={this.state.disableNextArrow}
          />
        )}
        {this.props.isFetching && (
          <div className="spinner-container">
            <div className="load-spinner" />
          </div>
        )}
        {Object.values(this.props.availablePatchDays).length === 1 &&
          Object.values(this.props.availablePatchDays)[0].indexOf('Exceeds') >= 0 && (
            <div style={{ paddingTop: '20px' }}>
              <p>
                {`We checked until ${moment()
                  .add(this.props.check_ncob_availability_until, 'd')
                  .format('Do MMMM YYYY')}.`}
              </p>
              <p>
                Please go back and select a different staff member to check their availability, or try again tomorrow.
                Alternatively, give us a call on {salonDetails.phone}
              </p>
            </div>
          )}
        {mappedSlots.length > 0 && <span className={CN.title}>Times below are in {timeZone} timezone</span>}
        {!this.props.isFetching && <div className={CN.containerTimeSlot}>{mappedSlots.length > 0 && mappedSlots}</div>}
        {errorMessage && <ErrorPopupComponent message={errorMessage} />}
        {!mappedSlots.length && <div className="cta">Click on a day that you wish to come in.</div>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { booking } = state;
  const {
    salonDetails,
    isFetching,
    availablePatchSlots,
    errorMessage,
    selectedStylists,
    selectedServices,
    bookingResponse,
    availablePatchDays,
  } = booking;
  return {
    salonDetails,
    isFetching,
    availablePatchSlots,
    errorMessage,
    selectedStylists,
    selectedServices,
    availablePatchDays,
    appointment_id: bookingResponse ? bookingResponse.appointment_id : null,
  };
};

export default connect(mapStateToProps)(PatchTimeSelection);
