// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { optOutMarketingSMS, verifyBooking } from 'actions/verifybooking';

import { getSalonDetails } from 'booking/actions';
import HeaderComponent from 'booking/common/Header';
import type { SalonDetails } from 'types/salonDetails';

type Props = {
  match: {
    isExact: boolean,
    path: string,
    url: string,
    params: {
      client_id: string,
    },
  },
  salonDetails: SalonDetails,
  isFetching: boolean,
  bookingStatus: string,
  errorMessage: string,
  verifyBooking: (salonId: string, uniqcode: string) => void,
  optOutMarketingSMS: (salonId: string, uniqcode: string) => void,
  getSalonDetails: (salonId: number) => void,
};

/* type State = {
  bookingStatus: string,
}; */

class Verification extends React.Component<Props> {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    let salonId: string = '';
    if (!this.props.salonDetails.id) {
      const arr = document.location.href.split('/');
      salonId = arr[arr.length - 2].toString();
      this.props.getSalonDetails(parseInt(salonId, 10));
    }
    if (document.location.href.indexOf('opt-out') < 0) {
      if (salonId !== '' && this.props.bookingStatus === '') {
        this.verifyBooking(salonId);
      }
    } else if (salonId !== '') {
      this.optOutMarketingSMS();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  verifyBooking = (salonId: string) => {
    const arr = document.location.href.split('/');
    const uniqcode = arr[arr.length - 1];
    this.props.verifyBooking(salonId, uniqcode);
  };
  optOutMarketingSMS = () => {
    const reCode = /opt-out\/verify\/(\d+)\/(.*)/;
    const matches = document.location.href.match(reCode);
    if (matches && matches.length > 2) {
      const salonIdMatch = matches[1];
      const uniqcode = matches[2];
      this.props.optOutMarketingSMS(salonIdMatch, uniqcode);
    }
  };
  translateErrorMessage = () => `Sorry, we have a problem trying to get the information about your booking.
  Please try again later.`;

  render() {
    const isMarketingOptOut = document.location.href.indexOf('opt-out') !== -1;
    if (isMarketingOptOut) {
      // TODO: Need to add reducer states for the opt out request here.
      // Probably better to refactor this into a separate route
      return (
        <div>
          <div className="app-content-verify">
            <HeaderComponent title="Verifying Your Opt Out" aditionalClass="small-title" />
          </div>
          <div className="container confirm">
            <div className="info-confirmed">
              <div className="text-header">We have requested your opt out of marketing messages.</div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        {!this.props.isFetching && (
          <div className="app-content-verify">
            <HeaderComponent title="Verifying Your Booking" aditionalClass="small-title" />
          </div>
        )}
        <div className="container confirm">
          {this.props.bookingStatus === 'verified' && this.props.bookingStatus !== 'failed' && (
            <div className="info-confirmed">
              <div className="text-header">Your Booking has been confirmed.</div>
            </div>
          )}
          {this.props.errorMessage && this.props.bookingStatus !== 'verified' && (
            <div className="info-cancelled">
              <div className="text-header">Sorry, we are unable to find your Booking</div>
            </div>
          )}
        </div>
        {this.props.isFetching && (
          <div className="spinner-container">
            <div className="load-spinner" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  client: state.selection.client,
  appointment: state.selection.appointment,
  bookingStatus: state.verifybooking.bookingStatus,
  isFetching: state.booking.isFetching,
  salonDetails: state.booking.salonDetails,
  errorMessage: state.verifybooking.errorMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
  verifyBooking: (salonId: string, uniqcode: string) => {
    dispatch(verifyBooking(salonId, uniqcode));
  },
  getSalonDetails: (salonId: number) => {
    dispatch(getSalonDetails(salonId));
  },
  optOutMarketingSMS: (salonId: string, uniqcode: string) => {
    dispatch(optOutMarketingSMS(salonId, uniqcode));
  },
});

export const ConfirmComponent = Verification;

export default withRouter<any, any>(
  connect<any, Props, any, any, any, any>(mapStateToProps, mapDispatchToProps)(Verification),
);
