import getAnyOrVoid from 'utils/accessors/getAnyOrVoid.util';
import nullBind from 'utils/functions/nullBind.util';

const checkedOf = nullBind(getAnyOrVoid, 'target.checked');
const typeOf = nullBind(getAnyOrVoid, 'target.type');
const valueOf = nullBind(getAnyOrVoid, 'target.value');

const fromEventToValue = ev => (typeOf(ev) === 'checkbox' ? checkedOf(ev) : valueOf(ev));

// noinspection JSUnusedGlobalSymbols
export default fromEventToValue;
