import React, { useCallback } from 'react';
import cn from 'classnames';

import IMG_ARROW from 'assets/images/arrow-dark.svg';
import Icon from 'components/Icon.component';
import useTip from 'hooks/useTip.hook';
import { useTheme } from 'providers/Theme.provider';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';

import CN from './FoldOut.module.scss';

const FoldOut = props => {
  const { isDarkMode, isNeutralMode } = useTheme();
  const { val: hasDropdownOpen, tip: setDropdown } = useTip(fromAnyToBool(props.isSingle));

  const toggle = useCallback(() => {
    setDropdown();
  }, [setDropdown]);

  const { children, title, icon } = props;

  const componentClasses = cn({
    [CN.component]: true,
    [CN.isOpen]: hasDropdownOpen,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="FoldOut" className={componentClasses}>
      <div data-bem="FoldOut__button" role="button" tabIndex="0" onClick={toggle} className={CN.button}>
        <div className={CN.title}>
          {icon && (
            <div className={CN.iconContainer}>
              <Icon variant={icon} width="lg" />
            </div>
          )}

          {title}
        </div>
        <div className={CN.arrowContainer}>
          <img
            src={IMG_ARROW}
            alt="dropdown arrow"
            className={cn({
              [CN.arrow]: true,
              [CN.isOpen]: hasDropdownOpen,
            })}
          />
        </div>
      </div>

      {hasDropdownOpen && (
        <div data-bem="FoldOut__container" className={CN.container}>
          {children}
        </div>
      )}
    </div>
  );
};

export default FoldOut;
