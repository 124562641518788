// @flow

/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable react/no-danger */
/* eslint-disable object-curly-newline */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable comma-dangle */

import React from 'react';
import { connect } from 'react-redux';
import DisclaimerText from 'invite/components/DisclaimerText.component';
import moment from 'moment';

import { clearBillingInfo, clearIntentClientSecret, CreateToken, setupIntent } from 'actions/billing';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';

import {
  clearUserCardDetails,
  getClientInvites,
  getUserCardDetails,
  resetPaymentIntentId,
  setPageVisited,
} from 'booking/actions';
import HeaderComponent from 'booking/common/Header';
import SetupFormWrap from 'booking/confirmBooking/SetupFormWrap';
import type { SalonDetails } from 'types/salonDetails';
import type { Service } from 'types/service';
import type { Stylist } from 'types/stylist';

// import SplitFormNewCard from '../../booking/confirmBooking/AddNewCard';
// import { NCOB_STRIPE_KEY } from '../../config';

const L = logger('InviteAddNewCardWrap');

const ERROR_MESSAGE =
  'Sorry, there’s been a problem and your booking has not been created. ' +
  'The time slot you chose may have been taken or you might be on an old version, ' +
  'so please close this tab, open a new tab and try again';

type State = {
  errorMessage: string,
  paymentError: boolean,
  cardDetailsCalled: any,
};

type StateProps = {
  salon: any,
  billing: any,
  invites: [],
  cognito: any,
  booking: any,
  needs_card: boolean,
  no_show_protection: boolean,
  ncob_deposit_settings: string,

  salon_ncob_text: string,
  salonDetails: SalonDetails,
  selectedServices: Service[],
  selectedPatchTime: moment | null,
  selectedAppointmentTime: moment,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  bookingResponse: any,
  totalPrice: number,
  notes: string,
  username: string,
  cardDetails: {
    last4: string,
    exp_month: string,
    exp_year: string,
  },
  CreateToken: (token: any) => void,
  clearBillingInfo: () => void,
  setPageVisited: (page: string) => void,
  stripeToken: any,
  clearUserCardDetails: () => void,
  clearIntentClientSecret: () => void,
  chargeClientSecret: (
    salonId: number,
    appointmentDate: moment,
    services: number[],
    stylists: number[],
    username: string,
  ) => void,
  setupIntent: (salonId: number, username: string) => void,
  resetPaymentIntentId: () => void,
  getClientInvites: (cognitoUsername: string) => void,
  getUserCardDetails: (username: string) => void,
  errorMessage: string,
  phone_number: string,
  email: string,
  isFetching: boolean,
};

type OwnProps = {
  history: any,
  match: {
    params: { id: number },
    url: string,
  },
  location: any,
  public_key: string,
  bookingError: any,
};

type Props = StateProps & OwnProps;

export class InviteAddNewCardWrap extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      paymentError: false,
      cardDetailsCalled: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(nextProps) {
    const { cardDetailsCalled } = this.state;
    if (cardDetailsCalled) return;

    const { getUserCardDetails, username } = this.props;
    const { billing, cardDetails } = nextProps;

    const { last4 } = asObject(cardDetails);
    const { setupIntent } = asObject(billing);
    const { status } = asObject(setupIntent);

    L.debug('componentWillReceiveProps()', { last4, status });

    if (!last4 || status === 'succeeded') {
      L.unignore('componentWillReceiveProps()', 'getUserCardDetails:', getUserCardDetails(username));
      this.setState({ cardDetailsCalled: true });
    }
  }

  setToken = token => void this.props.CreateToken(token);
  setPaymentError = paymentError => void this.setState({ paymentError });
  isEmpty = object => !Object.keys(object).length;
  hideError = () => void this.setState({ errorMessage: '' });
  onAppleRequestBtnClick = () => {};
  startAgain = () => void this.props.history.push(`/salon/${this.props.match.params.id}`);

  render() {
    const { paymentError, errorMessage } = this.state;
    const { no_show_protection: noShowProtection, bookingError, history, match, location } = this.props;

    const { id: salonId } = match.params;
    const { appointmentId } = location.state;

    const hasBookingError = fromAnyToBool(bookingError);

    return (
      <div data-bem="InviteAddNewCardWrap" className="app-content color-title no-margin color-white">
        <HeaderComponent title="" />
        <header className="app-header text-center simple-title">
          <span className="header-title" style={paymentError ? { color: 'rgb(227, 46, 69)' } : {}}>
            {paymentError ? (
              <div>
                Card setup failed <br /> Please try again
              </div>
            ) : (
              <div>
                Please enter your card details.
                <br />
                Then confirm your booking on the next page.
              </div>
            )}
          </span>
          <span className="header-breadcrumb">Step 2 of 3</span>
        </header>

        {hasBookingError && <span className="login-error-2">{ERROR_MESSAGE}</span>}

        {hasBookingError && (
          <button className="btn btn-primary try-again" onClick={this.startAgain}>
            Try again
          </button>
        )}

        {!hasBookingError && (
          <div className="form-wrapper has-hight-footer">
            <div className="booking-info">
              {!paymentError && noShowProtection && (
                <p className="text-sb">
                  Your card details will be stored securely and anonymously on your account. Once your card is added,
                  you can proceed to pay the deposit for your appointment, or decline the request.
                </p>
              )}
            </div>

            <SetupFormWrap history={history} salonId={salonId} appointmentId={appointmentId} />

            <DisclaimerText />

            {bookingError.indexOf('Not found') < 0 && (
              <div className="login-error-2">
                <div className="input-baseline" />
                <span>{errorMessage ? ERROR_MESSAGE : ''}</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  salonDetails: state.booking.salonDetails,
  selectedServices: state.booking.selectedServices,
  selectedPatchTime: state.booking.selectedPatchTime,
  selectedAppointmentTime: state.booking.selectedAppointmentTime,
  notes: state.booking.notes,
  totalPrice: state.booking.totalPrice,
  selectedStylists: state.booking.selectedStylists,
  bookingResponse: state.booking.bookingResponse,
  bookingError: state.booking.errorMessage,
  isFetching: state.booking.isFetching,
  username: state.cognito.user ? state.cognito.user.username : state.billing.username,
  booking: state.booking,
  cardDetails: state.booking.cardDetails,
  stripeToken: Object.keys(state.billing.stripeToken).length ? state.billing.stripeToken : {},
  cognito: state.cognito,
  billing: state.billing,
  ncob_deposit_settings: state.booking.salonDetails.ncob_deposit_settings,
  no_show_protection: state.booking.salonDetails.no_show_protection,
  needs_card: state.booking.salonDetails.needs_card,
  salon_ncob_text: state.booking.salonDetails.salon_ncob_text,
  invites: state.booking.invites,
});

export function mapDispatchToProps(dispatch: any) {
  return {
    CreateToken(token: any) {
      dispatch(CreateToken(token));
    },
    clearUserCardDetails() {
      dispatch(clearUserCardDetails());
    },
    clearBillingInfo() {
      dispatch(clearBillingInfo());
    },
    setPageVisited(page: string) {
      dispatch(setPageVisited(page));
    },
    setupIntent(salonId: number, username: string) {
      dispatch(setupIntent(salonId, username));
    },
    resetPaymentIntentId() {
      dispatch(resetPaymentIntentId());
    },
    clearIntentClientSecret() {
      dispatch(clearIntentClientSecret());
    },
    getClientInvites(cognitoUsername: string) {
      dispatch(getClientInvites(cognitoUsername));
    },
    getUserCardDetails(username: string) {
      dispatch(getUserCardDetails(username));
    },
  };
}

const InviteAddNewCardWrapHoc = connect(mapStateToProps, mapDispatchToProps)(InviteAddNewCardWrap);

export default InviteAddNewCardWrapHoc;
