import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';

import CN from './Spinner.module.scss';

const Spinner = ({ variant }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  return (
    <>
      <div
        data-bem="Spinner"
        className={cn({
          [CN.component]: true,
          [CN[`${variant}Variant`]]: variant,
          [CN.neutral]: isNeutralMode,
          [CN.dark]: isDarkMode,
        })}
      >
        <svg className={CN.svg} viewBox="0 0 50 50">
          <circle className={CN.circle} cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
        </svg>
      </div>
    </>
  );
};

export default Spinner;
