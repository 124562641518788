import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import IMG_CLOUD from 'assets/images/icons/carbon-cloud-offline.svg';
import { useTheme } from 'providers/Theme.provider';
import asObject from 'utils/objects/asObject.util';

import Spinner from 'common/Spinner.component';

import CN from './Offline.module.scss';

const Offline = ({ salonDetails, isFetching }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const { phone: salonPhone } = asObject(salonDetails);

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div>
      {isFetching ? (
        <Spinner variant="overlay" />
      ) : (
        <div data-bem="Offline" className={componentClasses}>
          <div className={CN.statusIcon}>
            <img src={IMG_CLOUD} alt="offline icon" />
          </div>
          <div className={CN.title}>Unfortunately this business does not currently offer online booking</div>
          <div className={CN.text}>
            If you would like to make an appointment then please contact them on:
            <span className={CN.phone}>{salonPhone}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = state => ({
  salonDetails: state.booking.salonDetails,
  isFetching: state.booking.isFetching,
});

export default connect(mapStateToProps)(Offline);
