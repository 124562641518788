import withOptions from 'utils/functions/withOptions.util';
import isFullString from 'utils/predicates/string/isFullString.util';

// eslint-disable-next-line max-len
const RE_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const validateEmail = withOptions(({ value }) => {
  if (value === '') return false;

  // for non-string values, return null, not false, as a signal for error
  if (!isFullString(value)) return null;

  return RE_EMAIL.test(value);
});

export default validateEmail;
