// @flow

import React from 'react';

type Props = {
  message: string,
};

const SuccessfulPopup = (props: Props) => <div className="succes-popup animated bounceIn">{props.message}</div>;

export default SuccessfulPopup;
