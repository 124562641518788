import React, { useState } from 'react';
import cn from 'classnames';

import Toggler from 'components/Toggler.component';
import useTip from 'hooks/useTip.hook';
import { useTheme } from 'providers/Theme.provider';

import currency from 'booking/common/currency.util';
import currencyRange from 'booking/common/currencyRange.util';
import { convertDurationToHrMin } from 'lib/utils';

import CN from './ListItem.module.scss';

const ListItem = ({ service, currency: abbreviation, isChecked, onCheckboxClick, recommended, variant }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const { val: expanded, tip: setExpanded } = useTip(false);
  const [description] = useState(service.description);

  const toggleText = ev => {
    ev.stopPropagation();
    setExpanded(!expanded);
  };

  const {
    max_duration: maxDuration,
    max_price: maxPrice,
    variable_pricing: variablePricing,
    min_price: minPrice,
    name,
    min_duration: minDuration,
  } = service;

  const price =
    minPrice !== maxPrice
      ? `${currencyRange({ from: minPrice, to: maxPrice, abbreviation })}`
      : `${currency({ value: minPrice, abbreviation })}`;

  const duration =
    minDuration !== maxDuration
      ? `${convertDurationToHrMin(minDuration)} - ${convertDurationToHrMin(maxDuration)}`
      : `${convertDurationToHrMin(minDuration)}`;

  const componentClasses = cn({
    [CN.component]: true,
    isHidden: !isChecked,
    recommended,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
    [CN[`${variant}Variant`]]: variant,
  });

  return (
    <div data-bem="ListItem" className={componentClasses} role="button" tabIndex={0} onClick={onCheckboxClick}>
      <div className={CN.container}>
        <div className={CN.info}>
          <span className={CN.title}>{name}</span>
          <div className={CN.duration}>{duration}</div>

          <div
            className={cn({
              [CN.description]: true,
              [CN.clip]: !expanded,
            })}
          >
            {description}
            <div
              className={cn({
                [CN.showMore]: true,
                [CN.isExpanded]: expanded,
              })}
            >
              {description && description.length >= 70 ? (
                <span role="button" tabIndex={0} onClick={toggleText}>
                  {expanded ? '...view less' : '...view more'}
                </span>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
        <div className={CN.row}>
          <span className={CN.price}>
            {variablePricing ? 'from ' : ''}
            {price}
          </span>
          <Toggler selected={isChecked} tag="ListItem__toggler" />
        </div>
      </div>
    </div>
  );
};

export default ListItem;
