const UNKNOWN = 'UnknownComponent';
const UNNAMED = 'UnnamedComponent';

const fromComponentToName = WrappedComponent => {
  // NOTE: there is a subtle difference between unknown and unnamed

  if (WrappedComponent) {
    // we know there is a component, we just have to figure out the name
    return WrappedComponent.displayName || WrappedComponent.name || UNNAMED;
  }

  // we're maybe having a null or undefined or something else here, not a component
  return UNKNOWN;
};

// noinspection JSUnusedGlobalSymbols
export default fromComponentToName;
