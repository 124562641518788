import { COOKIE_SELECTION } from 'actions/cookieSelection.action';
import { TRACKING_STATE } from 'actions/trackingSet.action';
import asObject from 'utils/objects/asObject.util';

const initialState = {
  cookieChoice: '',
  tracking: false,
};

const cookies = (state = initialState, action) => {
  const { type, cookieChoice, tracking } = asObject(action);

  if (type === COOKIE_SELECTION) return { ...state, cookieChoice };
  if (type === TRACKING_STATE) return { ...state, tracking };

  return state;
};

export default cookies;
