// @flow

import React from 'react';

import FavouriteStylistForOneService from 'booking/stylistSelection/FavouriteStylistForOneService';
import type { Service } from 'types/service';
import type { Stylist, StylistsService } from 'types/stylistsForService';

type Props = {
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  selectedServices: Service[],
  stylistsForServiceWeekly: StylistsService,
  onCheckboxClick: (serviceId: number, stylist: Stylist) => void,
  onClickAnyStylist: (serviceId: number) => void,
  book_ncob_with_same: boolean,
};

type State = {};

class FavouriteStylistSelect extends React.Component<Props, State> {
  componentDidUpdate(prevProps) {
    if (prevProps.stylistsForServiceWeekly !== this.props.stylistsForServiceWeekly) {
      this.sortAndSetStylists(this.props.stylistsForServiceWeekly);
    }
  }

  sortAndSetStylists = stylistsForServiceWeekly => {
    const sorted = this.sortAccordingToService(stylistsForServiceWeekly);
    const singleAvailableStylists = [];
    if (sorted.length) {
      for (let i = 0; i < sorted.length; i += 1) {
        if (sorted[i].length === 1) {
          singleAvailableStylists.push(Object.values(sorted[i])[0]);
        }
      }
    }
  };

  sortAccordingToService = obj => {
    const serviceIds = this.props.selectedServices.map(service => service.id);
    return serviceIds.map(serviceId => obj[serviceId.toString()]).filter(service => service);
  };

  render() {
    const sorted = this.sortAccordingToService(this.props.stylistsForServiceWeekly);

    return (
      <div className="stylist-container">
        {sorted.length > 0 &&
          sorted.map((stylistArr, i) => (
            <FavouriteStylistForOneService
              stylistArr={stylistArr}
              service={this.props.selectedServices[i]}
              selectedStylists={this.props.selectedStylists}
              i={i}
              key={this.props.selectedServices[i].id}
              onCheckboxClick={this.props.onCheckboxClick}
              onClickAnyStylist={this.props.onClickAnyStylist}
              book_ncob_with_same={this.props.book_ncob_with_same}
            />
          ))}
      </div>
    );
  }
}

export default FavouriteStylistSelect;
