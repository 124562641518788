// @flow
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import LOGO_SLICK from 'assets/images/Slick-logo.svg';
import LOGO_SLICK_TEXT from 'assets/images/Slicklogo.svg';
import COGNITO from 'constants/cognito.const';
import { withTheme } from 'providers/Theme.provider';

import clearHalfCache from 'lib/clearHalfCache';

import LogoutButtonComponent from './LogoutButton.component';

import CN from './Header.module.scss';

type Props = {
  title?: string,
  titleNewLine?: string,
  subtitle?: string,
  extratitle?: string | any,
  aditionalClass?: string,
  salonImage?: string,
  cognito?: any,
  history?: any,
  children?: any,
  pageVisited: any,
};

type State = {
  logo: string,
};

export class Header extends React.Component<Props, State> {
  static defaultProps = {
    title: '',
    subtitle: '',
    extratitle: '',
    aditionalClass: '',
    salonImage: '',
    cognito: {},
    titleNewLine: '',
    history: null,
    children: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      logo: this.props.salonImage ? this.props.salonImage : LOGO_SLICK,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.salonImage !== this.props.salonImage) {
      if (nextProps.salonImage) {
        this.setState({ logo: nextProps.salonImage });
      } else {
        this.setState({ logo: LOGO_SLICK });
      }
    }
  }

  gotoMyProfile = () => {
    const salonId = this.props.history.location.pathname.split('/salon/')[1].split('/')[0];
    const pathname = `/salon/${salonId}/my-profile`;
    const login = `/salon/${salonId}/profile-login`;
    clearHalfCache();
    if (this.props.history) {
      if (this.props.cognito && this.props.cognito.state === COGNITO.loggedIn) {
        this.props.history.push(pathname);
      } else {
        this.props.history.push({
          pathname: login,
          state: { fromMyBook: true },
        });
      }
    }
  };
  gotoMyBooking = () => {
    const salonId = this.props.history.location.pathname.split('/salon/')[1].split('/')[0];
    const pathname = `/salon/${salonId}/my-book`;
    const login = `/salon/${salonId}/profile-login`;
    clearHalfCache();
    if (this.props.history) {
      if (this.props.cognito && this.props.cognito.state === COGNITO.loggedIn) {
        this.props.history.push(pathname);
      } else {
        this.props.history.push({
          pathname: login,
          state: { fromMyBook: true },
        });
      }
    }
  };
  gotoMyFeed = () => {
    const salonId = this.props.history.location.pathname.split('/salon/')[1].split('/')[0];
    const pathname = `/salon/${salonId}/my-feeds`;
    const login = `/salon/${salonId}/profile-login`;
    clearHalfCache();
    if (this.props.history) {
      if (this.props.cognito && this.props.cognito.state === COGNITO.loggedIn) {
        this.props.history.push(pathname);
      } else {
        this.props.history.push({
          pathname: login,
          state: { fromMyBook: true },
        });
      }
    }
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { extratitle, isDarkMode, isNeutralMode, cognito, displayGoBack } = this.props;

    const logoUrl = {
      backgroundImage: `url("${this.state.logo}")`,
    };

    const componentClasses = cn({
      'app-header text-center': true,
      [CN.component]: true,
      [CN.dark]: isDarkMode,
      [CN.neutral]: isNeutralMode,
    });

    return (
      <div data-bem="Header" className={componentClasses}>
        <nav>
          <ul className={CN.navigation}>
            <li className={CN.navItem}>
              <div className={CN.navLink} role="button" tabIndex={0} onClick={this.gotoMyProfile}>
                <div className={CN.navContent}>
                  <div className={CN.iconProfile} />
                  <div>Profile</div>
                </div>
              </div>
            </li>
            <li className={CN.navItem}>
              <div className={CN.navLink} role="button" tabIndex={0} onClick={this.gotoMyBooking}>
                <div className={CN.navContent}>
                  <div className={CN.iconBooking} />
                  <div>Bookings</div>
                </div>
              </div>
            </li>

            <li className={CN.navItem}>
              <div className={CN.navLink} role="button" tabIndex={0} onClick={this.gotoMyFeed}>
                <div className={CN.navContent}>
                  <div className={CN.iconContainer}>
                    <div className={CN.iconPound} />
                  </div>
                  <div>Deposits</div>
                </div>
              </div>
            </li>
          </ul>
        </nav>

        <header>
          <div className={CN.gradientContainer} data-bem="Header__gradient" />
          <div className={CN.logoPanel} data-bem="Header__logo">
            <div className={CN.logoContainer}>
              <span className={CN.salonLogo} style={this.state.logo && logoUrl} />
            </div>

            <div className={CN.logoText}>
              <span>Powered by</span>
              <img src={LOGO_SLICK_TEXT} alt="Slick logo" className={CN.slickLogo} />
            </div>
          </div>
          <span className={CN.title}>{extratitle}</span>
          <span className="header-title" dangerouslySetInnerHTML={{ __html: this.props.title }} />
          <span className="header-title-new-line" dangerouslySetInnerHTML={{ __html: this.props.titleNewLine }} />
          <span className="header-sub-title">{this.props.subtitle}</span>

          {this.props.children}

          {cognito && cognito.state === COGNITO.loggedIn && displayGoBack && (
            <div
              data-bem="Header_arrowGoBack"
              className={CN.arrowGoBack}
              role="button"
              tabIndex={0}
              onClick={this.handleGoBack}
            >
              <img src={IMG_ARROW} alt="Arrow go back" className={CN.arrowImg} />
            </div>
          )}

          <div data-bem="Header__logout-button-component" className="logout-btn">
            <LogoutButtonComponent />
          </div>
        </header>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { booking, cognito } = state;
  const { salonDetails } = booking;
  return {
    salonDetails,
    salonImage: salonDetails.image,
    cognito,
  };
};

export default connect(mapStateToProps)(withTheme()(Header));
