import getAnyOrNull from 'utils/accessors/getAnyOrNull.util';
import nullBind from 'utils/functions/nullBind.util';
import asObject from 'utils/objects/asObject.util';

const getParams = nullBind(getAnyOrNull, 'match.params');

const fromPropsToParams = props => asObject(getParams(props));

// noinspection JSUnusedGlobalSymbols
export default fromPropsToParams;
