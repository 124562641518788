import S from 'constants/string.const';
import fromAnyToString from 'utils/converters/fromAnyToString';

const asInput = value => {
  if (value === null || void 1 === value) return S.empty;
  if (value === Infinity || value === -Infinity || Number.isNaN(value)) return S.empty;

  return fromAnyToString(value);
};

// noinspection JSUnusedGlobalSymbols
export default asInput;
