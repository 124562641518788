import Raven from 'raven-js';

import fromAnyToString from 'utils/converters/fromAnyToString';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';

const L = logger('captureError');

const captureError = (logger, tags, extra, error) => {
  try {
    const options = {
      logger: Array.isArray(logger) ? logger.map(fromAnyToString).join(' ') : fromAnyToString(logger),
      level: 'error',
      tags: asObject(tags),
      extra: asObject(extra),
    };

    L.debug('()', 'capturing', options, error, '...');
    // noinspection JSUnresolvedFunction
    Raven.captureException(error, options);
  } catch (e) {
    L.warn('()', { logger, tags, extra, error }, e);
  }

  return error;
};

// noinspection JSUnusedGlobalSymbols
export default captureError;
