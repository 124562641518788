import React from 'react';
import cn from 'classnames';

import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import S from 'constants/string.const';
import { useTheme } from 'providers/Theme.provider';

import CN from './WarningAppointment.module.scss';

const WarningAppointment = ({ onClose, onCancel, salonName, salonPhone }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="WarningAppointment" className={componentClasses}>
      <div className={CN.wrapper}>
        <div className={CN.header}>
          <div
            data-bem="WarningAppointment_arrowGoBack"
            className={CN.arrowGoBack}
            role="button"
            tabIndex={0}
            onClick={onClose}
          >
            <img src={IMG_ARROW} alt="Arrow go back" className={CN.arrowImg} />
          </div>
        </div>
        <IconStatus variant="warning">
          <Icon variant="warning" width="xl" />
        </IconStatus>

        <div className={CN.container}>
          <div className={CN.title}>Sorry, it{S.apos}s not possible to cancel this appointment</div>
          <div className={CN.text}>
            <div className={CN.subTitle}>
              {salonName}
              {S.apos}s cancellation policy requires you to contact them directly to cancel.
              <div className={CN.gap}>Please contact them on:</div>
              {salonPhone}
            </div>
          </div>
        </div>
      </div>
      <Footer data-bem="WarningAppointment__footer">
        <Button variant="primary" width="fixed" onClick={onCancel}>
          OK THANKS
        </Button>
      </Footer>
    </div>
  );
};

export default WarningAppointment;
