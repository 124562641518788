// @flow

/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable react/no-danger */
/* eslint-disable object-curly-newline */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable comma-dangle */

import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import cn from 'classnames';
import DisclaimerText from 'invite/components/DisclaimerText.component';
import moment from 'moment';

import Button from 'components/Button.component';
import FoldOut from 'components/FoldOut.component';
import { withTheme } from 'providers/Theme.provider';

import { clearBillingInfo, clearIntentClientSecret, CreateToken, setupIntent } from '../../actions/billing';
import currency from '../../booking/common/currency.util';
import { NCOB_STRIPE_KEY } from '../../config';
import { fbPixelTracking, getBookingCostFunc } from '../../lib/utils';
import type { SalonDetails } from '../../types/salonDetails';
import type { Service } from '../../types/service';
import type { Stylist } from '../../types/stylist';
import { clearUserCardDetails, confirmXAuthentication, getUserCardDetails, setPageVisited } from '../actions';
import HeaderComponent from '../common/Header';

import ConfirmWithCard from './ConfirmWithCard';

import CN from './ConfirmWithCardWrap.module.scss';

const DISPLAY_NCOB_TEXT = false;

type State = {
  errorMessage: string,
  paymentError: boolean,
  getCostDone: boolean,
};
type StateProps = {
  salon: any,
  billing: any,
  cognito: any,
  booking: any,
  errorMessage: '',
  needs_card: boolean,
  no_show_protection: boolean,
  ncob_deposit_settings: string,
  salon_ncob_text: string,
  salonDetails: SalonDetails,
  selectedServices: Service[],
  selectedPatchTime: moment | null,
  selectedAppointmentTime: moment,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  bookingResponse: any,
  errorMessage: any,
  totalPrice: number,
  notes: string,
  username: string,
  cardDetails: {
    last4: string,
    exp_month: string,
    exp_year: string,
  },
  CreateToken: (token: any) => void,
  clearBillingInfo: () => void,
  setPageVisited: (page: string) => void,
  stripeToken: any,
  clearUserCardDetails: () => void,
  clearIntentClientSecret: () => void,
  chargeClientSecret: (
    salonId: number,
    appointmentDate: moment,
    services: number[],
    stylists: number[],
    username: string,
  ) => void,
  setupIntent: (salonId: number, username: string) => void,
  confirmXAuthentication: (
    salonId: number,
    appointmentId: number,
    paymentintentId: number,
    cognitoUserName: string,
  ) => void,
  errorMessage: string,
  phone_number: string,
  email: string,
  isFetching: boolean,
  getUserCardDetails: (username: string) => void,
};
type OwnProps = {
  history: string[],
  match: {
    params: { id: number },
    url: string,
  },
  public_key: string,
  bookingError: any,
  confirmAuthenticationSuccess: boolean,
  confirmAuthenticationStatus: string,
};
type Props = StateProps & OwnProps;

class ConfirmWithCardWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: '',
      paymentError: false,
      getCostDone: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.selectedServices.length === 0) {
      this.props.history.push(`/salon/${this.props.match.params.id}`);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setPageVisited('confirm-with-card');
    this.props.getUserCardDetails(this.props.cognito.user.username);
    fbPixelTracking(this.props.salonDetails.fb_details.fb_pixel_id);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.billing.appointment_cost && !this.state.getCostDone) {
      this.setState({ getCostDone: true });
      getBookingCostFunc();
    }
  }

  setToken = (token: any) => {
    this.props.CreateToken(token);
  };
  setPaymentError = (trueOrFalse: boolean) => {
    this.setState({ paymentError: trueOrFalse });
  };
  isEmpty = (obj: any) => {
    const keys = Object.keys(obj);

    if (keys.length > 0) {
      return false;
    }
    return true;
  };

  hideError = () => {
    this.setState({ errorMessage: '' });
  };

  startAgain = () => {
    const salonId = this.props.match.params.id;
    const path = `/salon/${salonId}/`;

    if (this.props.history) {
      if (this.props.cognito && this.props.cognito.state === 'LOGGED_IN') {
        this.props.history.push(path);
      }
    }
  };

  render() {
    const { salonDetails, billing, isDarkMode, isNeutralMode } = this.props;
    const abbreviation = salonDetails.currency;

    let { username } = this.props;
    if (!username) {
      if (this.props.cognito && Object.keys(this.props.cognito.attributes).length) {
        [username] = this.props.cognito.attributes.name.split(' ');
      }
    }

    let anyError;
    let bookingError;
    if (this.state.errorMessage) {
      anyError = 'We are having problems creating your booking, please try again.';
    }
    if (this.props.bookingError) {
      bookingError =
        'Sorry there has been a problem creating your booking. Your payment may have failed so please try again checking you have sufficient funds or try using a different card.';
    }
    return (
      <div
        data-bem="ConfirmWithCardWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          <HeaderComponent history={this.props.history} />
          {!this.props.booking.cardDetails.last4 && (
            <div className={CN.title}>
              Welcome {username}! <br /> Confirm your booking
            </div>
          )}
          {!bookingError && !this.state.paymentError && (
            <div>
              <div className={CN.title}>Welcome back {username}!</div>
              <div className={CN.small}>Please check the payments details are correct in order to pay your deposit</div>
            </div>
          )}
          <div className={CN.subTitle} />

          {bookingError && <span className={CN.error}>{bookingError}</span>}
          {bookingError && (
            <div className="text-center">
              <Button variant="primary" width="fixed" onClick={this.startAgain}>
                START AGAIN
              </Button>
            </div>
          )}
          {/* case: requires authentication for payments but payment failed */}
          {this.state.paymentError ? (
            <div>
              <div className={CN.title}>Your card has been declined and payment was unsuccessful.</div>
              <div className={CN.small}> Please try again using a different card.</div>

              <div className="text-center">
                <Button variant="primary" width="fixed" onClick={this.startAgain}>
                  START AGAIN
                </Button>
              </div>
            </div>
          ) : (
            <div>
              {!bookingError && (
                <div>
                  <div>
                    <div className={CN.bookingValue}>
                      <span>Total:</span>
                      <span className={CN.total}>{currency({ value: billing.appointment_cost, abbreviation })}</span>
                    </div>
                    <div className={CN.bookingValue}>
                      <span>of which payabale today:</span>
                      <span className={CN.totalToday}>{currency({ value: billing.pay_today, abbreviation })}</span>
                    </div>
                    {DISPLAY_NCOB_TEXT && (
                      <div>
                        {!this.state.paymentError && this.props.no_show_protection && (
                          <div className={CN.bold}>
                            Card details are required to confirm your booking but you will NOT be charged today. Payment
                            will only be taken after your appointment is complete. We reserve the right to charge a
                            cancellation or no-show fee as per our cancellation policy below.
                          </div>
                        )}
                        {!this.state.paymentError && this.props.ncob_deposit_settings !== 'null' && (
                          <div className={CN.bold}>
                            To secure your booking we require an upfront payment which we reserve the right to keep if
                            you fail to show for your appointment or cancel last minutes. Cancellations with advanced
                            notice may be refundable in line with our cancellation policy.
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {NCOB_STRIPE_KEY && (this.props.needs_card || this.props.cardDetails.last4) && (
                    <StripeProvider apiKey={NCOB_STRIPE_KEY}>
                      <Elements>
                        <ConfirmWithCard
                          salonName={salonDetails && salonDetails.name ? this.props.salonDetails.name : 'Salon name'}
                          salonPhone={salonDetails && salonDetails.phone ? salonDetails.phone : 'Salon phone number'}
                          setToken={this.setToken}
                          stripeToken={this.props.stripeToken}
                          isEmpty={this.isEmpty}
                          salonId={this.props.match.params.id}
                          history={this.props.history}
                          chargeClientSecret={this.props.chargeClientSecret}
                          cardDetails={this.props.cardDetails}
                          selectedAppointmentTime={this.props.selectedAppointmentTime}
                          selectedStylists={this.props.selectedStylists}
                          selectedServices={this.props.selectedServices}
                          salonDetails={salonDetails}
                          username={this.props.cognito.user ? this.props.cognito.user.username : ''}
                          billing={billing}
                          bookingResponse={this.props.bookingResponse}
                          bookingError={this.props.booking.errorMessage}
                          clearUserCardDetails={this.props.clearUserCardDetails}
                          clearBillingInfo={this.props.clearBillingInfo}
                          clearIntentClientSecret={this.props.clearIntentClientSecret}
                          setPaymentError={this.setPaymentError}
                          isFetching={this.props.isFetching}
                          pageVisited={this.props.booking.pageVisited}
                          setupIntent={this.props.setupIntent}
                          confirmXAuthentication={this.props.confirmXAuthentication}
                          payment_method={billing.payment_method}
                          payment_intent_id={billing.payment_intent_id}
                          client_secret={billing.client_secret}
                          confirmAuthenticationSuccess={this.props.confirmAuthenticationSuccess}
                          confirmAuthenticationStatus={this.props.confirmAuthenticationStatus}
                        />
                      </Elements>
                    </StripeProvider>
                  )}

                  <DisclaimerText />

                  {!this.props.isWaitList && (
                    <div className={CN.policyContainer} data-bem="ConfirmWithCardWrap__foldOut">
                      <FoldOut title="Cancellation Policy" icon="policy">
                        <div className={CN.policyText} data-bem="ConfirmWithCardWrap__policy--text">
                          {salonDetails.salon_ncob_text}
                        </div>
                      </FoldOut>
                    </div>
                  )}

                  {this.props.bookingError.indexOf('Not found') < 0 && <div className={CN.error}>{anyError}</div>}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  salonDetails: state.booking.salonDetails,
  selectedServices: state.booking.selectedServices,
  selectedPatchTime: state.booking.selectedPatchTime,
  selectedAppointmentTime: state.booking.selectedAppointmentTime,
  notes: state.booking.notes,
  totalPrice: state.booking.totalPrice,
  selectedStylists: state.booking.selectedStylists,
  bookingResponse: state.booking.bookingResponse,
  bookingError: state.booking.errorMessage,
  isFetching: state.booking.isFetching,
  username: state.billing.username,
  booking: state.booking,
  cardDetails: state.booking.cardDetails,
  stripeToken: Object.keys(state.billing.stripeToken).length ? state.billing.stripeToken : {},
  cognito: state.cognito,
  billing: state.billing,
  ncob_deposit_settings: state.booking.salonDetails.ncob_deposit_settings,
  no_show_protection: state.booking.salonDetails.no_show_protection,
  needs_card: state.booking.salonDetails.needs_card,
  salon_ncob_text: state.booking.salonDetails.salon_ncob_text,
  confirmAuthenticationSuccess: state.booking.confirmAuthenticationSuccess,
  confirmAuthenticationStatus: state.booking.confirmAuthenticationStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  CreateToken(token: any) {
    dispatch(CreateToken(token));
  },
  clearUserCardDetails() {
    dispatch(clearUserCardDetails());
  },
  clearBillingInfo() {
    dispatch(clearBillingInfo());
  },
  clearIntentClientSecret() {
    dispatch(clearIntentClientSecret());
  },
  setPageVisited(page: string) {
    dispatch(setPageVisited(page));
  },
  setupIntent(salonId: number, username: string) {
    dispatch(setupIntent(salonId, username));
  },
  confirmXAuthentication(salonId: any, appointmentId: any, paymentintentId: any, cognitoUserName: string) {
    return dispatch(confirmXAuthentication(salonId, appointmentId, paymentintentId, cognitoUserName));
  },
  getUserCardDetails(username: string) {
    dispatch(getUserCardDetails(username));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(ConfirmWithCardWrap));
