import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import IMG_CHECK from 'assets/images/icons/check-circle-purple.svg';
import IMG_EMAIL from 'assets/images/icons/email-purple.svg';
import IMG_SPARKLE from 'assets/images/icons/sparkles-icon-white.svg';
import validateEmail from 'bl/validation/validateEmail.bl';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import LINK from 'constants/link.const';
import S from 'constants/string.const';
import useAfn from 'hooks/useAfn.hook';
import useStateWhileMounted from 'hooks/useStateWhileMounted.hook';
import { useDispatcher } from 'providers/Dispatcher.provider';
import { useCurrentSalonId } from 'providers/SalonBasicInfo.provider';
import { useTheme } from 'providers/Theme.provider';
import getAnyOrVoid from 'utils/accessors/getAnyOrVoid.util';
import fromEventToName from 'utils/converters/event/fromEventToName.util';
import fromEventToValue from 'utils/converters/event/fromEventToValue.util';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import fromEntriesToObject from 'utils/converters/object/fromEntriesToObject.util';
import fromPropsToQuery from 'utils/converters/props/fromPropsToQuery.util';
import alwaysTrue from 'utils/functions/nullary/alwaysTrue.util';
import withOptions from 'utils/functions/withOptions.util';
import linkTo from 'utils/links/linkTo.util';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';
import isFullArray from 'utils/predicates/array/isFullArray.util';
import batchValidate from 'utils/predicates/batchValidate.util';
import isFullString from 'utils/predicates/string/isFullString.util';
import asInput from 'utils/strings/asInput.util';

import beSendEgiftVoucher from 'booking/beSendEgiftVoucher.action';
import Spinner from 'common/Spinner.component';

import CN from './SendVoucherPage.module.scss';

const L = logger('SendVoucherPage');

const MAX_MESSAGE_LENGTH = 320;

const F = Object.freeze({
  mail: 'recipient_email',
  mesg: 'custom_message',
  recp: 'recipient_name',
  send: 'purchaser_name',
});

const VALIDATOR = Object.freeze({
  [F.recp]: ({ value }) => isFullString(value),
  [F.mail]: validateEmail,
  [F.send]: ({ value }) => isFullString(value),
});

const checkError = withOptions(({ key, fields, current, bee }) => {
  // if BE returned a list of errors for this key
  const beErrors = asObject(bee)[key];
  if (isFullArray(beErrors)) return true;

  // if we're currently editing this key, maybe should we show there is an error?
  if (current === key) return false;

  const field = asObject(fields)[key];

  L.debug('checkError()', 'inside', key, field.dirty, field.error);
  // if the field is not dirty, should we show there is an error?
  if (!field.dirty) return false;

  return fromAnyToBool(field.error);
});

const SendVoucherPage = props => {
  const { session_id: sessionId } = fromPropsToQuery(props);

  const salonId = useCurrentSalonId();
  const { isDarkMode, isNeutralMode } = useTheme();
  const dispatchBeSendGiftVoucher = useDispatcher(beSendEgiftVoucher);

  // short for back end error(s)
  const [bee, setBee] = useStateWhileMounted({});
  const [current, setCurrent] = useStateWhileMounted(null);
  const [fields, setFields] = useStateWhileMounted({
    [F.mail]: { value: S.empty, error: false, dirty: false },
    [F.recp]: { value: S.empty, error: false, dirty: false },
    [F.send]: { value: S.empty, error: false, dirty: false },
    [F.mesg]: { value: S.empty, error: false, dirty: false },
  });

  const recipient = fields[F.recp];
  const email = fields[F.mail];
  const sender = fields[F.send];
  const message = fields[F.mesg];

  const messageLength = message.value.length || 0;
  const linkToSalon = linkTo({ pattern: LINK.salon, params: { salonId } });

  const handleFocus = useCallback(ev => void setCurrent(fromEventToName(ev)), [setCurrent]);

  const handleBlur = useCallback(
    ev => {
      const key = fromEventToName(ev);
      const val = fromEventToValue(ev);

      const validate = VALIDATOR[key] || alwaysTrue;
      const error = !validate({ value: val });

      setCurrent(error ? key : null);
      setFields({ ...fields, [key]: { ...fields[key], value: val, error } });
      L.debug('handleBlur()', key, val, error);
    },
    [fields, setFields, setCurrent],
  );

  const handleChange = useCallback(
    ev => {
      const key = fromEventToName(ev);
      const val = fromEventToValue(ev);

      const validate = VALIDATOR[key] || alwaysTrue;

      const error = !validate({ value: val });
      const value = key === F.mesg && val.length > MAX_MESSAGE_LENGTH ? val.substring(0, MAX_MESSAGE_LENGTH) : val;

      setFields({ ...fields, [key]: { value, error, dirty: true } });
      setBee({ ...bee, [key]: false });
      L.debug('handleChange()', key, val, error);
    },
    [bee, fields, setBee, setFields],
  );

  const [{ value: valueSend, isLoading: isLoadingSend }, handleSend] = useAfn(async () => {
    if (!salonId || !sessionId) return void 1;

    const result = await dispatchBeSendGiftVoucher({
      salonId,
      sessionId,
      data: {
        recepientName: recipient.value,
        recepientEmail: email.value,
        purchaserName: sender.value,
        customMessage: message.value,
      },
    });

    if (getAnyOrVoid('responseObject.ok', result)) {
      setBee({});
      return true;
    }

    setBee(result.responseJson ? result.responseJson : {});
    L.warn('handleSend()', result);
    return false;
  }, [
    dispatchBeSendGiftVoucher,
    email.value,
    message.value,
    recipient.value,
    salonId,
    sender.value,
    sessionId,
    setBee,
  ]);

  const isFormValid = batchValidate({
    validators: VALIDATOR,
    fields: fromEntriesToObject(Object.entries(fields).map(([k, v]) => [k, v.value])),
  });

  const hasErrorInUnfocusedField = Object.values(fields)
    .filter(f => f.name !== current)
    .some(f => f.error);

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  const recipientClasses = cn({
    [CN.input]: true,
    [CN.hasError]: checkError({ key: F.recp, fields, current, bee }),
  });

  const emailClasses = cn({
    [CN.input]: true,
    [CN.hasError]: checkError({ key: F.mail, fields, current, bee }),
  });

  const senderClasses = cn({
    [CN.input]: true,
    [CN.hasError]: checkError({ key: F.send, fields, current, bee }),
  });

  L.debug('()', isLoadingSend, bee, valueSend, current, fields);

  return (
    <div data-bem="SendVoucherPage" className={componentClasses}>
      {isLoadingSend && <Spinner variant="overlay" />}
      {valueSend ? (
        <div>
          <div className={CN.statusIcon}>
            <img src={IMG_CHECK} alt="successful icon" />
          </div>
          <div className={CN.title}>Your voucher has been sent!</div>
          <div className={CN.imagesGroup}>
            <img src={IMG_SPARKLE} alt="sparkle icon" className={CN.sparkleTop} />
            <img src={IMG_SPARKLE} alt="sparkle icon" className={CN.sparkleBottom} />
          </div>
        </div>
      ) : (
        <div>
          <div className={CN.statusIcon}>
            <img src={IMG_EMAIL} alt="successful icon" />
          </div>
          <div className={CN.title}>Send gift voucher</div>
          <div className={CN.details}>
            <div className={CN.row}>
              <div className={CN.rowTitle}>Who is this voucher for:</div>
              {bee[F.recp] && <span className={CN.inputError}>{bee[F.recp]}</span>}
              <input
                data-bem="SendVoucherPage__recp"
                type="text"
                name={F.recp}
                value={asInput(recipient.value)}
                data-has-error={recipient.error}
                data-is-dirty={recipient.dirty}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Recipient name"
                className={recipientClasses}
              />
              {bee[F.mail] && <span className={CN.inputError}>{bee[F.mail]}</span>}
              <input
                data-bem="SendVoucherPage__mail"
                type="email"
                name={F.mail}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Recipient email address"
                value={asInput(email.value)}
                data-has-error={email.error}
                data-is-dirty={email.dirty}
                className={emailClasses}
              />
            </div>
            <div className={CN.row}>
              <div className={CN.rowTitle}>Who is this voucher from:</div>
              {bee[F.send] && <span className={CN.inputError}>{bee[F.send]}</span>}
              <input
                data-bem="SendVoucherPage__send"
                type="text"
                name={F.send}
                value={asInput(sender.value)}
                data-has-error={sender.error}
                data-is-dirty={sender.dirty}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Your name"
                className={senderClasses}
              />
            </div>
            <div className={CN.row}>
              <div className={CN.rowTitle}>Your message to the recipient:</div>
              {bee[F.mesg] && <span className={CN.inputError}>{bee[F.mesg]}</span>}
              <textarea
                data-bem="SendVoucherPage__mesg"
                name={F.mesg}
                value={asInput(message.value)}
                data-has-error={message.error}
                data-is-dirty={message.dirty}
                maxLength={MAX_MESSAGE_LENGTH}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Add a message (optional)"
                className={CN.area}
              />
              <div className={CN.charCount}>{messageLength} characters (max 320)</div>
            </div>
          </div>
        </div>
      )}

      <Footer data-bem="SendVoucherPage__footer">
        <div className={CN.footerInnerContainer}>
          {hasErrorInUnfocusedField && (
            <span className={CN.commonError}>Please complete the fields above to proceed</span>
          )}
          {valueSend ? (
            <NavLink to={linkToSalon} data-bem="BuyVoucherButton" className={CN.link}>
              <Button variant="primary" width="fixed">
                BACK TO HOME
              </Button>
            </NavLink>
          ) : (
            <Button variant="primary" width="fixed" disabled={!isFormValid} onClick={handleSend}>
              SEND VOUCHER
            </Button>
          )}
        </div>
      </Footer>
    </div>
  );
};

export default SendVoucherPage;
