import React from 'react';
import cn from 'classnames';

import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import { useTheme } from 'providers/Theme.provider';

import CN from './DeclineAppointment.module.scss';

const DeclineAppointment = ({ onClose, onDecline, salonName }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="DeclineAppointment" className={componentClasses}>
      <div className={CN.wrapper}>
        <div className={CN.header}>
          <div
            data-bem="DeclineAppointment_arrowGoBack"
            className={CN.arrowGoBack}
            role="button"
            tabIndex={0}
            onClick={onClose}
          >
            <img src={IMG_ARROW} alt="Arrow go back" className={CN.arrowImg} />
          </div>
        </div>
        <IconStatus variant="warning">
          <Icon variant="warning" width="xl" />
        </IconStatus>

        <div className={CN.container}>
          <div className={CN.title}>Are you sure you want to decline this request?</div>
          <div className={CN.text}>
            <div className={CN.subTitle}>
              The {salonName} may contact you to cancel your appointment if the deposit is not paid.
            </div>
          </div>
        </div>
      </div>
      <Footer data-bem="DeclineAppointment__footer">
        <Button variant="error" width="fixed" onClick={onDecline}>
          DECLINE REQUEST
        </Button>
      </Footer>
    </div>
  );
};

export default DeclineAppointment;
