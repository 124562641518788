// @flow

import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import Navigation from 'invite/components/Navigation.component';
import moment from 'moment';

import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import { withTheme } from 'providers/Theme.provider';

import { getSalonDetails, updateTotalPrice } from 'booking/actions';
import Spinner from 'common/Spinner.component';
import createBooking, { fbPixelTracking, getBookingCostFunc, setPageVisitedFunc } from 'lib/utils';
import type { SalonDetails } from 'types/salonDetails';
import type { Service } from 'types/service';
import type { Stylist } from 'types/stylist';

import SummaryComponent from './Summary';

import CN from './SummaryWrap.module.scss';

type Props = {
  salonDetails: SalonDetails,
  selectedServices: Service[],
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  selectedAppointmentTime: moment | null,
  totalPrice: string,
  selectedServices: Service[],
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  appointment_cost: number,
  pay_today: number,
  bookingResponse: any,
  cognito: any,
  history: any,
  match: {
    params: {
      id: string,
    },
  },
  isWaitList: boolean,
  getSalonDetails: (salonId: number) => void,
  updateTotalPrice: (price: string) => void,
  cardDetails: any,
};

type State = {
  shouldMount: boolean,
  submitDisabled: boolean,
  isLoading: boolean,
};

export class SummaryWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shouldMount: false,
      submitDisabled: false,
      isLoading: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    getBookingCostFunc();
    if (!this.props.salonDetails.id) {
      this.props.getSalonDetails(parseInt(this.props.match.params.id, 10));
    }
    this.setState({ shouldMount: true });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setPageVisitedFunc('summary');
    fbPixelTracking(this.props.salonDetails.fb_details.fb_pixel_id, 'AddToCart', {
      services: this.props.selectedServices,
      cost: this.props.totalPrice,
    });
  }

  CheckServiceStartTime() {
    const selectedStylists = Object.assign({}, this.props.selectedStylists);
    const serviceIds = this.props.selectedServices.map(service => service.id);
    let startTime;
    let endTime;

    for (let i = 0; i < serviceIds.length; i += 1) {
      const selectedStylist: Stylist = selectedStylists[serviceIds[i].toString()];
      // first service

      if (i === 0) {
        startTime = moment(this.props.selectedAppointmentTime);
        selectedStylist.start_time = startTime.toISOString();
        endTime = startTime
          .add(moment.duration(selectedStylist.processing))
          .add(moment.duration(selectedStylist.duration));
      } else {
        startTime = endTime;

        const p = selectedStylist.processing;
        const d = selectedStylist.duration;
        if (startTime) {
          selectedStylist.start_time = startTime.toISOString();
          if (p) {
            endTime = startTime.add(moment.duration(p));
          }
          if (d) {
            endTime = startTime.add(moment.duration(d));
          }
        }
      }
    }
  }

  btnClick = () => {
    if (this.state.submitDisabled) {
      return;
    }
    this.CheckServiceStartTime();
    this.setState({ submitDisabled: true, isLoading: true });
    if (!this.props.cognito.user || this.props.cognito.state !== 'LOGGED_IN') {
      this.props.history.push(`/salon/${this.props.match.params.id}/login`);
    } else if (
      this.props.salonDetails.needs_card &&
      !this.props.isWaitList &&
      !(this.props.appointment_cost === 0 && this.props.pay_today === 0)
    ) {
      if (this.props.cardDetails && this.props.cardDetails.last4) {
        this.props.history.push(`/salon/${this.props.match.params.id}/confirm-with-card`);
      } else {
        this.props.history.push(`/salon/${this.props.match.params.id}/add-new-card`);
      }
    } else {
      // not need card or do need card but don't need to pay anything
      createBooking();
      const interval = setInterval(() => {
        this.checkBookingStatus(interval);
      }, 500);
    }
  };

  checkBookingStatus = (intervalId: any) => {
    if (this.props.bookingResponse && this.props.bookingResponse.createBookingFailed !== true) {
      clearInterval(intervalId);
      this.setState({ isLoading: false });
      this.props.history.push(`/salon/${this.props.match.params.id}/bookingConfirmed`);
    }
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { isDarkMode, isNeutralMode } = this.props;

    return (
      <div
        data-bem="SummaryWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          {this.state.isLoading && <Spinner variant="overlay" />}
          <Navigation match={this.props.match} />
          <div className={CN.header}>
            <div
              data-bem="SummaryWrap_arrowGoBack"
              className={CN.arrowGoBack}
              role="button"
              tabIndex={0}
              onClick={this.handleGoBack}
            >
              <img src={IMG_ARROW} alt="Arrow go back" className={CN.arrowImg} />
            </div>
          </div>
          <div className={CN.title}>Review details to continue</div>

          {this.state.shouldMount && (
            <div data-bem="SummaryWrap__SummaryComponent">
              <SummaryComponent
                updateTotalPrice={this.props.updateTotalPrice}
                appointment_cost={this.props.appointment_cost}
              />
            </div>
          )}
        </div>

        <Footer data-bem="SummaryWrap__footer">
          <div className={CN.footerInnerContainer}>
            <Button variant="primary" width="fixed" onClick={this.btnClick}>
              NEXT
            </Button>
          </div>
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    booking: {
      salonDetails,
      selectedServices,
      selectedStylists,
      totalPrice,
      notes,
      selectedAppointmentTime,
      selectedPatchTime,
      bookingResponse,
      pageVisited,
      cardDetails,
      isWaitList,
    },
    billing,
    cognito,
    salon,
  } = state;
  return {
    salonDetails,
    selectedServices,
    selectedStylists,
    totalPrice,
    billing,
    appointment_cost: billing.appointment_cost,
    pay_today: billing.pay_today,
    cognito,
    notes,
    selectedAppointmentTime,
    selectedPatchTime,
    ncob_deposit_settings: salon.ncob_deposit_settings,
    bookingResponse,
    pageVisited,
    cardDetails,
    isWaitList,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getSalonDetails(salonId: number) {
    dispatch(getSalonDetails(salonId));
  },
  updateTotalPrice(price: string) {
    dispatch(updateTotalPrice(price));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(SummaryWrap));
