import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import IMG_CHECK from 'assets/images/icons/check-circle-purple.svg';
import IMG_GIFT from 'assets/images/icons/gift-icon-dark.svg';
import IMG_SPARKLE from 'assets/images/icons/sparkles-icon-white.svg';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import LINK from 'constants/link.const';
import S from 'constants/string.const';
import useAef from 'hooks/useAef.hook';
import { useDispatcher } from 'providers/Dispatcher.provider';
import { useCurrentSalonId, useSalonBasicInfo } from 'providers/SalonBasicInfo.provider';
import { useTheme } from 'providers/Theme.provider';
import fromPropsToQuery from 'utils/converters/props/fromPropsToQuery.util';
import { formatDayToYearOrdinal } from 'utils/date/format.util';
import linkTo from 'utils/links/linkTo.util';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';

import beGiftVoucher from 'booking/beEgiftVoucher.action';

import CN from './ConfirmVoucherPage.module.scss';

const L = logger('ConfirmVoucherPage');

const ConfirmVoucherPage = props => {
  const { session_id: sessionId } = fromPropsToQuery(props);

  const { salonName, salonAddressLine1, salonPostCode, salonCity } = useSalonBasicInfo();
  const salonId = useCurrentSalonId();
  const { isDarkMode, isNeutralMode } = useTheme();
  const dispatchBeGiftVoucher = useDispatcher(beGiftVoucher);

  const { value: voucher, isLoading, error } = useAef(
    // has fields: voucher_code, expiry_date, amount, address_1, address_2, city, postcode, purchased_by_client_email
    async () => {
      if (!sessionId) return void 1;
      return asObject(await dispatchBeGiftVoucher({ sessionId })).response;
    },
  );

  const { voucher_code: voucherCode, amount, expiry_date: expiryDate, purchased_by_client_email: userEmail } = asObject(
    voucher,
  );
  const linkToSalon = linkTo({ pattern: LINK.salon, params: { salonId } });
  const linkToVoucherSend = linkTo({ pattern: LINK.voucherSend, params: { salonId, sessionId } });

  const componentClasses = cn({
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  if (error) L.warn('()', isLoading, error, voucher);

  return (
    <div data-bem="ConfirmVoucherPage" className={componentClasses}>
      <div className={CN.statusIcon}>
        <img src={IMG_CHECK} alt="successful icon" />
      </div>
      <div className={CN.title}>
        Payment successful! <br />
        Your voucher has been sent.
      </div>

      <div className={CN.card}>
        <img src={IMG_SPARKLE} alt="sparkle icon" className={CN.sparkleTop} />
        <img src={IMG_SPARKLE} alt="sparkle icon" className={CN.sparkleBottom} />
        Your voucher code is:
        <div className={CN.code}>{voucherCode}</div>
      </div>
      <div className={CN.details}>
        <div className={CN.subtitle}>
          <img src={IMG_GIFT} alt="Gift icon" className={CN.icon} />
          Voucher Details
        </div>
        <div className={CN.row}>
          Amount:
          <span className={CN.value}>
            {S.pound}
            {amount}
          </span>
        </div>
        <div className={CN.row}>
          Expiry date:
          <span className={CN.value}>{formatDayToYearOrdinal(expiryDate)}</span>
        </div>
        <div className={CN.row}>
          Redeemable at:
          <span className={CN.value}>
            <span className={CN.block}>{salonName}</span>
            <span className={CN.block}>{salonAddressLine1}</span>
            <span className={CN.block}>{salonCity}</span>
            <span className={CN.block}>{salonPostCode}</span>
          </span>
        </div>
        <div className={CN.row}>
          Sent via email to:
          <span className={CN.value}>{userEmail}</span>
        </div>
      </div>
      <Footer data-bem="ConfirmVoucherPage__footer">
        <NavLink to={linkToVoucherSend} data-bem="BuyVoucherButton" className={CN.link}>
          <Button variant="primary" width="fixed">
            SEND TO RECIPIENT
          </Button>
        </NavLink>
        <NavLink to={linkToSalon} data-bem="BuyVoucherButton" className={CN.link}>
          <Button variant="secondary" width="fixed">
            BACK TO HOME
          </Button>
        </NavLink>
      </Footer>
    </div>
  );
};

export default ConfirmVoucherPage;
