import { API_ROOT } from 'config/index';
import { AUTH_API } from 'middleware/api';
import withOptions from 'utils/functions/withOptions.util';
import actionType from 'utils/mw/actionType.util';

const ATAG = 'beGetSalonUpcomingBookings';
const AT = actionType(ATAG);

const beGetSalonUpcomingBookings = withOptions(({ bookingsSalonId, cognitoUserName, page = 1 }) => ({
  [AUTH_API]: {
    apiRoot: API_ROOT,
    endpoint: `ncob/client/appointments/${bookingsSalonId}/future/${cognitoUserName}/?page=${page}`,
    authenticated: true,
    types: [AT.init, AT.pass, AT.fail, AT.done],
    config: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  },
}));

beGetSalonUpcomingBookings.ATAG = ATAG;
beGetSalonUpcomingBookings.AT = AT;

export default beGetSalonUpcomingBookings;
