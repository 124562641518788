import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

import COGNITO from 'constants/cognito.const';
import { useTheme } from 'providers/Theme.provider';

import clearHalfCache from 'lib/clearHalfCache';

import CN from './Navigation.module.scss';

const BaseNavigation = ({ cognito = {}, history }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const gotoMyProfile = () => {
    if (!history) return;

    const salonId = history.location.pathname.split('/salon/')[1].split('/')[0];
    const pathname = `/salon/${salonId}/my-profile`;
    const login = `/salon/${salonId}/profile-login`;
    clearHalfCache();
    if (cognito && cognito.state === COGNITO.loggedIn) {
      history.push(pathname);
    } else {
      history.push({
        pathname: login,
        state: { fromMyBook: true },
      });
    }
  };

  const gotoMyBooking = () => {
    if (!history) return;

    const salonId = history.location.pathname.split('/salon/')[1].split('/')[0];
    const pathname = `/salon/${salonId}/my-book`;
    const login = `/salon/${salonId}/profile-login`;
    clearHalfCache();
    if (cognito && cognito.state === COGNITO.loggedIn) {
      history.push(pathname);
    } else {
      history.push({
        pathname: login,
        state: { fromMyBook: true },
      });
    }
  };

  const gotoMyFeed = () => {
    if (!history) return;

    const salonId = history.location.pathname.split('/salon/')[1].split('/')[0];
    const pathname = `/salon/${salonId}/my-feeds`;
    const login = `/salon/${salonId}/profile-login`;
    clearHalfCache();
    if (cognito && cognito.state === COGNITO.loggedIn) {
      history.push(pathname);
    } else {
      history.push({
        pathname: login,
        state: { fromMyBook: true },
      });
    }
  };

  const componentClasses = cn({
    'app-header text-center': true,
    [CN.component]: true,
    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <div data-bem="BaseNavigation" className={componentClasses}>
      <nav>
        <ul className={CN.navigation}>
          <li className={CN.navItem}>
            <div className={CN.navLink} role="button" tabIndex={0} onClick={gotoMyProfile}>
              <div className={CN.navContent}>
                <div className={CN.iconProfile} />
                <div>Profile</div>
              </div>
            </div>
          </li>
          <li className={CN.navItem}>
            <div className={CN.navLink} role="button" tabIndex={0} onClick={gotoMyBooking}>
              <div className={CN.navContent}>
                <div className={CN.iconBooking} />
                <div>Bookings</div>
              </div>
            </div>
          </li>
          <li className={CN.navItem}>
            <div className={CN.navLink} role="button" tabIndex={0} onClick={gotoMyFeed}>
              <div className={CN.navContent}>
                <div className={CN.iconContainer}>
                  <div className={CN.iconPound} />
                </div>
                <div>Deposits</div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const mapStateToProps = state => {
  const { booking, cognito } = state;
  const { salonDetails } = booking;
  return {
    salonDetails,
    cognito,
  };
};

export default withRouter(connect(mapStateToProps)(BaseNavigation));
