// @flow

/* eslint-disable react/no-unused-prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Button from 'components/Button.component';
import Footer from 'components/Footer.component';

import { clearBillingInfo } from '../../actions/billing';
import { fbPixelTracking, setPageVisitedFunc } from '../../lib/utils';
import type { Service } from '../../types/service';
import type { ServicesToBook } from '../../types/servicesToBook';
import type { Stylist, StylistsForService } from '../../types/stylistsForService';
import {
  clearAvailableStylists,
  clearSelectedStylists,
  clearServicesToBook,
  getSalonDetails,
  getStylistsForService,
  getUserCardDetails,
  setSelectedStylists,
  setServicesToBook,
  setServicesToShow,
} from '../actions';

import StylistSelectionComponent from './StylistSelection';

type Props = {
  cognito: any,
  history: string[],
  match: {
    params: {
      id: string,
    },
  },
  pageVisited: [],
  isWaitList: boolean,
  availableStylists: any,
  salonDetails: any,
  availableWaitListStylists: any,
  cardDetails: any,
  username: string,
  needs_card: boolean,
  billing: any,
  salonId: number,
  totalPrice: string,
  selectedServices: Service[],
  selectedAppointmentTime: moment,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  selectedPatchTime: moment | null,
  servicesToShow: {
    name: string,
    service_id: number,
    stylists: Stylist[],
  }[],
  servicesToBook: ServicesToBook,
  notes: string,
  isFetching: boolean,
  availableStylists: StylistsForService,
  ncob_deposit_settings: string,
  setSelectedStylists: (
    selectedStylists: { [serviceId: string]: null | Stylist },
    serviceId?: number,
    stylist?: any,
  ) => void,
  setServicesToBook: (servicesTobook: ServicesToBook[]) => void,
  clearBillingInfo: (clearBookingInfo?: any) => void,
  getStylistsForService: (
    salonId: number,
    serviceId: number,
    dateTime: moment,
    services: number[],
    stylists: number[],
    isWaitList?: boolean,
  ) => void,
  setServicesToShow: (
    services: {
      name: string,
      service_id: number,
      stylists: Stylist[],
    }[],
  ) => void,
  clearSelectedStylists: () => void,
  clearAvailableStylists: () => void,
  clearServicesToBook: () => void,
  getSalonDetails: (id: number) => void,
  getUserCardDetails: (username: string) => void,
  book_ncob_with_same: boolean,
  stylistsForServiceWeekly: any,
};

type State = {
  servicesToBook: any,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  getCardStarted: boolean,
  isButtonDisabled: boolean,
};

export class StylistSelectionWrap extends React.Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props);
    let selectedStylists = {};
    if (props.selectedStylists && Object.keys(props.selectedStylists).length) {
      ({ selectedStylists } = props);
    } else if (state.selectedStylists && Object.keys(state.selectedStylists).length) {
      ({ selectedStylists } = state);
    }
    let servicesToBook = [];
    if (props.servicesToShow) {
      ({ servicesToBook } = props);
    }
    this.state = {
      selectedStylists,
      getCardStarted: false,
      servicesToBook,
      isButtonDisabled: true,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.clearBillingInfo('clearBookingInfo');
    // console.log('this.props.ncob_deposit_settings', this.props.ncob_deposit_settings);
    if (!this.props.ncob_deposit_settings) {
      this.props.getSalonDetails(parseInt(this.props.match.params.id, 10));
    }
    if (this.props.selectedServices.length === 0) {
      this.props.history.push(`/salon/${this.props.match.params.id}`);
      return;
    }
    const serviceIds = this.props.selectedServices.map(service => service.id);

    const stylistIds = [];

    for (let i = 0; i < serviceIds.length; i += 1) {
      const stylist = this.props.selectedStylists[serviceIds[i].toString()];
      if (stylist) {
        if (stylist !== 'any') {
          stylistIds.push(stylist.stylist_id);
        } else {
          stylistIds.push(0);
        }
      } else {
        stylistIds.push(0);
      }
    }
    const serviceNeedStylist = [];
    serviceIds.forEach((serviceId, i) => {
      if (this.props.selectedStylists[serviceId.toString()] === null) {
        serviceNeedStylist.push([i, serviceId]);
      }
    });
    if (this.state.servicesToBook.length !== serviceIds.length && serviceNeedStylist.length > 0) {
      // find the first service that hasn't got a stylist
      const startTime: moment = moment(this.props.selectedAppointmentTime);
      for (let i = 0; i < serviceNeedStylist.length; i += 1) {
        /* only need to add start time for services that are not the first procedure */

        // for (j = 0; j < serviceIds.length; j += 1) {
        if (serviceIds[0] === serviceNeedStylist[i][1]) {
          this.props.getStylistsForService(
            parseInt(this.props.match.params.id, 10),
            serviceNeedStylist[i][1],
            startTime,
            serviceIds,
            stylistIds,
          );
        }
      }
    }
    this.buildServicesToBook();
    this.checkFooterButton();
  }

  componentDidMount() {
    setPageVisitedFunc('stylistselect');
    fbPixelTracking(this.props.salonDetails.fb_details.fb_pixel_id);
    if (
      this.props.cognito.user &&
      this.props.cognito.state === 'LOGGED_IN' &&
      !this.props.cardDetails.last4 &&
      !this.state.getCardStarted
    ) {
      this.props.getUserCardDetails(this.props.username);
      this.setState({ getCardStarted: true });
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    /* when there is only one stylist available, select them, set the selectedStylists
        and go to next screen */
    if (Object.keys(nextProps.selectedStylists).length === nextProps.selectedServices.length) {
      if (Object.keys(this.state.selectedStylists).length === 0) {
        this.setState({ selectedStylists: nextProps.selectedStylists }, () => {
          if (!this.props.pageVisited.includes('summary')) {
            setTimeout(() => {
              this.btnClick(); // wait a second for the next button to be active
            }, 1000);
          }
        });
      }
    }
    if (this.props.availableStylists.data !== nextProps.availableStylists.data) {
      const servicesToBook = this.state.servicesToBook.slice();

      const timeSlots = Object.keys(nextProps.availableStylists.data);
      if (timeSlots.length > 0) {
        for (let i = 0; i < timeSlots.length; i += 1) {
          const slot = nextProps.availableStylists.data[timeSlots[i]];
          if (slot) {
            for (let j = 0; j < slot.options.length; j += 1) {
              let shouldAdd = true;
              for (let k = 0; k < servicesToBook.length; k += 1) {
                if (servicesToBook[k]) {
                  if (servicesToBook[k].service_id === slot.options[j].service_id) {
                    shouldAdd = false;

                    if (servicesToBook[k].stylists[0] === null || servicesToBook[k].stylists[0] === undefined) {
                      servicesToBook[k].stylists.splice(0, 1);
                    }
                    const allstylistforservices: number[] = servicesToBook[k].stylists.map(s => s.stylist_id);
                    if (!allstylistforservices.includes(slot.options[j].stylist_id)) {
                      servicesToBook[k].stylists.push(slot.options[j]);
                    }
                    break;
                  }
                }
              }

              if (shouldAdd) {
                servicesToBook.push({
                  name: slot.options[j].name,
                  service_id: slot.options[j].service_id,
                  stylists: [slot.options[j]],
                });
              }
            }
          }
        }
      }
      this.setState({ servicesToBook }, this.checkFooterButton());
    }

    // no 'I don't mind', all services has favourite stylists selected
    if (!Object.values(nextProps.selectedStylists).includes('any')) {
      // no more selection needed, go to next step
      if (Object.keys(nextProps.selectedStylists).length === nextProps.selectedServices.length) {
        this.CheckServiceStartTime();
        if (!this.props.pageVisited.includes('summary')) {
          this.props.history.push(`/salon/${this.props.match.params.id}/summary`);
        }
      }
    }
  }

  componentWillUnmount() {
    /* if don't clear these, the componentWillReceiveProps condition:
    if (this.props.availableStylists.data !== nextProps.availableStylists.data) would be false
    therefore servicesToBook's stylist would be null if we select 1st as any stylist, and
    2nd as favorite stylist, resulting in 1st stylist can't be selected */
    this.props.clearAvailableStylists();
    this.props.clearServicesToBook();
  }

  onCheckboxClick1 = (serviceId: number, stylist?: Stylist | null) => {
    const name = stylist ? stylist.stylist_name : '';
    let { selectedStylists } = this.state;
    if (window.analytics) {
      window.analytics.track(`salon${this.props.salonId.toString()}_click Clicked stylist`, {
        label: name,
        category: `salon${this.props.salonId.toString()}_click`,
      });
    }
    if (!selectedStylists) {
      selectedStylists = Object.assign({}, this.state.selectedStylists);
    }
    if (stylist) {
      selectedStylists[serviceId.toString()] = stylist;
    }
    let serviceIndex = 0;
    for (let i = 0; i < this.props.selectedServices.length; i += 1) {
      if (serviceId === this.props.selectedServices[i].id) {
        serviceIndex = i;
        break;
      }
    }
    this.setState({ selectedStylists }, () => {
      if (serviceIndex < this.props.selectedServices.length - 1) {
        const startTime = moment(this.props.selectedAppointmentTime);
        const serviceIds = this.props.selectedServices.map(service => service.id);
        const stylistIds = [];

        for (let i = 0; i < serviceIds.length; i += 1) {
          const s: Stylist | null = selectedStylists[serviceIds[i].toString()];
          if (s) {
            startTime.add(moment.duration(s.duration));
            startTime.add(moment.duration(s.processing));
          }
        }

        // const keys = Object.keys(selectedStylists);
        for (let i = 0; i < serviceIds.length; i += 1) {
          const s = selectedStylists[serviceIds[i].toString()];
          if (s && s !== 'any') {
            stylistIds.push(s.stylist_id);
          } else {
            stylistIds.push(0);
          }
        }
        const stylistForNextService: Stylist | null = this.state.selectedStylists[
          serviceIds[serviceIndex + 1].toString()
        ];
        if (stylistForNextService === null || stylistForNextService === undefined) {
          this.props.getStylistsForService(
            parseInt(this.props.match.params.id, 10),
            serviceIds[serviceIndex + 1],
            startTime,
            serviceIds,
            stylistIds,
            this.props.isWaitList,
          );
        } else {
          this.onCheckboxClick1(serviceIds[serviceIndex + 1], stylistForNextService);
        }
      }
      this.checkFooterButton();
    });
  };

  CheckServiceStartTime() {
    const selectedStylists = Object.assign({}, this.state.selectedStylists);
    const serviceIds = this.props.selectedServices.map(service => service.id);
    let startTime;
    let endTime;

    for (let i = 0; i < serviceIds.length; i += 1) {
      const selectedStylist: Stylist = selectedStylists[serviceIds[i].toString()];
      // first service

      if (i === 0) {
        startTime = moment(this.props.selectedAppointmentTime);
        selectedStylist.start_time = startTime.toISOString();
        endTime = startTime
          .add(moment.duration(selectedStylist.processing))
          .add(moment.duration(selectedStylist.duration));
      } else {
        startTime = endTime;

        const p = selectedStylist.processing;
        const d = selectedStylist.duration;
        if (startTime) {
          selectedStylist.start_time = startTime.toISOString();
          if (p) {
            endTime = startTime.add(moment.duration(p));
          }
          if (d) {
            endTime = startTime.add(moment.duration(d));
          }
        }
      }
    }
  }

  buildServicesToBook() {
    const serviceIds = this.props.selectedServices.map(service => service.id);

    const servicesToBook: any[] = [];
    const { selectedServices } = this.props;
    serviceIds.forEach((serviceId: any) => {
      let stylist = [this.props.selectedStylists[serviceId]];
      if (stylist === 'any') {
        stylist = this.props.stylistsForServiceWeekly[serviceId];
      }
      const service = selectedServices.filter(s => s.id === serviceId)[0];
      servicesToBook.push({
        name: service.name,
        service_id: service.id,
        stylists: stylist,
      });
    });
    this.props.setServicesToBook(servicesToBook);
    this.setState({ servicesToBook });
  }

  checkFooterButton = () => {
    let isButtonDisabled = false;
    const stylistIds = Object.keys(this.state.selectedStylists);
    if (stylistIds.length === 0 || stylistIds.length !== this.props.selectedServices.length) {
      isButtonDisabled = true;
    } else {
      for (let i = 0; i < stylistIds.length; i += 1) {
        const stylistId = stylistIds[i];
        if (!this.state.selectedStylists[stylistId]) {
          isButtonDisabled = true;
          break;
        }
      }
    }
    this.setState({ isButtonDisabled });
  };
  btnClick = () => {
    if (this.state.isButtonDisabled) {
      return;
    }
    this.props.setSelectedStylists(this.state.selectedStylists);
    this.CheckServiceStartTime();
    this.props.history.push(`/salon/${this.props.match.params.id}/summary`);
  };

  render() {
    return (
      <div>
        <div className="app-content">
          {this.props.isFetching && (
            <div className="spinner-container">
              <div className="load-spinner" />
            </div>
          )}
          <StylistSelectionComponent
            services={this.state.servicesToBook}
            selectedStylists={this.state.selectedStylists}
            onCheckboxClick={this.onCheckboxClick1}
            selectedServices={this.props.selectedServices}
            availableWaitListStylists={this.props.availableWaitListStylists}
            isWaitList={this.props.isWaitList}
            book_ncob_with_same={this.props.book_ncob_with_same}
          />
        </div>

        <Footer data-bem="CreateAccountFormWrap__footer">
          <Button
            variant="primary"
            width="fixed"
            onClick={() => this.btnClick()}
            disabled={this.state.isButtonDisabled}
          >
            CONTINUE
          </Button>
        </Footer>
      </div>
    );
  }
}

function mapStateToProps(state): any {
  const { booking, cognito, billing } = state;
  const { needs_card } = booking.salonDetails;
  const {
    isFetching,
    selectedSlot,
    selectedServices,
    selectedStylists,
    selectedAppointmentTime,
    selectedPatchTime,
    availableStylists,
    availableWaitListStylists,
    servicesToShow,
    servicesToBook,
    salonDetails,
    cardDetails,
    notes,
    pageVisited,
    isWaitList,
    stylistsForServiceWeekly,
  } = booking;
  return {
    cognito,
    billing,
    needs_card,
    isFetching,
    isWaitList,
    selectedSlot,
    selectedServices,
    selectedStylists,
    notes,
    selectedAppointmentTime,
    selectedPatchTime,
    availableStylists,
    availableWaitListStylists,
    servicesToShow,
    servicesToBook,
    salonDetails,
    salonId: salonDetails.id,
    ncob_deposit_settings: salonDetails.ncob_deposit_settings,
    cardDetails,
    pageVisited,
    stylistsForServiceWeekly,
    username: cognito.user ? cognito.user.username : '',
    book_ncob_with_same: salonDetails.book_ncob_with_same,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedStylists(selectedStylists: { [serviceId: string]: null | Stylist }, serviceId?: number, stylist?: any) {
      dispatch(setSelectedStylists(selectedStylists, serviceId, stylist));
    },
    setServicesToBook(servicesToBook: ServicesToBook[]) {
      dispatch(setServicesToBook(servicesToBook));
    },
    getStylistsForService(
      salonId: number,
      serviceId: number,
      dateTime: moment,
      services: number[],
      stylists: number[],
      isWaitList?: boolean,
    ) {
      dispatch(getStylistsForService(salonId, serviceId, dateTime, services, stylists, isWaitList));
    },
    clearAvailableStylists() {
      dispatch(clearAvailableStylists());
    },
    clearSelectedStylists() {
      dispatch(clearSelectedStylists());
    },
    clearServicesToBook() {
      dispatch(clearServicesToBook());
    },
    setServicesToShow(services: any) {
      dispatch(setServicesToShow(services));
    },
    getSalonDetails(id: number) {
      dispatch(getSalonDetails(id));
    },
    getUserCardDetails(username: string) {
      dispatch(getUserCardDetails(username));
    },
    clearBillingInfo() {
      dispatch(clearBillingInfo());
    },
  };
}

export default connect<any, any, Props, any, any, any>(mapStateToProps, mapDispatchToProps)(StylistSelectionWrap);
