// @flow

/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import Navigation from 'invite/components/Navigation.component';
import moment from 'moment';

import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import { withTheme } from 'providers/Theme.provider';

import {
  checkWaitListForService,
  clearAvailableDays,
  clearAvailableSlots,
  clearSelectedServices,
  clearSelectedStylists,
  clearServicesToShow,
  getBookingAvailability,
  getStylistsForService,
  getWeeklyAvailability,
  getWeeklyAvailabilityRepeat,
  setAppointmentTime,
  setAvailabilitySlot,
  setSelectedStylists,
  updateFirstAvailableDate,
} from 'booking/actions';
import StartAgainAndBackButtonsComponent from 'booking/common/StartAgainAndBackButtons.component';
import TimeSelectionComponent from 'booking/timeSelection/TimeSelection';
import { setPageVisitedFunc } from 'lib/utils';
import type { AvailabilitySlot } from 'types/availabilitySlot';
import type { AvailableDays } from 'types/availableDays';
import type { Service } from 'types/service';
import type { Stylist } from 'types/stylistsForService';

import CN from './TimeSelectionWrap.module.scss';

type Props = {
  history: any,
  match: {
    params: {
      id: string,
    },
  },
  selectedStylists: {
    [serviceId: number]: null | Stylist,
  },
  stylistsForServiceWeekly: any,
  location: any,
  skipToday: boolean,
  selectedAppointmentTime: moment | null,
  check_ncob_availability_until: number,
  selectedServices: Service[],
  availableDays: AvailableDays,
  slotMessage: string,
  isWaitList: boolean,
  has_waitlist: boolean,
  getBookingAvailability: (
    salonId: number,
    appointmentDate: moment,
    services: number[],
    stylists: number[],
    isWaitList: boolean,
  ) => void,
  getWeeklyAvailability: (
    salonId: number,
    services: number[],
    stylists: number[],
    startDateOfWeek: moment,
    continueCheck: number,
    isWaitList?: boolean,
  ) => void,
  getWeeklyAvailabilityRepeat: (
    salonId: number,
    services: number[],
    stylists: number[],
    startDateOfWeek: moment,
    isWaitList?: boolean,
  ) => void,
  setAppointmentTime: (time: any) => void,
  setAvailabilitySlot: (slot: any) => void,
  clearAvailableSlots: () => void,
  clearAvailableDays: () => void,
  clearSelectedStylists: () => void,
  updateFirstAvailableDate: (date: moment | null) => void,
  setSelectedStylists: (
    selectedStylists: { [serviceId: string]: null | Stylist },
    serviceId?: number,
    stylist?: any,
  ) => void,
  getStylistsForService: (
    salonId: number,
    serviceId: number,
    dateTime: moment,
    services: any[],
    stylists: number[],
    isWaitList?: boolean,
  ) => void,
  showWaitList: boolean,
  checkWaitListForService: (salonId: string, services: number[]) => void,
  ncob_stylists_count: number,
  book_ncob_with_same: boolean,
};

type State = {
  appointmentTime: null | moment,
  slot: null | AvailabilitySlot,
  firstAvailableDate: string,
  firstAvailableDateSet: boolean,
  isWaitList: boolean,
  checkWaitListForServiceCalled: boolean,
  selectAnyStylistForAll: boolean,
};

export class TimeSelectionWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let appointmentTime = null;
    if (props.selectedAppointmentTime !== null) {
      appointmentTime = props.selectedAppointmentTime;
    }
    this.state = {
      appointmentTime,
      firstAvailableDate: '',
      firstAvailableDateSet: false,
      slot: null,
      isWaitList: false,
      checkWaitListForServiceCalled: false,
      selectAnyStylistForAll: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.selectedServices.length === 0) {
      this.props.history.push(`/salon/${this.props.match.params.id}`);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setPageVisitedFunc('timeselect');
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.isWaitList && !this.state.isWaitList) {
      this.setState({ isWaitList: true });
    }
    if (!this.state.checkWaitListForServiceCalled && !this.props.showWaitList) {
      const serviceIds = this.props.selectedServices.map(service => service.id);
      this.props.checkWaitListForService(this.props.match.params.id, serviceIds);
      this.setState({ checkWaitListForServiceCalled: true });
    }
    if (!this.props.location.state && this.props.match.params.id !== nextProps.match.params.id) {
      this.props.history.push(`/salon/${nextProps.match.params.id}`);
    } else {
      if (this.state.firstAvailableDate || Object.keys(nextProps.availableDays).length === 0) {
        return;
      }
      if (nextProps.availableDays !== this.props.availableDays && !this.state.firstAvailableDateSet) {
        let firstAvailableDate;
        const { availableDays } = nextProps;

        const keys = Object.keys(availableDays);
        for (let i = 0; i < keys.length; i += 1) {
          if (availableDays[keys[i]] === true) {
            firstAvailableDate = keys[i];
            this.setState({ firstAvailableDateSet: true, firstAvailableDate });
            // add a firstAvailableDate in store
            this.props.updateFirstAvailableDate(moment(firstAvailableDate));
            break;
          }
        }
      }
    }
    if (nextProps.selectedStylists) {
      const stylistsArr = Object.values(nextProps.selectedStylists);
      const favStylists = stylistsArr.filter(s => s !== null);
      if (favStylists.length === 0 || this.props.ncob_stylists_count === 1) {
        const selectAnyStylistForAll = true;
        this.setState({ selectAnyStylistForAll });
      }
    }
  }

  componentWillUnmount() {
    this.setState({ firstAvailableDateSet: false, firstAvailableDate: '' });
    this.props.updateFirstAvailableDate(null);
  }

  setTime = (time: moment, slot: AvailabilitySlot) => {
    this.setState({ appointmentTime: time, slot });
  };

  getBookingAvailability = (appointmentDate: moment, isWaitList: boolean) => {
    const serviceIds = this.props.selectedServices.map(service => service.id);
    const stylistIds = [];

    for (let i = 0; i < serviceIds.length; i += 1) {
      const stylist = this.props.selectedStylists[serviceIds[i]];
      if (stylist) {
        if (stylist !== 'any') {
          stylistIds.push(stylist.stylist_id);
        } else {
          stylistIds.push(0);
        }
      } else {
        stylistIds.push(0);
      }
    }
    const salonId = parseInt(this.props.match.params.id, 10);
    this.props.getBookingAvailability(salonId, appointmentDate, serviceIds, stylistIds, isWaitList);
  };

  getWeeklyAvailability = (weekStartDate: moment, isWaitList: boolean, continueCheck: number = 1) => {
    const salonId = parseInt(this.props.match.params.id, 10);
    const serviceIds = this.props.selectedServices.map(service => service.id);
    const stylistIds = [];
    for (let i = 0; i < serviceIds.length; i += 1) {
      const stylist = this.props.selectedStylists[serviceIds[i]];
      if (stylist && !isWaitList) {
        if (stylist !== 'any') {
          stylistIds.push(stylist.stylist_id);
        } else {
          stylistIds.push(0);
        }
      } else {
        stylistIds.push(0);
      }
    }
    if (serviceIds.length && stylistIds.length) {
      this.props.getWeeklyAvailability(salonId, serviceIds, stylistIds, weekStartDate, continueCheck, isWaitList);
    }
  };

  getWeeklyAvailabilityRepeat = (weekStartDate: moment, isWaitList: boolean) => {
    const salonId = parseInt(this.props.match.params.id, 10);
    const serviceIds = this.props.selectedServices.map(service => service.id);
    const stylistIds = [];

    for (let i = 0; i < serviceIds.length; i += 1) {
      const stylist = this.props.selectedStylists[serviceIds[i]];
      if (stylist && !isWaitList) {
        if (stylist !== 'any') {
          stylistIds.push(stylist.stylist_id);
        } else {
          stylistIds.push(0);
        }
      } else {
        stylistIds.push(0);
      }
    }
    if (serviceIds.length && stylistIds.length) {
      this.props.getWeeklyAvailabilityRepeat(salonId, serviceIds, stylistIds, weekStartDate, isWaitList);
    }
  };

  btnClick = () => {
    if (this.state.appointmentTime !== null) {
      const { appointmentTime } = this.state;
      this.props.setAppointmentTime(moment(appointmentTime));
      this.props.setAppointmentTime(moment(appointmentTime));
      if (this.state.slot !== null) {
        this.props.setAvailabilitySlot(this.state.slot);
      }
      const serviceNeedStylist = [];
      Object.keys(this.props.selectedStylists).map(key => {
        if (this.props.selectedStylists[parseInt(key, 10)] === null) {
          serviceNeedStylist.push(key);
        }
        return null;
      });
      if (serviceNeedStylist.length === 0) {
        const selectedStylists = Object.assign({}, this.props.selectedStylists);

        const keys = Object.keys(selectedStylists);
        let startTime;
        let endTime;
        const myInterval = setInterval(() => {
          if (this.props.selectedAppointmentTime) {
            clearInterval(myInterval);
            for (let i = 0; i < keys.length; i += 1) {
              const stylist: Stylist = selectedStylists[keys[i]];
              if (i === 0) {
                startTime = moment(this.props.selectedAppointmentTime);
                stylist.start_time = startTime.toISOString();
                endTime = startTime.add(moment(stylist.duration)).add(moment(stylist.processing));
              } else if (endTime) {
                stylist.start_time = endTime.toISOString();

                const p = stylist.processing;
                const d = stylist.duration;

                if (p) {
                  endTime = endTime.add(moment.duration(p));
                }
                if (d) {
                  endTime = endTime.add(moment.duration(d));
                }
              }
            }
            if (!this.props.location.state) {
              if (this.props.ncob_stylists_count > 1) {
                this.props.history.push(`/salon/${this.props.match.params.id}/stylistselect`);
              } else {
                this.props.history.push(`/salon/${this.props.match.params.id}/summary`);
              }
            }
          }
        }, 500);
      }
      // for show All availability
      const stylistIds = [];

      const serviceIds = this.props.selectedServices.map(service => service.id);
      for (let i = 0; i < serviceIds.length; i += 1) {
        stylistIds.push(0);
      }
      if (!this.state.isWaitList) {
        this.props.getStylistsForService(
          parseInt(this.props.match.params.id, 10),
          serviceIds[0],
          moment(this.state.appointmentTime),
          serviceIds,
          stylistIds,
          false,
        );
        if (this.props.ncob_stylists_count > 1) {
          this.props.history.push(`/salon/${this.props.match.params.id}/stylistselect`);
        } else {
          this.props.history.push(`/salon/${this.props.match.params.id}/summary`);
        }
      }
      // for put me on waitlist
      if (this.state.appointmentTime && this.props.isWaitList) {
        this.props.getStylistsForService(
          parseInt(this.props.match.params.id, 10),
          serviceIds[0],
          moment(this.state.appointmentTime),
          serviceIds,
          stylistIds,
          true,
        );
        if (this.props.ncob_stylists_count > 1) {
          this.props.history.push(`/salon/${this.props.match.params.id}/stylistselect`);
        } else {
          this.props.history.push(`/salon/${this.props.match.params.id}/summary`);
        }
      }
    }
  };

  updateFirstAvailableDate = (date: any) => {
    this.setState({
      firstAvailableDateSet: false,
      firstAvailableDate: '',
    });
    this.props.updateFirstAvailableDate(date);
  };

  clearSelectedTime = () => {
    this.setState({
      appointmentTime: null,
      slot: null,
    });
    this.props.setAppointmentTime(null);
    this.props.setAvailabilitySlot(null);
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { isDarkMode, isNeutralMode } = this.props;

    return (
      <div
        data-bem="TimeSelectionWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className={CN.wrapper}>
          {/* TODO: move navigation and header buttons in a separate component */}
          <Navigation match={this.props.match} />
          <div className={CN.header}>
            <StartAgainAndBackButtonsComponent />
          </div>
          <div className={CN.title}>Select a time for your appointment</div>
          <TimeSelectionComponent
            slotMessage={this.props.slotMessage}
            firstAvailableDate={this.state.firstAvailableDate}
            firstAvailableDateSet={this.state.firstAvailableDateSet}
            getAvailability={this.getBookingAvailability}
            getWeeklyAvailability={this.getWeeklyAvailability}
            getWeeklyAvailabilityRepeat={this.getWeeklyAvailabilityRepeat}
            setTime={this.setTime}
            skip_today={this.props.skipToday}
            selectedTime={this.state.appointmentTime}
            selectedStylists={this.props.selectedStylists}
            clearSelectedStylists={this.props.clearSelectedStylists}
            selectedServices={this.props.selectedServices}
            clearAvailableSlots={this.props.clearAvailableSlots}
            clearAvailableDays={this.props.clearAvailableDays}
            selectedPatchTime={this.UNSAFE_componentWillMount.selectedPatchTime}
            setSelectedStylists={this.props.setSelectedStylists}
            isWaitList={this.props.isWaitList}
            has_waitlist={this.props.has_waitlist}
            getStylistsForService={this.props.getStylistsForService}
            updateFirstAvailableDate={this.updateFirstAvailableDate}
            showWaitList={this.props.showWaitList}
            selectAnyStylistForAll={this.state.selectAnyStylistForAll}
            stylistsForServiceWeekly={this.props.stylistsForServiceWeekly}
            check_ncob_availability_until={this.props.check_ncob_availability_until}
            book_ncob_with_same={this.props.book_ncob_with_same}
            clearSelectedTime={this.clearSelectedTime}
          />
        </div>

        <Footer data-bem="TimeSelectionWrap__footer">
          <Button
            variant="primary"
            width="fixed"
            onClick={() => this.btnClick()}
            disabled={this.state.appointmentTime === null}
          >
            NEXT
          </Button>
        </Footer>
      </div>
    );
  }
}

function mapStateToProps(state): any {
  const { booking } = state;
  const {
    availableDays,
    selectedServices,
    selectedPatchTime,
    selectedAppointmentTime,
    selectedStylists,
    totalPrice,
    slotMessage,
    salonDetails,
    stylistsForServiceWeekly,
    firstAvailableDate,
    pageVisited,
    isWaitList,
    waitListStylist,
    showWaitList,
  } = booking;
  return {
    availableDays,
    selectedServices,
    selectedPatchTime,
    selectedAppointmentTime,
    selectedStylists,
    totalPrice,
    slotMessage,
    skipToday: salonDetails.skip_today,
    stylistsForServiceWeekly,
    firstAvailableDate,
    pageVisited,
    isWaitList,
    waitListStylist,
    showWaitList,
    has_waitlist: salonDetails.has_waitlist,
    check_ncob_availability_until: salonDetails.check_ncob_availability_until,
    ncob_stylists_count: salonDetails.ncob_stylists_count,
    book_ncob_with_same: salonDetails.book_ncob_with_same,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getBookingAvailability(
      salonId: number,
      appointmentDate: moment,
      services: number[],
      stylistIds: number[],
      isWaitList: boolean,
    ) {
      dispatch(getBookingAvailability(salonId, appointmentDate, services, stylistIds, isWaitList));
    },
    getWeeklyAvailabilityRepeat(
      salonId: number,
      services: number[],
      stylists: number[],
      startDateOfWeek: moment,
      isWaitList?: boolean,
    ) {
      dispatch(getWeeklyAvailabilityRepeat(salonId, services, stylists, startDateOfWeek, isWaitList));
    },
    getWeeklyAvailability(
      salonId: number,
      services: number[],
      stylists: number[],
      startDateOfWeek: moment,
      continueCheck: number,
      isWaitList?: boolean,
    ) {
      dispatch(getWeeklyAvailability(salonId, services, stylists, startDateOfWeek, continueCheck, isWaitList));
    },
    setAppointmentTime(time: moment) {
      dispatch(setAppointmentTime(time));
    },
    setAvailabilitySlot(slot: AvailabilitySlot) {
      dispatch(setAvailabilitySlot(slot));
    },
    clearAvailableSlots() {
      dispatch(clearAvailableSlots());
    },
    clearSelectedServices() {
      dispatch(clearSelectedServices());
    },
    clearSelectedStylists() {
      dispatch(clearSelectedStylists());
    },
    clearServicesToShow() {
      dispatch(clearServicesToShow());
    },
    updateFirstAvailableDate(date: moment) {
      dispatch(updateFirstAvailableDate(date));
    },
    clearAvailableDays() {
      dispatch(clearAvailableDays());
    },
    setSelectedStylists(selectedStylists: { [serviceId: string]: null | Stylist }, serviceId?: number, stylist?: any) {
      dispatch(setSelectedStylists(selectedStylists, serviceId, stylist));
    },
    getStylistsForService(
      salonId: number,
      serviceId: number,
      dateTime: moment,
      services: any[],
      stylists: number[],
      isWaitList?: boolean,
    ) {
      dispatch(getStylistsForService(salonId, serviceId, dateTime, services, stylists, isWaitList));
    },
    checkWaitListForService(salonId: string, services: number[]) {
      dispatch(checkWaitListForService(salonId, services));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(TimeSelectionWrap));
