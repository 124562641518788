// @flow

/* eslint-disable react/sort-comp */
/* eslint-disable linebreak-style */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable key-spacing */
/* eslint-disable flowtype/space-after-type-colon */
/* eslint-disable function-paren-newline */

import React from 'react';
import { PasswordReset } from 'react-cognito-mm';
// $FlowFixMe
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import STORE from 'store.singleton';

import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import { withTheme } from 'providers/Theme.provider';

import HeaderComponent from 'booking/common/Header';
import InputVerifyCodeComponent from 'common/InputVerifyCode';
import PasswordInputFieldContainer from 'common/PasswordInputField';
import { validatePassword } from 'lib/validators';

import 'react-phone-number-input/style.css';
import CN from './ForgotPasswordWrap.module.scss';

const PasswordResetState = {
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
  VERIFICATION_ERROR: 'VERIFICATION_ERROR',
  VERIFICATION_REQUESTED: 'VERIFICATION_REQUESTED',
  PASSWORD_PENDING: 'PASSWORD_PENDING',
  PASSWORD_SUCCESS: 'PASSWORD_SUCCESS',
  PASSWORD_ERROR: 'PASSWORD_ERROR',
};

type Props = {
  history: any,
  sendVerificationCode?: (username: string, userPool: any) => Promise<*>,
  setPassword?: (username: string, code: string, password: string) => Promise<*>,
};

type State = {
  code: Array<number | null>,
  password: string,
  isFooterButtonActive: boolean,
  inputHasError: boolean,
  verificationerror: string,
  passworderror: string,
  phone: string,
  state:
    | typeof PasswordResetState.VERIFICATION_PENDING
    | typeof PasswordResetState.VERIFICATION_REQUESTED
    | typeof PasswordResetState.VERIFICATION_ERROR
    | typeof PasswordResetState.PASSWORD_SUCCESS
    | typeof PasswordResetState.PASSWORD_PENDING
    | typeof PasswordResetState.PASSWORD_ERROR,
};

class ForgotPassword extends React.Component<Props, State> {
  static defaultProps = {
    // $FlowFixMe
    sendVerificationCode: () => {},
    // $FlowFixMe
    setPassword: () => {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      code: [null, null, null, null, null, null],
      password: '',
      isFooterButtonActive: false,
      inputHasError: false,
      state: PasswordResetState.VERIFICATION_PENDING,
      verificationerror: '',
      passworderror: '',
      phone: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onResetPasswordClicked = () => {
    if (!this.state.isFooterButtonActive) {
      return;
    }
    const codeAsString = this.state.code.join('');
    const state = STORE.getState();

    if (this.props.setPassword) {
      this.props
        .setPassword(this.state.phone, codeAsString, this.state.password)
        .then(() => {
          this.setState({
            passworderror: '',
            state: PasswordResetState.PASSWORD_SUCCESS,
          });
          this.props.history.push(`/salon/${state.booking.salonDetails.id}`);
        })
        .catch(err =>
          this.setState({
            passworderror: err.message,
            state: PasswordResetState.PASSWORD_ERROR,
          }),
        );
    }
  };

  validateCode = (value: Array<number | null>) => {
    const indx = value.findIndex(n => n === null);
    // still have the original state value null, not finished typing yet
    if (indx >= 0) {
      return '';
    }
    const codeRegex = /^[0-9]+$/;
    const codeAsString = value.join('');
    if (codeRegex.test(codeAsString) && codeAsString.length === 6) {
      this.setState({ inputHasError: false }, () => {
        this.checkPasswordButton();
      });
      this.setState({ isFooterButtonActive: true });
      return '';
    }
    this.setState({ inputHasError: true }, () => {
      this.checkPasswordButton();
    });
    return 'Please enter a valid code';
  };
  changeCode = (value: Array<number | null>) => {
    this.setState({ code: value });
  };
  changePassword = (value: string) => {
    this.setState({ password: value, passworderror: '' });
  };
  validatePassword = (value: string) => {
    // eslint-disable-next-line no-useless-escape
    if (!validatePassword(value)) {
      this.setState({ inputHasError: false, isFooterButtonActive: true }, () => {
        this.setState({ password: value });
        this.checkPasswordButton();
      });
      return '';
    }
    this.setState({ inputHasError: true, isFooterButtonActive: false }, () => {
      this.checkPasswordButton();
    });
    return 'Password needs to be at least 8 characters in length and contain an uppercase character and a number.';
  };

  onInternationalPhone = (value: string, type: 'inter') => {
    const phoneSymbolsRegex = /^[0-9+)(\s]*$/;
    if (phoneSymbolsRegex.test(value)) {
      if (type === 'inter') {
        this.setState({ inputHasError: false }, () => {
          this.checkButton();
        });
        this.setState({ phone: value });
        return '';
      }
    }
    this.setState({ inputHasError: true }, () => {
      this.checkButton();
    });
    return 'Error';
  };
  checkButton = () => {
    if (this.state.inputHasError === false && this.state.phone !== '') {
      this.setState({ isFooterButtonActive: true });
    } else {
      this.setState({ isFooterButtonActive: false });
    }
  };
  checkPasswordButton = () => {
    if (!this.state.isFooterButtonActive) {
      return;
    }
    if (this.state.inputHasError === false && this.state.password !== '' && this.state.code !== '') {
      this.setState({ isFooterButtonActive: true });
    } else {
      this.setState({ isFooterButtonActive: false });
    }
  };
  nextStep = () => {
    this.setState({
      verificationerror: '',
      state: PasswordResetState.PASSWORD_PENDING,
      inputHasError: false,
    });
  };
  checkAndsendVerificationCode = (event?: SyntheticInputEvent<HTMLInputElement>) => {
    if (!this.state.isFooterButtonActive) {
      return;
    }
    if (event) {
      event.preventDefault();
    }

    if (this.props.sendVerificationCode) {
      this.props
        .sendVerificationCode(this.state.phone)
        .then(() =>
          this.setState({
            verificationerror: '',
            state: PasswordResetState.PASSWORD_PENDING,
            inputHasError: false,
            isFooterButtonActive: false,
          }),
        )
        .catch(err => {
          if (err.code === 'UserNotFoundException') {
            this.setState({
              verificationerror: 'User not found',
              state: PasswordResetState.VERIFICATION_ERROR,
            });
          } else {
            this.setState({
              verificationerror: err.message,
              state: PasswordResetState.VERIFICATION_ERROR,
            });
          }
        });
    }
  };

  render() {
    const { isDarkMode, isNeutralMode } = this.props;

    const phoneClasses = cn({
      [CN.phoneContainer]: true,
      [CN.hasError]: this.state.phone && !isValidPhoneNumber(this.state.phone),
    });

    return (
      <div
        data-bem="ForgotPassword"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        {[PasswordResetState.VERIFICATION_PENDING, PasswordResetState.VERIFICATION_REQUESTED].includes(
          this.state.state,
        ) && (
          <div className={CN.wrapper}>
            <HeaderComponent history={this.props.history} />
            <div className={CN.subTitle}>
              <div className={CN.icon} />
              Password reset
            </div>

            <div className={CN.formContainer}>
              <span className={CN.resetInfo}>
                Enter your mobile address and we will send you a code via SMS in order to reset
              </span>
              <div className={phoneClasses}>
                <label htmlFor="phone" className={CN.inputLabel}>
                  Mobile number
                </label>
                <PhoneInput
                  defaultCountry="GB"
                  placeholder="Enter phone number"
                  value={this.state.phone}
                  onChange={value => void this.onInternationalPhone(value, 'inter')}
                />
                {this.state.phone && !isValidPhoneNumber(this.state.phone) && (
                  <span className={CN.error}>Please enter valid phone number</span>
                )}
              </div>
              <span className={CN.resetNote}>
                If you do not receive a code, please contact us with your name and phone number so we can verify your
                account and send you a new code.
              </span>
            </div>

            <Footer data-bem="ForgotPassword__footer-VERIFICATION_REQUESTED">
              <Button
                variant="primary"
                width="fixed"
                onClick={this.checkAndsendVerificationCode}
                disabled={!this.state.isFooterButtonActive}
              >
                SEND CODE
              </Button>
            </Footer>
          </div>
        )}
        {this.state.state === PasswordResetState.VERIFICATION_ERROR && (
          <div className={CN.wrapper}>
            <HeaderComponent
              history={this.props.history}
              subtitle="We couldn't send you a reset code, if you already have one, you can continue"
            />
            <div className={CN.subTitle}>
              <div className={CN.icon} />
              Reset went wrong
            </div>
            <span className="text-pink error">{this.state.verificationerror}</span>

            <Footer data-bem="ForgotPassword__footer-VERIFICATION_ERROR">
              <Button variant="primary" width="fixed" onClick={this.nextStep}>
                ERROR
              </Button>
            </Footer>
          </div>
        )}
        {[PasswordResetState.PASSWORD_PENDING, PasswordResetState.PASSWORD_ERROR].includes(this.state.state) && (
          <div className={CN.wrapper}>
            <HeaderComponent />
            <div className={CN.subTitle}>
              <div className={CN.icon} />
              Enter your 6 digit code
            </div>
            <div className={CN.codeInfo}>This code was sent to you via SMS</div>
            <div className={CN.inputLabel}>Code</div>
            <div className={CN.formContainer}>
              <InputVerifyCodeComponent
                id="CodeInput"
                label=""
                type="number"
                inputValue=""
                onBlurHandler={this.changeCode}
                validateBlur={this.validateCode}
              />

              {this.state.verificationerror === '' && parseInt(this.state.code[5], 10) >= 0 && (
                <div className={CN.formContainer}>
                  <div className={CN.inputLabel}>Create a Password</div>
                  <PasswordInputFieldContainer
                    data={{
                      id: 'PasswordInput',
                      label: '',
                      type: 'password',
                      placeholder: '●●●●●●●●',
                      inputValue: '',
                    }}
                    validateBlur={this.validatePassword}
                    onBlurHandler={this.changePassword}
                    allowAutocomplete
                  />
                </div>
              )}
              {this.state.passworderror !== '' && <span className={CN.error}>{this.state.passworderror}</span>}
            </div>

            <Footer data-bem="ForgotPassword__footer-PASSWORD_ERROR">
              <Button
                variant="primary"
                width="fixed"
                onClick={this.onResetPasswordClicked}
                disabled={!this.state.isFooterButtonActive}
              >
                SUBMIT
              </Button>
            </Footer>
          </div>
        )}
      </div>
    );
  }
}

const ConnectedForgotPassword = withRouter(withTheme()(ForgotPassword));

export const ForgotPasswordComponent = () => (
  <PasswordReset>
    <ConnectedForgotPassword />
  </PasswordReset>
);

export default ForgotPasswordComponent;
