import logger from 'utils/logger.util';

const L = logger('isEnumVal');

const isEnumVal = (enumeration, val) => {
  try {
    return Object.values(enumeration).includes(val);
  } catch (e) {
    L.warn('()', e);
    return false;
  }
};

// noinspection JSUnusedGlobalSymbols
export default isEnumVal;
