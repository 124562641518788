import moment from 'moment';

import nullBind from 'utils/functions/nullBind.util';
import isMoment from 'utils/predicates/isMoment.util';

const DEFAULT = 'Invalid date';

const DAY = 'D';
const DAY_OF_WEEK = 'dddd';
const DAY_OF_WEEK_SHORT = 'ddd';

const DAY_TO_YEAR_STANDARD = 'DD-MM-YYYY';
const DAY_TO_YEAR_ORDINAL = 'Do MMM YYYY';
const DAY_TO_YEAR_LONG = 'DD MMM YYYY';
const DAY_TO_YEAR_SHORT = 'DD MMM YY';
const DAY_TO_YEAR_SHORTER = 'D MMM YYYY';
const DAY_TO_MONTH = 'D MMM';
const DAY_TO_YEAR_WITH_WEEKDAY_ORD = 'dddd Do MMM YY';

const DAY_TO_YEAR_WITH_WEEKDAY_INT = 'ddd DD MMM YYYY';
const DAY_TO_YEAR_WITH_SHORT_WEEKDAY = 'dd, D MMM YYYY';
const DAY_TO_YEAR_WITH_MID_WEEKDAY = 'ddd, D MMM Y';
const DAY_TO_YEAR_WITH_FULL_WEEKDAY = 'dddd, D MMM YYYY';
const DAY_TO_YEAR_WITH_FULL_WEEKDAY_SUFFIX = 'dddd Do MMM YYYY';
const DAY_TO_YEAR_WITH_AVERAGED_WEEKDAY = 'ddd D MMM YY';

const DAY_TO_MONTH_LONG_LOCALE = 'dddd D MMM';

const HOUR_AND_MINUTE_MERIDIEM = 'hh:mm A';
const HOUR_AND_MINUTE_SHORT_MERIDIEM = 'h:mm a';
const HOUR_AND_MINUTE_SHORT = 'h:mm';
const HOUR_AND_MINUTE_HALF = 'hh:mm';
const HOUR_AND_MINUTE_FULL = 'HH:mm';
const HOUR_TO_YEAR = 'HH.mm DD MMM YYYY';

const PART_DATE = 'dddd, Do MMMM';
const PART_TIME = 'h:mma';

const SEGMENT_WEEK = 'W[W]YYYY';

const YEAR_TO_DAY_LOCALIZED = 'll';
const YEAR_TO_DAY_STANDARD = 'YYYY-MM-DD';
const YEAR_TO_DAY_COMPACT = 'YYYYMMDD';
const YEAR_TO_SECOND_SN = 'YYMMDD-hhmmss';

const DAY_TO_HOUR = 'DDMMYYhh';
const MONTH_TO_YEAR = 'MMM YYYY';

const formatWithMoment = (format, date) => {
  if (void 1 === date) return DEFAULT;
  if (isMoment(date)) return date.format(format);
  if (Number.isSafeInteger(date)) return moment(date).format(format);
  if (typeof date !== 'string') return DEFAULT;

  const parsed = moment(date);
  return parsed.isValid() ? parsed.format(format) : DEFAULT;
};

export const formatDay = nullBind(formatWithMoment, DAY);
export const formatDayOfWeek = nullBind(formatWithMoment, DAY_OF_WEEK);
export const formatDayOfWeekShort = nullBind(formatWithMoment, DAY_OF_WEEK_SHORT);
export const formatDayToYear = nullBind(formatWithMoment, DAY_TO_YEAR_SHORT);
export const formatDayToYearShorter = nullBind(formatWithMoment, DAY_TO_YEAR_SHORTER);
export const formatDayToMonth = nullBind(formatWithMoment, DAY_TO_MONTH);
export const formatDayToYearLong = nullBind(formatWithMoment, DAY_TO_YEAR_LONG);
export const formatDayToYearStandard = nullBind(formatWithMoment, DAY_TO_YEAR_STANDARD);
export const formatDayToYearOrdinal = nullBind(formatWithMoment, DAY_TO_YEAR_ORDINAL);
export const formatDayToYearWeekday = nullBind(formatWithMoment, DAY_TO_YEAR_WITH_WEEKDAY_ORD);
export const formatDayToYearFullWeekdaySuffix = nullBind(formatWithMoment, DAY_TO_YEAR_WITH_FULL_WEEKDAY_SUFFIX);
export const formatDayToYearWeekdayInt = nullBind(formatWithMoment, DAY_TO_YEAR_WITH_WEEKDAY_INT);
export const formatDayToYearShortWeekday = nullBind(formatWithMoment, DAY_TO_YEAR_WITH_SHORT_WEEKDAY);
export const formatDayToYearMidWeekday = nullBind(formatWithMoment, DAY_TO_YEAR_WITH_MID_WEEKDAY);
export const formatDayToYearFullWeekday = nullBind(formatWithMoment, DAY_TO_YEAR_WITH_FULL_WEEKDAY);
export const formatDayToYearAveragedWeekday = nullBind(formatWithMoment, DAY_TO_YEAR_WITH_AVERAGED_WEEKDAY);
export const formatDayToMonthLongLocale = nullBind(formatWithMoment, DAY_TO_MONTH_LONG_LOCALE);
export const formatHourAndMinute = nullBind(formatWithMoment, HOUR_AND_MINUTE_MERIDIEM);
export const formatHourAndMinuteShortMeridiem = nullBind(formatWithMoment, HOUR_AND_MINUTE_SHORT_MERIDIEM);
export const formatHourAndMinuteShort = nullBind(formatWithMoment, HOUR_AND_MINUTE_SHORT);
export const formatHourAndMinuteHalf = nullBind(formatWithMoment, HOUR_AND_MINUTE_HALF);
export const formatHourAndMinuteFull = nullBind(formatWithMoment, HOUR_AND_MINUTE_FULL);
export const formatHourToYear = nullBind(formatWithMoment, HOUR_TO_YEAR);
export const formatPartDate = nullBind(formatWithMoment, PART_DATE);
export const formatPartTime = nullBind(formatWithMoment, PART_TIME);
export const formatSegmentWeek = nullBind(formatWithMoment, SEGMENT_WEEK);
export const formatYearToDayLocalized = nullBind(formatWithMoment, YEAR_TO_DAY_LOCALIZED);
export const formatYearToDayStandard = nullBind(formatWithMoment, YEAR_TO_DAY_STANDARD);
export const formatYearToDayCompact = nullBind(formatWithMoment, YEAR_TO_DAY_COMPACT);
export const formatYearToSecondSn = nullBind(formatWithMoment, YEAR_TO_SECOND_SN);
export const formatDayToHour = nullBind(formatWithMoment, DAY_TO_HOUR);
export const formatMonthToYear = nullBind(formatWithMoment, MONTH_TO_YEAR);
