// @flow
import React from 'react';

type Props = {
  checkId: string,
  isChecked: boolean,
  onCheckboxClick: () => void,
};

type State = {
  checked: boolean,
};

export class declinePayment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checked: props.isChecked,
    };
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.isChecked !== this.state.checked) {
      this.setState({ checked: nextProps.isChecked });
    }
  }
  render() {
    return (
      <div>
        <input
          type="checkbox"
          className="checkbox-switch"
          id={this.props.checkId}
          checked={this.state.checked}
          onChange={this.props.onCheckboxClick}
        />
        <label htmlFor={this.props.checkId}>
          <span />
        </label>
      </div>
    );
  }
}
export default declinePayment;
