/* eslint-disable no-nested-ternary */
// @flow

/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react/no-did-mount-set-state */

import * as React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import moment from 'moment-timezone';

import Button from 'components/Button.component';
import S from 'constants/string.const';
import { withTheme } from 'providers/Theme.provider';

import SliderComponent from 'booking/common/CalendarSlider/Slider';
import ErrorPopupComponent from 'booking/common/ErrorPopup';
import TimeItem from 'booking/common/TimeItem';
import type { AvailabilitySlot } from 'types/availabilitySlot';
import type { SalonDetails } from 'types/salonDetails';
import type { Stylist } from 'types/stylistsForService';

import AvailabilityButton from './AvailabilityButton.component';

import CN from './TimeSelection.module.scss';

const RENDER_AVAILABILITY_BUTTON = false;

type StateProps = {
  salonDetails: SalonDetails,
  isFetching: boolean,
  availableSlots: {
    [time: string]: AvailabilitySlot,
  },
  errorMessage: string,
  selectedStylists: {
    [serviceId: string]: null | Stylist,
  },
  availableDays: {
    [time: string]: boolean,
  },
  firstAvailableDate: moment | null,
  appointment_id?: number,
  isWaitList: boolean,
  has_waitlist: boolean,
  showWaitList: boolean,
  selectAnyStylistForAll: boolean,
  stylistsForServiceWeekly: any,
  check_ncob_availability_until: number,
  book_ncob_with_same: boolean,
};

type OwnProps = {
  selectedTime: moment | null,
  selectedPatchTime: moment | null,
  skip_today?: boolean,
  firstAvailableDateSet: boolean,
  clearAvailableSlots: () => void,
  clearAvailableDays: () => void,
  getAvailability: (appointmentDate: moment, isWaitList: boolean) => void,
  getWeeklyAvailabilityRepeat: (start_date_of_week: moment, isWaitList: boolean) => void,
  getWeeklyAvailability: (start_date_of_week: moment, isWaitList: boolean, continueCheck: number) => void,
  setTime: (appointmentTime: moment, slot: AvailabilitySlot) => void,
  clearSelectedStylists: () => void,
  updateFirstAvailableDate: (date: moment | null) => void,
  clearSelectedTime: () => void,
};

type Props = StateProps & OwnProps;

type State = {
  selectedDate: moment | null,
  isFetching: boolean,
  disableNextArrow: boolean,
  mounted: boolean,
};

export class TimeSelection extends React.Component<Props, State> {
  static defaultProps = { skip_today: false, appointment_id: 0 };
  constructor(props: Props) {
    super(props);
    this.sliderRef = React.createRef();
    let selectedDate = null;
    if (props.selectedTime !== null) {
      selectedDate = props.selectedTime;
    }

    this.state = {
      selectedDate,
      isFetching: false,
      disableNextArrow: false,
      mounted: false,
      isButtonClicked: false,
    };
  }
  componentDidMount() {
    this.setState({ mounted: true });
  }
  componentWillReceiveProps(nextProps: Props) {
    // when reach maximum days can book, response contains only one item
    if (nextProps.firstAvailableDate || Object.keys(nextProps.availableDays).length === 1) {
      this.setState({ isFetching: false });
    }
    if (
      Object.keys(nextProps.availableDays).length === 1 &&
      Object.values(nextProps.availableDays)[0].indexOf('Exceeds') >= 0
    ) {
      this.setState({ disableNextArrow: true });
    } else {
      this.setState({ disableNextArrow: false });
    }
  }
  componentDidUpdate() {
    const div = document.querySelector('.app-content .fully-booked-cta');
    if (div) {
      div.style.display = 'block';
    }
  }
  onCheckboxClick = time => {
    if (!this.state.selectedDate) {
      return;
    }
    const slot = this.props.availableSlots[time];
    this.props.setTime(moment(time), slot);
  };
  getAvailability = (appointmentDate: moment, isWaitList: boolean) => {
    this.setState({ selectedDate: appointmentDate });
    this.props.getAvailability(appointmentDate, isWaitList);
  };
  getWeeklyAvailabilityRepeat = (weekStartDate: moment, isWaitList: boolean) => {
    this.setState({ isFetching: true });
    this.props.getWeeklyAvailabilityRepeat(weekStartDate, isWaitList);
  };
  clearAvailability = () => {
    this.props.clearSelectedStylists();
    this.props.clearAvailableSlots();
    this.props.clearAvailableDays();
    this.props.updateFirstAvailableDate(null);
  };
  clearSelectedDate = () => {
    this.setState({ selectedDate: null });
  };
  showWaitListAvailability = () => {
    this.clearAvailability();
    let theDate;
    if (this.props.skip_today) {
      theDate = moment()
        .add(1, 'days')
        .startOf('day');
    } else {
      theDate = moment().startOf('day');
    }
    this.props.getAvailability(theDate, true);
    this.setState({ selectedDate: theDate });
    const SliderComp = this.sliderRef.current;
    const interval = setInterval(() => {
      if (!this.props.isFetching && Object.keys(this.props.availableSlots).length > 0) {
        clearInterval(interval);
        this.props.updateFirstAvailableDate(theDate);
        this.sliderRef.current.onSelectDay(theDate);
        const diff = moment(this.props.firstAvailableDate).diff(moment(theDate), 'd');
        if (SliderComp.sliderRef && SliderComp.sliderRef.current && diff > 0) {
          SliderComp.sliderRef.current.slickGoTo(diff);
        }
      } else if (!this.props.isFetching) {
        clearInterval(interval);
        let startDate;
        if (this.props.skip_today) {
          startDate = moment()
            .add(1, 'days')
            .startOf('day');
        } else {
          startDate = moment().startOf('day');
        }
        SliderComp.getAvailableDays(true, startDate);
        const sInterval = setInterval(() => {
          if (this.props.firstAvailableDate) {
            clearInterval(sInterval);
            let destDate = startDate;
            if (this.props.selectedPatchTime) {
              destDate = moment(this.props.selectedPatchTime).add(2, 'd');
            }
            const diff = moment(this.props.firstAvailableDate).diff(moment(destDate), 'd');
            if (SliderComp.sliderRef && SliderComp.sliderRef.current && diff > 0) {
              SliderComp.sliderRef.current.slickGoTo(diff);
              this.sliderRef.current.onSelectDay(this.props.firstAvailableDate);
            }
          }
        });
      }
    }, 500);
  };
  showAllAvailability = () => {
    this.clearAvailability();
    const theDate = this.props.skip_today
      ? moment()
          .add(1, 'days')
          .startOf('day')
      : moment().startOf('day');
    setTimeout(() => {
      if (!this.props.firstAvailableDate) {
        this.props.getAvailability(theDate, false);
        this.setState({ selectedDate: theDate });
      }
      const activeDate = document.querySelector('.slick-item.activeClass');
      if (activeDate) {
        activeDate.click();
      }
    }, 2000);
    // give it 2 second for selectedStylists, availableSlots, availableDays to be cleared
    const SliderComp = this.sliderRef.current;
    const interval = setInterval(() => {
      if (!this.props.isFetching && Object.keys(this.props.availableSlots).length > 0) {
        clearInterval(interval);
        if (this.props.firstAvailableDate) {
          this.sliderRef.current.onSelectDay(this.props.firstAvailableDate);
          const diff = moment(this.props.firstAvailableDate).diff(moment(theDate), 'd');
          if (SliderComp.sliderRef && SliderComp.sliderRef.current && diff > 0) {
            SliderComp.sliderRef.current.slickGoTo(diff);
          }
        }
      } else if (!this.props.isFetching) {
        clearInterval(interval);
        let startDate;
        if (this.props.skip_today) {
          startDate = moment()
            .add(1, 'days')
            .startOf('day');
        } else {
          startDate = moment().startOf('day');
        }
        SliderComp.getAvailableDays(false, startDate);
        const sInterval = setInterval(() => {
          if (this.props.firstAvailableDate) {
            clearInterval(sInterval);
            let destDate = startDate;
            if (this.props.selectedPatchTime) {
              destDate = moment(this.props.selectedPatchTime).add(2, 'd');
            }
            const diff = moment(this.props.firstAvailableDate).diff(moment(destDate), 'd');
            if (SliderComp.sliderRef && SliderComp.sliderRef.current && diff > 0) {
              SliderComp.sliderRef.current.slickGoTo(diff);
              this.sliderRef.current.onSelectDay(this.props.firstAvailableDate);
            }
          }
        });
      }
    }, 500);

    this.setState({ isButtonClicked: true });
  };

  sliderRef: any;
  render() {
    const {
      stylistsForServiceWeekly,
      salonDetails,
      selectedStylists,
      isNeutralMode,
      isDarkMode,
      availableDays,
      check_ncob_availability_until: availabilityUntil,
    } = this.props;

    const stylistsArray = Object.values(selectedStylists);

    const { timezone: timeZone } = salonDetails || {};
    const stylistList = Object.values(stylistsForServiceWeekly);
    const haveMoreStylistForService = stylistList.filter(arr => arr.length > 1);
    let { errorMessage } = this.props;
    const slots = [];
    Object.keys(this.props.availableSlots).map(slot => slots.push(slot));
    const mappedSlots = slots.map((slot, i) => {
      let checked = false;

      if (this.props.selectedTime && this.props.selectedTime.isSame(slot)) {
        checked = true;
      }
      return (
        <TimeItem
          checkId={`cb${i}`}
          ItemTime={slot}
          timezone={timeZone}
          onCheckboxClick={this.onCheckboxClick}
          key={slot}
          checked={checked}
          optionsCount={this.props.availableSlots[slot].count}
          hasMore={this.props.availableSlots[slot].has_more}
        />
      );
    });

    if (!errorMessage && !this.props.isFetching && mappedSlots.length === 0) {
      if (
        this.props.selectedPatchTime &&
        this.sliderRef.current &&
        moment(this.sliderRef.current.props.selectedDate).isSameOrBefore(moment(this.props.selectedPatchTime))
      ) {
        errorMessage = 'Please keep scrolling to a future date to find availability for your appointment.';
      }
    }

    return (
      <div
        data-bem="TimeSelection"
        className={cn({
          [CN.component]: true,
          [CN.neutral]: isNeutralMode,
          [CN.dark]: isDarkMode,
        })}
      >
        {document.location.href.indexOf('timeselect') > 0 && (
          <SliderComponent
            ref={this.sliderRef}
            firstAvailableDate={this.props.firstAvailableDate}
            getWeeklyAvailability={this.props.getWeeklyAvailability}
            getWeeklyAvailabilityRepeat={this.getWeeklyAvailabilityRepeat}
            appointment_id={this.props.appointment_id}
            selectedPatchTime={this.props.selectedPatchTime}
            getAvailability={this.getAvailability}
            isWaitList={this.props.isWaitList}
            errorMessage={errorMessage}
            selectedDate={this.state.selectedDate}
            availableDays={availableDays}
            clearAvailableSlots={this.props.clearAvailableSlots}
            clearAvailableDays={this.props.clearAvailableDays}
            salonDetails={salonDetails}
            skip_today={this.props.skip_today}
            availableSlots={this.props.availableSlots}
            availablePatchSlots={null}
            isFetching={this.props.isFetching}
            showWaitListAvailability={this.showWaitListAvailability}
            updateFirstAvailableDate={this.props.updateFirstAvailableDate}
            check_ncob_availability_until={availabilityUntil}
            disableNextArrow={this.state.disableNextArrow}
            mounted={this.state.mounted}
            selectedTime={this.props.selectedTime}
            clearSelectedDate={this.clearSelectedDate}
            clearSelectedTime={this.props.clearSelectedTime}
          />
        )}
        {(this.props.isFetching || this.state.isFetching) && (
          <div className="spinner-container">
            <div className="load-spinner" />
          </div>
        )}
        {Object.values(availableDays).length === 1 && Object.values(availableDays)[0].indexOf('Exceeds') >= 0 && (
          <div className={CN.availableDaysContainer}>
            <div className={CN.text}>
              {stylistsArray.length === 1 ? (
                <div>
                  <span key={stylistsArray[0].stylist_name}>{stylistsArray[0].stylist_name}</span>
                  {S.space}is only accepting bookings{S.space}
                  {availabilityUntil}
                  {S.space}days in advance and unfortunately has no availability between now and{S.space}
                  {moment()
                    .add(availabilityUntil, 'd')
                    .format('Do MMMM ')}
                </div>
              ) : (
                <div>
                  {stylistsArray.map((stylist, index) => (
                    <span key={stylist.stylist_name}>
                      {stylist.stylist_name}
                      {index < stylistsArray.length - 2 ? ', ' : index === stylistsArray.length - 2 ? ' and ' : ''}
                    </span>
                  ))}
                  {S.space}are only accepting bookings{S.space}
                  {availabilityUntil}
                  {S.space}
                  days in advance and unfortunately have no availability between now and{S.space}
                  {moment()
                    .add(availabilityUntil, 'd')
                    .format('Do MMMM ')}
                </div>
              )}
            </div>
            <div className={CN.textInstructions}>
              Please go back and select a different staff member to check their availability, or try again tomorrow.
              Alternatively, give us a call on{S.space}
              <a href={`tel:${salonDetails.phone}`} className={CN.phone}>
                {salonDetails.phone}
              </a>
            </div>
          </div>
        )}
        {mappedSlots.length > 0 && <span className={CN.title}>Times below are in {timeZone} timezone</span>}
        {!this.props.isFetching && <div className={CN.containerTimeSlot}>{mappedSlots.length > 0 && mappedSlots}</div>}
        {errorMessage && <ErrorPopupComponent message={errorMessage} />}
        {!mappedSlots.length && this.props.firstAvailableDate && (
          <div className="cta">Click on a day that you wish to come in.</div>
        )}
        {this.props.firstAvailableDateSet && !this.props.firstAvailableDate && (
          <div className="fully-booked-cta">
            The salon is fully booked for the next 6 months, please call the salon.
          </div>
        )}
        {slots.length === 0 && !this.props.isFetching && this.props.selectedTime && (
          <div className="fully-booked-cta">
            {!this.props.isWaitList && (
              <p>
                Sorry, it looks like there&apos;s no availability for your chosen date. Please select another date to
                check availability.
              </p>
            )}
            {this.props.isWaitList && (
              <p>Sorry looks like there&apos;s no availability for your selected date. Select another day</p>
            )}
          </div>
        )}
        <div className={CN.buttonsContainer}>
          {RENDER_AVAILABILITY_BUTTON &&
            !this.state.isButtonClicked &&
            !this.props.isFetching &&
            !this.props.book_ncob_with_same &&
            !this.props.isWaitList &&
            haveMoreStylistForService.length === stylistList.length &&
            !this.props.selectAnyStylistForAll && <AvailabilityButton onClick={this.showAllAvailability} />}

          {!this.props.isFetching && !this.props.isWaitList && this.props.has_waitlist && this.props.showWaitList && (
            <div className={CN.waitListButtonContainer}>
              <Button variant="secondary" width="fixed" onClick={() => this.showWaitListAvailability()}>
                ADD ME TO WAITLIST
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { booking } = state;
  const {
    salonDetails,
    isFetching,
    availableSlots,
    errorMessage,
    selectedStylists,
    selectedServices,
    bookingResponse,
    availableDays,
    firstAvailableDate,
    selectedPatchTime,
  } = booking;
  return {
    salonDetails,
    isFetching,
    availableSlots,
    errorMessage,
    selectedStylists,
    selectedServices,
    availableDays,
    firstAvailableDate,
    selectedPatchTime,
    appointment_id: bookingResponse ? bookingResponse.appointment_id : null,
  };
};

export default connect(mapStateToProps)(withTheme()(TimeSelection));
