import { useCallback, useMemo, useRef } from 'react';
import { noop } from 'lodash';

import useStateWhileMounted from 'hooks/useStateWhileMounted.hook';
import asArray from 'utils/arrays/asArray.util';
import logger from 'utils/logger.util';
import isFn from 'utils/predicates/function/isFn.util';

const L = logger('useInitializedAsyncFn');

// aliases for code brevity, not clarity
const T = true;
const F = false;
const U = void 1;

const nextId = prev => {
  // simple function that rotates the integer in that very rare case it might go out of bounds

  if (!Number.isSafeInteger(prev) || Number.MAX_SAFE_INTEGER === prev) {
    // if the previous is not a valid integer or is already at the max allowed, reset to the lowest allowed
    return Number.MIN_SAFE_INTEGER;
  }

  // otherwise, just increment and return the new one
  return prev + 1;
};

// SEE: https://github.com/streamich/react-use/blob/master/src/useAsyncFn.ts

const useInitializedAsyncFn = (init, fn, deps) => {
  const [state, setState] = useStateWhileMounted(init);
  const latestCallId = useRef(0);

  const asyncFunction = useCallback(
    async (...args) => {
      latestCallId.current = nextId(latestCallId.current);
      const thisCallId = latestCallId.current;

      try {
        if (!state.isLoading) {
          // if already the process of loading, do not trigger the state change, otherwise, just add the difference
          setState(prev => ({ ...prev, isLoading: T, isLoaded: F }));
        }

        const value = await fn(...args);

        if (thisCallId === latestCallId.current) {
          // if multiple calls are made, only the last one is allowed to change the state
          setState({ value, error: U, isLoading: F, isLoaded: T });
        }

        return value;
      } catch (error) {
        if (thisCallId === latestCallId.current) {
          // if multiple calls are made, only the last one is allowed to change the state
          setState({ value: U, error, isLoading: F, isLoaded: T });
        }

        throw error;
      }
    },
    // instead of [fn, setState, state.isLoading], pass the external dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    asArray(deps),
  );

  const memoizedPassResult = useMemo(() => [state, asyncFunction], [state, asyncFunction]);
  const memoizedFailResult = useMemo(() => [state, noop], [state]);

  if (isFn(fn)) {
    return memoizedPassResult;
  }

  L.error('()', 'Invalid function fn:', fn);
  return memoizedFailResult;
};

// noinspection JSUnusedGlobalSymbols
export default useInitializedAsyncFn;
