import Raven from 'raven-js';

import fromAnyToString from 'utils/converters/fromAnyToString';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';

const L = logger('captureWarn');

const captureWarn = (logger, tags, extra, message) => {
  try {
    const options = {
      logger: Array.isArray(logger) ? logger.map(fromAnyToString).join(' ') : fromAnyToString(logger),
      level: 'warning',
      tags: asObject(tags),
      extra: asObject(extra),
    };

    L.debug('()', 'capturing', options, message, '...');
    // noinspection JSUnresolvedFunction
    Raven.captureMessage(fromAnyToString(message), options);
  } catch (e) {
    L.warn('()', { logger, tags, extra, message }, e);
  }

  return message;
};

// noinspection JSUnusedGlobalSymbols
export default captureWarn;
