import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useParams, withRouter } from 'react-router-dom';
import { logoutUser } from 'auth/actions';
import cn from 'classnames';
import Navigation from 'invite/components/Navigation.component';
import { bindActionCreators } from 'redux';

import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import Badge from 'components/Badge.component';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import Icon from 'components/Icon.component';
import IconStatus from 'components/IconStatus.component';
import TabButton from 'components/TabButton.component';
import TabButtonsContainer from 'components/TabButtonsContainer.component';
import LINK from 'constants/link.const';
import S from 'constants/string.const';
import useAef from 'hooks/useAef.hook';
import useTip from 'hooks/useTip.hook';
import { useCurrentSalonId } from 'providers/SalonBasicInfo.provider';
import { useTheme } from 'providers/Theme.provider';
import fromAnyToTrimmed from 'utils/converters/any/fromAnyToTrimmed.util';
import {
  formatDay,
  formatDayOfWeekShort,
  formatDayToYearFullWeekday,
  formatHourAndMinute,
  formatMonthToYear,
} from 'utils/date/format.util';
import linkTo from 'utils/links/linkTo.util';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';
import isEmptyArray from 'utils/predicates/isEmptyArray.util';

import { getClientInvites } from 'booking/actions';
import beGetSalonPastBookings from 'booking/beGetSalonPastBookings.action';
import beGetSalonUpcomingBookings from 'booking/beGetSalonUpcomingBookings.action';
import currency from 'booking/common/currency.util';
import Spinner from 'common/Spinner.component';

import CN from './SingleSalonBookings.module.scss';

const L = logger('SingleSalonBookings');

const SingleSalonBookings = ({
  match,
  history,
  currency: abbreviation,
  cognitoUserName,
  beGetSalonUpcomingBookings,
  beGetSalonPastBookings,
  getClientInvites,
}) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const salonId = useCurrentSalonId();
  const { bookingsSalonId } = useParams();
  const linkToSalon = linkTo({ pattern: LINK.salon, params: { salonId } });

  const [pastPage, setCurrentPage] = useState(1);
  const [futurePage, setFuturePage] = useState(1);

  const { val: isUpcoming, yay: setUpcoming, nay: removeUpcoming } = useTip(true);
  const { val: isPast, yay: setPast, nay: removePast } = useTip(false);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleUpcomingAppointments = useCallback(() => {
    setUpcoming();
    removePast();
  }, [removePast, setUpcoming]);

  const handlePastAppointments = useCallback(() => {
    setPast();
    removeUpcoming();
  }, [removeUpcoming, setPast]);

  const handleRebook = useCallback(
    ev => {
      const { appointment } = ev;

      history.push({
        pathname: `/salon/${fromAnyToTrimmed(bookingsSalonId) || 0}`,
      });
      L.debug('()', { appointment });
    },
    [bookingsSalonId, history],
  );

  const { value: upcomingSalonData, isLoading: upcomingIsLoading, error: upcomingError } = useAef(async () => {
    try {
      const result = await beGetSalonUpcomingBookings({
        bookingsSalonId,
        cognitoUserName,
        page: futurePage,
      });
      return result;
    } catch (error) {
      throw error;
    }
  }, [beGetSalonUpcomingBookings, cognitoUserName, futurePage, bookingsSalonId]);

  const { value: pastSalonData, isLoading: pastIsLoading, error: pastError } = useAef(async () => {
    try {
      const result = await beGetSalonPastBookings({
        bookingsSalonId,
        cognitoUserName,
        page: pastPage,
      });
      return result;
    } catch (error) {
      throw error;
    }
  }, [beGetSalonPastBookings, bookingsSalonId, cognitoUserName, pastPage]);

  const renderPaginationUpcoming =
    upcomingSalonData && upcomingSalonData.response && upcomingSalonData.response.count > 30;
  const { response: { previous: hasPrevPageUpcoming } = {} } = asObject(upcomingSalonData);
  const { response: { next: hasNextPageUpcoming } = {} } = asObject(upcomingSalonData);

  const renderPaginationPast = pastSalonData && pastSalonData.response && pastSalonData.response.count > 30;
  const { response: { previous: hasPrevPagePast } = {} } = asObject(pastSalonData);
  const { response: { next: hasNextPagePast } = {} } = asObject(pastSalonData);

  const handleNextPagePast = () => {
    setCurrentPage(pastPage => pastPage + 1);
  };

  const handlePrevPagePast = () => {
    setCurrentPage(pastPage => Math.max(pastPage - 1, 1));
  };

  const handleNextPageUpComing = () => {
    setFuturePage(futurePage => futurePage + 1);
  };

  const handlePrevPageUpcoming = () => {
    setFuturePage(futurePage => Math.max(futurePage - 1, 1));
  };

  const handleLogout = () => {
    logoutUser();
    history.push(`/salon/${salonId}/`);
  };

  const upcomingClasses = cn({
    [CN.count]: true,
    [CN.isSelected]: isUpcoming,
  });

  const pastClasses = cn({
    [CN.count]: true,
    [CN.isSelected]: isPast,
  });

  useEffect(() => {
    if (cognitoUserName) {
      getClientInvites(cognitoUserName);
    }
  }, [beGetSalonUpcomingBookings, cognitoUserName, getClientInvites]);

  L.debug('()', { upcomingIsLoading, upcomingError, pastIsLoading, pastError, upcomingSalonData, pastSalonData });

  return (
    <div
      data-bem="SingleSalonBookings"
      className={cn({
        [CN.component]: true,
        [CN.neutral]: isNeutralMode,
        [CN.dark]: isDarkMode,
      })}
    >
      <div className={CN.wrapper}>
        <Navigation match={match} />
        <div className={CN.header}>
          <div
            data-bem="SingleSalonBookings_arrow-go-back"
            className={CN.arrowGoBack}
            role="button"
            tabIndex={0}
            onClick={handleGoBack}
          >
            <img src={IMG_ARROW} alt="Arrow go back" className={CN.arrowImg} />
          </div>

          <Button variant="primary" onClick={handleLogout}>
            <div className={CN.buttonContent}>
              <Icon variant="calendarFill" width="lg" />
              <span className={CN.space}>NEW</span>
            </div>
          </Button>
        </div>

        <TabButtonsContainer>
          <TabButton isActive={isUpcoming} onClick={handleUpcomingAppointments}>
            Upcoming
            <div className={upcomingClasses}>
              {upcomingSalonData && upcomingSalonData.response && upcomingSalonData.response.count}
            </div>
          </TabButton>
          <TabButton isActive={isPast} onClick={handlePastAppointments}>
            Past
            <div className={pastClasses}>{pastSalonData && pastSalonData.response && pastSalonData.response.count}</div>
          </TabButton>
        </TabButtonsContainer>

        {isUpcoming && (
          <div data-bem="SingleSalonBookings_upcoming">
            {upcomingIsLoading ? (
              <Spinner variant="overlay" />
            ) : (
              <div className={CN.container}>
                {upcomingSalonData &&
                  upcomingSalonData.response &&
                  upcomingSalonData.response.results.map(salon => {
                    const isWaitlistStylist = salon.procedures[0].stylist.serves_as_waitlist;
                    const appointmentId = salon.procedures[0].id;

                    const handleItemClick = appointmentId => {
                      const url = `/salon/${match.params.id}/${match.params.bookingsSalonId}/appointment/${appointmentId}`;
                      history.push({
                        pathname: url,
                        state: { procedureData: salon },
                      });
                    };

                    return (
                      <div
                        key={salon.id}
                        className={CN.salonItem}
                        onClick={() => handleItemClick(appointmentId)}
                        role="button"
                        tabIndex={0}
                      >
                        <div className={CN.dateContainer}>
                          <span> {formatDayOfWeekShort(salon.starts_at)}</span>
                          <span className={CN.day}> {formatDay(salon.starts_at)}</span>
                          <span> {formatMonthToYear(salon.starts_at)}</span>
                        </div>
                        <div className={CN.futureContainer}>
                          <div>
                            <div className={CN.subTitle}>
                              {formatHourAndMinute(salon.starts_at)} - {formatHourAndMinute(salon.ends_at)}
                            </div>

                            <div className={CN.servicesText}>
                              {`${salon.procedures.length} ${salon.procedures.length === 1 ? 'Service' : 'Services'}`}
                              {S.space}
                              {S.passdot}
                              {S.space}
                              {currency({ value: salon.price, abbreviation })}
                            </div>
                            <div className={CN.depositText}>
                              Deposit paid: {currency({ value: salon.deposit, abbreviation })}
                            </div>
                            <div className={CN.badgeContainer}>
                              {isWaitlistStylist ? (
                                <Badge variant="default">Waitlist Booking</Badge>
                              ) : (
                                <Badge variant="confirm">
                                  <Icon variant="check" width="xs" />
                                  <span className={CN.space}>Booking Confirmed</span>
                                </Badge>
                              )}
                            </div>
                          </div>
                          <div data-bem="SingleSalonBookings_arrow-details" className={CN.arrowGoDetails}>
                            <img src={IMG_ARROW} alt="Arrow go to details" className={CN.arrowImg} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {renderPaginationUpcoming && (
                  <div className={CN.paginationButtons}>
                    <Button variant="secondary" onClick={handlePrevPageUpcoming} disabled={!hasPrevPageUpcoming}>
                      Previous
                    </Button>
                    <Button variant="secondary" onClick={handleNextPageUpComing} disabled={!hasNextPageUpcoming}>
                      Next
                    </Button>
                  </div>
                )}
                {isEmptyArray(
                  upcomingSalonData && upcomingSalonData.response && upcomingSalonData.response.results,
                ) && (
                  <div className={CN.emptyState}>
                    <IconStatus variant="inactive">
                      <Icon variant="calendarInactive" width="xl" />
                    </IconStatus>
                    <p>You have no upcoming bookings</p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {isPast && (
          <div data-bem="SingleSalonBookings_past">
            {pastIsLoading ? (
              <Spinner variant="overlay" />
            ) : (
              <div className={CN.container}>
                {pastSalonData &&
                  pastSalonData.response &&
                  pastSalonData.response.results.map(salon => {
                    const salonStartTime = salon.procedures[0].start_time;
                    const appointment = salon.procedures[0];

                    return (
                      <div key={salon.id} className={CN.salonItem} role="button" tabIndex={0}>
                        <div className={CN.procedureContainer}>
                          <div className={CN.flexRow}>
                            <div>
                              <div className={CN.title}>{formatDayToYearFullWeekday(salonStartTime)}</div>
                              <div className={CN.subTitle}>
                                {formatHourAndMinute(salon.starts_at)} - {formatHourAndMinute(salon.ends_at)}
                              </div>
                            </div>
                            <div className={CN.rebookContainer}>
                              {salon.can_rebook ? (
                                <Button variant="secondary" onClick={() => void handleRebook({ appointment })}>
                                  REBOOK
                                </Button>
                              ) : (
                                <div className={CN.smallText}>NOT BOOKABLE ONLINE</div>
                              )}
                            </div>
                          </div>
                          {salon.procedures.map(procedure => (
                            <div key={procedure.id}>
                              <div className={CN.flexRow}>
                                <div>
                                  <div className={CN.title}>{procedure.service.category.name}</div>
                                  <div className={CN.serviceInfo}>
                                    <span>
                                      {procedure.stylist && procedure.stylist.user_name ? (
                                        procedure.stylist.user_name
                                      ) : (
                                        <div />
                                      )}
                                      {S.space}
                                      {S.minus}
                                      {S.space}
                                      {procedure.stylist && procedure.stylist.role_name ? (
                                        procedure.stylist.role_name
                                      ) : (
                                        <div />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className={CN.textFocused}>
                                  {currency({ value: procedure.price, abbreviation })}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                {renderPaginationPast && (
                  <div className={CN.paginationButtons}>
                    <Button variant="secondary" onClick={handlePrevPagePast} disabled={!hasPrevPagePast}>
                      Previous
                    </Button>
                    <Button variant="secondary" onClick={handleNextPagePast} disabled={!hasNextPagePast}>
                      Next
                    </Button>
                  </div>
                )}
                {isEmptyArray(pastSalonData && pastSalonData.response && pastSalonData.response.results) && (
                  <div className={CN.emptyState}>
                    <IconStatus variant="inactive">
                      <Icon variant="calendarInactive" width="xl" />
                    </IconStatus>
                    <p>You have no past bookings</p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Footer data-bem="SingleSalonBookings__footer">
        <div className={CN.footerContainer}>
          <NavLink to={linkToSalon} data-bem="SingleSalonBookings__link" className={CN.link}>
            <Button variant="primary" width="fixed">
              MAKE A BOOKING
            </Button>
          </NavLink>
        </div>
      </Footer>
    </div>
  );
};

const mapStateToProps = state => ({
  cognitoUserName: state.cognito.user.username,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      beGetSalonUpcomingBookings,
      beGetSalonPastBookings,
      getClientInvites,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SingleSalonBookings));
