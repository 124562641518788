/* eslint-disable import/no-named-as-default */
import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';

import CategorySticky from 'booking/serviceSelection/CategorySticky';

import CN from './ServiceSelectionCopy.module.scss';

const ServiceSelectionCopy = ({ categories, recommendedServices, selectedServices }) => {
  const { isDarkMode, isNeutralMode } = useTheme();

  const componentClasses = cn({
    [CN.component]: true,

    [CN.dark]: isDarkMode,
    [CN.neutral]: isNeutralMode,
  });

  return (
    <section data-bem="ServiceSelectionCopy" id="selected-services-sticky" className={componentClasses}>
      {recommendedServices &&
        recommendedServices.length === 0 &&
        categories.map((category, index) => {
          if (category.services.length > 0) {
            // eslint-disable-next-line react/no-array-index-key
            const key = `${category.name}-${index}`;
            return <CategorySticky category={category} index={index} key={key} selectedServices={selectedServices} />;
          }
          return null;
        })}
    </section>
  );
};

ServiceSelectionCopy.defaultProps = { recommendedServices: [] };

export default ServiceSelectionCopy;
