import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';
import fromAnyToTrimmed from 'utils/converters/any/fromAnyToTrimmed.util';

import CN from './Badge.module.scss';

const Badge = ({ name, tag: tagFromProps, children, variant }) => {
  const { isDarkMode, isNeutralMode } = useTheme();
  return (
    <div
      data-bem="Badge"
      data-tag={fromAnyToTrimmed(tagFromProps)}
      className={cn({
        [CN.component]: true,
        [CN[`${variant}Variant`]]: variant,
        [CN.neutral]: isNeutralMode,
        [CN.dark]: isDarkMode,
      })}
    >
      <div>{name}</div>
      {children}
    </div>
  );
};

export default Badge;
