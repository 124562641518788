// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import history from 'history.singleton';
import Navigation from 'invite/components/Navigation.component';

import { clearExtraAuthenticationInfo, getClientInvites, setPageVisited } from '../booking/actions';

type State = {
  clientInvitesCalled: boolean,
};
type Props = {
  getClientInvites: (username: string) => void,
  cognito: any,
  match: any,
  clearExtraAuthenticationInfo: () => void,
};

export class ProfileWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clientInvitesCalled: false,
    };
  }

  componentDidMount() {
    if (this.props.cognito && this.props.cognito.user) {
      this.props.getClientInvites(this.props.cognito.user.username);
    }
    this.props.clearExtraAuthenticationInfo();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.cognito && nextProps.cognito.user) {
      if (!this.state.clientInvitesCalled) {
        this.props.getClientInvites(nextProps.cognito.user.username);
        this.setState({ clientInvitesCalled: true });
      }
    }
  }

  componentWillUnmount() {
    this.setState({ clientInvitesCalled: false });
  }

  render() {
    return (
      <div className="profile-wrap">
        {/* // $FlowFixMe */}
        <Route history={history}>
          <div>
            <Navigation match={this.props.match} />
          </div>
        </Route>
      </div>
    );
  }
}

export const mapStateToProps = (state: any): any => ({
  salonDetails: state.booking.salonDetails,
  invites: state.booking.invites,
  cognito: state.cognito,
});

export function mapDispatchToProps(dispatch: any) {
  return {
    setPageVisited(page: string) {
      dispatch(setPageVisited(page));
    },
    getClientInvites(username: string) {
      dispatch(getClientInvites(username));
    },
    clearExtraAuthenticationInfo() {
      dispatch(clearExtraAuthenticationInfo());
    },
  };
}

// $FlowFixMe
export default connect<any, Dispatch, Props, any, any>(mapStateToProps, mapDispatchToProps)(ProfileWrap);
