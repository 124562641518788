import currency from './currency.util';

const DEFAULT_SEPARATOR = ' - ';

const currencyRange = ({ from, to, abbreviation, separator }) => {
  const formattedFrom = currency({ value: from, abbreviation });
  const formattedTo = currency({ value: to, abbreviation });

  return `${formattedFrom}${separator || DEFAULT_SEPARATOR}${formattedTo}`;
};

// noinspection JSUnusedGlobalSymbols
export default currencyRange;
