import React from 'react';
import cn from 'classnames';
import moment from 'moment-timezone';

import Toggler from 'components/Toggler.component';
import { withTheme } from 'providers/Theme.provider';

import CN from './TimeItem.module.scss';

class TimeItem extends React.Component {
  static defaultProps = { hasMore: false, itemStylistId: null };
  onCheckboxClick = () => {
    if (this.props.itemStylistId) {
      // for patch test, need stylist id
      this.props.onCheckboxClick(this.props.ItemTime, this.props.itemStylistId);
    } else {
      this.props.onCheckboxClick(this.props.ItemTime);
    }
  };

  getOptionText() {
    if (this.props.optionsCount === 0 || this.props.optionsCount === 1) {
      return '+ option';
    } else if (this.props.hasMore) {
      return '+ options';
    }
    return 'options';
  }

  render() {
    const { isDarkMode, isNeutralMode, checked } = this.props;

    const componentClasses = cn({
      [CN.component]: true,
      [CN.selected]: checked,
      [CN.dark]: isDarkMode,
      [CN.neutral]: isNeutralMode,
    });

    const time = moment(this.props.ItemTime)
      .tz(this.props.timezone)
      .format('hh:mm A');
    let text = '';
    if (this.props.optionsCount) {
      text = `${this.props.optionsCount} ${this.getOptionText()}`;
    }
    return (
      <div className={componentClasses} role="button" tabIndex={0} onClick={this.onCheckboxClick}>
        <div className={CN.flex}>
          <span className={CN.title}>{time}</span>
          {this.props.optionsCount && <span className={CN.subTitle}>{text}</span>}
        </div>

        <Toggler selected={checked} tag="TimeItem__toggler" />
      </div>
    );
  }
}

export default withTheme()(TimeItem);
