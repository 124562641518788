import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';

import CN from './ExternalLink.module.scss';

const ExternalLink = ({ children, link, onClick, ...extra }) => {
  const { isDarkMode, isNeutralMode } = useTheme();
  return (
    <a
      {...extra}
      data-bem="ExternalLink"
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      onClick={onClick}
      className={cn({
        [CN.component]: true,
        [CN.dark]: isDarkMode,
        [CN.neutral]: isNeutralMode,
      })}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
