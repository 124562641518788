import moment from 'moment-timezone/moment-timezone';

import asArray from 'utils/arrays/asArray.util';
import fromDateToMomentTzFormat from 'utils/date/fromDateToMomentTzFormat.util';

import {
  CANCEL_APPOINTMENT_FAILURE,
  CANCEL_APPOINTMENT_REQUEST,
  CANCEL_APPOINTMENT_SUCCESS,
  CHECK_WAITLIST_FOR_SERVICES_FAILURE,
  CHECK_WAITLIST_FOR_SERVICES_REQUEST,
  CHECK_WAITLIST_FOR_SERVICES_SUCCESS,
  CLEAR_AVAILABLE_DAYS,
  CLEAR_AVAILABLE_PATCH_DAYS,
  CLEAR_AVAILABLE_SLOTS,
  CLEAR_AVAILABLE_STYLISTS,
  CLEAR_CLIENT_APPOINTMENTS,
  CLEAR_EXTRA_AUTHENTICATION_INFO,
  CLEAR_PAGE_VISITED,
  CLEAR_SALON_DETAILS,
  CLEAR_SELECTED_APPOINTMENT_TIME,
  CLEAR_SELECTED_PATCH_TIME,
  CLEAR_SELECTED_SERVICES,
  CLEAR_SELECTED_STYLISTS,
  CLEAR_SERVICES_TO_BOOK,
  CLEAR_SERVICES_TO_SHOW,
  CLEAR_USER_CARD_DETAILS,
  CONFIRM_EXTRA_AUTHENTICATION_FAILURE,
  CONFIRM_EXTRA_AUTHENTICATION_REQUEST,
  CONFIRM_EXTRA_AUTHENTICATION_SUCCESS,
  CRUD_CARD_FAILURE,
  CRUD_CARD_REQUEST,
  CRUD_CARD_SUCCESS,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  FETCH_APPOINTMENT_FAILURE,
  FETCH_APPOINTMENT_REQUEST,
  FETCH_APPOINTMENT_SUCCESS,
  GET_BOOKING_AVAILABILITY_FAILURE,
  GET_BOOKING_AVAILABILITY_REQUEST,
  GET_BOOKING_AVAILABILITY_SUCCESS,
  GET_CARD_DETAILS_FAILURE,
  GET_CARD_DETAILS_REQUEST,
  GET_CARD_DETAILS_SUCCESS,
  GET_CLIENT_APPOINTMENTS_FAILURE,
  GET_CLIENT_APPOINTMENTS_REQUEST,
  GET_CLIENT_APPOINTMENTS_SUCCESS,
  GET_CLIENT_DETAILS_FAILURE,
  GET_CLIENT_DETAILS_REQUEST,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_INVITES_FAILURE,
  GET_CLIENT_INVITES_REQUEST,
  GET_CLIENT_INVITES_SUCCESS,
  GET_PATCH_AVAILABILITY_FAILURE,
  GET_PATCH_AVAILABILITY_REQUEST,
  GET_PATCH_AVAILABILITY_SUCCESS,
  GET_PATCH_WEEKLY_AVAILABILITY_FAILURE,
  GET_PATCH_WEEKLY_AVAILABILITY_REQUEST,
  GET_PATCH_WEEKLY_AVAILABILITY_SUCCESS,
  GET_RECOMMENDED_SERVICES_FAILURE,
  GET_RECOMMENDED_SERVICES_REQUEST,
  GET_RECOMMENDED_SERVICES_SUCCESS,
  GET_SALON_ONLINE_GROUP_FAILURE,
  GET_SALON_ONLINE_GROUP_REQUEST,
  GET_SALON_ONLINE_GROUP_SUCCESS,
  GET_SERVICES_FAILURE,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_STYLISTS_FOR_SERVICE_FAILURE,
  GET_STYLISTS_FOR_SERVICE_REQUEST,
  GET_STYLISTS_FOR_SERVICE_SUCCESS,
  GET_STYLISTS_FOR_SERVICE_WEEKLY_FAILURE,
  GET_STYLISTS_FOR_SERVICE_WEEKLY_REQUEST,
  GET_STYLISTS_FOR_SERVICE_WEEKLY_SUCCESS,
  GET_WEEKLY_AVAILABILITY_FAILURE,
  GET_WEEKLY_AVAILABILITY_REQUEST,
  GET_WEEKLY_AVAILABILITY_SUCCESS,
  PROCCESS_INVITE_FAILURE,
  PROCCESS_INVITE_REQUEST,
  PROCCESS_INVITE_SUCCESS,
  SALON_DETAILS_FAILURE,
  SALON_DETAILS_REQUEST,
  SALON_DETAILS_SUCCESS,
  SEARCH_QUERY_SERVICES,
  SELECT_SERVICE,
  SEND_BOOKING_DATA_FAILURE,
  SEND_BOOKING_DATA_REQUEST,
  SEND_BOOKING_DATA_SUCCESS,
  SEND_PATCH_TEST_BOOKING_DATA_FAILURE,
  SEND_PATCH_TEST_BOOKING_DATA_REQUEST,
  SEND_PATCH_TEST_BOOKING_DATA_SUCCESS,
  SET_ADD_CARD_ERROR,
  SET_APPOINTMENT_TIME,
  SET_AVAILABILITY_SLOT,
  SET_INVITE_POST_DATA,
  SET_PAGE_VISITED,
  SET_PATCH_TIME,
  SET_PAY_FULL_AMOUNT,
  SET_REFERRER,
  SET_SELECTED_STYLISTS,
  SET_SERVICES_TO_BOOK,
  SET_SERVICES_TO_SHOW,
  SET_SOHOW_ALL_AVAILABLE,
  SET_UTM,
  UPDATE_CLIENT_EMAIL,
  UPDATE_CLIENT_NAME,
  UPDATE_CLIENT_PHONE,
  UPDATE_FIRST_AVAILABLE_DATE,
  UPDATE_FIRST_AVAILABLE_PATCH_DATE,
  UPDATE_NOTE,
  UPDATE_TOTAL_PRICE,
} from 'booking/actions';

export const CREATE_TOKEN_SUCCESS = 'CREATE_TOKEN_SUCCESS';
export const CLEAR_BILLING_INFO = 'CLEAR_BILLING_INFO';

export const initState = {
  isFetching: false,
  groupList: {},
  salonDetails: {
    city: '',
    address_line_1: '',
    address_line_2: '',
    email: '',
    name: '',
    timezone: 'Europe/London',
    phone: '',
    needs_card: false,
    ncob_deposit_settings: 'null',
    ncob_mode: '',
    no_show_protection: false,
    isFetchingCard: false,
    id: 0,
    has_waitlist: true,
    ncob_stylists_count: 0,
    group: 0,
    fb_details: {
      fb_pixel_id: '',
    },
    online_booking: false,
    book_ncob_with_same: false,
  },
  salonDetailsList: [],
  categories: {},
  pageVisited: [],
  recommendedServices: [],
  selectedServices: [],
  selectedPatchTime: null,
  seletedPatchStylistId: null,
  selectedAppointmentTime: null,
  selectedSlot: null,
  selectedStylists: {},
  totalPrice: '0',
  isPatchTestRequred: false,
  availableSlots: {},
  availableDays: {},
  availablePatchDays: {},
  availablePatchSlots: {},
  firstAvailableDate: null,
  firstAvailablePatchDate: null,
  slotMessage: '',
  notes: '',
  clientName: '',
  clientPhone: '',
  clientEmail: '',
  bookingResponse: null,
  patchTestBookingResponse: null,
  errorMessage: '',
  availableStylists: {
    data: {},
    message: '',
    code: 0,
  },
  availableWaitListStylists: [],
  stylistsForServiceWeekly: {},
  originalStylistForServiceWeekly: {},
  servicesToShow: [],
  servicesToBook: [],
  cardDetails: {
    last4: '',
    exp_month: '',
    exp_year: '',
    id: '',
  },
  newClient: {
    email: '',
    phone: '',
    first_name: '',
    last_name: '',
  },
  invites: [],
  invitePostData: null,
  invitePostStatus: '',
  inviteAccepted: '',
  fetchedAppointmentObj: null,
  confirmAuthenticationSuccess: false,
  confirmAuthenticationStatus: '',
  currentInviteSalon: '',
  currentInvite: 0,
  currentInviteCardOnly: false,
  clientAppointments: {},
  cancelSucceeded: null,
  isWaitList: false,
  showWaitList: false,
  deletingCard: '',
  showAllAvailable: false,
  check_ncob_availability_until: 0,
  utm: '',
  stylistFiltered: false,
};

export function calculateTotal(services) {
  const minTotal = services.reduce((a, b) => a + b.min_price, 0);
  const maxTotal = services.reduce((a, b) => a + b.max_price, 0);
  if (minTotal !== maxTotal) {
    return `£${minTotal} - £${maxTotal}`;
  }
  return `£${minTotal}`;
}

export function checkForPatchTest(services) {
  let required = false;
  for (let i = 0; i < services.length; i += 1) {
    if (services[i].has_patch_test) {
      required = true;
      break;
    }
  }
  return required;
}

export function changeAvailableDateForPatch(daysIn, patchTime) {
  const daysOut = Object.assign({});
  const days = Object.keys(daysIn);
  for (let i = 0; i < days.length; i += 1) {
    if (moment(days[i]).diff(moment(patchTime), 'd') > 2) {
      daysOut[days[i]] = daysIn[days[i]];
    }
  }
  return daysOut;
}

export function changeAvailableSlotsForPatch(slotsIn, patchTime) {
  const slotsOut = Object.assign({});
  const keys = Object.keys(slotsIn);
  for (let i = 0; i < keys.length; i += 1) {
    if (moment(keys[i]).diff(moment(patchTime), 'h') > 48) {
      slotsOut[keys[i]] = slotsIn[keys[i]];
    }
  }
  return slotsOut;
}

const booking = (state = initState, action) => {
  if (action && action.type) {
    switch (action.type) {
      case CHECK_WAITLIST_FOR_SERVICES_REQUEST:
      case GET_CLIENT_APPOINTMENTS_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case CANCEL_APPOINTMENT_REQUEST: {
        return {
          ...state,
          isFetching: true,
          cancelSucceeded: null,
        };
      }
      case CANCEL_APPOINTMENT_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          cancelSucceeded: true,
          errorMessage: '',
        };
      }
      case SET_SOHOW_ALL_AVAILABLE: {
        return {
          ...state,
          showAllAvailable: action.tf,
        };
      }
      case CHECK_WAITLIST_FOR_SERVICES_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          showWaitList: action.response.show_waitlist,
          errorMessage: '',
        };
      }
      case CHECK_WAITLIST_FOR_SERVICES_FAILURE: {
        return {
          ...state,
          isFetching: false,
          showWaitList: false,
          errorMessage: action.errorMessage,
        };
      }
      case CANCEL_APPOINTMENT_FAILURE: {
        return {
          ...state,
          isFetching: false,
          cancelSucceeded: false,
          errorMessage: action.errorMessage,
        };
      }
      case CONFIRM_EXTRA_AUTHENTICATION_REQUEST: {
        // before confirm payment clear old booking info
        return {
          ...state,
          isFetching: true,
          // bookingResponse: null,
        };
      }
      case SALON_DETAILS_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case SALON_DETAILS_SUCCESS: {
        const { salonDetailsList } = state;
        const newList = [];
        if (salonDetailsList.findIndex(s => s.id === action.response.id) < 0) {
          newList.push(action.response);
        }
        return {
          ...state,
          isFetching: false,
          salonDetails: action.response,
          errorMessage: '',
          salonDetailsList: [...salonDetailsList, ...newList],
        };
      }
      // group
      case GET_SALON_ONLINE_GROUP_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case GET_SALON_ONLINE_GROUP_SUCCESS: {
        // const groupId = action.response.id;
        return {
          ...state,
          isFetching: false,
          groupList: action.response,
          errorMessage: '',
          //  groupId,
        };
      }
      case GET_SALON_ONLINE_GROUP_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      }
      case CRUD_CARD_REQUEST: {
        return {
          ...state,
          isFetchingCard: true,
        };
      }
      case CRUD_CARD_SUCCESS: {
        return {
          ...state,
          isFetchingCard: false,
          errorMessage: '',
        };
      }
      case DELETE_CARD_REQUEST: {
        return {
          ...state,
          deletingCard: 'deleting',
          errorMessage: '',
        };
      }
      case DELETE_CARD_SUCCESS: {
        return {
          ...state,
          deletingCard: 'deleted',
          errorMessage: '',
          cardDetails: {
            last4: '',
            exp_month: '',
            exp_year: '',
            id: '',
          },
        };
      }
      case CRUD_CARD_FAILURE: {
        return {
          ...state,
          isFetchingCard: false,
          errorMessage: action.errorMessage,
        };
      }
      case SET_ADD_CARD_ERROR: {
        return {
          ...state,
          errorMessage: action.errorMessage,
        };
      }
      case GET_CLIENT_APPOINTMENTS_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          clientAppointments: action.response,
          errorMessage: '',
        };
      }
      case CLEAR_CLIENT_APPOINTMENTS: {
        return {
          ...state,
          clientAppointments: {},
        };
      }
      case CLEAR_SALON_DETAILS: {
        return {
          ...state,
          salonDetails: {},
          isWaitList: false,
        };
      }
      case GET_CARD_DETAILS_FAILURE: {
        return {
          ...state,
          isFetchingCard: false,
          errorMessage: action.errorMessage,
        };
      }
      case SALON_DETAILS_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      }
      case GET_CARD_DETAILS_REQUEST: {
        return {
          ...state,
          isFetchingCard: true,
        };
      }
      case GET_CARD_DETAILS_SUCCESS: {
        return {
          ...state,
          isFetchingCard: false,
          cardDetails: action.response,
          errorMessage: '',
        };
      }
      case CONFIRM_EXTRA_AUTHENTICATION_SUCCESS: {
        return {
          ...state,
          errorMessage: '',
          confirmAuthenticationSuccess: true,
          confirmAuthenticationStatus: action.response.status,
          isFetching: false,
        };
      }
      case CLEAR_EXTRA_AUTHENTICATION_INFO: {
        return {
          ...state,
          confirmAuthenticationSuccess: false,
          confirmAuthenticationStatus: '',
        };
      }
      case CLEAR_USER_CARD_DETAILS:
        return { ...state, cardDetails: { last4: '', exp_month: '', exp_year: '', id: '' } };
      case GET_SERVICES_REQUEST:
        return { ...state, isFetching: true };
      case GET_SERVICES_SUCCESS: {
        const categoryList = action.response;
        const salonId = Object.keys(action.response)[0];
        const { categories } = state;
        const existingKeys = Object.keys(categories);
        let newCategories;
        if (existingKeys.indexOf(salonId) < 0) {
          newCategories = { ...categories, ...categoryList };
        } else {
          // eslint-disable-next-line prefer-destructuring
          categories[parseInt(salonId, 10)] = Object.values(categoryList)[0];
          newCategories = Object.assign({}, categories);
        }
        return {
          ...state,
          isFetching: false,
          categories: newCategories,
          errorMessage: '',
        };
      }
      case GET_CLIENT_APPOINTMENTS_FAILURE:
      case CONFIRM_EXTRA_AUTHENTICATION_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      }
      case GET_SERVICES_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      }
      case GET_RECOMMENDED_SERVICES_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case GET_RECOMMENDED_SERVICES_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          recommendedServices: action.response,
          errorMessage: '',
        };
      }
      case GET_RECOMMENDED_SERVICES_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      }
      case SELECT_SERVICE: {
        const serviceId = action.service.id;
        const selectedServices = [...state.selectedServices];
        const selectedStylists = Object.assign({}, state.selectedStylists);
        const service = selectedServices.filter(ser => ser.id === serviceId);
        if (service.length === 0) {
          selectedServices.push(action.service);
        } else {
          const index = selectedServices.findIndex(ser => ser.id === serviceId);
          selectedServices.splice(index, 1);
          delete selectedStylists[action.service.id.toString()];
        }
        const totalPrice = calculateTotal(selectedServices);
        const isPatchTestRequred = checkForPatchTest(selectedServices);

        return {
          ...state,
          selectedServices,
          selectedStylists,
          totalPrice,
          isPatchTestRequred,
          bookingResponse: null,
          patchTestBookingResponse: null,
          stylistFiltered: false,
        };
      }
      case CLEAR_SELECTED_SERVICES: {
        const newState = Object.assign({}, state);
        delete newState.selectedStylists;
        return {
          ...newState,
          selectedServices: [],
          selectedPatchTime: null,
          selectedAppointmentTime: null,
          selectedSlot: null,
          selectedStylists: {},
          totalPrice: '0',
          isPatchTestRequred: false,
          servicesToShow: [],
          stylistsForServiceWeekly: {},
          availableStylists: {
            data: {},
            message: '',
            code: 0,
          },
        };
      }
      case GET_BOOKING_AVAILABILITY_REQUEST: {
        const { isWaitList } = state;
        let newIsWaitList = isWaitList;
        if (action.params && action.params.isWaitList && !isWaitList) {
          newIsWaitList = action.params.isWaitList;
        }
        return {
          ...state,
          isFetching: true,
          errorMessage: '',
          slotMessage: '',
          isWaitList: newIsWaitList,
        };
      }
      case GET_BOOKING_AVAILABILITY_SUCCESS: {
        const patchTime = state.selectedPatchTime;
        let newSlots;
        if (patchTime) {
          newSlots = changeAvailableSlotsForPatch(action.response.data, patchTime);
        } else {
          newSlots = action.response.data;
        }
        return {
          ...state,
          isFetching: false,
          availableSlots: newSlots,
          errorMessage: '',
          slotMessage: action.response.message,
        };
      }
      case GET_BOOKING_AVAILABILITY_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
          slotMessage: '',
        };
      }
      case GET_WEEKLY_AVAILABILITY_REQUEST: {
        const { isWaitList } = state;
        let newIsWaitList = isWaitList;
        if (action.params && action.params.isWaitList && !isWaitList) {
          newIsWaitList = action.params.isWaitList;
        }
        let checkNcobAvailabilityUntil;
        if (action.params && action.params.check_ncob_availability_until) {
          checkNcobAvailabilityUntil = action.params.check_ncob_availability_until;
        }
        return {
          isFetching: true,
          ...state,
          errorMessage: '',
          slotMessage: '',
          isWaitList: newIsWaitList,
          check_ncob_availability_until: checkNcobAvailabilityUntil,
        };
      }
      case GET_WEEKLY_AVAILABILITY_SUCCESS: {
        const { check_ncob_availability_until: checkNcobAvailabilityUntil } = state;
        const avail = Object.entries(action.response).filter(e => e[1] === true);
        const dateChecked = avail.length ? avail[0][0] : Object.keys(action.response).pop();
        if (
          (dateChecked &&
            checkNcobAvailabilityUntil &&
            moment(dateChecked).diff(moment(), 'd') < checkNcobAvailabilityUntil) ||
          !checkNcobAvailabilityUntil
        ) {
          return {
            ...state,
            isFetching: false,
            availableDays: action.response,
            errorMessage: '',
            slotMessage: action.response.message,
          };
        }
        return {
          ...state,
          isFetching: false,
          errorMessage: '',
          slotMessage: 'No slots',
          availableDays: { [moment(dateChecked).format('dddd, D MMM YYYY')]: 'Exceeds Maximum days' },
        };
      }
      case 'Exceeds_Maximum_Days': {
        const { check_ncob_availability_until: checkNcobAvailabilityUntil } = state;
        const key = moment()
          .add(checkNcobAvailabilityUntil, 'd')
          .format('dddd, D MMM YYYY');
        return {
          ...state,
          isFetching: false,
          errorMessage: '',
          slotMessage: 'No slots',
          availableDays: { [key]: 'Exceeds Maximum days' },
          availablePatchDays: { [key]: 'Exceeds Maximum days' },
        };
      }
      case GET_WEEKLY_AVAILABILITY_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
          slotMessage: '',
        };
      }
      case GET_PATCH_WEEKLY_AVAILABILITY_REQUEST: {
        return {
          ...state,
          isFetching: true,
          errorMessage: '',
          slotMessage: '',
        };
      }
      case GET_PATCH_WEEKLY_AVAILABILITY_SUCCESS: {
        const { check_ncob_availability_until: checkNcobAvailabilityUntil } = state;
        const avail = Object.entries(action.response).filter(e => e[1] === true);
        const dateChecked = avail.length ? avail[0][0] : Object.keys(action.response).pop();
        if (
          (dateChecked &&
            checkNcobAvailabilityUntil &&
            moment(dateChecked).diff(moment(), 'd') < checkNcobAvailabilityUntil) ||
          !checkNcobAvailabilityUntil
        ) {
          return {
            ...state,
            isFetching: false,
            availablePatchDays: action.response,
            errorMessage: '',
            slotMessage: action.response.message,
          };
        }
        return {
          ...state,
          isFetching: false,
          errorMessage: '',
          slotMessage: 'No slots',
          availablePatchDays: { [moment(dateChecked).format('dddd, D MMM YYYY')]: 'Exceeds Maximum days' },
        };
      }
      case GET_PATCH_WEEKLY_AVAILABILITY_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
          slotMessage: '',
        };
      } // guan
      case GET_PATCH_AVAILABILITY_REQUEST: {
        return {
          ...state,
          isFetching: true,
          errorMessage: '',
          slotMessage: '',
        };
      }
      case GET_PATCH_AVAILABILITY_SUCCESS: {
        let availablePatchSlots;
        if (!Object.keys(action.response.data).length) {
          availablePatchSlots = null;
        } else {
          availablePatchSlots = action.response.data;
        }
        return {
          ...state,
          isFetching: false,
          availablePatchSlots,
          errorMessage: '',
          slotMessage: action.response.message,
        };
      }
      case GET_PATCH_AVAILABILITY_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
          slotMessage: '',
        };
      }
      case SET_PATCH_TIME: {
        if (!action.time) {
          return {
            ...state,
          };
        }
        return {
          ...state,
          selectedPatchTime: fromDateToMomentTzFormat(action.time),
          seletedPatchStylistId: action.stylistId,
        };
      }
      case SET_APPOINTMENT_TIME: {
        return {
          ...state,
          selectedAppointmentTime: action.time,
        };
      }
      case SET_AVAILABILITY_SLOT: {
        return {
          ...state,
          selectedSlot: action.slot,
        };
      }
      case CLEAR_AVAILABLE_SLOTS: {
        return {
          ...state,
          availableSlots: {},
          availablePatchSlots: {},
          slotMessage: '',
        };
      }
      case CLEAR_AVAILABLE_DAYS: {
        return {
          ...state,
          availableDays: {},
        };
      }
      case CLEAR_AVAILABLE_PATCH_DAYS: {
        return {
          ...state,
          availablePatchDays: {},
        };
      }
      case SET_SELECTED_STYLISTS: {
        const { stylistsForServiceWeekly, selectedServices, originalStylistForServiceWeekly, salonDetails } = state;
        const filteredStylistsWeekly = Object.assign({}, originalStylistForServiceWeekly);
        const index = selectedServices.findIndex(s => s.id === action.serviceId);
        const keys = selectedServices.map(service => service.id);
        let currentSelectedStylistId;
        if (action.stylist) {
          currentSelectedStylistId = action.stylist.stylist_id;
        }
        const weekly = index === 0 ? originalStylistForServiceWeekly : stylistsForServiceWeekly;
        const updatedSelectedStylist = Object.assign({}, action.selectedStylists);
        let updatedStylistFiltered = false;
        if (salonDetails.book_ncob_with_same && currentSelectedStylistId) {
          if (index === 0) {
            for (let i = 1; i < keys.length; i += 1) {
              const n = keys[i];
              for (let j = 0; j < weekly[n].length; j += 1) {
                if (weekly[n][j].stylist_id === currentSelectedStylistId) {
                  filteredStylistsWeekly[n] = [weekly[n][j]];
                  updatedStylistFiltered = true;
                }
              }
            }
          }
        }
        return {
          ...state,
          stylistFiltered: updatedStylistFiltered,
          selectedStylists: updatedSelectedStylist,
          stylistsForServiceWeekly: index === 0 ? filteredStylistsWeekly : stylistsForServiceWeekly,
          // stylistsForServiceWeekly: stylistFiltered ? filteredStylistsWeekly : stylistsForServiceWeekly,
        };
      }
      case SET_SERVICES_TO_BOOK: {
        return {
          ...state,
          servicesToBook: action.servicesToBook,
        };
      }
      case CLEAR_SERVICES_TO_BOOK: {
        return {
          ...state,
          servicesToBook: [],
        };
      }
      case CLEAR_SELECTED_STYLISTS: {
        return {
          ...state,
          selectedStylists: {},
          servicesToShow: [],
        };
      }
      case UPDATE_NOTE: {
        return {
          ...state,
          notes: action.notes,
        };
      }
      case UPDATE_TOTAL_PRICE: {
        return {
          ...state,
          totalPrice: action.price,
        };
      }
      case UPDATE_FIRST_AVAILABLE_PATCH_DATE: {
        return {
          ...state,
          firstAvailablePatchDate: action.date,
        };
      }
      case UPDATE_FIRST_AVAILABLE_DATE: {
        return {
          ...state,
          firstAvailableDate: action.date,
        };
      }
      case UPDATE_CLIENT_NAME: {
        return {
          ...state,
          clientName: action.clientName,
        };
      }
      case UPDATE_CLIENT_PHONE: {
        return {
          ...state,
          clientPhone: action.clientPhone,
        };
      }
      case UPDATE_CLIENT_EMAIL: {
        return {
          ...state,
          clientEmail: action.clientEmail,
        };
      }
      case SEND_BOOKING_DATA_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case SEND_BOOKING_DATA_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          bookingResponse: action.response,
        };
      }
      case SEND_BOOKING_DATA_FAILURE: {
        // console.log('SEND_BOOKING_DATA_FAILURE action.response', action.response);
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
          bookingResponse: { createBookingFailed: true },
        };
      }
      case SEND_PATCH_TEST_BOOKING_DATA_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case SEND_PATCH_TEST_BOOKING_DATA_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          patchTestBookingResponse: action.response,
        };
      }
      case SEND_PATCH_TEST_BOOKING_DATA_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      }
      case CLEAR_SELECTED_PATCH_TIME: {
        return {
          ...state,
          selectedPatchTime: null,
        };
      }
      case CLEAR_SELECTED_APPOINTMENT_TIME: {
        return {
          ...state,
          selectedAppointmentTime: null,
          selectedSlot: null,
          selectedStylists: {},
          servicesToShow: [],
        };
      }
      case GET_STYLISTS_FOR_SERVICE_REQUEST: {
        const { isWaitList } = state;
        let newIsWaitList = isWaitList;
        if (action.params.isWaitList && !isWaitList) {
          newIsWaitList = action.params.isWaitList;
        }
        return {
          ...state,
          isFetching: true,
          isWaitList: newIsWaitList,
        };
      }
      case GET_STYLISTS_FOR_SERVICE_WEEKLY_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case GET_STYLISTS_FOR_SERVICE_WEEKLY_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          stylistsForServiceWeekly: action.response,
          originalStylistForServiceWeekly: action.response,
        };
      }
      case GET_STYLISTS_FOR_SERVICE_WEEKLY_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      }
      case GET_STYLISTS_FOR_SERVICE_SUCCESS: {
        const { isWaitList, availableWaitListStylists } = state;
        let newAvailableStylists;
        let newAvailableWaitListStylists = [];
        if (!isWaitList) {
          newAvailableStylists = action.response;
        } else {
          newAvailableStylists = action.response;
          let serviceIds;
          if (availableWaitListStylists.length > 0) {
            serviceIds = availableWaitListStylists.map(
              stylists => Object.values(stylists.data)[0].options[0].service_id,
            );
          }
          if (
            serviceIds &&
            Object.keys(newAvailableStylists.data).length > 0 &&
            serviceIds.indexOf(Object.values(newAvailableStylists.data)[0].options[0].service_id) < 0
          ) {
            newAvailableWaitListStylists = [...availableWaitListStylists, newAvailableStylists];
          } else {
            newAvailableWaitListStylists = [newAvailableStylists];
          }
        }
        return {
          ...state,
          isFetching: false,
          availableStylists: newAvailableStylists,
          availableWaitListStylists: newAvailableWaitListStylists,
        };
      }

      case GET_STYLISTS_FOR_SERVICE_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      }
      case CLEAR_AVAILABLE_STYLISTS: {
        const availableStylists = {
          data: {},
          message: '',
          code: 0,
        };
        return {
          ...state,
          availableStylists,
          servicesToShow: [],
        };
      }
      case SET_SERVICES_TO_SHOW: {
        return {
          ...state,
          servicesToShow: action.services,
        };
      }
      case CLEAR_SERVICES_TO_SHOW: {
        return {
          ...state,
          servicesToShow: [],
          selectedStylists: {},
        };
      }
      case SET_REFERRER: {
        return {
          ...state,
          referrerInfo: action.referrerInfo,
        };
      }
      case SET_PAGE_VISITED: {
        const { pageVisited } = state;
        const newPageVisited = pageVisited.slice();
        if (!newPageVisited.includes(action.page)) {
          newPageVisited.push(action.page);
        }
        return {
          ...state,
          pageVisited: newPageVisited,
        };
      }
      case CLEAR_PAGE_VISITED: {
        return {
          ...state,
          pageVisited: [],
          bookingResponse: null,
        };
      }
      case SEARCH_QUERY_SERVICES: {
        const { categories } = state;
        const searchString = action.searchQuery;
        const salonId = parseInt(document.location.href.split('#/salon/')[1].split('/')[0], 10);
        const theCat = categories[salonId];
        let activeCategories = asArray(theCat).map(p => ({ ...p, show: true }));
        const salonCategories = categories[salonId];
        if (searchString) {
          for (let i = 0; i < asArray(salonCategories).length; i += 1) {
            let countOfShow = 0;
            for (let j = 0; j < salonCategories[i].services.length; j += 1) {
              if (salonCategories[i].services[j].name.toLowerCase().includes(searchString.toLowerCase())) {
                salonCategories[i].services[j].show = true;
                countOfShow += 1;
              } else {
                salonCategories[i].services[j].show = false;
              }
            }
            salonCategories[i].show = countOfShow > 0;
          }
          activeCategories = salonCategories;
        } else {
          for (let i = 0; i < asArray(salonCategories).length; i += 1) {
            for (let j = 0; j < salonCategories[i].services.length; j += 1) {
              salonCategories[i].services[j].show = true;
            }
            salonCategories[i].show = true;
          }
          activeCategories = salonCategories;
        }
        return { ...state, categories: { ...state.categories, [salonId]: activeCategories } };
      }
      case GET_CLIENT_DETAILS_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case GET_CLIENT_DETAILS_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          newClient: action.response, // email, phone, first_name, last_name
        };
      }
      case GET_CLIENT_DETAILS_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      }
      case GET_CLIENT_INVITES_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case GET_CLIENT_INVITES_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          invites: action.response, // array of invites
        };
      }
      case GET_CLIENT_INVITES_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
        };
      }
      case PROCCESS_INVITE_REQUEST: {
        const theInvites = state.invites.slice();
        let currentInviteSalon = '';
        let { currentInviteCardOnly } = state;
        if (theInvites.length > 0) {
          for (let i = 0; i < theInvites.length; i += 1) {
            if (theInvites[i].id === action.params.data.invite_id) {
              currentInviteSalon = theInvites[i].salon_name;
              currentInviteCardOnly = theInvites[i].requested_card_only;
              break;
            }
          }
        }
        return {
          ...state,
          isFetching: true,
          invitePostData: 'POSTING',
          inviteAccepted: action.params.data.accepted,
          currentInvite: action.params.data.invite_id,
          currentInviteSalon,
          currentInviteCardOnly,
        };
      }
      case PROCCESS_INVITE_SUCCESS: {
        const { inviteAccepted } = state;
        let { invitePostData } = state;
        if (inviteAccepted === 'False') {
          invitePostData = 'DECLINED';
        } else if (inviteAccepted === 'True') {
          invitePostData = 'SUCCESS';
        }
        return {
          ...state,
          isFetching: false,
          invitePostData,
          invitePostStatus: action.response.r_status,
        };
      }
      case PROCCESS_INVITE_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.errorMessage,
          invitePostData: 'FAILED',
        };
      }
      case SET_PAY_FULL_AMOUNT: {
        const theInvites = state.invites.slice();
        if (theInvites.length > 0) {
          for (let i = 0; i < theInvites.length; i += 1) {
            if (theInvites[i].id === action.inviteId) {
              theInvites[i].payFullAmount = true;
            }
          }
        }
        return {
          ...state,
          invites: theInvites,
        };
      }
      case FETCH_APPOINTMENT_REQUEST:
        return {
          ...state,
          fetchedAppointmentObj: {
            isFetching: true,
            response: null,
          },
        };
      case FETCH_APPOINTMENT_SUCCESS: {
        return {
          ...state,
          fetchedAppointmentObj: {
            isFetching: false,
            response: action.response,
            errorMessage: null,
          },
        };
      }
      case FETCH_APPOINTMENT_FAILURE: {
        return {
          ...state,
          fetchedAppointmentObj: {
            isFetching: false,
            response: null,
            errorMessage: action.errorMessage,
          },
        };
      }
      case SET_INVITE_POST_DATA: {
        return {
          ...state,
          invitePostData: action.status,
          confirmAuthenticationSuccess: false,
        };
      }
      case CREATE_TOKEN_SUCCESS: {
        const { cardDetails } = state;
        cardDetails.last4 = action.stripetoken.token.card.last4;
        return {
          ...state,
          cardDetails,
          isFetching: false,
        };
      }
      case SET_UTM: {
        return {
          ...state,
          utm: action.utm,
        };
      }
      default: {
        return state;
      }
    }
  }
  return state;
};

export default booking;
