import { useCallback, useMemo } from 'react';

import useStateWhileMounted from 'hooks/useStateWhileMounted.hook';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';

const useTip = init => {
  const [val, setVal] = useStateWhileMounted(fromAnyToBool(init));

  const yay = useCallback(() => setVal(true), [setVal]);
  const nay = useCallback(() => setVal(false), [setVal]);

  const set = useCallback(value => setVal(fromAnyToBool(value)), [setVal]);
  const tip = useCallback(() => setVal(!val), [setVal, val]);

  return useMemo(
    () => ({
      val,
      inv: !val,

      yay,
      nay,

      tip,
      set,
    }),
    [val, yay, nay, tip, set],
  );
};

// noinspection JSUnusedGlobalSymbols
export default useTip;
