// @flow

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';

type Props = {
  onItemClick: (e: Event) => void,
  monthName?: string,
};

const DropdownItem = (props: Props) => (
  <li className="month-item" onClick={props.onItemClick}>
    {props.monthName}
  </li>
);

DropdownItem.defaultProps = { monthName: '' };

export default DropdownItem;
