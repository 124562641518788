// @flow

import moment from 'moment';

import { API_ROOT } from 'config/index';
import { AUTH_API } from 'middleware/api';

export const CREATE_STRIP_CUSTOMER = 'CREATE_STRIP_CUSTOMER';
export const CREATE_STRIP_CUSTOMER_REQUEST = 'CREATE_STRIP_CUSTOMER_REQUEST';
export const CREATE_STRIP_CUSTOMER_SUCCESS = 'CREATE_STRIP_CUSTOMER_SUCCESS';
export const CREATE_STRIP_CUSTOMER_FAILURE = 'CREATE_STRIP_CUSTOMER_FAILURE';

export const CREATE_TOKEN_SUCCESS = 'CREATE_TOKEN_SUCCESS';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const UPDATE_STRIP_TOKEN_REQUEST = 'UPDATE_STRIP_TOKEN_REQUEST';
export const UPDATE_STRIP_TOKEN_SUCCESS = 'UPDATE_STRIP_TOKEN_SUCCESS';
export const UPDATE_STRIP_TOKEN_FAILURE = 'UPDATE_STRIP_TOKEN_FAILURE';
export const SET_USERNAME = 'SET_USERNAME';
export const GET_CLIENT_SECRET_REQUEST = 'GET_CLIENT_SECRET_REQUEST';
// export const GET_CLIENT_SECRET_SUCCESS = 'GET_CLIENT_SECRET_SUCCESS';
export const GET_CLIENT_SECRET_FAILURE = 'GET_CLIENT_SECRET_FAILURE';
export const CHARGE_CLIENT_SECRET_REQUEST = 'CHARGE_CLIENT_SECRET_REQUEST';
export const CHARGE_CLIENT_SECRET_SUCCESS = 'CHARGE_CLIENT_SECRET_SUCCESS';
export const CHARGE_CLIENT_SECRET_FAILURE = 'CHARGE_CLIENT_SECRET_FAILURE';
export const CLEAR_BILLING_INFO = 'CLEAR_BILLING_INFO';
export const GET_BOOKING_COST_REQUEST = 'GET_BOOKING_COST_REQUEST';
export const GET_BOOKING_COST_SUCCESS = 'GET_BOOKING_COST_SUCCESS';
export const GET_BOOKING_COST_FAILURE = 'GET_BOOKING_COST_FAILURE';
export const SETUP_INTENT_REQUEST = 'SETUP_INTENT_REQUEST';
export const SETUP_INTENT_SUCCESS = 'SETUP_INTENT_SUCCESS';
export const SETUP_INTENT_FAILURE = 'SETUP_INTENT_FAILURE';
export const CLEAR_INTENT_CLIENT_SECRET = 'CLEAR_INTENT_CLIENT_SECRET';
export const SETUP_INTENT_FRONTEND = 'SETUP_INTENT_FRONTEND';

type CreateStripeCustomerAction = {
  type: typeof CREATE_STRIP_CUSTOMER,
};

type CreateTokenAction = {
  type: typeof CREATE_TOKEN_SUCCESS,
  stripetoken: any,
};

export type billingAction = CreateStripeCustomerAction | CreateTokenAction;

export const CreateToken = stripetoken => ({ stripetoken, type: CREATE_TOKEN_SUCCESS });
export const GetToken = () => ({ type: GET_TOKEN_SUCCESS });
export const setUserName = username => ({ type: SET_USERNAME, username });
export const clearBillingInfo = clearBookingInfo => ({ type: CLEAR_BILLING_INFO, clearBookingInfo });
export const setupIntentFrontend = setupIntentFromStrip => ({ type: SETUP_INTENT_FRONTEND, setupIntentFromStrip });
export const clearIntentClientSecret = () => ({ type: CLEAR_INTENT_CLIENT_SECRET });

export function getBookingCost(salonId, appointmentDate, services, stylists) {
  return {
    [AUTH_API]: {
      apiRoot: API_ROOT,
      endpoint: `ncob/checkout/salon/appointment/cost/${salonId.toString()}/${moment(
        appointmentDate,
      ).toISOString()}/${services.toString()}/${stylists.toString()}/`,
      authenticated: true,
      types: [GET_BOOKING_COST_REQUEST, GET_BOOKING_COST_SUCCESS, GET_BOOKING_COST_FAILURE],
      config: { method: 'GET', headers: { 'Content-Type': 'application/json' } },
    },
  };
}

export const setupIntent = (salonId, username) => ({
  [AUTH_API]: {
    apiRoot: API_ROOT,
    endpoint: `ncob/checkout/salon/setupintent/${salonId.toString()}/${username}/`,
    authenticated: true,
    types: [SETUP_INTENT_REQUEST, SETUP_INTENT_SUCCESS, SETUP_INTENT_FAILURE],
    config: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // body: JSON.stringify({ cognito_username: username }),
    },
  },
});
