// @flow
/* eslint-disable camelcase */

import { SMS_FAILURE, SMS_REQUEST, SMS_SUCCESS } from '../actions/authentication';

type $ExtractFunctionReturn = <V>(v: (...args: any) => V) => V;
export type AuthState = $ObjMap<any, $ExtractFunctionReturn>;

function auth_request(
  state: AuthState = {
    isFetching: false,
    smsSent: false,
    phone: '',
    errorMessage: null,
  },
  action: Action,
) {
  switch (action.type) {
    case SMS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        smsSent: false,
        phone: action.phone,
        errorMessage: null,
      });
    case SMS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        smsSent: true,
        phone: action.response.phone,
        errorMessage: null,
      });
    case SMS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        smsSent: false,
        errorMessage: action.errorMessage,
      });
    default:
      return state;
  }
}

export default auth_request;
