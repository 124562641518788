const ACTION_TYPE_SUFFIX = Object.freeze({
  // normal fetch operations
  init: 'InitType',
  pass: 'PassType',
  fail: 'FailType',
  done: 'DoneType',
  // pusher communication
  push: 'PushType',
});

// noinspection JSUnusedGlobalSymbols
export default ACTION_TYPE_SUFFIX;
