import { API_ROOT } from 'config/index';
import { AUTH_API } from 'middleware/api';
import withOptions from 'utils/functions/withOptions.util';
import actionType from 'utils/mw/actionType.util';

const ATAG = 'beGiftVoucher';
const AT = actionType(ATAG);

// https://staging-core.api.getslick.com/payments/stripe/get/voucher/cs_test_a1zBfblF4MY9fC8j9tYWETpOcAaM6g14fbJ5vJLwIhuINVlw25w7p2URUl/

const beGiftVoucher = withOptions(({ sessionId }) => ({
  [AUTH_API]: {
    apiRoot: API_ROOT,
    endpoint: `payments/stripe/get/voucher/${sessionId}/`,
    authenticated: false,
    types: [AT.init, AT.pass, AT.fail, AT.done],
    config: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  },
}));

beGiftVoucher.ATAG = ATAG;
beGiftVoucher.AT = AT;

export default beGiftVoucher;
