// @flow
import moment from 'moment';

import { AUTH_API } from 'middleware/api';

export const AVAILABILITY_REQUEST = 'AVAILABILITY_REQUEST';
export const AVAILABILITY_SUCCESS = 'AVAILABILITY_SUCCESS';
export const AVAILABILITY_FAILURE = 'AVAILABILITY_FAILURE';

export function fetchAvailabilityList(bookingId: string, start: moment, end: moment) {
  const from = start.toISOString();
  const to = end.toISOString();
  return {
    [AUTH_API]: {
      endpoint: `rebooking/availability/appointment/${bookingId}/?from=${from}&to=${to}`,
      authenticated: true,
      types: [AVAILABILITY_REQUEST, AVAILABILITY_SUCCESS, AVAILABILITY_FAILURE],
    },
  };
}
