// @flow

/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-useless-return */

import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import moment from 'moment';

import { fetchGithub } from 'actions/authentication';
import { clearBillingInfo } from 'actions/billing';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
// $FlowFixMe
import gitlog from 'config/gitlog.txt';
import { withTheme } from 'providers/Theme.provider';
import asArray from 'utils/arrays/asArray.util';
import fromAnyToInteger from 'utils/converters/fromAnyToInteger';
import fromAnyToString from 'utils/converters/fromAnyToString';
import fromPropsToParams from 'utils/converters/props/fromPropsToParams.util';
import fromPropsToQuery from 'utils/converters/props/fromPropsToQuery.util';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';
import MOBILE_VIEWPORT from 'utils/strings/mobileViewport.const';

import {
  clearAvailableDays,
  clearAvailableSlots,
  clearExtraAuthenticationInfo,
  clearPageVisited,
  clearSalonDetails,
  clearSelectedServices,
  clearUserCardDetails,
  getCategoriesWithServices,
  getRecommendedServices,
  getSalonDetails,
  getStylistsForServiceWeekly,
  getUserCardDetails,
  searchServices,
  selectService,
  setSelectedStylists,
  setUtm,
  updateFirstAvailableDate,
} from 'booking/actions';
import HeaderComponent from 'booking/common/Header';
import PatchTest from 'booking/common/PatchTest.component';
import ServiceSelection from 'booking/serviceSelection/ServiceSelection';
import ServiceSelectionComponentCopy from 'booking/serviceSelection/ServiceSelectionCopy';
import BuyVoucherButton from 'booking/voucher/BuyVoucherButton.component';
import Offline from 'booking/voucher/Offline.component';
import Spinner from 'common/Spinner.component';
import clearCache from 'lib/clearCache';
import { fbPixelTracking, setPageVisitedFunc } from 'lib/utils';
import type { Category } from 'types/category';
import type { SalonDetails } from 'types/salonDetails';
import type { Service } from 'types/service';
import type { Stylist } from 'types/stylistsForService';

import CN from './ServiceSelectionWrap.module.scss';

const L = logger('ServiceSelectionWrap');

const MAX_TEXT = 190; // 2 lines
const MAX_TEXT_MOBILE = 140; // 3 lines
const USE_LOG = false;
const USE_SEARCH = false;

const filterCategories = ({ categories, query }) => {
  const q = asObject(query);
  let filteredCategories = categories;

  const categoryName = fromAnyToString(q.categoryName);
  const categoryId = fromAnyToInteger(q.categoryId);

  if (categoryName) {
    filteredCategories = filteredCategories.filter(c => c.name === categoryName);
  }

  if (categoryId) {
    filteredCategories = filteredCategories.filter(c => c.id === categoryId);
  }

  return filteredCategories;
};

type Props = {
  cognito: any,
  history: any,
  match: {
    params: {
      id: string,
      searchQueryId?: string,
    },
  },
  cardDetails: any,
  username: string,
  categories: { number: Category[] },
  selectService: (service: Service) => void,
  selectedServices: Service[],
  isFetching: boolean,
  salonDetails: SalonDetails,
  fetchGithub: () => {},
  getUserCardDetails: (username: string) => void,
  getCategoriesWithServices: (salonId: number) => void,
  setSelectedStylists: (
    selectedStylists: { [serviceId: string]: null | Stylist },
    serviceId?: number,
    stylist?: any,
  ) => void,
  getStylistsForServiceWeekly: (salonId: number, services: number[]) => void,
  clearSelectedServices: () => void,
  searchServices: (searchQuery: string) => void,
  isPatchTestRequred: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  stylistsForServiceWeekly: any,
  setUtm: (utm: string) => void,
  getRecommendedServices: (salonId: number, services: number[]) => void,
  recommendedServices: Service[],
};

type State = {
  searchQuery: string,
  searchButtonTitle: string,
  searchButtonVisible: boolean,
  showAllcalled: boolean,
  release: string,
  isLoading: boolean,
  getStyCalled: boolean,
  stylistsForServiceWeekly: any,
  showPopup: boolean,
  getCardStarted: boolean,
};

class ServiceSelectionWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchQuery: '',
      searchButtonTitle: 'Search',
      searchButtonVisible: false,
      showAllcalled: false,
      release: '',
      isLoading: false,
      getStyCalled: false,
      stylistsForServiceWeekly: null,
      showPopup: false,
      getCardStarted: false,
      isExpanded: false,
      isMobile: window.innerWidth <= MOBILE_VIEWPORT,
    };
    fetch(gitlog).then(textdata => {
      textdata.text().then(gitCommit => {
        if (gitCommit) {
          this.setState({ release: gitCommit.replace(/\n/, '') });
        }
      });
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    // console.log('fetchGithub');
    this.props.fetchGithub();
    console.log('before clearCache');
    clearCache();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.handleResize);

    setPageVisitedFunc('service-select');
    // it take some time for the clearCashe to clear things
    setTimeout(() => {
      if (
        this.props.cognito.user &&
        this.props.cognito.state === 'LOGGED_IN' &&
        !this.props.cardDetails.last4 &&
        !this.state.getCardStarted
      ) {
        console.log('before getUserCardDetails');
        this.props.getUserCardDetails(this.props.username);
        this.setState({ getCardStarted: true });
      }
    }, 1000);
    let utm;
    if (window.location.href.indexOf('/?') < 0) {
      // directly from fb
      utm = unescape(this.props.history.location.search);
    } else {
      // from salonPicker
      utm = `?${unescape(window.location.href.split('?')[1].split('#')[0])}`;
    }
    if (utm) {
      this.props.setUtm(utm);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.categories !== this.props.categories && !this.state.showAllcalled) {
      this.showAllServices();
      this.setState({ showAllcalled: true });
    }
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.getCategoriesWithServices(parseInt(nextProps.match.params.id, 10));
      this.props.clearSelectedServices();
    }

    setTimeout(() => {
      if (!this.state.release) {
        // this.setState({ githubBanner: true });
        const hardReload = this.getCookie('hardReload');
        console.log('xxxx hardReload ', hardReload);
        if (!hardReload) {
          console.log('xxxx reload ');
          window.location.reload(true);
          const dt = new Date();
          dt.setHours(dt.getHours() + 2);
          document.cookie = `hardReload=true; expires=${dt.toUTCString()}`;
          console.log('after reload cookie set');
        }
      }
    }, 1000);
    // console.log('this.props.categories', this.props.categories)
    if (nextProps.categories && Object.keys(nextProps.categories).length === 0) {
      this.setState({ isLoading: true });
    } else {
      this.setState({ isLoading: false });
    }
    if (nextProps.stylistsForServiceWeekly) {
      this.setState({
        stylistsForServiceWeekly: nextProps.stylistsForServiceWeekly,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onClosePatchTest = () => void this.setState({ showPopup: false });

  onPopupConfrimClick = (isTested: boolean) => {
    this.setStylists();
    if (isTested === 'isSelectedYes') {
      this.props.history.push(`/salon/${this.props.match.params.id}/timeselect`);
    } else {
      this.props.history.push(`/salon/${this.props.match.params.id}/patchtimeselect`);
    }
  };

  setSearch = (searchString: string) => {
    this.setState({ searchQuery: searchString });
  };

  setStylists = () => {
    const selectedStylists = {};
    const interval = setInterval(() => {
      const { stylistsForServiceWeekly } = this.state;
      if (Object.keys(stylistsForServiceWeekly).length) {
        clearInterval(interval);
        for (let i = 0; i < this.props.selectedServices.length; i += 1) {
          const { id } = this.props.selectedServices[i];
          for (let j = 0; j < Object.keys(stylistsForServiceWeekly).length; j += 1) {
            if (stylistsForServiceWeekly[id]) {
              for (let k = 0; k < stylistsForServiceWeekly[id].length; k += 1) {
                if (stylistsForServiceWeekly[id][k].service_id === id) {
                  selectedStylists[id] = stylistsForServiceWeekly[id][k];
                  break;
                }
              }
            }
          }
        }
        this.props.setSelectedStylists(selectedStylists);
        if (this.props.isPatchTestRequred) {
          if (!this.props.selectedServices[this.props.selectedServices.length - 1].has_processing_time) {
            window.scrollTo(0, 0);
            this.setState({ showPopup: true });
          } else {
            this.props.history.push(`/salon/${this.props.match.params.id}/recommendedservice`);
          }
        } else {
          this.props.history.push(`/salon/${this.props.match.params.id}/timeselect`);
        }
      }
    }, 500);
  };

  getCookie = (cname: string) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  handleTextChange = (event: Event) => {
    const { value } = (event.currentTarget: any);
    this.setState({ searchQuery: value, searchButtonVisible: true });
  };

  btnClick = async () => {
    // track page view
    if (this.props.salonDetails && this.props.salonDetails.fb_details) {
      fbPixelTracking(this.props.salonDetails.fb_details.fb_pixel_id);
    }

    if (this.props.selectedServices.length === 0) {
      return;
    }

    if (this.props.selectedServices && this.props.salonDetails.ncob_stylists_count === 1 && !this.state.getStyCalled) {
      const serviceIds = this.props.selectedServices.map(ser => ser.id);
      this.setState({ getStyCalled: true });
      //  proceed only after getStylistsForServiceWeekly completed successfully
      await this.props.getStylistsForServiceWeekly(this.props.salonDetails.id, serviceIds);
    }

    if (
      this.props.selectedServices.length >= 1 &&
      this.props.selectedServices[this.props.selectedServices.length - 1].has_processing_time
    ) {
      const serviceIds = this.props.selectedServices.map(ser => ser.id);
      await this.props.getRecommendedServices(this.props.salonDetails.id, serviceIds);

      // wait until  receive the recommended services
      await this.waitForRecommendedServices();

      if (this.props.selectedServices.length > 1 && this.props.isPatchTestRequred) {
        if (!this.props.selectedServices[this.props.selectedServices.length - 1].has_processing_time) {
          window.scrollTo(0, 0);
          this.setState({ showPopup: true });
        } else {
          this.props.history.push(`/salon/${this.props.match.params.id}/recommendedservice`);
        }
      } else {
        this.props.history.push(`/salon/${this.props.match.params.id}/recommendedservice`);
      }
    } else if (this.props.salonDetails.ncob_stylists_count > 1) {
      this.props.history.push(`/salon/${this.props.match.params.id}/favouritestylistselect`);
    } else {
      this.setStylists();
    }
  };

  waitForRecommendedServices = async () => {
    const wait = async () => {
      if (this.props.recommendedServices.length || this.props.isFetching) {
        return;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
      await wait();
    };

    await wait();
  };

  closeBanner = () => {
    window.location.reload(true);
  };

  searchQuery = () => {
    if (this.state.searchButtonTitle === 'Search') {
      this.props.searchServices(this.state.searchQuery);
      this.setState({ searchButtonTitle: 'Clear' });
    } else {
      this.setState({ searchButtonTitle: 'Search' });
      this.showAllServices();
    }
  };

  showAllServices = () => {
    this.setState({ searchQuery: '' });
    this.props.searchServices('');
  };

  gotoMyGroup = () => {
    const utm = this.props.history.location.search;
    let pathname = '';
    let groupId = this.props.salonDetails.group;
    if (groupId) {
      groupId = groupId.toString();
      pathname = `/group/${groupId}/`;
    }
    if (utm) {
      this.props.setUtm(utm);
      pathname = `${pathname}${utm}`;
    }
    if (this.props.salonDetails && pathname) {
      if (this.props.history) {
        this.props.history.push({
          pathname,
        });
      }
    }
  };

  selectService = (s: Service) => {
    this.props.selectService(s);
  };

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= MOBILE_VIEWPORT });
  };

  handleToggleMessage = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { history, categories, salonDetails, selectedServices, isDarkMode, isNeutralMode } = this.props;
    const { isExpanded } = this.state;

    const params = fromPropsToParams(this.props);
    const query = fromPropsToQuery(this.props);

    const salonId = parseInt(params.id, 10);
    const originalCategories = asArray(asObject(categories)[salonId]);
    const filteredCategories = filterCategories({ categories: originalCategories, query });
    const {
      group,
      name: salonName,
      online_booking: onlineBooking,
      ncob_welcome_message: ncobWelcomeMessage,
    } = asObject(salonDetails);

    const defaultWelcomeText = `Please select services in the order you would like them. If you can’t find what you’re looking for, call us on ${salonDetails.phone}`;
    const maxLength = this.state.isMobile ? MAX_TEXT_MOBILE : MAX_TEXT;
    const truncatedMessage =
      ncobWelcomeMessage && ncobWelcomeMessage.length > maxLength
        ? `${ncobWelcomeMessage.substring(0, maxLength)}`
        : ncobWelcomeMessage;

    if (USE_LOG) L.debug('render()', { params, query, originalCategories, filteredCategories });

    return (
      <div
        data-bem="ServiceSelectionWrap"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        <div className="app-content">
          <HeaderComponent
            extratitle={salonName ? `Welcome to ${salonName}` : <div>{salonName}</div>}
            aditionalClass="no-title"
            history={history}
          />
          {ncobWelcomeMessage ? (
            <div className={CN.welcomeText}>
              {isExpanded ? ncobWelcomeMessage : truncatedMessage}
              {ncobWelcomeMessage.length > MAX_TEXT && (
                <div className={CN.readMore} onClick={this.handleToggleMessage} role="button" tabIndex={0}>
                  {isExpanded ? 'close' : 'read more...'}
                </div>
              )}
            </div>
          ) : (
            <div className={CN.welcomeText}> {defaultWelcomeText}</div>
          )}

          {group !== null && (
            <div className={CN.locationButtonContainer} data-bem="ServiceSelectionWrap__locationButton">
              <Button variant="tertiary" onClick={() => this.gotoMyGroup()}>
                <div className={CN.textUnderline}>CHANGE LOCATION</div>
              </Button>
            </div>
          )}
          {filteredCategories && filteredCategories.length > 0 && (
            <ServiceSelectionComponentCopy
              categories={filteredCategories.filter(c => c.show === true)}
              selectedServices={selectedServices}
            />
          )}
          {!onlineBooking && USE_SEARCH && (
            <div>
              <div className="search-container">
                <input
                  type="text"
                  name="searchBox"
                  placeholder="Search services"
                  onChange={event => this.handleTextChange(event)}
                  value={this.state.searchQuery}
                  className="search-input"
                />
                {this.state.searchButtonVisible && (
                  <button className="btn-search" onClick={this.searchQuery}>
                    {this.state.searchButtonTitle}
                  </button>
                )}
              </div>
            </div>
          )}
          {this.props.isFetching ? (
            <Spinner variant="overlay" />
          ) : (
            <div>
              {!onlineBooking && (
                <div>
                  <BuyVoucherButton />
                  <Offline />
                </div>
              )}
            </div>
          )}
          {filteredCategories && filteredCategories.length > 0 && (
            <ServiceSelection
              categories={filteredCategories.filter(c => c.show === true)}
              selectedServices={selectedServices}
              showBuyButton
            />
          )}
        </div>

        <Footer data-bem="ServiceSelectionWrap__footer">
          <Button
            variant="primary"
            width="fixed"
            onClick={() => this.btnClick()}
            disabled={selectedServices.length === 0}
          >
            NEXT
          </Button>
        </Footer>
        {this.state.showPopup && (
          <PatchTest onClose={this.onClosePatchTest} onConfirmClick={this.onPopupConfrimClick} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  salonDetails: state.booking.salonDetails,
  cardDetails: state.booking.cardDetails,
  isFetching: state.booking.isFetching,
  categories: state.booking.categories,
  totalPrice: state.booking.totalPrice,
  selectedServices: state.booking.selectedServices,
  referrerInfo: state.booking.referrerInfo,
  latestRelease: state.login.latestRelease,
  isPatchTestRequred: state.booking.isPatchTestRequred,
  stylistsForServiceWeekly: state.booking.stylistsForServiceWeekly,
  cognito: state.cognito,
  username: state.cognito.user ? state.cognito.user.username : '',
  recommendedServices: state.booking.recommendedServices,
});

const mapDispatchToProps = (dispatch: any) => ({
  getSalonDetails(salonId: number) {
    dispatch(getSalonDetails(salonId));
  },
  getCategoriesWithServices(salonId: number) {
    dispatch(getCategoriesWithServices(salonId));
  },
  clearSelectedServices() {
    dispatch(clearSelectedServices());
  },
  selectService(service: Service) {
    dispatch(selectService(service));
  },
  clearBillingInfo(clearBookingInfo?: any) {
    dispatch(clearBillingInfo(clearBookingInfo));
  },
  clearUserCardDetails() {
    dispatch(clearUserCardDetails());
  },
  clearSalonDetails() {
    dispatch(clearSalonDetails());
  },
  clearPageVisited() {
    dispatch(clearPageVisited());
  },
  clearAvailableSlots() {
    dispatch(clearAvailableSlots());
  },
  updateFirstAvailableDate(date: moment) {
    dispatch(updateFirstAvailableDate(date));
  },
  clearAvailableDays() {
    dispatch(clearAvailableDays());
  },
  searchServices(searchQuery: string) {
    dispatch(searchServices(searchQuery));
  },
  clearExtraAuthenticationInfo() {
    dispatch(clearExtraAuthenticationInfo());
  },
  fetchGithub() {
    dispatch(fetchGithub());
  },
  setSelectedStylists(selectedStylists: { [serviceId: string]: null | Stylist }, serviceId?: number, stylist?: any) {
    dispatch(setSelectedStylists(selectedStylists, serviceId, stylist));
  },
  getStylistsForServiceWeekly(salonId: number, services: number[]) {
    dispatch(getStylistsForServiceWeekly(salonId, services));
  },
  getUserCardDetails(username: string) {
    dispatch(getUserCardDetails(username));
  },
  setUtm(utm: string) {
    dispatch(setUtm(utm));
  },
  getRecommendedServices(salonId: number, services: number[]) {
    dispatch(getRecommendedServices(salonId, services));
  },
});

const ServiceSelectionWrapHoc = connect(mapStateToProps, mapDispatchToProps)(withTheme()(ServiceSelectionWrap));

export default ServiceSelectionWrapHoc;
