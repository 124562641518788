import React from 'react';
import { connect } from 'react-redux';

import { selectService } from 'booking/actions';
import ItemSticky from 'booking/common/ItemSticky.component';

const ServiceSticky = ({ service, recommended, selectedServices, salonDetails, selectService }) => {
  const { currency } = salonDetails;

  const checked = selectedServices.some(selectedService => selectedService.id === service.id);

  const onCheckboxClick = ev => {
    ev.preventDefault();
    selectService(service);
  };

  return (
    <ItemSticky
      currency={currency}
      service={service}
      isChecked={checked}
      onCheckboxClick={onCheckboxClick}
      key={`serviceItem${service.id}`}
      recommended={recommended}
    />
  );
};

const mapStateToProps = state => ({
  selectedServices: state.booking.selectedServices,
  salonDetails: state.booking.salonDetails,
});

const mapDispatchToProps = dispatch => ({
  selectService(service) {
    dispatch(selectService(service));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSticky);
