// @flow

import type { Appointment } from 'types/appointment';
import type { Client } from 'types/client';

export const SELECT_CLIENT = 'SELECT_CLIENT';
export const SELECT_APPOINTMENT = 'SELECT_APPOINTMENT';

export function selectClient(client: Client) {
  return {
    type: SELECT_CLIENT,
    client,
  };
}

export function selectAppointment(appointment: Appointment) {
  return {
    type: SELECT_APPOINTMENT,
    appointment,
  };
}
