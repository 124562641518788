import { generatePath } from 'react-router-dom';

import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';

const L = logger('linkTo');

const linkTo = L.wrap('()', options => {
  const { pattern, params, fault } = asObject(options);

  try {
    return generatePath(pattern, params);
  } catch (e) {
    return L.warn('()', e, 'returning de fault:', fault);
  }
});

// noinspection JSUnusedGlobalSymbols
export default linkTo;
