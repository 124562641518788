import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cookieSelection } from 'actions/cookieSelection.action';
import CONSENT from 'constants/consent.const';
import LINK from 'constants/link.const';
import LSKEY from 'constants/lskey.const';
import getAnyOrVoid from 'utils/accessors/getAnyOrVoid.util';
import lsGet from 'utils/dom/lsGet.util';
import lsSet from 'utils/dom/lsSet.util';
import logger from 'utils/logger.util';

import CN from './CookieBanner.module.scss';

const L = logger('CookieBanner');

const CookieBanner = ({ cookieSelection }) => {
  const [isRendered, setIsRendered] = useState(false);

  const handleAccept = useCallback(() => {
    lsSet(LSKEY.cookies, CONSENT.all);
    cookieSelection(CONSENT.all);
    setIsRendered(false);
  }, [cookieSelection]);

  const handleReject = useCallback(() => {
    lsSet(LSKEY.cookies, CONSENT.esssential);
    cookieSelection(CONSENT.esssential);
    setIsRendered(false);
  }, [cookieSelection]);

  useEffect(() => {
    const cookieChoice = lsGet(LSKEY.cookies);
    L.info('Previous cookieChoice:', cookieChoice);

    const isValidChoice = Object.values(CONSENT).includes(cookieChoice);
    if (isValidChoice) {
      cookieSelection(cookieChoice);
    }
    setIsRendered(!isValidChoice);
  }, [cookieSelection]);

  if (isRendered !== true) {
    // NOTE: start without rendering anything, since useEffect executes after the render,
    // checking exactly true tries to avoid showing up the banner before the actual check
    return null;
  }

  return (
    <div data-bem="CookieBanner" className={CN.component}>
      <div className={CN.inner}>
        <div className={CN.text}>
          <p>
            We use cookies (and other similar technologies) to collect data to improve your experience on our site. By
            using our website, you’re agreeing to the collection of data as described in our{' '}
            <a className={CN.link} href={LINK.external.privacyPolicy}>
              Website Data Collection Policy
            </a>
            .
          </p>
        </div>
        <div className={CN.buttons}>
          <button className={CN.button} onClick={handleReject}>
            Essential cookies only
          </button>
          <button className={CN.button} onClick={handleAccept}>
            Accept all cookies
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  salonId: getAnyOrVoid('booking.salonDetails.id', state),
  cookieChoice: getAnyOrVoid('cookies.cookieChoice', state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ cookieSelection }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CookieBanner);
