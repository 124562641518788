// @flow

import {
  GET_VERSION_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from 'actions/authentication';

type $ExtractFunctionReturn = <V>(v: (...args: any) => V) => V;
export type LoginState = $ObjMap<any, $ExtractFunctionReturn>;

function login(
  state: LoginState = {
    isFetching: false,
    isAuthenticated: false,
    token: null,
    errorMessage: null,
    latestRelease: '',
  },
  action: Action,
) {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return { ...state, isFetching: true, isAuthenticated: false, urlhash: action.urlhash };
    }
    case LOGIN_SUCCESS: {
      return { ...state, isFetching: false, isAuthenticated: true, errorMessage: '', token: action.token };
    }
    case LOGIN_FAILURE: {
      return { ...state, isFetching: false, isAuthenticated: false, errorMessage: action.message };
    }
    case LOGOUT_SUCCESS: {
      return { ...state, isFetching: true, isAuthenticated: false };
    }
    case GET_VERSION_SUCCESS: {
      return { ...state, ...state, latestRelease: action.response.latest_release };
    }
    default:
      return state;
  }
}

export default login;
