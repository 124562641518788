import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

import IMG_ARROW from 'assets/images/icons/arrow-left-black.svg';
import Button from 'components/Button.component';
import { useCurrentSalonId } from 'providers/SalonBasicInfo.provider';
import { useTheme } from 'providers/Theme.provider';

import CN from './StartAgainAndBackButtons.module.scss';

const StartAgainAndBackButtons = ({ history }) => {
  const salonId = useCurrentSalonId();
  const { isDarkMode, isNeutralMode } = useTheme();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleStartPage = () => {
    history.push(`/salon/${salonId}/`);
  };

  return (
    <div
      data-bem="StartAgainAndBackButtons"
      className={cn({
        [CN.component]: true,
        [CN.dark]: isDarkMode,
        [CN.neutral]: isNeutralMode,
      })}
    >
      <div
        data-bem="SummaryWrap_arrowGoBack"
        className={CN.arrowGoBack}
        role="button"
        tabIndex={0}
        onClick={handleGoBack}
      >
        <img src={IMG_ARROW} alt="Arrow go back" className={CN.arrowImg} />
      </div>
      <Button variant="quaternary" width="small" onClick={handleStartPage}>
        <span className={CN.text}>START AGAIN</span>
      </Button>
    </div>
  );
};

const mapStateToProps = state => {
  const { booking } = state;
  const { salonDetails } = booking;
  return {
    salonDetails,
  };
};

export default withRouter(connect(mapStateToProps)(StartAgainAndBackButtons));
