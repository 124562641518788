// SEE: https://stripe.com/docs/billing/migration/strong-customer-authentication#summary-webhooks
// SEE: https://stripe.com/docs/billing/subscriptions/fixed-price
// SEE: https://github.com/stripe-archive/react-stripe-elements

import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import cn from 'classnames';

import LOGO_AMEX from 'assets/images/amex.svg';
import LOGO_MAESTRO from 'assets/images/maestro.svg';
import LOGO_MASTER from 'assets/images/mastercard.svg';
import LOGO_VISA from 'assets/images/visa.svg';
import Button from 'components/Button.component';
import Footer from 'components/Footer.component';
import CARD from 'constants/card.const';
import LINK from 'constants/link.const';
import { withTheme } from 'providers/Theme.provider';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import captureError from 'utils/core/captureError.util';
import captureInfo from 'utils/core/captureInfo.util';
import captureWarn from 'utils/core/captureWarn.util';
import linkTo from 'utils/links/linkTo.util';
import navFor from 'utils/links/navFor.util';
import logger from 'utils/logger.util';
import asObject from 'utils/objects/asObject.util';

import CM from 'booking/confirmBooking/ConfirmWithCard.capmsg';
import Spinner from 'common/Spinner.component';
import createBooking, { trackingEvent } from 'lib/utils';

import CN from './ConfirmWithCard.module.scss';

const CTAG = 'ConfirmWithCard';
const L = logger(CTAG);

// as the intents are captured manually, requires_capture is the new success status
// we get succeeded from intent response if there is no deposit to pay
const PASS = Object.freeze(['succeeded', 'requires_capture']);
const ACTION = 'requires_action';

const PAYMENT_ERROR_SUFFIX =
  'Unfortunately the payment was not successful. ' +
  'Please check your details are correct and ensure you have sufficient funds, then try again.';

const CARD_ERRORS = Object.freeze([
  'Your card has insufficient funds',
  'Your card has been declined',
  'Your card was declined',
  'Your card has expired',
  "Your card's security code is incorrect",
  'An error occurred while processing your card',
]);

const fromPaymentMethodToId = paymentMethod => {
  const { id } = asObject(paymentMethod);
  if (id) {
    return id;
  }

  if (typeof paymentMethod !== 'string') {
    return null;
  }

  return paymentMethod;
};

const checkPaymentError = message => {
  for (let i = 0; i < CARD_ERRORS.length; i += 1) {
    if (message.indexOf(CARD_ERRORS[i]) >= 0) {
      return true;
    }
  }
  return false;
};

const trackEvents = ({ ncobDepositSettings, last4Digits, needsCard }) => {
  if (ncobDepositSettings !== 'null' && last4Digits) {
    trackingEvent('appointment_deposit_existing_card', 'Appointment deposit existing card');
  }

  if (needsCard) {
    trackingEvent('appointment_noshow_existing_card', 'Appointment noshow existing card');
  } else {
    trackingEvent('appointment_no_card', 'Appointment no card');
  }
};

class ConfirmWithCard extends Component {
  static defaultProps = { disabled: false };

  constructor(props) {
    super(props);
    this.cardElement = React.createRef();
    this.expiryElement = React.createRef();
    this.cvcElement = React.createRef();
    this.state = {
      errorMessage: '',
      paymentError: '',
      cardError: '',
      isLoading: false,
      confirmAuthenticationSend: false,
      helpMessage: '',
      xtraAuthenticationDone: false,
      bookingDataSent: false,
      interval: 0,
    };
  }

  componentWillReceiveProps() {
    L.lifecycle('componentWillReceiveProps()', this);
    const { paymentError, disableSubmit } = this.state;
    if (paymentError && disableSubmit) {
      this.setState({ disableSubmit: false });
    }
  }

  componentWillUnmount() {
    L.lifecycle('componentWillUnmount()', this);
    const { interval: intervalId } = this.state;

    clearInterval(intervalId);
  }

  confirmCardAuthentication = (inputBookClientSecret, inputPaymentMethod) => {
    const FTAG1 = 'confirmCardAuthentication()';
    const FTAG2 = 'confirmCardPayment()';
    const FTAG3 = 'afterStateUpdate()';
    const CLOG1 = Object.freeze([CTAG, FTAG1]);
    const CLOG2 = Object.freeze([...CLOG1, FTAG2]);
    const CLOG3 = Object.freeze([...CLOG2, FTAG3]);

    L.stripecycle(FTAG1, this);

    const {
      bookingResponse,
      confirmXAuthentication,
      salonDetails,
      username,
      payment_intent_id: paymentIntentId,
      billing,
      stripe,
    } = this.props;
    const { xtraAuthenticationDone, confirmAuthenticationSend } = this.state;

    const clientSecret = inputBookClientSecret || asObject(billing).book_client_secret;
    const paymentMethod = inputPaymentMethod || asObject(billing).payment_method;
    const paymentMethodId = fromPaymentMethodToId(paymentMethod);
    const salonId = asObject(salonDetails).id;
    const appointmentId = asObject(bookingResponse).appointment_id;

    const capTags1 = { salonId, appointmentId, paymentIntentId, paymentMethodId, username };
    const capExtra1 = {
      xtraAuthenticationDone,
      confirmAuthenticationSend,
      paymentMethod,
      bookingResponse,
      ...capTags1,
    };

    if (xtraAuthenticationDone) {
      L.debug(FTAG1, CM.confirm1, capExtra1);
      return void captureInfo(CLOG1, capTags1, capExtra1, CM.confirm1);
    }

    this.setState({ xtraAuthenticationDone: true });

    return void stripe
      .confirmCardPayment(clientSecret, { payment_method: paymentMethod })
      .then(result => {
        const { error, paymentIntent } = asObject(result);
        const paymentIntentStatus = asObject(paymentIntent).status;
        const errorMessage = asObject(error).message;

        const capTags2 = { paymentIntentStatus, ...capTags1 };
        const capExtra2 = { result, errorMessage, ...capTags2 };

        if (error) {
          L.warn(FTAG2, capExtra2, error);
          captureError(CLOG2, capTags2, capExtra2, error);
          return void this.setState({ errorMessage });
        }

        if (!PASS.includes(paymentIntentStatus)) {
          L.debug(FTAG2, CM.confirm2, capExtra2);
          return void captureInfo(CLOG2, capTags2, capExtra2, CM.confirm2);
        }

        if (confirmAuthenticationSend) {
          L.debug(FTAG2, CM.confirm3, capExtra2);
          return void captureInfo(CLOG2, capTags2, capExtra2, CM.confirm3);
        }

        const afterStateUpdate = () => {
          confirmXAuthentication(salonId, appointmentId, paymentIntentId, username)
            .then(result => {
              const capExtra3 = { ...capExtra2, result };
              L.debug(FTAG3, CM.confirm4, capExtra3);
              return void captureInfo(CLOG2, capTags2, capExtra3, CM.confirm4);
            })
            .catch(e => {
              L.warn(FTAG3, capTags2, e);
              captureError(CLOG3, capTags2, capExtra2, e);
            });

          const intervalId = setInterval(() => void this.check3dAuthenticationResult(intervalId), 500);
          this.setState({ interval: intervalId });
        };

        return void this.setState({ isLoading: true, confirmAuthenticationSend: true }, afterStateUpdate);
      })
      .catch(e => {
        L.warn(FTAG2, capTags1, e);
        captureError(CLOG2, capTags1, capExtra1, e);
      });
  };

  submitInfo = () => {
    const FTAG = 'submitInfo()';
    const CLOG = Object.freeze([CTAG, FTAG]);

    const { bookingDataSent } = this.state;
    const { bookingResponse, salonDetails, username, payment_intent_id: paymentIntentId, billing } = this.props;

    const paymentMethod = asObject(billing).payment_method;
    const paymentMethodId = fromPaymentMethodToId(paymentMethod);
    const salonId = asObject(salonDetails).id;
    const appointmentId = asObject(bookingResponse).appointment_id;

    const capTags = { salonId, appointmentId, paymentIntentId, paymentMethodId, username };
    const capExtra = { bookingDataSent, ...capTags };

    // return if booking already created
    if (!bookingDataSent) {
      L.debug(FTAG, CM.submit1, capExtra);

      this.setState({ bookingDataSent: true }, () => {
        createBooking()
          .then(r => {
            const capExtra2 = { ...capExtra, result: r };

            if (r && r.success === false) {
              // NOTE: must explicitly be set to false in order to distinguish from other results
              L.debug(FTAG, CM.submit2, capExtra2);
              captureInfo(CLOG, capTags, capExtra2, CM.submit2);
              return;
            }

            L.debug(FTAG, CM.submit3, capExtra2);
          })
          .catch(e => {
            L.warn(FTAG, CM.submit4, e);
            captureInfo(CLOG, capTags, { ...capExtra, error: e }, CM.submit4);
          });
      });
    }

    const intervalId = setInterval(() => void this.checkBookingStatus(intervalId), 500);

    this.setState({ interval: intervalId });

    this.setState({ isLoading: true });
  };

  handleSubmit = event => {
    const { disableSubmit } = this.state;

    if (disableSubmit) {
      return;
    }

    if (document.querySelector('.btn.btn-primary').classList.contains('disabled')) {
      return;
    }

    this.handleTryAgain();
    this.setState({ paymentError: '', cardError: '', errorMessage: '' });

    event.preventDefault();

    document.querySelector('.btn.btn-primary').classList.add('disabled');

    this.submitInfo();
    this.setState({ disableSubmit: true, isLoading: true });
  };

  check3dAuthenticationResult = intervalId => {
    const FTAG = 'check3dAuthenticationResult()';
    const CLOG = Object.freeze([CTAG, FTAG]);
    const { salonId, confirmAuthenticationStatus, confirmAuthenticationSuccess, bookingResponse } = this.props;

    const confirmAuthStatusBool = !PASS.includes(confirmAuthenticationStatus);

    const isWaiting = !bookingResponse || confirmAuthStatusBool || !confirmAuthenticationSuccess;

    const capTags = { salonId, intervalId };
    const capExtra = {
      isWaiting,
      bookingResponse,
      confirmAuthenticationStatus,
      confirmAuthenticationSuccess,
      ...capTags,
    };

    if (isWaiting) {
      L.debug(FTAG, CM.check3d1, capExtra);
      captureInfo(CLOG, capTags, capExtra, CM.check3d1);
      return;
    }

    L.info(FTAG, capExtra, CM.check3d2);
    captureInfo(CLOG, capTags, capExtra, CM.check3d2);

    this.setState({ isLoading: false });
    clearInterval(intervalId);

    navFor(CTAG, FTAG, this.props).push(linkTo({ pattern: LINK.bookingConfirmed, params: { salonId } }));
  };

  checkBookingStatus = intervalId => {
    const FTAG = 'checkBookingStatus()';
    const CLOG = Object.freeze([CTAG, FTAG]);

    const {
      ncob_deposit_settings: ncobDepositSettings,
      needs_card: needsCard,
      bookingResponse,
      cardDetails,
      bookingError,
      salonId,
    } = this.props;

    const { username, payment_intent_id: paymentIntentId, billing } = this.props;

    const paymentMethod = asObject(billing).payment_method;
    const paymentMethodId = fromPaymentMethodToId(paymentMethod);
    const appointmentId = asObject(bookingResponse).appointment_id;

    const last4Digits = asObject(cardDetails).last4;
    const { pi_status: paymentIntentStatus } = asObject(bookingResponse);
    const capTags = {
      salonId,
      paymentIntentId,
      intervalId,
      paymentIntentStatus,
      username,
      appointmentId,
      paymentMethodId,
    };
    const capExtra = { bookingError, bookingResponse, last4Digits, ...capTags };

    if (bookingResponse === null) {
      L.info(FTAG, capExtra, CM.checkstat1);
    } else if (!PASS.includes(paymentIntentStatus)) {
      L.info(FTAG, capExtra, CM.checkstat2);
      captureInfo(CLOG, capTags, capExtra, CM.checkstat2);
    } else {
      L.info(FTAG, capExtra, CM.checkstat3);
      trackEvents({ ncobDepositSettings, last4Digits, needsCard });
      clearInterval(intervalId);
      navFor(CTAG, FTAG, this.props).push(linkTo({ pattern: LINK.bookingConfirmed, params: { salonId } }));
    }

    if (bookingError) {
      L.info(FTAG, capExtra, CM.checkstat4);
      captureWarn(CLOG, capTags, capExtra, CM.checkstat4);
      this.setState({ isLoading: false });
      clearInterval(intervalId);
    } else if (paymentIntentStatus === ACTION) {
      L.info(FTAG, capExtra, CM.checkstat5);
      captureWarn(CLOG, capTags, capExtra, CM.checkstat5);
      clearInterval(intervalId);
      this.confirmCardAuthentication();
    }
  };

  handleTryAgain = () => {
    const { setPaymentError } = this.props;

    this.setState({ disableSubmit: false, paymentError: '', xtraAuthenticationDone: false });
    const element = document.querySelector('.btn.btn-primary');
    if (element.classList.contains('disabled')) {
      document.querySelector('.btn.btn-primary').classList.remove('disabled');
    }
    setPaymentError(false);
  };

  handleUpdateCard = () => {
    const { clearBillingInfo, salonId, clearIntentClientSecret, clearUserCardDetails } = this.props;

    clearBillingInfo(true);
    clearUserCardDetails();
    clearIntentClientSecret();

    navFor(CTAG, 'updateCard()', this.props).push(linkTo({ pattern: LINK.addNewCard, params: { salonId } }));
  };

  render() {
    const { cardDetails, setPaymentError, isDarkMode, isNeutralMode } = this.props;
    const {
      errorMessage,
      cardError,
      helpMessage,
      paymentError: paymentErrorFromState,
      disableSubmit,
      isLoading,
    } = this.state;

    const cardBrand = asObject(cardDetails).brand;
    const last4Digits = asObject(cardDetails).last4;
    const hasLast4Digits = fromAnyToBool(last4Digits);

    if (
      errorMessage &&
      errorMessage.indexOf('Your card number is invalid') < 0 &&
      errorMessage.indexOf('Your card number is incomplete') < 0 &&
      !paymentErrorFromState
    ) {
      setPaymentError(true);

      const paymentError = `${errorMessage} 
      ${PAYMENT_ERROR_SUFFIX}`;

      const renderedHelpMessage = (
        <>
          <div>
            Need help? Our support team are available via the purple live chat button{' '}
            <span role="img" aria-label="button help">
              ↘️
            </span>
          </div>
        </>
      );

      this.setState({ paymentError, isLoading: false, helpMessage: renderedHelpMessage });
    } else if (errorMessage && cardError !== errorMessage) {
      if (!checkPaymentError(errorMessage)) {
        setPaymentError(true);
        this.setState({ cardError: errorMessage, isLoading: false });
      }
    } else if ((paymentErrorFromState || cardError) && !errorMessage) {
      this.setState({ cardError: '', paymentError: '', isLoading: false });
    }

    return (
      <div
        data-bem="ConfirmWithCard"
        className={cn({
          [CN.component]: true,
          [CN.dark]: isDarkMode,
          [CN.neutral]: isNeutralMode,
        })}
      >
        {isLoading && <Spinner variant="overlay" />}

        {fromAnyToBool(paymentErrorFromState || cardError) && (
          <p data-bem="ConfirmWithCard__error" className={CN.error}>
            {paymentErrorFromState || cardError}
          </p>
        )}

        {fromAnyToBool(paymentErrorFromState) && <p className={CN.error}>{helpMessage}</p>}

        {fromAnyToBool(paymentErrorFromState) && (
          <footer data-bem="ConfirmWithCard__retry-footer" className="app-footer complicated">
            <button className="btn btn-primary try-again" onClick={this.handleTryAgain}>
              Try again
            </button>
          </footer>
        )}

        <form data-bem="ConfirmWithCard__card-info-form" className="cardInfo" onSubmit={this.handleSubmit}>
          {hasLast4Digits && (
            <>
              <div className="card-number">
                <span className={CN.cardNumberLabel}>Card number</span>
                <div
                  className={cn({
                    [CN.input]: true,
                    'card-number-field': true,
                  })}
                >
                  {`**** **** **** ${last4Digits}`}
                  {cardBrand === CARD.visa && <img src={LOGO_VISA} alt="Visa brand logo" className={CN.brandImg} />}
                  {cardBrand === CARD.amex && <img src={LOGO_AMEX} alt="AMEX brand logo" className={CN.brandImg} />}
                  {cardBrand === CARD.masterCard && (
                    <img src={LOGO_MASTER} alt="MasterCard brand logo" className={CN.brandImg} />
                  )}
                  {cardBrand === CARD.mastero && (
                    <img src={LOGO_MAESTRO} alt="Mastero brand logo" className={CN.brandImg} />
                  )}
                </div>
              </div>

              <div className={CN.buttonContainer}>
                <Button variant="tertiary" onClick={this.handleUpdateCard}>
                  <span className={CN.textUnderline}> UPDATE CARD DETAILS</span>
                </Button>
              </div>

              <Footer data-bem="ConfirmWithCard__confirm-footer">
                <Button variant="primary" width="fixed" onClick={this.submitInfo} disabled={disableSubmit}>
                  PAY DEPOSIT
                </Button>
              </Footer>
            </>
          )}
        </form>
      </div>
    );
  }
}

export default injectStripe(withTheme()(ConfirmWithCard));
