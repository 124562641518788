// @flow

/* eslint-disable camelcase */
/* eslint-disable quotes */

import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import ICON_AVATAR from 'assets/images/icons/user-circle-purple.svg';
import Toggler from 'components/Toggler.component';
import { withTheme } from 'providers/Theme.provider';
import asObject from 'utils/objects/asObject.util';

import { getStylistsForServiceWeekly } from 'booking/actions';
import currency from 'booking/common/currency.util';
import type { Stylist } from 'types/stylistsForService';

import CN from './StylistItem.module.scss';

const calculateRanges = stylistsForServiceWeekly =>
  Object.values(stylistsForServiceWeekly) // $FlowFixMe
    .flat()
    .map(service => ({ id: service.service_id, price: service.price }))
    .reduce((result, data) => {
      const a = result[data.id] || { min: null, max: null };

      a.min = a.min === null ? data.price : Math.min(data.price, a.min);
      a.max = a.max === null ? data.price : Math.max(data.price, a.max);

      // eslint-disable-next-line no-param-reassign
      result[data.id] = a;

      return result;
    }, {});

const determineNameAndRole = ({ stylist, isWithSame }) => {
  if (stylist && stylist !== 'any') {
    const { stylist_role, stylist_name } = stylist;
    const fullName = stylist_name !== null ? stylist_name : 'Name';

    const name = fullName;
    let initials = '';

    const namesArray = fullName.split(' ');

    if (namesArray.length >= 1) {
      initials = namesArray[0].charAt(0).toUpperCase();

      if (namesArray.length > 1) {
        initials += namesArray[1].charAt(0).toUpperCase();
      }
    } else {
      initials = fullName;
    }

    return {
      name,
      role: stylist_role !== null ? stylist_role : 'Stylist',
      initials,
    };
  }

  if (!isWithSame) {
    return {
      name: "I don't mind",
      role: 'Showing maximum number of time slots',
      initials: <img src={ICON_AVATAR} alt="Avatar icon I don't mind" className={CN.iconUser} />,
    };
  }

  return { name: '', role: '' };
};

type Props = {
  stylist: Stylist | 'any',
  service_id: number,
  itemPriceCost?: number,
  checked: boolean,
  favourite: string,
  onClick: (serviceId: number, stylist: Stylist) => void,
  onClickAnyStylist?: (serviceId: number) => void,
  noPrice?: boolean,
  variable_pricing: boolean,
  book_ncob_with_same: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  getStylistsForServiceWeekly: (saloneId: number, services: number[]) => void,
  stylistsForServiceWeekly: any,
  salonDetails: any,
};

type State = {
  favourite: string,
};

export class StylistItem extends React.Component<Props, State> {
  static defaultProps = {
    itemPriceCost: 0,
    onClickAnyStylist: () => {},
    noPrice: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      favourite: this.props.favourite === 'favourite' ? 'favourite' : '',
    };
    // if it's favourite, need to mock a click to bring the stylists for next service out
    if (this.props.checked && this.state.favourite === 'favourite') {
      this.onClick();
    }
  }

  onClick = () => {
    const { stylist } = this.props;

    if (stylist && stylist !== 'any') {
      const serviceId = stylist.service_id;

      this.props.onClick(serviceId, stylist);
    } else {
      const { onClickAnyStylist } = this.props;

      if (onClickAnyStylist) {
        onClickAnyStylist(this.props.service_id);
      }
    }
  };

  render() {
    const {
      stylist,
      itemPriceCost,
      checked,
      variable_pricing,
      noPrice,
      stylistsForServiceWeekly,
      book_ncob_with_same: isWithSame,
      salonDetails,
      service_id: serviceId,
      isDarkMode,
      isNeutralMode,
    } = this.props;

    const abbreviation = salonDetails.currency;

    const stylistRanges = calculateRanges(stylistsForServiceWeekly);
    const { stylist_id: stylistId } = asObject(stylist);
    const { max, min } = asObject(stylistRanges[serviceId]);
    const { name, role, initials } = determineNameAndRole({ stylist, isWithSame });

    if ((!stylist || stylist === 'any') && isWithSame) {
      return null;
    }

    const displayMin = min || 'Unknown';
    const displayMax = max || 'Unknown';

    const displayPrice =
      min === max && min !== null ? (
        currency({ value: min, abbreviation }) || 'Unknown'
      ) : (
        <>
          {currency({ value: displayMin, abbreviation })} - {currency({ value: displayMax, abbreviation })}
        </>
      );

    const componentClasses = cn({
      noPrice,
      favourite: this.state.favourite,
      [CN.component]: true,
      [CN.dark]: isDarkMode,
      [CN.neutral]: isNeutralMode,
    });

    return (
      <div
        data-bem="StylistItem"
        className={componentClasses}
        role="button"
        tabIndex={0}
        onClick={e => {
          if (
            (e.target.toString().indexOf('HTMLInputElement') < 0 &&
              e.target.toString().indexOf('HTMLSpanElement') > 0) ||
            e.target.toString().indexOf('HTMLDivElement') > 0
          ) {
            // prevent checkBox (input) to call onClick the second time
            this.onClick();
          }
        }}
      >
        <div className={CN.content}>
          <div className={CN.container}>
            <div className={CN.avatar}>{initials}</div>
            <div className={CN.info}>
              <span className={CN.name}>{name}</span>
              <span className={CN.role}>{role}</span>
              <span className={CN.price}>
                {stylistId ? (
                  <>
                    {variable_pricing && 'from '}
                    {currency({ value: itemPriceCost, abbreviation })}
                  </>
                ) : (
                  <>{displayPrice}</>
                )}
              </span>
            </div>
          </div>
          <div className={CN.status}>
            <Toggler selected={checked} tag="StylistItem__toggler" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  salonDetails: state.booking.salonDetails,
  stylistsForServiceWeekly: state.booking.stylistsForServiceWeekly,
});

const mapDispatchToProps = (dispatch: any) => ({
  getStylistsForServiceWeekly(salonId: number, services: number[]) {
    dispatch(getStylistsForServiceWeekly(salonId, services));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(StylistItem));
