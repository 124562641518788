import React from 'react';
import cn from 'classnames';

import { useTheme } from 'providers/Theme.provider';
import fromAnyToTrimmed from 'utils/converters/any/fromAnyToTrimmed.util';

import CN from './Icon.module.scss';

const Icon = ({ children, tag, variant, variantInvert, width }) => {
  const { isDarkMode } = useTheme();

  return (
    <div
      data-bem="Icon"
      data-tag={fromAnyToTrimmed(tag)}
      className={cn({
        [CN.component]: true,
        [CN.variantInvert]: variantInvert,
        [CN[`${variant}Variant`]]: variant,
        [CN[`${width}Width`]]: width,
        [CN.dark]: isDarkMode,
      })}
    >
      {children}
    </div>
  );
};

export default Icon;
