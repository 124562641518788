import ENV from 'config/env.conf';
import fromAnyToBool from 'utils/converters/fromAnyToBool.util';
import fromAnyToString from 'utils/converters/fromAnyToString';
import fromPropsToRouting from 'utils/converters/props/fromPropsToRouting.util';
import determineProductionConfiguration from 'utils/env/determineProductionConfiguration.util';
import nullBind from 'utils/functions/nullBind.util';
import asObject from 'utils/objects/asObject.util';

const IS_PROD = determineProductionConfiguration();
const FLAG_LOG_ALL = fromAnyToBool(ENV.REACT_APP_LOG_ALL);

const withOptions = ({ tag, method }) => (...args) => {
  if (FLAG_LOG_ALL || !IS_PROD || method === 'error' || method === 'warn' || method === 'info') {
    // eslint-disable-next-line no-console
    console[method]('[', tag, ']', ...args);
  }

  return args.pop();
};

// noinspection SpellCheckingInspection
const unignore = (tag, ...args) => {
  const promise = args.pop();

  if (promise instanceof Promise) {
    promise.catch(reason => {
      // eslint-disable-next-line no-console
      console.warn('[', tag, ']', ...args, reason);
    });
  }

  return promise;
};

const wrap = (tag, ...extra) => {
  const fn = extra.pop();
  if (!FLAG_LOG_ALL || IS_PROD) {
    return fn;
  }

  // noinspection UnnecessaryLocalVariableJS
  const wrapper = (...args) => {
    const result = fn(...args);
    // eslint-disable-next-line no-console
    console.log('[', tag, ']', ...extra, '📞 call', '(', ...args, ')', '->', result);
    return result;
  };

  const name = fromAnyToString(fn && fn.name) || 'unknown';
  Object.defineProperty(wrapper, 'name', { value: `wrapped(${name})` });

  return wrapper;
};

const routing = (tag, ...args) => {
  const props = args.pop();
  if (!FLAG_LOG_ALL || IS_PROD) {
    return props;
  }

  const { match, location, history, l, u, p, h, q, m, x, s } = fromPropsToRouting(props);

  // browser might try to be smart and sort them by first letter instead the order here, hence the numbers
  const data = { '1L': l, '2U': u, '3P': p, '4H': h, '5Q': q, '6M': m, '7X': x, '8S': s };

  // eslint-disable-next-line no-console
  console.log('[', tag, ']', ...args, '🔀 routing', data, { match, location, history });
  return props;
};

const lifecycle = (tag, ...args) => {
  const that = args.pop();
  if (!FLAG_LOG_ALL || IS_PROD) {
    return that;
  }

  const { props, state } = asObject(that);

  // eslint-disable-next-line no-console
  console.log('[', tag, ']', ...args, '♻️ lifecycle', { props, state });
  return that;
};

const stripecycle = (tag, ...args) => {
  const that = args.pop();
  if (!FLAG_LOG_ALL || IS_PROD) {
    return that;
  }

  const { stripe, elements } = asObject(that);

  // eslint-disable-next-line no-console
  console.log('[', tag, ']', ...args, '💳 stripecycle', { stripe, elements });
  return that;
};

// noinspection SpellCheckingInspection
const logger = tag => ({
  // generic console functionality
  trace: withOptions({ tag, method: 'trace' }),
  debug: withOptions({ tag, method: 'log' }),
  info: withOptions({ tag, method: 'info' }),
  warn: withOptions({ tag, method: 'warn' }),
  error: withOptions({ tag, method: 'error' }),
  // cpecialized log functions for common use caess
  lifecycle: nullBind(lifecycle, tag),
  routing: nullBind(routing, tag),
  stripecycle: nullBind(stripecycle, tag),
  unignore: nullBind(unignore, tag),
  wrap: nullBind(wrap, tag),
});

// noinspection JSUnusedGlobalSymbols
export default logger;
