// @flow

import { VERIFYBOOKING_FAILURE, VERIFYBOOKING_REQUEST, VERIFYBOOKING_SUCCESS } from 'actions/verifybooking';

export type VerifyBookingState = {
  isFetching: boolean,
  errorMessage: string | null,
  authenticated: boolean,
  response: string | null,
  bookingStatus: '',
};

const initialState = {
  isFetching: false,
  errorMessage: null,
  authenticated: false,
  response: null,
  bookingStatus: '',
};

function verifybooking(state: VerifyBookingState = initialState, action: Action) {
  switch (action.type) {
    case VERIFYBOOKING_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        response: null,
        errorMessage: null,
      });
    case VERIFYBOOKING_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        authenticated: action.authenticated || false,
        bookingStatus: 'verified',
      });
    case VERIFYBOOKING_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.errorMessage,
        bookingStatus: 'failed',
      });
    default:
      return state;
  }
}

export default verifybooking;
